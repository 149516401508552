import React, { useState } from 'react';
import { Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
const { Option } = Select;

export default function NewsSortSelect(props) {
    const { data, onChange } = props;
    const [open, setOpen] = useState(false);
    return (
        <Select
            suffixIcon={<CaretDownOutlined />}
            style={{ fontSize: '16px', borderRadius: '5px', overflow: 'hidden' }}
            defaultValue={data[0].value}
            onChange={onChange}
            onClick={() => setOpen(!open)}
            open={open}
        >
            {data.map((item, index) => {
                return (
                    <Option value={item.value} key={item.content + item.value + index}>
                        {item.content}
                    </Option>
                );
            })}
        </Select>
    );
}

import im2 from '../../../asset/img/2.jpg';
import im3 from '../../../asset/img/3.jpg';
import logo from '../../../asset/img/Logo.png';

// IntroFieldImg
import imgPhanphoithep from '../../../asset/img/002-4_Linh vuc kinh doanh/Thumbnails/Phanphoithep.jpg';
import imgChothuethep from '../../../asset/img/002-4_Linh vuc kinh doanh/Thumbnails/Chothuethep.jpg';
import imgGiacongthep from '../../../asset/img/002-4_Linh vuc kinh doanh/Thumbnails/Giacongthep.jpeg';
import imgNhaXuong from '../../../asset/img/002-4_Linh vuc kinh doanh/Thumbnails/NhaXuong.jpg';
import imgBatdongsan from '../../../asset/img/002-4_Linh vuc kinh doanh/Thumbnails/Batdongsan.jpg';
import field1 from '../../../asset/img/002-4_Linh vuc kinh doanh/Popup/Image-1.jpg';
import field2 from '../../../asset/img/002-4_Linh vuc kinh doanh/Popup/Image.jpg';
import field3 from '../../../asset/img/002-4_Linh vuc kinh doanh/Popup/Image-3.jpg';
import field4 from '../../../asset/img/002-4_Linh vuc kinh doanh/Popup/Image-4.jpg';
import field5 from '../../../asset/img/002-4_Linh vuc kinh doanh/Popup/Image-5.jpg';
import field6 from '../../../asset/img/002-4_Linh vuc kinh doanh/Popup/Image-6.jpg';
import field7 from '../../../asset/img/002-4_Linh vuc kinh doanh/Popup/Image-7.jpg';
import field8 from '../../../asset/img/002-4_Linh vuc kinh doanh/Popup/Image-8.jpg';
import SodoCongTyVI from '../../../asset/img/002-3 (So Do To Chuc)/OS_TienLen_CTY - VIE.png';
import SodoCongTyEN from '../../../asset/img/002-3 (So Do To Chuc)/OS_TienLen_CTY - ENG.png';
import SodoPhongBanVI from '../../../asset/img/002-3 (So Do To Chuc)/OS_TienLen_PHONG BAN - VIE.png';
import SodoPhongBanEN from '../../../asset/img/002-3 (So Do To Chuc)/OS_TienLen_PHONG BAN - ENG.png';

// Lich su hinh thanh
import TIENLEN_HISTORY_MOBILE_VIE from '../../../asset/img/002_Lich su hinh thanh/TIENLEN_HISTORY_MOBILE-VIE.jpg';
import TIENLEN_HISTORY_MOBILE_ENG from '../../../asset/img/002_Lich su hinh thanh/TIENLEN_HISTORY_MOBILE-ENG.jpg';

import TIENLEN_HISTORY_WEB_VIE from '../../../asset/img/002_Lich su hinh thanh/TIENLEN_HISTORY_WEB-VIE.jpg';
import TIENLEN_HISTORY_WEB_ENG from '../../../asset/img/002_Lich su hinh thanh/TIENLEN_HISTORY_WEB-ENG.jpg';

import prize1 from '../../../asset/img/001_Giai thuong/bang-cap-11_11.jpg';
import prize2 from '../../../asset/img/001_Giai thuong/bang-cap-11_04.jpg';
import prize3 from '../../../asset/img/001_Giai thuong/bang-cap-11_07.jpg';
import prize4 from '../../../asset/img/001_Giai thuong/bang-cap-11_05.jpg';

import standard from '../../../asset/img/tieuchuan.png';

// Intro thumbnail
import intro1 from '../../../asset/img/001_Thumbnail/thumbnail-mobile-01.png';
import intro2 from '../../../asset/img/001_Thumbnail/thumbnail-mobile-02.png';
import intro3 from '../../../asset/img/001_Thumbnail/thumbnail-mobile-03.png';
import intro4 from '../../../asset/img/001_Thumbnail/thumbnail-mobile-04.png';
import intro5 from '../../../asset/img/001_Thumbnail/thumbnail-mobile-05.png';
import intro6 from '../../../asset/img/001_Thumbnail/thumbnail-mobile-06.png';

export const IntroCommon = {
	vi: {
		path: '/intro',
		title: 'Giới thiệu',
		download: 'Tải về',
		backIntro: 'Về trang giới thiệu',
		titleMain: 'GIỚI THIỆU VỀ TẬP ĐOÀN',
		buttonDetail: 'Chi tiết',
		sliderIntro: [
			{
				path: '/intro/about',
				title: 'TỔNG QUAN VỀ TẬP ĐOÀN',
				background: intro1,
			},
			{
				path: '/intro/field',
				title: 'LĨNH VỰC KINH DOANH',
				background: intro2,
			},
			{
				path: '/intro/organization',
				title: 'SƠ ĐỒ TỔ CHỨC',
				background: intro3,
			},
			{
				path: '/intro/agency',
				title: 'ĐƠN VỊ TRỰC THUỘC',
				background: intro4,
			},
			{
				path: '/intro/achieve',
				title: 'THÀNH TÍCH - CHỨNG NHẬN',
				background: intro5,
			},
			{
				path: '/intro/values',
				title: 'LỊCH SỬ HÌNH THÀNH - TẦM NHÌN - GIÁ TRỊ CỐT LỖI',
				background: intro6,
			},
		]
	},
	en: {
		path: '/intro',
		title: 'About Us',
		download: 'Download',
		backIntro: 'Back to About Us',
		titleMain: 'About Us',
		buttonDetail: 'Details',
		sliderIntro: [
			{
				path: '/intro/about',
				title: 'General Introduction',
				background: intro1,
			},
			{
				path: '/intro/field',
				title: 'Business Areas',
				background: intro2,
			},
			{
				path: '/intro/organization',
				title: 'Organization Chart',
				background: intro3,
			},
			{
				path: '/intro/agency',
				title: 'Affiliated companies',
				background: intro4,
			},
			{
				path: '/intro/achieve',
				title: 'Awards and Honors',
				background: intro5,
			},
			{
				path: '/intro/values',
				title: 'Milestone, Vision, Core Values',
				background: intro6,
			},
		]
	}
};

// Intro About
export const IntroAboutCommon = {
	vi: {
		title: 'Tổng quan về Tập Đoàn',
		subtitle: 'Lời giới thiệu của Chủ Tịch Hội Đồng Quản Trị',
		IntroAboutHeading: [
			{
				name: 'Tên Công ty',
				main: 'Công ty Cổ Phần Tập Đoàn Thép Tiến Lên',
			},
			{
				name: 'Tên tiếng Anh',
				main: 'Tienlen Steel Corporation Joint Stock Company',
			},
			{
				name: 'Tên viết tắt',
				main: 'TLC',
			},
			{
				name: 'Mã cổ phiếu',
				main: 'TLH'
			},
			{
				name: 'Địa chỉ',
				main: 'G4A, Khu Phố 4, Phường Tân Hiệp, Thành Phố Biên Hòa, Tỉnh Đồng Nai',
			},
			{
				name: 'Điện thoại',
				main: '+84 251 3823187'
			},
			{
				name: 'Fax',
				main: '+84 251 3829043 – 3857591'
			},
			{
				name: 'Website',
				main: 'https://www.tienlensteel.com.vn'
			},
			{
				name: 'Email',
				main: 'info@tienlen.com.vn'
			}
		],
		IntroAboutMain: {
			main: [
				'Thành lập năm 1993, cổ phần hoá năm 2009, với chiến lược tập trung xây dựng, không ngừng mở rộng hoạt động, khẳng định tên tuổi. Công Ty Cổ Phần Tập Đoàn Thép Tiến Lên từ những năm đầu thành lập đến nay luôn là một trong những đơn vị phân phối dẫn đầu ngành thép công nghiệp đa dạng tại Việt Nam.',
				'Hiện nay với tiềm lực về con người, tài chính, cơ sở hạ tầng trải rộng từ Nam ra Bắc cùng với công nghệ tiên tiến và mạng lưới phân phối rộng, Tập Đoàn Thép Tiến Lên đã chinh phục thị trường trong nước và từng bước chinh phục thị trường các nước trong khu vực. Tập Đoàn định hướng sẽ là một trong những đơn vị dẫn đầu lĩnh vực gia công, cắt, cán, kéo kim loại trong tương lai gần. Ngoài ra, Tập đoàn cũng hướng tới đầu tư vào những ngành nghề đa dạng theo nhu cầu phát triển của đất nước, từ đó mang lại lợi ích lâu dài cho Tập Đoàn, cổ đông và cộng đồng.',
				'Có được thành tựu như hiện nay là nhờ vào sự quan tâm của Tập Đoàn Thép Tiến Lên dành cho lợi ích của khách hàng, của người lao động và của cổ đông. Vì thế, Tập Đoàn Thép Tiến Lên tự tin sẽ tiếp tục gặt hái thành công tiếp theo trong chiến lược kinh doanh của mình.',
				'<strong><em>Đối với khách hàng</em></strong>, từ những năm đầu thành lập, Tập đoàn đã luôn chú trọng đến chữ tín (uy tín), chất lượng đồng hành cùng phát triển và cam kết rằng sẽ luôn giữ vững thương hiệu để tạo ra lợi ích dài hạn cho đôi bên.',
				'<strong><em>Đối với cán bộ công nhân viên</em></strong>, Tập đoàn luôn đảm bảo và không ngừng phát triển một môi trường làm việc chuyên nghiệp với những chính sách đãi ngộ tương xứng để các cá nhân có thể tin tưởng, không ngừng tìm tòi và phát triển bản thân.',
				'<strong><em>Đối với cổ đông</em></strong>, Tập đoàn cam kết luôn thực hiện nghiêm túc việc minh bạch thông tin sử dụng nguồn vốn cẩn trọng và hiệu quả, lợi nhuận từ hoạt động kinh doanh được công khai minh bạch, đảm bảo kế hoạch đầu tư tăng trưởng và kế hoạch phân phối cổ tức hợp lý cho tất cả cổ đông.',
				'Với sức mạnh về tiềm lực tài chính tự có, cùng với việc các Định chế tài chính sẵn sàng tài trợ ưu đãi cho Tiến Lên trong thời gian dài, Tập đoàn có thể chủ động trong việc đề phòng biến động giá cả các mặt hàng do Tập đoàn cung cấp cũng như đầu tư các trang thiết bị hiện đại nhằm đáp ứng nhu cầu đối với các mặt hàng chất lượng cao ngày càng lớn tại thị trường trong và ngoài nước.',
				'Trên tinh thần quyết tâm xây dựng và giữ vững khẩu hiệu “Nâng giá trị, Vững niềm tin,” tôi tin rằng Tập đoàn sẽ đáp ứng được mong mỏi của Quý khách hàng, Quý cổ đông và toàn thể Cán Bộ Công Nhân Viên cũng như đảm bảo về việc thực hiện trách nhiệm với xã hội. Tôi xin cam kết rằng Tập đoàn sẽ luôn thay đổi phát triển không ngừng nghỉ và sát cánh cùng đất nước trong công việc phát triển ngày mai lên từng tầng cao mới.',
				'Trân trọng,'
			],
			writer: [
				'NGUYỄN MẠNH HÀ.',
				'Chủ tịch Hội đồng Quản trị,',
				'Công ty Cổ phần Tập đoàn Thép Tiến Lên'
			]
		}
	},
	en: {
		title: 'General Introduction',
		subtitle: 'Message from the Chairman',
		IntroAboutHeading: [
			{
				name: 'Company’s name',
				main: 'Tien Len Steel Corporation Joint Stock Company',
			},
			{
				name: 'Abbreviation',
				main: 'TLC',
			},
			{
				name: 'Stock symbol',
				main: 'TLH'
			},
			{
				name: 'Address',
				main: 'G4A, Quarter 4, Tan Hiep Ward, Bien Hoa City, Dong Nai Province, Vietnam',
			},
			{
				name: 'Tel',
				main: '+84 251 3823187'
			},
			{
				name: 'Fax',
				main: '+84 251 3829043 – 3857591'
			},
			{
				name: 'Website',
				main: 'https://www.tienlensteel.com.vn'
			},
			{
				name: 'Email',
				main: 'info@tienlen.com.vn'
			}
		],
		IntroAboutMain: {
			main: [
				`Founded in 1993 and became a joint-stock company in 2009, with the ceaseless effort to build, expand and 
affirm its name in the market, Tien Len Steel Corporation Joint Stock Company (Tien Len Group) is now 
one of the leading steel distributors in Vietnam.`,
				`With a talented team, secure finance, a broad distribution network, and advanced technology, Tien Len 
Group now has a substantial market share in the domestic market and is gradually expanding into other 
markets in the region. We aim to become one of the leading companies in machining, cutting, rolling, and 
steel distribution in the future. We also seek to invest in other industries that are essential to Vietnam’s 
growth, thereby bringing long-term benefits to our customers, shareholders, and the community.`,
				`The success thus far provides us a basis of confidence and motivation to continue achieving even better 
results. We have always and will continue to place the benefits of consumers, shareholders, and employees 
as our top priority.`,
				`<strong><em>For customers</em></strong>, since its establishment, Tien Len group has always complied with the trademarks of 
commitment, quality, and development.`,
				`<strong><em>For employees</em></strong>, Tien Len Group provides a professional working environment with an appropriate 
compensation policy and a place where every individual can trust, learn, and develop.`,
				`<strong><em>For shareholders</em></strong>, we apply strict regulations regarding the transparency of information and the use of 
funds assiduously and efficiently for the purpose of reinvestment for growth. We also strive to distribute 
dividends to all shareholders as planned.`,
				`Regarding risk-management, with a strong finance and the willingness of financial institutions to provide 
us with preferential financing, we have in hands the tools needed to measure and prevent risk in the event 
of an unforeseen price fluctuation. Meanwhile, we also reinvest a portion in modern equipment to meet the 
increasing demand for high-quality products in domestic and foreign markets.`,
				`With the determination to build and maintain the slogan of “To raise the value of trust,” we shall continue 
to meet the expectations of customers, shareholders, and all employees while conforming to the society's 
general requirements,`,
			],
			writer: [
				'Nguyen Manh Ha.',
				'Chairman',
				'Tien Len Steel Corporation Joint Stock Company'
			]
		}
	}
}

// Intro Value
export const IntroValueCommon = {
	vi: {
		historyMain: {
			mobile: TIENLEN_HISTORY_MOBILE_VIE,
			desktop: TIENLEN_HISTORY_WEB_VIE
		},
		titleBreacrum: 'Lịch sử hình thành - Tầm nhìn - Giá trị cốt lõi',
		title: 'Lịch sử hình thành - Tầm nhìn - Giá trị cốt lõi',
		history: 'LỊCH SỬ HÌNH THÀNH',
		vision: 'TẦM NHÌN',
		value: 'GIÁ TRỊ CỐT LÕI',
		IntroValueVision: [
			`Trở thành nhà cung cấp thép công nghiệp, dân dụng số 1 tại Việt Nam và là một trong những đơn vị dẫn đầu lĩnh vực 
            phân phối, gia công, cắt, cán, kéo kim loại, đặc biệt cho các mặt hàng thép tiêu chuẩn, chất lượng cao.`,
			`Trong đó, lĩnh vực trọng tâm là gia công và phân phối các sản phẩm về thép công nghiệp thông qua chiến lược phát triển bền vững dựa trên chuỗi lợi thế 
            cạnh tranh như cơ sở hạ tầng trải rộng cả nước và cốt lõi nhằm mang lại giá trị cao nhất cho các cổ đông, cán bộ công nhân viên của tập đoàn, 
            người tiêu dùng và cộng đồng xã hội. 
            Với đó là sự lớn mạnh hàng ngày của Tập đoàn, hướng tới việc trở thành một Tập đoàn đa ngành trong và ngoài nước trong tương lai.`,
		],
		IntroValueDiagram: {
			nature: [
				'TRUNG THỰC',
				'TRUNG THÀNH',
				'TẬN TỤY',
				'TRÍ TUỆ',
				'THÂN THIỆN',
			],
			generator: 'TRIẾT LÝ KINH DOANH',
			circle: 'TRUNG THỰC CỘNG ĐỒNG PHÁT TRIỂN'
		},
		IntroValueParagraph: [
			`Với phương châm hoạt động hiệu quả, uy tín chất lượng là hàng đầu, Tập đoàn đề cao sự trung 
            thực, trung thành và tận tụy trong mối quan hệ giữa Tập đoàn và đối tác, cổ đông cũng như cán 
            bộ công nhân viên. Chính những yếu tố đó đã mang lại cho Tập đoàn một nội lực vững mạnh, 
            ổn định và nhiệt huyết.`,
			`Về trí tuệ, Tập đoàn cũng phấn đấu hết mình để đảm bảo các cán bộ công nhân viên của Tập 
            đoàn có cơ hội được đào tạo chuyên môn, nghiệp vụ để hoạt động sản xuất kinh doanh không 
            ngừng mở rộng và phát triển, mang lại lợi ích tới cộng đồng xã hội nói chung và với đối tác, cổ
            đông nói riêng.`,
			`Về thân thiện, Tập đoàn luôn hướng tới sự hài hoà trong công việc phát triển quan hệ với khách 
            hàng, đối tác và cổ đông. Tập đoàn cam kết về sự chính trực, và trách nhiệm trong việc công 
            khai, minh bạch về thông tin cũng như trong việc tuân thủ các luật hiện hành trong nước và 
            quốc tế. Ngoài ra, Tập đoàn cũng luôn chú trọng tới xanh và sạch trong môi trường nhà máy, 
            nơi làm việc của Tập đoàn để hướng tới sự bền vững, thân thiện, an toàn cho cộng đồng và cho 
            từng sản phẩm mà Tập đoàn cung cấp.`,
		]
	},
	en: {
		historyMain: {
			mobile: TIENLEN_HISTORY_MOBILE_ENG,
			desktop: TIENLEN_HISTORY_WEB_ENG
		},
		titleBreacrum: 'Milestone, Vision, Core Values',
		title: 'Milestone, Vision, Core Values',
		history: 'Milestones',
		vision: 'Vision',
		value: 'Core Values',
		IntroValueVision: [
			`To become the Number 1 steel supplier in Vietnam and one of the leading companies in the field of 
            steel processing, cutting, and rolling…chiefly for products of high standard.`,
			`The area of focus primarily consists of the processing and distribution of industrial steel products 
            through sustainable development strategies based on the competitive advantage that brings the 
            maximum benefit to shareholders, employees, customers, and the society. We also seek to become a 
            leading conglomerate in the near future.`
		],
		IntroValueDiagram: {
			nature: [
				'Honesty',
				'Loyalty',
				'Commitment',
				'Intelligence',
				'Truthfulness',
			],
			generator: 'TRIẾT LÝ KINH DOANH',
			circle: 'Growth and Societal Development'
		},
		IntroValueParagraph: [
			`By placing an emphasis on effectiveness, prestige, and quality, we value honesty, loyalty, and 
            dedication in the relationship with our customers, partners, shareholders, and employees. These factors 
            have allowed us to foster a strong, stable, and enthusiastic team.`,
			`In terms of knowledge, we endeavor to ensure that our employees have the opportunity to receive 
            professional training. We believe knowledge is the key to leading us to new heights, which would 
            bring more benefits to our customers, partners, shareholders, employees, and the society.`,
			`In terms of friendliness, we aim for harmony in developing the relationship with customers, partners, 
            and shareholders. We are committed to the highest standards of integrity and responsibility, ensuring 
            the disclosure and transparency of information and compliance with national and international laws. In 
            addition, we place importance on maintaining a clean working environment at our factories and office 
            to promote sustainability, ecological balance, and safety for the community and each of the products 
            we provide.`
		]
	}
}

// Intro Field
export const IntroFieldCommon = {
	vi: {
		title: 'Lĩnh vực kinh doanh',
		subtitle: 'CÁC LĨNH VỰC KINH DOANH CỦA CÔNG TY CỔ PHẦN TẬP ĐOÀN THÉP TIẾN LÊN',
		IntroFieldMain: [
			{
				background: imgPhanphoithep,
				title: 'Phân phối thép'
			},
			{
				background: imgGiacongthep,
				title: 'Gia công thép các loại theo yêu cầu'
			},
			{
				background: imgChothuethep,
				title: 'Cho thuê thép các loại theo yêu cầu'
			},
			{
				background: imgNhaXuong,
				title: 'Cho thuê nhà xưởng'
			},
			{
				background: imgBatdongsan,
				title: 'Bất động sản'
			},
		],
		IntroFieldModal: {
			'Phân phối thép': {
				title: 'Phân phối thép',
				content: 'Tập đoàn thép Tiến Lên với các cửa hàng, nhà máy trên khắp cả nước luôn tự hào là một trong những nhà phân phối thép lớn nhất Việt Nam. Sản phẩm do Tập đoàn phân phối luôn đa dạng, như thép hình, thép tấm và thép cuộn cán nóng, thép xây dựng dân dụng…',
				img: [
					{
						src: field1,
					},
					{
						src: field8,
					},
				]
			},
			'Gia công thép các loại theo yêu cầu': {
				title: 'GIA CÔNG THÉP CÁC LOẠI THEO YÊU CẦU',
				content: 'Tập đoàn Thép Tiến Lên cũng cung cấp dịch vụ gia công cán cắt theo yêu cầu của khách hàng. Chúng tôi luôn chú trọng tới việc đổi mới máy móc cán cắt, vì thế Thép Tiến Lên là một trong những nhà phân phối thép tại Việt Nam. Với nhiều nhà máy phân xưởng và máy móc tiên tiến, Tập đoàn luôn có thể đáp ứng mọi yêu cầu của khách hàng với chất lượng tốt nhất.',
				img: [
					{
						src: field2,
					}
				]
			},
			'Cho thuê thép các loại theo yêu cầu': {
				title: 'Cho thuê thép các loại theo yêu cầu',
				content: 'Để tạo sự tiện ích cho nhu cầu của khách hàng, không lãng phí do nhu cầu chỉ sử dụng một lần hoặc trong những lúc khẩn cấp như lót, cầu đường và các công trình xây dựng, Tập đoàn có cung cấp dịch vụ cho thuê thép các loại như thép cọc cừ, thép tấm, thép hình…',
				img: [
					{
						src: field3,
					},
					{
						src: field4,
					},
					{
						src: field5,
					}
				]
			},
			'Cho thuê nhà xưởng': {
				title: 'Cho thuê nhà xưởng',
				content: 'Là tập đoàn được hình thành từ những năm đầu đất nước mở cửa đổi mới để phát triển kinh tế, hiện Tập đoàn hiện đang sở hữu nhiều khu đất ở những vị trí đắc địa tại những tỉnh thành lớn. Những khu đất này hiện được Tập đoàn đang sử dụng và cho thuê.',
				img: [
					{
						src: field6,
					},
					{
						src: field7,
					}
				],
				detail: 'Xưởng cho thuê tại quốc lộ 51, Long Thành, Đồng Nai, có lợi thế tiện ích về giao thông khi chỉ cách sân bay Long Thành 7 km và nằm trên đường cao tốc ra các cảng biển lớn.'
			},
			'Bất động sản': {
				title: 'Bất động sản',
				content: 'Với phương châm hoạt động luôn luôn phát triển và đổi mới. Tập Đoàn đang tiến tới lĩnh vực Bất Động Sản. Thị trường Bất Động Sản vốn là một môi trường đầu tư màu mỡ và an toàn cho tất cả mọi người, hướng đến nhu cầu về Bất Động Sản của cộng đồng, Tập Đoàn đang lên kế hoạch để mang đến những dự án tốt nhất nhằm đáp ứng nhu cầu đầu tư và sở hữu Bất Động Sản của toàn thể quý khách hàng.',
			}
		}
	},
	en: {
		title: 'Business Areas',
		subtitle: 'Business Areas of Tien Len Steel Corporation Joint Stock Company',
		IntroFieldMain: [
			{
				background: imgPhanphoithep,
				title: 'Steel distribution'
			},
			{
				background: imgGiacongthep,
				title: 'Steel processing'
			},
			{
				background: imgChothuethep,
				title: 'Steel leasing'
			},
			{
				background: imgNhaXuong,
				title: 'Factory/Warehouse leasing'
			},
			{
				background: imgBatdongsan,
				title: 'Real estate'
			},
		],
		IntroFieldModal: {
			'Steel distribution': {
				title: 'Steel distribution',
				content: `With branches and warehouse throughout Vietnam, we are proud to be one of the largest steel 
                distributors in the country. We provide a variety of steel products, including steel sections, steel plate, 
                hot rolled coil, building steel…`,
				img: [
					{
						src: field1,
					},
					{
						src: field8,
					},
				]
			},
			'Steel processing': {
				title: 'Steel processing',
				content: `We offer steel processing service based on request. We carry out regular maintenance as well as updates 
                in order to process steel of all types and levels of difficulty.`,
				img: [
					{
						src: field2,
					}
				]
			},
			'Steel leasing': {
				title: 'Steel leasing',
				content: `In order to accommodate special and urgent needs (i.e. one-time use) such as in the construction of 
                bridges and roads, we offer leasing service for all types of steel; namely steel pile, steel plate, steel 
                section etc.`,
				img: [
					{
						src: field3,
					},
					{
						src: field4,
					},
					{
						src: field5,
					}
				]
			},
			'Factory/Warehouse leasing': {
				title: 'Factory/Warehouse leasing',
				content: `With many years in operation, we currently own lands at major areas in big cities and prefectures. These 
                lands, all of which are close to port and highways are turned into factories/warehouses for use by us and 
                also for leasing.`,
				img: [
					{
						src: field6,
					},
					{
						src: field7,
					}
				],
				detail: `Warehouse currently open for leasing at national highway 51, Long Thanh, Dong 
                Nai province. It is situated on the highway itself and is only 7km away from Long Thanh 
                airport.`
			},
			'Real estate': {
				title: 'Real estate',
				content: `With the motto of always developing and innovating, we are striving to expand into the Real Estate
                market, known for being lucrative and a safe long-term investment option. We are currently planning 
                to bring the best real estate projects to our customers.`,
			}
		}
	}
}

// Intro Achieve

export const IntroAchieveCommon = {
	vi: {
		title: 'Thành tích - Chứng nhận',
		IntroAchieveMain: [
			{
				title: 'Tiêu chuẩn chất lượng',
				img: [
					{
						src: standard,
						width: 200,
					}
				]
			},
			{
				title: 'Các giải thưởng',
				img: [
					{
						src: prize1,
						width: 200,
					},
					{
						src: prize2,
						width: 180,
					},
					{
						src: prize3,
						width: 190,
					},
					{
						src: prize4,
						width: 325,
					}
				]
			},
		],
		IntroAchieveMainImg: {
			title: 'HÌNH ẢNH SỰ KIỆN',
			main: {
				sub: 'Chúc mừng Công ty Cổ phần tập đoàn thép Tiến Lên',
				main: 'Doanh nghiệp HÀNG ĐẦU THƯƠNG HIỆU MẠNH VIỆT NAM 2012',
				content: 'Môt số hình ảnh Tiến Lên Group Triển lãm tại Doanh nghiệp thương hiệu mạnh Việt Nam',
				img: [
					{
						image: im2,
						des: 'Ông Đào Đức Toàn thành viên Hội đồng quản trị Tiến Lên Group thay mặt công ty nhận giải'
					},
					{
						image: im3,
						des: 'Các doanh nghiệp đạt giải chụp hình lưu niệm'
					}
				]
			}
		}
	},
	en: {
		title: 'Awards and Honors',
		IntroAchieveMain: [
			{
				title: 'Quality certificate',
				img: [
					{
						src: standard,
						width: 200,
					}
				]
			},
			{
				title: 'Awards and Honors',
				img: [
					{
						src: prize1,
						width: 200,
					},
					{
						src: prize2,
						width: 180,
					},
					{
						src: prize3,
						width: 190,
					},
					{
						src: prize4,
						width: 325,
					}
				]
			},
		],
		IntroAchieveMainImg: {
			title: 'Event pictures',
			main: {
				sub: 'Congratulations Tien Len Steel Group Joint Stock Company',
				main: 'Tien Len Steel Corporation Joint Stock Company was honored to receive the award for the 2012 Vietnam’s Top Brand Award.',
				content: 'Some pictures of Tien Len Group Exhibition at Vietnam’s Top Brand Award',
				img: [
					{
						image: im2,
						des: 'Mr. Dao Duc Toan, member of the Board of Executives represented Tien Len Group.'
					},
					{
						image: im3,
						des: 'Group photo with representatives from firms that received the same award'
					}
				]
			}
		}
	}
}

export const IntroOrganizationCommon = {
	vi: {
		title: 'Sơ đồ tổ chức',
		chartCompany: 'SƠ ĐỒ TẬP ĐOÀN',
		chartCompanyMain: SodoCongTyVI,
		chartDepartment: 'SƠ ĐỒ PHÒNG BAN',
		chartDepartmentMain: SodoPhongBanVI
	},
	en: {
		title: 'Organization Chart',
		chartCompany: 'Corporate Chart',
		chartCompanyMain: SodoCongTyEN,
		chartDepartment: 'Department Structure of Tien Len Steel Corporation Joint Stock Company',
		chartDepartmentMain: SodoPhongBanEN
	}
}

export const IntroAgencyCommon = {
	vi: {
		title: 'Đơn vị trực thuộc',
		subtitle: 'CÁC ĐƠN VỊ TRỰC THUỘC CÔNG TY CỔ PHẦN TẬP ĐOÀN THÉP TIẾN LÊN',
		all: 'Tất cả',
		labelAll: 'TẤT CẢ CÁC ĐƠN VỊ TRỰC THUỘC',
		areaTitle: 'CÁC ĐƠN VỊ TẠI',
		filterAgency: [
			{
				logo,
				area: 'ĐỒNG NAI',
				name: 'CÔNG TY CỔ PHẦN TẬP ĐOÀN THÉP TIẾN LÊN (TRỤ SỞ CHÍNH)',
				address: 'G4A, Khu Phố 4, Phường Tân Hiệp, Thành phố Biên Hoà, Tỉnh Đồng Nai',
				phone: '+84 251 382 3187',
				fax: '+84 251 382 9043',
				email: 'info@tienlen.com.vn',
				coordinates: 'https://goo.gl/maps/wWij4uJGC4XkneGu7',
			},
			{
				area: 'HÀ NỘI',
				name: 'CÔNG TY TNHH MỘT THÀNH VIÊN PHÚC TIẾN',
				address: '307-309 đường Tam Trinh, Quận Hoàng Mai, TP. Hà Nội',
				phone: '+84 24 3634 2591',
				email: 'info@tienlen.com.vn',
				coordinates: 'https://goo.gl/maps/DafMvvW6HRdCqNr26'
			},
			{
				area: 'HƯNG YÊN',
				name: 'CÔNG TY TNHH SẢN XUẤT & THƯƠNG MẠI PHÚC TIẾN HƯNG YÊN',
				address: 'Thôn Nghĩa Trai, Xã Tân Quang, Huyện Văn Lâm, Tỉnh Hưng Yên',
				phone: '+84 221 399 1783',
				email: 'info@tienlen.com.vn',
				coordinates: 'https://goo.gl/maps/k9MjfQaKhjp9CCwM9',
			},
			{
				area: 'HẢI PHÒNG',
				name: 'CÔNG TY TNHH PHÚC TIẾN HẢI PHÒNG',
				address: 'Thôn Bắc Hà, Xã Bắc Sơn, Huyện An Dương, Thành phố Hải Phòng',
				phone: '+84 225 352 8901',
				email: 'info@tienlen.com.vn',
				coordinates: 'https://goo.gl/maps/1fkeJar6v27JwV9E7',
			},
			{
				area: 'ĐỒNG NAI',
				name: 'CỬA HÀNG KINH DOANH KIM KHÍ SỐ 2',
				address: 'A16, Xa Lộ Hà Nội, Phường Tân Hiệp, Thành phố Biên Hoà, Tỉnh Đồng Nai',
				email: 'info@tienlen.com.vn',
				coordinates: 'https://goo.gl/maps/Unq2bUnV7HBnCKqh9',
			},
			{
				area: 'ĐỒNG NAI',
				name: 'CÔNG TY CỔ PHẦN THÉP BẮC NAM',
				address: 'Khu Công Nghiệp Tam Phước, Xã Tam Phước, Thành phố Biên Hoà, Tỉnh Đồng Nai',
				email: 'info@tienlen.com.vn',
				coordinates: 'https://goo.gl/maps/buoSDRVZr7g3h8vM6',
			},
			{
				area: 'TP. HỒ CHÍ MINH',
				name: 'CÔNG TY TNHH THÉP TÂY NGUYÊN',
				address: 'Lô 12-14 đường Số 1, Khu Công Nghiệp Tân Tạo, Phường Tân Tạo A, Quận Bình Tân, TP. Hồ Chí Minh',
				phone: '+84 28 3750 7818',
				fax: '+84 28 3754 0238',
				email: 'info@tienlen.com.vn',
				coordinates: 'https://goo.gl/maps/xpb7FGXtS47cByku7',
			},
			{
				area: 'TP. HỒ CHÍ MINH',
				name: 'CÔNG TY TNHH SẢN XUẤT VÀ THƯƠNG MẠI ĐẠI PHÚC',
				address: 'Lô 8 đường Song Hành, Khu Công Nghiệp Tân Tạo, Phường Tân Tạo A, Quận Bình Tân, TP.Hồ Chí Minh',
				phone: '+84 28 3750 7818',
				fax: '+84 28 3754 0238',
				email: 'info@tienlen.com.vn',
				coordinates: 'https://goo.gl/maps/NpRJsKHnkr8HY7iLA',
			},
			{
				area: 'ĐÀ NẴNG',
				name: 'VĂN PHÒNG/CHI NHÁNH ĐÀ NẴNG',
				email: 'info@tienlen.com.vn'
			}
		]
	},
	en: {
		title: 'Affiliated companies',
		subtitle: 'Affiliated companies of Tien Len Steel Corporation Joint Stock Company',
		all: 'All',
		labelAll: 'All affiliated companies',
		areaTitle: 'Companies in',
		filterAgency: [
			{
				logo,
				area: 'DONG NAI',
				name: 'TIEN LEN STEEL CORPORATION JOINT STOCK COMPANY (Head Office)',
				address: 'G4A, Quarter 4, Tan Hiep Ward, Bien Hoa City, Dong Nai Province',
				phone: '+84 251 382 3187',
				fax: '+84 251 382 9043',
				email: 'info@tienlen.com.vn',
				coordinates: 'https://goo.gl/maps/wWij4uJGC4XkneGu7',

			},
			{
				area: 'HA NOI',
				name: 'PHUC TIEN STEEL COMPANY LIMITED',
				address: '307-309, Tam Trinh Street, Hoang Mai District, Ha Noi',
				phone: '+84 28 3750 7818',
				email: 'info@tienlen.com.vn',
				coordinates: 'https://goo.gl/maps/DafMvvW6HRdCqNr26'
			},
			{
				area: 'HUNG YEN',
				name: 'PHUC TIEN HUNG YEN TRADE AND MANUFACTURE',
				address: 'Nghia Trai Hamlet, Tan Quang Village, Van Lam District, Hung Yen Province',
				phone: '+84 221 399 1783',
				email: 'info@tienlen.com.vn',
				coordinates: 'https://goo.gl/maps/k9MjfQaKhjp9CCwM9',
			},
			{
				area: 'HAI PHONG',
				name: 'PHUC TIEN HAI PHONG LIMITED COMPANY',
				address: 'Bac Ha Hamlet, Bac Son Village, An Duong District, Hai Phong City',
				phone: '+84 225 352 8901',
				email: 'info@tienlen.com.vn',
				coordinates: 'https://goo.gl/maps/1fkeJar6v27JwV9E7',
			},
			{
				area: 'DONG NAI',
				name: 'STEEL SUPPLY STORE NO.2',
				address: 'A16, Ha Noi Highway, Tan Hiep Ward, Bien Hoa City, Dong Nai Province',
				email: 'info@tienlen.com.vn',
				coordinates: 'https://goo.gl/maps/GEsCxjB7dHtPfMbP7',
			},
			{
				area: 'DONG NAI',
				name: 'BAC NAM STEEL JOINT STOCK COMPANY',
				address: 'Tam Phuoc Industrial Park, Bien Hoa City, Dong Nai Province',
				email: 'info@tienlen.com.vn',
				coordinates: 'https://goo.gl/maps/buoSDRVZr7g3h8vM6',
			},
			{
				area: 'HO CHI MINH CITY',
				name: 'TAY NGUYEN CO., LTD',
				address: 'Lot 12-14, No.1 Street, Tan Tao Industrial Zone, Tan Tao A Ward, Binh Tan District, Ho Chi Minh City',
				phone: '+84 28 3750 7818',
				fax: '+84 28 3754 0238',
				email: 'info@tienlen.com.vn',
				coordinates: 'https://goo.gl/maps/xpb7FGXtS47cByku7',
			},
			{
				area: 'HO CHI MINH CITY',
				name: 'DAI PHUC TRADING AND PRODUCTION CO., LTD',
				address: 'Lot 8, Song Hanh Street, Tan Tao Industrial Zone, Tan Tao A Ward, Binh Tan District, Ho Chi Minh City',
				phone: '+84 28 3750 7818',
				fax: '+84 28 3754 0238',
				email: 'info@tienlen.com.vn',
				coordinates: 'https://goo.gl/maps/NpRJsKHnkr8HY7iLA',
			},
			{
				area: 'DA NANG',
				name: 'Da Nang branch',
				email: 'info@tienlen.com.vn'
			}
		]
	}
};

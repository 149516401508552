import React, { useEffect, useRef, useState } from 'react';
// import { Link } from 'react-router-dom';
import { Form, Select } from 'antd';
import { DownloadDocumentCommon } from './Model/DownloadDocumentModel';
import Helmet1 from '../Layout/Helmet';
import { Helmet } from 'react-helmet';
import Breadcrum from '../Layout/Breadcrum';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import background from '../../asset/img/img-9.jpg';
// import RelationSelect from './Control/RelationSelect';
import DownloadDocumentItem from './Control/DownloadDocumentItem';
import PaginationMain from '../Layout/PaginationMain';
import { DocumentService } from './Service/DocumentService';
import MpsRangePicker from '../MpsCotrols/MpsRangePicker/MpsRangePicker'
import { SearchOutlined } from '@ant-design/icons'

const { Option } = Select;

export default function DownloadDocumentPage(props) {
  const { language } = props;
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [data, setData] = useState(null);
  const [dataSort, setDataSort] = useState([]);
  const [, setPage] = useState(1);
  const [initSort, setInitSort] = useState(null);
  const [dataSearch, setDataSearch] = useState('');
  const [dataSearchShow, setDataSearchShow] = useState(null);

  const handleValueSort = (value) => {
    setDataSort(value);
  };
  const handleSearchChange = (e) => {
    // console.log(e.target.value)
    setDataSearch(e.target.value)
  }
  const handleSearchShow = (e) => {
    setDataSearchShow(e)
  }

  const handleChangePage = (currentPage) => {
    setPage(currentPage);
    const searchModel = { Status: true, CreatedAt: dataSort, Document_Title: dataSearchShow };
    const searchOption = { page: currentPage, limit: 10, sortAscending: false };
    DocumentService.search(searchModel, searchOption).then(result => {
      setData(result.result);
    }).catch(() => {
      // TO DO
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  };

  useEffect(async () => {
    window.scrollTo(0, 0);
    const searchModel = { Status: true, CreatedAt: dataSort, Document_Title: dataSearchShow, Language: language };
    const searchOption = { page: 1, limit: 10, sortAscending: false };
    const data = await DocumentService.search(searchModel, searchOption)
    setData(data.result);
    setPage(1);
    if (formRef.current) form.resetFields();
  }, [dataSort, dataSearchShow, form, language]);

  useEffect(() => {
    let dateNow = Date.now();
    dateNow = new Date(dateNow);
    const yearNow = dateNow.getFullYear();
    const sort = [
      {
        value: 0,
        interval: [],
        content: DownloadDocumentCommon[language].initSort,
      }
    ];
    for (let i = 1; i < 4; i++) {
      sort.push({
        content: yearNow - i * 1.0 + 1,
        interval: [
          `${yearNow - i * 1.0 + 1}-01-01`,
          `${yearNow - i * 1.0 + 1}-12-30`,
        ],
        value: i
      });
    }
    setInitSort(sort);
  }, [language]);
  return data && (
    <Helmet1 title={DownloadDocumentCommon[language].Titile_Helmet}>
      <Helmet>
        <link rel='canonical' href={`https://product.tienlensteel.com.vn/${language}/DownloadDocument`} />
      </Helmet>
      <Breadcrum data={[(DownloadDocumentCommon[language].Titile_Helmet)]}></Breadcrum>
      <TitleBreadcrum title={DownloadDocumentCommon[language].Titile_Helmet}></TitleBreadcrum>
      <div className='relation-generation relation-detail-generation' style={{ backgroundImage: `url(${background})` }}>
        <div className='relation-generation__overlay'></div>
        <div className='container'>
          {/* <div className='relation-generation__heading1' ></div> */}
          <div className='relation-generation__heading' >

            <div className='generation-rangedate__heading__item generation-rangedate__heading__item__input'>
              <label >{DownloadDocumentCommon[language].documentRangeTime}</label>
              <MpsRangePicker language={language} onChange={e => handleValueSort(e)} ></MpsRangePicker>
            </div>
            <div className='generation-search__heading__item' >
              <form onSubmit={(e) => { e.preventDefault(); handleSearchShow(dataSearch) }}>
                <input value={dataSearch} onChange={e => handleSearchChange(e)} type="text" placeholder={DownloadDocumentCommon[language].search} name="search" />
                <button onClick={() => handleSearchShow(dataSearch)} ><SearchOutlined /></button>
              </form>
            </div>

          </div>
          <div className='relation-container'>
            <div className='relation__main relation__main-detail'>
              <div className='relation__main__item'>
                {
                  (data.docs.length) > 0 ? data.docs.map((item, index) => {
                    return <DownloadDocumentItem key={index + item.Document_Title} data={item} language={language}></DownloadDocumentItem>;
                  }) :
                    <p className='relation__main__item__empty'>{DownloadDocumentCommon[language].empty}</p>
                }
              </div>
              <div className='relation__main__pagination'>
                {
                  data.docs.length > 0 ?
                    <PaginationMain current={data.page} total={data.total} pageSize={data.limit} onChange={handleChangePage}></PaginationMain>
                    : ''
                }
              </div>
            </div>
            <div className='relation__main__line-background relation__detail__line-background'></div>
          </div>
        </div>
      </div>
    </Helmet1>
  );
}

import React from 'react';
import { Layout } from 'antd';
import { Switch, useRouteMatch } from 'react-router-dom';
import ManageUserPage from '../Admin/ManageUser/ManageUserPage';
import AdminSider from '../Admin/Layout/AdminSider';
import AdminHeader from '../Admin/Layout/AdminHeader';
import AdminFooter from '../Admin/Layout/AdminFooter';
import ChangePasswordPage from '../Admin/ChangePassword/ChangePasswordPage';
import ManageNewsPage from './ManageNews/ManageNewsPage';
import ManageRecruitmentPage from './ManageRecruitment/ManageRecruitmentPage.jsx';
import { PrivateRoute } from './Common/PrivateRoute';
import ManageRelationPage from './ManageRelation/ManageRelationPage';
import NotFoundPage from './Common/NotFoundPage';
import ManageProductPage from './ManageProduct/ManageProductPage';
import ManageUnitPage from './ManageUnit/ManageUnitPage';
import ManageProductTypePage from './ManageProductType/ManageProductTypePage';
import ManageSpecificationPage from './ManageSpecification/ManageSpecificationPage';
import ManageDocumentPage from './ManageDocument/ManageDocumentPage'
import ManageBannerPage from './ManageBanner/ManageBannerPage'


import '../Admin/Layout/Css/LayoutAdmin.css';
import ManageIntroPostPage from './ManageIntroPost/ManageIntroPostPage';
import ManageIntroTypePost from './ManageIntroTypePost/ManageIntroTypePost';

const { Content } = Layout;

export default function AdminApp() {
  const { path } = useRouteMatch();
  return (
    <Layout>
      <AdminSider></AdminSider>
      <Layout>
        <AdminHeader></AdminHeader>
        <Content className="admin-content-container">
          <Switch>
            <PrivateRoute path={`${path}/changepassword`} component={ChangePasswordPage}></PrivateRoute>
            <PrivateRoute path={`${path}/manageuser`} component={ManageUserPage}></PrivateRoute>
            <PrivateRoute path={`${path}/managerelation`} component={ManageRelationPage}></PrivateRoute>
            <PrivateRoute path={`${path}/managenews`} component={ManageNewsPage} />
            <PrivateRoute path={`${path}/manage-recruitment`} component={ManageRecruitmentPage} />
            <PrivateRoute path={`${path}/manage-product`} component={ManageProductPage} />
            <PrivateRoute path={`${path}/manage-unit`} component={ManageUnitPage} />
            <PrivateRoute path={`${path}/manage-product-type`} component={ManageProductTypePage} />
            <PrivateRoute path={`${path}/manage-specification`} component={ManageSpecificationPage} />
            <PrivateRoute path={`${path}/manage-introductory-post`} component={ManageIntroPostPage} />
            <PrivateRoute path={`${path}/manage-introductory-type-post`} component={ManageIntroTypePost} />
            <PrivateRoute path={`${path}/manage-documents`} component={ManageDocumentPage} />
            <PrivateRoute path={`${path}/manage-banner`} component={ManageBannerPage} />

            <PrivateRoute path={``} component={NotFoundPage}></PrivateRoute>
          </Switch>
        </Content>
        <AdminFooter></AdminFooter>
      </Layout>
    </Layout>
  );
}

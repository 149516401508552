import React, { memo } from 'react';
import AdminMenu from '../Layout/AdminMenu';
import { Row, Col, Form, Input } from 'antd';
import ManageNewsSelect from './Control/ManageTypePostStatusSelect';
import MpsRangePicker from '../../MpsCotrols/MpsRangePicker/MpsRangePicker'
import { ManageTypePostContext, ManageTypePostLanguageContext } from './ManageIntroTypePost';
import MpsSortSelect from '../../MpsCotrols/MpsSelect/MpsSortSelect';
import ManagePostTypeSelect from './Control/ManagePostTypeSelect';
import ManageTypePostStyleShowSelect from './Control/ManageTypePostStyleShowSelect';

function ManageNewsSearch() {
  const language = React.useContext(ManageTypePostLanguageContext);
  return (
    <AdminMenu title={language.MANAGE_TYPE_POST_SEARCH_INFO}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 50 }}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_TYPE_POST_TITLE}
            name='Type_Post_Title'
          >
            <Input placeholder={language.MANAGE_TYPE_POST_TITLE} />
          </Form.Item>
        </Col>

        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_TYPE_POST_TITLE_ENG}
            name='Type_Post_Title_Eng'
          >
            <Input placeholder={language.MANAGE_TYPE_POST_TITLE_ENG} />
          </Form.Item>
        </Col>

        <Col lg={12} md={12} sm={24} xs={24}>
          <ManageTypePostStyleShowSelect
            name="Type_Post_Style_Show"
            label={language.MANAGE_TYPE_POST_INTRO_STYLE_SHOW}
            placeholder={language.MANAGE_TYPE_POST_INTRO_STYLE_SHOW_PLACEHOLDER}

          />
        </Col>

        <Col lg={12} md={12} sm={24} xs={24}>
          <ManagePostTypeSelect
            status={true}
            label={language.MANAGE_TYPE_POST_CATEGORY}
            name='Type_Post_Id'
            placeholder={language.MANAGE_TYPE_POST_CATEGORY_PLACEHOLDER} />
        </Col>

        <Col lg={12} md={12} sm={24} xs={24}>
          <ManageNewsSelect label={language.MANAGE_TYPE_POST_STATUS} name='Status'
            placeholder={language.MANAGE_TYPE_POST_STATUS_PLACEHOLDER} dataSource={['Hiển thị', 'Ẩn']} />
        </Col>

        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsRangePicker onChange={() => { }} language='vi' label={language.MANAGE_TYPE_POST_CREATED_DATE} name='CreatedDate' />
        </Col>

        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsSortSelect name='Type_Post_Sort' />
        </Col>
      </Row>
    </AdminMenu>
  );
}

export default memo(ManageNewsSearch);


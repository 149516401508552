import React, { useState, useCallback, useEffect } from 'react';
import { Button, Modal, Form, Tabs, Input,Col, Row, Select} from 'antd';
import ManageUnitStatusSelect from '../ManageUnit/Control/ManageUnitStatusSelect';
import { ManageSpecificationContext, ManageSpecificationLanguageContext } from './ManageSpecificationPage';
import { ManageSpecificationService } from './Service/ManageSpecification.Service';

import { initialFormField, manageSpecificationLength, specificationInitData } from './Model/ManageSpecification.Model';
import { momentDateTime } from '../Common/FormatCommon';
import { RESULT_CODES } from '../../../common/Constant.Common';
import { openErrorNotification, openSuccessNotification } from '../Common/MethodCommon';
import MpsDatePicker from '../../MpsCotrols/MpsDatePicker/MpsDatePicker';
import ProductSelect from '../ManageProduct/Control/ProductSelect.js'
import ProductCategorySelect from '../ManageProduct/Control/ProductCategorySelect.js'

import UnitSelect from '../ManageUnit/Control/UnitSelect.js'
import ProductTypeSelect from '../ManageProductType/Control/ProductTypeSelect.js'

const { TabPane } = Tabs;

function ManageSpecificationAdd() {
  const language = React.useContext(ManageSpecificationLanguageContext);
  const handleSearch = React.useContext(ManageSpecificationContext);

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [specificationData, setSpecificationData] = useState({ ...specificationInitData });
  const [activeTabKey, setActiveTabKey] = useState('generalInfo');
  const [weight, setWeight] = useState(0);
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [weightOverUnit, setWeightOverUnit] = useState(0);
  const [category, setCategory] = useState('');

  useEffect(()=>{
    form.resetFields();
    setLength(null)
    setWeight(null)
    setWeightOverUnit(null)
  }, [visible])

  useEffect(()=>{
    if(category=='NSP003'){
      if(length&&weightOverUnit){
      const weight1=length*weightOverUnit/1000;
      setWeight(weight1)
      form.setFieldsValue({Specification_Weight:weight1})
      }
      else {
        setWeight(null)
        form.setFieldsValue({Specification_Weight:null})
      }
    }
    else if(category=='NSP004'){
      if(height&&width&&length){
      const height1=height?height:1
      const width1=width?width:1
      const weight1=height1/1000*(width1/1000)*(length/1000)*7.85;
      setWeight(weight1)
      form.setFieldsValue({Specification_Weight:weight1})
      } else {
        setWeight(null)
        form.setFieldsValue({Specification_Weight:null})
      }
    }
    else if(category=='NSP005'){
      if(height&&width&&length){
      const height1=height?height:1
      const width1=width?width:1000
      const weight1=(height1/1000*(width1/1000)*(length/1000)*7.85)+(3*(width1/1000)*(length/1000));
      setWeight(weight1)
      form.setFieldsValue({Specification_Weight:weight1})
      } else {
        setWeight(null)
        form.setFieldsValue({Specification_Weight:null})
      }
    }
  },[length,width,height,weightOverUnit,category])
  
  const showModal = () => {
    form.setFieldsValue({ CreatedDate: momentDateTime(Date.now()) });
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const resetField = () => {
    form.resetFields();
    setSpecificationData({ ...specificationInitData });
  };

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };


  const handleFinish = () => {

    form.validateFields().then(values => {
      const specificationFormData = {}

      // Append values to form data
      const formObjectValues = Object.entries(values);
      formObjectValues.forEach(value => {
        specificationFormData[value[0]]= value[1];
      });

      return ManageSpecificationService.create(specificationFormData);
    })
      .then(result => {
        if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);

        handleSearch();
        resetField();
        hideModal();
        setActiveTabKey('generalInfo');
        openSuccessNotification({ title: 'Thông báo', message: 'Thêm mới thành công' });
      })
      .catch(error => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({ title: 'Thông báo', message: 'Thêm mới thất bại' });
      });

  };

  return (
    <>
      <Button onClick={showModal} className='admin-button__main admin-button__download-pdf'>
        <i className='fas fa-plus'></i>
        {language.MANAGE_SPECIFICATION_CREATE_NEW}
      </Button>
      <Modal
        title={language.MANAGE_SPECIFICATION_CREATE_NEW}
        visible={visible}
        width='80%'
        className='manage-specification-modal admin-modal'
        onCancel={hideModal}
        footer={
          [
            <Button
              key='submit'
              type='secondary'
              onClick={hideModal}
              className='admin-button__main admin-button__delete'
            >
              {language.MANAGE_SPECIFICATION_CANCEL}
            </Button>,
            <Button
              onClick={handleFinish}
              key='cancel'
              type='primary'
              className='admin-button__main admin-button__save'
            >
              {language.MANAGE_SPECIFICATION_SAVE}
            </Button>
          ]
        }
      >
        <Tabs className='manage-specification-tabs' activeKey={activeTabKey} onChange={handleTabChange}>
          <TabPane tab={language.MANAGE_SPECIFICATION_GENERAL_INFO} key='generalInfo'>
            <Form
              form={form} requiredMark={false} id='manage-specification-add-form'
              className='manage-specification-form'
              colon={false}
              name='manage-specification-add-form'
              labelCol={{ lg: { span: 8 }, md: { span: 24 } }}
              wrapperCol={{ lg: { span: 14 }, md: { span: 24 } }}
            >
              <Row>

        <Col lg={12} md={12} sm={24} xs={24}>

              <ProductCategorySelect
                form={form}
                name={initialFormField.Specification_Product_Category.Name}
                label={language.MANAGE_SPECIFICATION_PRODUCT_CATEGORY}
                placeholder={language.MANAGE_SPECIFICATION_PRODUCT_CATEGORY}
                required={initialFormField.Specification_Product_Category.Required}
                onChange={value=>setCategory(value)}
              />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>

              <ProductSelect
                form={form}
                name={initialFormField.Specification_Product.Name}
                label={language.MANAGE_SPECIFICATION_PRODUCT}
                placeholder={language.MANAGE_SPECIFICATION_PRODUCT}
                required={initialFormField.Specification_Product.Required}
              />
              </Col>
        {category=='NSP004'||category=='NSP005'?'':
        (<Col lg={12} md={12} sm={24} xs={24}>
              <ProductTypeSelect
                form={form}
                name={initialFormField.Specification_Product_Type.Name}
                label={language.MANAGE_SPECIFICATION_PRODUCT_TYPE}
                placeholder={language.MANAGE_SPECIFICATION_PRODUCT_TYPE}
                required={initialFormField.Specification_Product_Type.Required}
              />
              </Col>)
          }
        <Col lg={12} md={12} sm={24} xs={24}>
               <Form.Item
                label={language.MANAGE_SPECIFICATION_CODE}
                labelAlign='left'
                name={initialFormField.Specification_Code.Name}
                rules={[{ required: category=='NSP003'?initialFormField.Specification_Code.Required:false || false, message: '' }]}
              >
                <Input placeholder={language.MANAGE_SPECIFICATION_CODE} maxLength={200} rows={3} />
              </Form.Item>
              </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={language.MANAGE_SPECIFICATION_NAME}
                labelAlign='left'
                name={initialFormField.Specification_Name.Name}
                rules={[{ required: category=='NSP003'?initialFormField.Specification_Name.Required:false || false, message: '' }]}
              >
                <Input placeholder={language.MANAGE_SPECIFICATION_NAME} maxLength={200} rows={3} />
              </Form.Item>
              </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={language.MANAGE_SPECIFICATION_LENGTH}
                labelAlign='left'
                name={initialFormField.Specification_Length.Name}
                rules={[{ required: category=='NSP003'?initialFormField.Specification_Length.Required:false || false, message: '' }]}
              >

                <Select onChange={(value) => setLength(value)} placeholder={language.MANAGE_SPECIFICATION_LENGTH} allowClear={true}>
                  {manageSpecificationLength.length > 0 && manageSpecificationLength.map(data => {
                    return <Select.Option key={`${data.key}`} value={data.value}>
                      {data.name}
                    </Select.Option>;
                  })}
                </Select>
                {/* <Input placeholder={language.MANAGE_SPECIFICATION_LENGTH} onChange={e=>setLength(e.target.value)} maxLength={200} rows={3} /> */}
              </Form.Item>
              </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={language.MANAGE_SPECIFICATION_WIDTH}
                labelAlign='left'
                name={initialFormField.Specification_Width.Name}
                rules={[{ required: initialFormField.Specification_Width.Required || false, message: '' }]}
              >
                <Input placeholder={language.MANAGE_SPECIFICATION_WIDTH} onChange={e=>setWidth(e.target.value)} maxLength={200} rows={3} />
              </Form.Item>
              </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={language.MANAGE_SPECIFICATION_HEIGHT}
                labelAlign='left'
                name={initialFormField.Specification_Height.Name}
                rules={[{ required: initialFormField.Specification_Height.Required|| false, message: '' }]}
              >
                <Input placeholder={language.MANAGE_SPECIFICATION_HEIGHT} onChange={e=>setHeight(e.target.value)} maxLength={200} rows={3} />
              </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
              <UnitSelect
                form={form}
                name={initialFormField.Specification_Product_Unit.Name}
                label={language.MANAGE_SPECIFICATION_UNIT}
                placeholder={language.MANAGE_SPECIFICATION_UNIT}
                required={initialFormField.Specification_Product_Unit.Required}
              />
              </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={language.MANAGE_SPECIFICATION_WEIGHT_OVER_UNIT}
                labelAlign='left'
                name={initialFormField.Specification_Weight_Over_Unit.Name}
                rules={[{ required: category=='NSP003'?initialFormField.Specification_Weight_Over_Unit.Required:false || false, message: '' }]}
              >
                <Input placeholder={language.MANAGE_SPECIFICATION_WEIGHT_OVER_UNIT} onChange={e=>setWeightOverUnit(e.target.value)} maxLength={200} rows={3} />
              </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={language.MANAGE_SPECIFICATION_WEIGHT}
                labelAlign='left'
                name={initialFormField.Specification_Weight.Name}
                rules={[{ required: initialFormField.Specification_Weight.Required || false, message: '' }]}
              >
                <Input disabled={true} placeholder={weight}  maxLength={200} rows={3} />
              </Form.Item>
              </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
              <ManageUnitStatusSelect
                form={form}
                name='Status'
                label={language.MANAGE_SPECIFICATION_STATUS}
                placeholder={language.MANAGE_SPECIFICATION_STATUS_PLACEHOLDER}
                required={initialFormField.Status.Required}
                hasInitialValue={true}
              />
              </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
              <MpsDatePicker
                label={language.MANAGE_SPECIFICATION_CREATED_DATE}
                name={initialFormField.CreatedDate.Name}
                value={Date.now()}
                required={initialFormField.CreatedDate.Required}
                disabled={true}

              />
              </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={language.MANAGE_SPECIFICATION_CREATED_PERSON}
                labelAlign='left'
                name={initialFormField.CreatedPerson.Name}
                rules={[{ required: initialFormField.CreatedPerson.Required || false, message: '' }]}
              >
                <Input placeholder={language.MANAGE_SPECIFICATION_CREATED_PERSON} maxLength={200} rows={3} 
                disabled={true}

                />
              </Form.Item>
              </Col>
              </Row>

            </Form>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
}

export default ManageSpecificationAdd;


import React, { useCallback, useEffect, useState } from 'react';
import { Form, Button, Modal, Row, Col } from 'antd';
import ManageRelationSelect from './Control/ManageRelationSelect';
import ManageNewsDeleteConfirm from '../ManageNews/Control/ManageNewsDeleteConfirm';
import ManageRelationFileView from './Control/ManageRelationFileView';
import ManageRelationTextArea from './Control/ManageRelationTextArea';
import MpsDatePicker from '../../MpsCotrols/MpsDatePicker/MpsDatePicker';
import { RelationAttribute, RelationCommon, RelationInitData, RelationPlaceholder } from './Model/ManageRelationModel';
import { ManageRelationService } from './Service/ManageRelationService';
import { store } from 'react-notifications-component';
import { DELETE_RETURN_CODE_DESCRIPTION, RESULT_CODES, UPDATE_RETURN_CODE_DESCRIPTION } from '../../../common/Constant.Common';
import { openErrorNotification, openSuccessNotification } from '../Common/MethodCommon';
import MpsLanguageSelect from '../../MpsCotrols/MpsSelect/MpsLanguageSelect';

function ManageRelationEdit(props) {
  const [form] = Form.useForm();
  const [initModelRelation, setInitModelRelation] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileUpload, setFileUpload] = useState('');
  const [newFile, setNewFile] = useState(null);

  const onUpdate = () => {
    form.validateFields().then(values => {
      const formData = new FormData();

      formData.append('Relation_New_File', newFile);

      const formObjectValues = Object.entries(values);
      formObjectValues.forEach(value => {
        formData.append(value[0], value[1]);
      });
      formData.append('_id', props.data._id);
      formData.delete('Relation_File');
      formData.append('Relation_File', props.data.Relation_File);

      return ManageRelationService.update(formData);

    }).then(result => {
      const oldFile = newFile ? props.data.Relation_File : '';
      // Xoá file cũ nếu user đổi file
      ManageRelationService.deleteFile({ Relation_Old_File: oldFile });

      setNewFile(null);
      if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);


      openSuccessNotification({ title: 'Success', message: UPDATE_RETURN_CODE_DESCRIPTION[parseInt(result.returnCode, 10)] });
      props.signChanged();
      handleClose();
    }).catch((error) => {
      if (error !== RESULT_CODES.SUCCESS)
        return openErrorNotification({ title: 'Error', message: UPDATE_RETURN_CODE_DESCRIPTION[parseInt(error, 10)] });
    });
  };

  const onDelete = useCallback(async () => {
    const data = await ManageRelationService.deleted(props.data._id);
    props.signChanged();
    store.addNotification({
      title: data.returnCode !== RESULT_CODES.SUCCESS ? 'Error!' : 'Success',
      message: `${DELETE_RETURN_CODE_DESCRIPTION[parseInt(data.returnCode, 10)]}`,
      type: data.returnCode !== RESULT_CODES.SUCCESS ? 'danger' : 'success',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  }, [props]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    fileUpload && URL.revokeObjectURL(fileUpload);
    const fileUrl = process.env.REACT_APP_API_URL + `/relation/${props.data['Relation_File']}`;
    setFileUpload(fileUrl);
    setNewFile(null);
    setIsModalVisible(false);
  };

  const handleFileUpload = (file) => {
    fileUpload && URL.revokeObjectURL(fileUpload);
    const fileUrl = URL.createObjectURL(file);
    setNewFile(file);
    setFileUpload(fileUrl);
  };

  useEffect(() => {
    ManageRelationService.getRelation_Category().then(data => {
      const initData = {
        ...RelationInitData,
        Select:
        {
          ...RelationInitData.Select,
          Relation_Type: data.result.map((item) => {
            return {
              key: item.Relation_Category_Title,
              value: item._id,
              title: item.Relation_Category_Title,
            };
          }),
        }
      };
      const fileUrl = process.env.REACT_APP_API_URL + `/relation/${props.data['Relation_File']}`;
      setFileUpload(fileUrl);
      setInitModelRelation(initData);
    });
  }, [props.data]);

  return initModelRelation && (
    <>
      <p
        className='admin-button-detail'
        onClick={() => showModal()}
      >
        Xem chi tiết
      </p>

      <Modal
        title={RelationCommon.TitleMainModal}
        visible={isModalVisible}
        onOk={form.submit}
        onCancel={handleClose}
        width='80%'
        className='admin-modal'
        destroyOnClose={true}
        footer={[
          <ManageNewsDeleteConfirm
            key='delete'
            title='Bạn có chắc chắn muốn xóa dữ liệu này ?'
            deleteLabel='Xóa'
            agreeLabel='Có'
            disagreeLabel='Không'
            onDelete={onDelete}
          />,
          <Button
            type='primary'
            // form='form-modal-view'
            onClick={onUpdate}
            htmlType='submit'
            className='admin-button__main'>
            Lưu
          </Button>,
        ]}
      >
        <Form
          form={form}
          id='manage-recruitment-edit-form'
          name='manage-recruitment-edit-form'
        // initialValues={props.data}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col lg={12} md={12} sm={24} xs={24}>
              <ManageRelationTextArea
                name={'Relation_Title'}
                title={RelationAttribute['Relation_Title'][0]}
                placeholder={RelationPlaceholder['Relation_Title']}
                value={props.data.Relation_Title}
                typeInput={RelationAttribute['Relation_Title'][1]}
                require={true}
              >
              </ManageRelationTextArea>
              {/* <ManageRelationTextArea
                name='Relation_Title_ENG'
                title={RelationAttribute['Relation_Title_ENG'][0]}
                placeholder={RelationPlaceholder['Relation_Title_ENG']}
                typeInput={RelationAttribute['Relation_Title_ENG'][1]}
                value={props.data.Relation_Title_ENG}
                require={true}
              ></ManageRelationTextArea> */}
              <ManageRelationSelect
                name={'Relation_Type'}
                title={RelationAttribute['Relation_Type'][0]}
                placeholder={RelationPlaceholder['Relation_Type']}
                value={props.data.Relation_Type}
                typeInput={RelationAttribute['Relation_Type'][1]}
                select={initModelRelation.Select['Relation_Type']}
                require={true}
              >
              </ManageRelationSelect>
              <Row className='admin-filed__list' gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                <Col className='admin-filed__list__item' lg={8} md={24} sm={24} xs={24}>
                  <label>{RelationAttribute['Language'][0]} <span className={`admin-filed__list__item__required active`}>*</span></label>
                </Col>
                <Col className='admin-filed__list__item' lg={14} md={24} sm={24} xs={24}>
                  <MpsLanguageSelect
                    name={'Language'}
                    required={true}
                    hasInitialValue={true}
                    initialValue={props.data.Language}
                    form={form}
                  />
                </Col>
              </Row>
              <Col lg={12} md={12} sm={24} xs={24}>

              </Col>
              <ManageRelationSelect
                name={'Status'}
                title={RelationAttribute['Status'][0]}
                placeholder={RelationPlaceholder['Status']}
                value={props.data.Status}
                typeInput={RelationAttribute['Status'][1]}
                select={initModelRelation.Select['Status']}
                require={true}
              >
              </ManageRelationSelect>
              <Row className='admin-filed__list' gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                <Col className='admin-filed__list__item' lg={8} md={24} sm={24} xs={24}>
                  <label>{RelationAttribute['CreatedAt'][0]} <span className={`admin-filed__list__item__required active`}>*</span></label>
                </Col>
                <Col className='admin-filed__list__item' lg={14} md={24} sm={24} xs={24}>
                  <MpsDatePicker
                    name='CreatedAt'
                    message='Đây là trường bắt buộc'
                    value={props.data.CreatedAt}
                    required={true}
                    disabled={false}

                  />
                </Col>
              </Row>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <ManageRelationFileView
                name={'Relation_File'}
                title={RelationAttribute['Relation_File'][0]}
                placeholder={RelationPlaceholder['Relation_File']}
                value={fileUpload}
                typeInput={RelationAttribute['Relation_File'][1]}
                select={RelationInitData.Select['Relation_File']}
                handleFileUpload={handleFileUpload}
              />
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              {/* <ManageRelationFile
                fileUpload={fileUpload}
                name='Relation_File'
                title={'Cập nhật file PDF'}
                placeholder={RelationPlaceholder['Relation_File']}
                typeInput={RelationAttribute['Relation_File'][1]}
                handleFileUpload={handleFileUpload}
              /> */}
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default ManageRelationEdit;

import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import IntroPage from './IntroPage';
import './Css/Intro.css';
import IntroTypePostDetail from './IntroTypePostDetail';

export default function IntroApp(props) {
    let { path } = useRouteMatch();
    const { language } = props;
    return (
        <>
            <Switch>
                <Route path={`${path}`} render={(props) => <IntroPage {...props} language={language} />} exact></Route>
                <Route path={`${path}/:typeSlug`} render={(props) => <IntroTypePostDetail {...props} language={language} />}></Route>
            </Switch>
        </>
    )
}

import React, { useCallback, useState, useEffect } from 'react';
import { Form, Button } from 'antd';
import Helmet from '../../Layout/Helmet';
import AdminBreadcrum from '../Layout/AdminBreadcrumb';
import AdminTable from '../Layout/AdminTable';
import ManageBannerSearch from './ManageBannerSearch';
import ManageBannerAdd from './ManageBannerAdd';
import { ManageBannerService } from './Service/ManageBanner.Service';
import { RESULT_CODES } from '../../../common/Constant.Common';
import { columns } from './Model/ManageBanner.Model';
import { LANGUAGE_STATE } from './Language/Language';
import './Css/Style.css';

export const ManageBannerContext = React.createContext();
export const ManageNewsLanguageContext = React.createContext();

export default function ManageBannerPage() {
  const [form] = Form.useForm();
  const [searchOption, setSearchOption] = useState({ page: 1, limit: 10 });
  const [dataSource, setDataSource] = useState([]);
  const [language] = useState(LANGUAGE_STATE['vi']);

  const clearSearchForm = () => {
    form.resetFields();
    //form.setFieldsValue({ News_Category: null });
  };

  const handleSearch = useCallback(() => {
    const searchModel = form.getFieldsValue();
    const sortAscending = form.getFieldValue('News_Sort');
    ManageBannerService.search(searchModel, { ...searchOption, sortAscending }).then(result => {
      if (result.returnCode !== RESULT_CODES.SUCCESS) return;

      const { pages } = result.result;
      if (pages >= searchOption.page) {
        setDataSource(result.result);
      } else {
        setSearchOption(prevState => ({ ...prevState, page: 1 }));
      }
    })
      .catch(() => {
        // TO DO
      });
  }, [form, searchOption]);

  const handlePagination = (currentPage, pageSizes) => {
    setSearchOption({ page: currentPage, limit: pageSizes });
  };

  useEffect(() => {
    handleSearch();
  }, [handleSearch, searchOption]);
  return (
    <ManageNewsLanguageContext.Provider value={language}>
      <Helmet title={language.MANAGE_BANNER}>
        <AdminBreadcrum title={language.MANAGE_BANNER} subKey= 'sub3' ></AdminBreadcrum>
        <ManageBannerContext.Provider value={handleSearch}>
          <div className="admin-content">
            <Form form={form} onFinish={handleSearch} labelCol={{ lg: { span: 8 } }} wrapperCol={{ lg: { span: 14 } }} >
              <ManageBannerSearch/>
              <div className="admin-button admin-button-search">
                <Button type="secondary" onClick={clearSearchForm} className="admin-button__main admin-button__delete">
                  {language.MANAGE_BANNER_CLEAR_SELECT}
                </Button>
                <Button type="primary" htmlType='submit' onClick={handleSearch} className="admin-button__main admin-button__save">
                  {language.MANAGE_BANNER_SEARCH}
                </Button>
              </div>
            </Form>
            <div className="admin-button">
              <ManageBannerAdd language = {language}/>
            </div>

            <AdminTable
              dataSource={dataSource.docs || []}
              columns={columns(language)}
              current={searchOption.page}
              changePage={handlePagination}
              rowKey='_id'
              total={dataSource.total || 0}
            />
          </div>
        </ManageBannerContext.Provider>
      </Helmet>
    </ManageNewsLanguageContext.Provider>
  );
}

import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'antd';
import Helmet from '../../Layout/Helmet';
import AdminBreadcrum from '../Layout/AdminBreadcrumb';
import AdminMenu from '../Layout/AdminMenu';
import ChangePasswordField from './Control/ChangePasswordField';
import { store } from 'react-notifications-component';
import { CHANGE_PASSWORD_RETURN_CODE_DESCRIPTION, RESULT_CODES } from '../../../common/Constant.Common';
import { ChangePasswordService } from './Service/ChangePasswordService';
import ReCAPTCHA from 'react-google-recaptcha';

export default function ChangePasswordPage() {
  const [captcha, setCaptcha] = useState(null);
  const onFinish = async (values) => {
    if (!captcha) return;
    const result = await ChangePasswordService.changePassword(values.oldpassword, values.password, captcha);
    store.addNotification({
      title: result.returnCode !== RESULT_CODES.SUCCESS ? 'Error!' : 'Success',
      message: `${CHANGE_PASSWORD_RETURN_CODE_DESCRIPTION[parseInt(result.returnCode, 10)]}`,
      type: result.returnCode !== RESULT_CODES.SUCCESS ? 'danger' : 'success',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  };
  const onChangeCaptcha = (value) => {
    setCaptcha(value);
  };
  return (
    <Helmet title='Thay đổi mật khẩu'>
      <AdminBreadcrum title='Thay đổi mật khẩu'></AdminBreadcrum>
      <div className='admin-content' style={{ padding: 24, minHeight: 360 }}>
        <Form
          onFinish={onFinish}
        >
          <AdminMenu title='Thay đổi mật khẩu'>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={16}>
                <ChangePasswordField
                  name='oldpassword'
                  title='Mật khẩu hiện tại'
                  warning='Hãy nhập mật khẩu hiện tại của bạn'
                  require={true}
                  placeholder='Mật khẩu hiện tại'
                >
                </ChangePasswordField>
                <ChangePasswordField
                  name='password'
                  title='Mật khẩu mới'
                  warning='Hãy nhập mật khẩu mới của bạn'
                  require={true}
                  placeholder='Mật khẩu mới'
                >
                </ChangePasswordField>
                <ChangePasswordField
                  name='repassword'
                  title='Xác nhận mật khẩu mới'
                  warning='Hãy nhập mật khẩu xác nhận của bạn'
                  require={true}
                  placeholder='Xác nhận mật khẩu mới'
                  dependencies='password'
                >
                </ChangePasswordField>
                <ReCAPTCHA
                  className='captcha'
                  sitekey='6Lerft4dAAAAAPVeYYDo7oG_2CBiC3wLVjMiymln'
                  onChange={onChangeCaptcha}
                  hl='vi'
                />,
              </Col>
            </Row>
          </AdminMenu>
          <div className='admin-button admin-button-search'>
            <Button type='primary' htmlType='submit' className='admin-button__main admin-button__save'>
              Lưu
            </Button>
          </div>
        </Form>
      </div>
    </Helmet>
  );
}

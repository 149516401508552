import { formatDate, formatDateTime } from '../../Common/FormatCommon';
import ManageRecruitmentEdit from '../ManageRecruitmentEdit';

export const initialFormField = {
  Recruitment_Title: {
    Name: 'Recruitment_Title',
    Required: true
  },
  Recruitment_Title_Eng: {
    Name: 'Recruitment_Title_Eng',
    Required: true
  },
  Recruitment_Type: {
    Name: 'Recruitment_Type',
    Required: true
  },
  Recruitment_Address: {
    Name: 'Recruitment_Address',
    Required: true
  },
  Recruitment_Quantity: {
    Name: 'Recruitment_Quantity',
    Required: true
  },
  Recruitment_Detail_Info: {
    Name: 'Recruitment_Detail_Info',
    Required: true
  },
  Recruitment_Detail_Info_Eng: {
    Name: 'Recruitment_Detail_Info_Eng',
    Required: true
  },
  Recruitment_ExpiredDate: {
    Name: 'Recruitment_ExpiredDate',
    Required: true
  },
  Language: {
    placeholder: 'Chọn ngôn ngữ',
    required: true,
    label: 'Ngôn ngữ',
    name: 'Language'
  },
  CreatedDate: {
    Name: 'CreatedDate',
    Required: true,
  },
  Status: {
    Name: 'Status',
    Required: true
  },
};

export const recruitmentInitData = {
  _id: null,
  Recruitment_Title: null,
  Recruitment_Title_Eng: null,
  Recruitment_Type: null,
  Recruitment_Address: null,
  Recruitment_Quantity: null,
  Recruitment_Detail_Info: null,
  Recruitment_Detail_Info_Eng: null,
  Recruitment_ExpiredDate: null,
  Language: '',
  Status: null,
  CreatedDate: null,
  UpdatedDate: null,
};

export const columns = (language) => {
  return [
    {
      width: 400,
      title: language.MANAGE_RECRUITMENT_SEARCH_TITLE,
      dataIndex: 'Recruitment_Title',
      key: 'Recruitment_Title'
    },
    {
      width: 200,
      title: language.MANAGE_RECRUITMENT_SEARCH_TYPE,
      dataIndex: 'Recruitment_TypeObject',
      key: 'Recruitment_TypeObject',
      render: (value) => {
        return value.Recruitment_Type_Name;
      }
    },
    {
      width: 200,
      title: language.MANAGE_RECRUITMENT_CREATED_DATE,
      dataIndex: 'CreatedDate',
      key: 'CreatedDate',
      render: (value) => {
        return formatDateTime(value);
      }
    },
    {
      width: 200,
      title: language.MANAGE_RECRUITMENT_EXPIRED_DATE,
      dataIndex: 'Recruitment_ExpiredDate',
      key: 'Recruitment_ExpiredDate',
      render: (value) => {
        return formatDate(value);
      }
    },
    {
      title: language.MANAGE_RECRUITMENT_LANGUAGE,
      dataIndex: 'Language',
      key: 'Language',
      render: (value) => {
        if (!value) {
          return ''
        }
        if (value === 'en') {
          return 'Tiếng Anh'
        }
        return 'Tiếng Việt'
      }
    },
    {
      title: language.MANAGE_RECRUITMENT_STATUS,
      dataIndex: 'Status',
      key: 'Status',
      render: (value) => {
        return value === false ? 'Ẩn' : 'Hiển thị';
      }
    },
    {
      title: '',
      dataIndex: '_id',
      render: (value) => {
        return <ManageRecruitmentEdit _id={value} />;
      }
    }
  ];
};



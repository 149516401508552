import React from 'react';
import AdminMenu from '../Layout/AdminMenu';
import { Row, Col, Form, Input } from 'antd';
import ManageUnitStatusSelect from '../ManageUnit/Control/ManageUnitStatusSelect';
import { ManageProductTypeLanguageContext } from './ManageProductTypePage';
import MpsRangePicker from '../../MpsCotrols/MpsRangePicker/MpsRangePicker';
import MpsSortSelect from '../../MpsCotrols/MpsSelect/MpsSortSelect';

function ManageProductTypeSearch() {
  const language = React.useContext(ManageProductTypeLanguageContext);
  return (
    <AdminMenu title={language.MANAGE_PRODUCT_TYPE_SEARCH_INFO}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Col xl={12} lg={18} md={18} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_PRODUCT_TYPE_CODE}
            name='Product_Type_Code'
          >
            <Input placeholder={language.MANAGE_PRODUCT_TYPE_CODE} />
          </Form.Item>
        </Col>
        <Col xl={12} lg={18} md={18} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_PRODUCT_TYPE_NAME_ENG}
            name='Product_Type_Name_Eng'
          >
            <Input placeholder={language.MANAGE_PRODUCT_TYPE_NAME_ENG} />
          </Form.Item>
        </Col>
        <Col xl={12} lg={18} md={18} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_PRODUCT_TYPE_NAME_VIE}
            name='Product_Type_Name'
          >
            <Input placeholder={language.MANAGE_PRODUCT_TYPE_NAME_VIE} />
          </Form.Item>
        </Col>
        <Col xl={12} lg={18} md={18} sm={24} xs={24}>
          <ManageUnitStatusSelect label={language.MANAGE_PRODUCT_TYPE_STATUS} name='Status'
            placeholder={language.MANAGE_PRODUCT_TYPE_STATUS} />
        </Col>
        <Col xl={12} lg={18} md={18} sm={24} xs={24}>
          <MpsRangePicker onChange={()=>{}} language='vi' label={language.MANAGE_PRODUCT_TYPE_CREATED_DATE} name='CreatedDate' />
        </Col>
        <Col xl={12} lg={18} md={18} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_PRODUCT_TYPE_CREATED_PERSON}
            name='CreatedPerson'
          >
            <Input placeholder={language.MANAGE_PRODUCT_TYPE_CREATED_PERSON} />
          </Form.Item>
        </Col>
        <Col xl={12} lg={18} md={18} sm={24} xs={24}>
          <MpsRangePicker onChange={()=>{}} language='vi' label={language.MANAGE_PRODUCT_TYPE_UPDATED_DATE} name='UpdatedDate' />
        </Col>
        <Col xl={12} lg={18} md={18} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_PRODUCT_TYPE_UPDATED_PERSON}
            name='UpdatedPerson'
          >
            <Input placeholder={language.MANAGE_PRODUCT_TYPE_UPDATED_PERSON} />
          </Form.Item>
        </Col>
        <Col xl={12} lg={18} md={18} sm={24} xs={24}>
          <MpsSortSelect name='Product_Type_Sort' />
        </Col>
      </Row>
    </AdminMenu>
  );
}

export default ManageProductTypeSearch

import React from 'react';
import AdminMenu from '../Layout/AdminMenu';
import { Row, Col } from 'antd';
import ManageDocumentInput from './Control/ManageDocumentInput';
import ManageDocumentSelect from './Control/ManageDocumentSelect';
import { LayoutAdminCommon } from '../Layout/Model/LayoutAdminModel';
import MpsRangePicker from '../../MpsCotrols/MpsRangePicker/MpsRangePicker';
import MpsSortSelect from '../../MpsCotrols/MpsSelect/MpsSortSelect';
import MpsLanguageSelect from '../../MpsCotrols/MpsSelect/MpsLanguageSelect';

export default function ManageDocumentSearch(props) {
  const { DocumentAttribute, DocumentPlaceholder, initModelDocument } = props;
  return (
    <AdminMenu title={LayoutAdminCommon.titleMenuSearch}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 50 }}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <ManageDocumentInput
            name='Document_Title'
            title={DocumentAttribute['Document_Title'][0]}
            placeholder={DocumentPlaceholder['Document_Title']}
            typeInput={DocumentAttribute['Document_Title'][1]}
          >
          </ManageDocumentInput>
        </Col>
        {/* <Col lg={12} md={12} sm={24} xs={24}>
          <ManageDocumentInput
            name='Document_Title_ENG'
            title={DocumentAttribute['Document_Title_ENG'][0]}
            placeholder={DocumentPlaceholder['Document_Title_ENG']}
            typeInput={DocumentAttribute['Document_Title_ENG'][1]}
          >
          </ManageDocumentInput>
        </Col> */}
        <Col lg={12} md={12} sm={24} xs={24}>
          <Row className='admin-filed__list' gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Col className='admin-filed__list__item' lg={8} md={24} sm={24} xs={24}>
              <label>{DocumentAttribute['Language'][0]}<span className={`admin-filed__list__item__required ${props.require ? 'active' : ''}`}>*</span></label>
            </Col>
            <Col className='admin-filed__list__item' lg={14} md={24} sm={24} xs={24}>
              <MpsLanguageSelect name='Language' />
            </Col>
          </Row>
        </Col>

        <Col lg={12} md={12} sm={24} xs={24}>
          <ManageDocumentSelect
            name='Status'
            title={DocumentAttribute['Status'][0]}
            placeholder={DocumentPlaceholder['Status']}
            typeInput={DocumentAttribute['Status'][1]}
            select={initModelDocument.Select['Status']}
          >
          </ManageDocumentSelect>
        </Col>
        {/* <Col lg={12} md={12} sm={24} xs={24}>
          <ManageDocumentSelect
            name='Relation_Type'
            title={DocumentAttribute['Relation_Type'][0]}
            placeholder={DocumentPlaceholder['Relation_Type']}
            typeInput={DocumentAttribute['Relation_Type'][1]}
            select={initModelDocument.Select['Relation_Type']}
          >
          </ManageDocumentSelect>
        </Col> */}
        <Col lg={12} md={12} sm={24} xs={24}>
          <Row className='admin-filed__list' gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Col className='admin-filed__list__item' lg={8} md={24} sm={24} xs={24}>
              <label>{DocumentAttribute['CreatedAt'][0]} <span className={`admin-filed__list__item__required ${props.require ? 'active' : ''}`}>*</span></label>
            </Col>
            <Col className='admin-filed__list__item' lg={14} md={24} sm={24} xs={24}>
              <MpsRangePicker onChange={() => { }} language='vi' name='CreatedAt' />
            </Col>
          </Row>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Row className='admin-filed__list' gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Col className='admin-filed__list__item' lg={8} md={24} sm={24} xs={24}>
              <label>{'Sắp xếp'} <span className={`admin-filed__list__item__required ${props.require ? 'active' : ''}`}>*</span></label>
            </Col>
            <Col className='admin-filed__list__item' lg={14} md={24} sm={24} xs={24}>
              <MpsSortSelect name='Document_Sort' label='' />
            </Col>
          </Row>
        </Col>
      </Row>
    </AdminMenu>
  );
}

import React from 'react'
import { contactMedia } from './Model/LayoutModel';
import { Link } from 'react-router-dom';
import { HomeCommon } from '../Home/Model/HomeModel';
import { DownloadOutlined  } from '@ant-design/icons';
import '../Layout/Css/layout.css'
import '../Home/Css/Home.css'

export default function MessageContact(props) {
    const handleScrollTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }
    return (
        <div className="message-contact">
            <a href={`mailto:${contactMedia.email}`}>
                <div className="message-contact__item message-contact__item--mail">
                    <i className="fas fa-envelope"></i>
                </div>
            </a>
            <a href={`tel:${contactMedia.phone}`}>
                <div className="message-contact__item message-contact__item--phone">
                <i className="fas fa-phone"></i>
                </div>
            </a>
            <div className="message-contact__item" onClick={handleScrollTop}>
                <i className="fas fa-chevron-up"></i>
            </div>
        </div>
    )
}

export const RelationCommon = {
    vi:{
        path: '/relation',
        title: "Quan hệ cổ đông",
        download: "Tải về",
        sort: "Sắp xếp theo năm",
        empty: "Tài liệu đang cập nhật",
        seeAll: "Xem tất cả",
        initSort:"Tất cả",
        documentRangeTime:"Các tài liệu trong thời gian",
        search:"Nhập tiêu đề cần tìm ",
        detailTitle: 'CÔNG BỐ THÔNG TIN'
    },
    en:{
        path: '/relation',
        title: "Investor Relations",
        download: "Download",
        sort: "Sort by year",
        empty: "Document is updating",
        seeAll: "View all",
        initSort:"All",
        documentRangeTime:"Time range",
        search:"Enter title or content ... ",
        detailTitle: 'IR NEWS'
    }
}


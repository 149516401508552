import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
const initDataLanguage = [
  {
    value: 'vi',
    label: 'Tiếng Việt'
  },
  {
    value: 'en',
    label: 'Tiếng Anh'
  },
];

function MpsLanguageSelect(props) {
  const [dataSource, setDataSource] = useState(initDataLanguage);

  useEffect(() => {
    if (!props.form) return;

    props.form.resetFields();

  }, [props.initialValue, props.form]);

  return (
    <Form.Item
      name={props.name ? props.name : 'Language'}
      label={props.label ? props.label : ''}
      labelAlign='left'
      colon={false}
      initialValue={props.hasInitialValue ? props.initialValue ?? dataSource[0].value : null}
      rules={[{ required: props.required || false, message: `${props.warning || ''}` }]}
      className={`admin-filed ${props.className || ''}`}
    >
      <Select placeholder={props.placeholder || ''} allowClear={true}>
        {dataSource.length > 0 && dataSource.map((data, index) => {
          return <Select.Option key={`option${index}`} value={data.value}>
            {data.label}
          </Select.Option>;
        })}
      </Select>

    </Form.Item>
  );
}

export default MpsLanguageSelect;

import React from 'react'
import { CloseOutlined } from "@ant-design/icons"
import { Row } from 'antd';
export default function IntroModal(props) {
    const { data, toggle, language } = props;

    return (
        <div className="intro-modal-overlay" >
            <div className="intro-modal">
                <div className="intro-modal__heading">
                    <div className="intro-modal__item">
                        <div className="intro-modal__heading__circle"></div>
                        <h2>{language === "vi" ? data.Post_Intro_Title : data.Post_Intro_Title}</h2>
                    </div>
                    <div className="intro-modal__item">
                        <CloseOutlined onClick={toggle} />
                    </div>
                </div>
                <div className="intro-modal__main">
                    <p className="intro-modal__main__content">
                        <div dangerouslySetInnerHTML={{ __html: language === "vi" ? data.Post_Intro_Content : data.Post_Intro_Content }} />
                    </p>
                    {
                        data.Post_Intro_Images ?
                            <Row className="intro-modal__main__img-container">
                                {data.Post_Intro_Images.map((item, index) => {
                                    return (
                                        // <Col lg={24 / data.img.length} md={(24 / (data.img.length - 1))} sm={24} xs={24} key={index}>
                                        <div className="intro-modal__main__img" key={index}
                                            style={{
                                                backgroundImage: `url(${process.env.REACT_APP_API_URL + "/" + item.url})`,
                                                maxWidth: `${item.width ? `${item.width}px` : "unset"}`,
                                                margin: `${item.width ? "0 auto" : "12px"}`
                                            }}>
                                        </div>
                                        // </Col>
                                    )
                                })}
                            </Row>
                            :
                            ""
                    }
                    <p className="intro-modal__main__detail">

                    </p>
                </div>
                <div className="intro-modal--background"></div>
            </div>
        </div>
    )
}

import React, { useState, useCallback } from 'react';
import { Button, Modal, Form, Tabs, Input } from 'antd';
import { Type_PostFormInitialField, Type_PostInitData } from './Model/ManageIntroTypePost.Model';
import MpsRichTextEditor from '../../MpsCotrols/MpsRichTextEditor/MpsRichTextEditor';
import { RESULT_CODES } from '../../../common/Constant.Common';
import { openSuccessNotification, openErrorNotification } from '../Common/MethodCommon';
import { ManageTypePostContext, ManageTypePostLanguageContext } from './ManageIntroTypePost';
import { momentDateTime } from '../Common/FormatCommon';
import ManageTypePostStatusSelect from './Control/ManageTypePostStatusSelect';
import { ManageTypePostService } from './Service/ManageTypePost.Service';
import MpsUploadImageTypeIntroPost from '../../MpsCotrols/MpsUploadImage/MpsUploadImageTypeIntroPost ';
import MpsDatePicker from '../../MpsCotrols/MpsDatePicker/MpsDatePicker';
import ManageTypePostStyleShowSelect from './Control/ManageTypePostStyleShowSelect';

const { TabPane } = Tabs;

function ManageIntroPostAdd() {
  //use context-----------
  const language = React.useContext(ManageTypePostLanguageContext);
  const handleSearch = React.useContext(ManageTypePostContext);
  //useform---------------------------------
  const [form] = Form.useForm();
  //use satte----------------------------------------
  const [visible, setVisible] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('generalInfo');
  const [typePostData, setTypePostData] = useState({ ...Type_PostInitData });
  //modal status-----------------------------------------------
  const showModal = () => {
    form.setFieldsValue({ CreatedDate: momentDateTime(Date.now()) });
    setVisible(true);
  };
  const hideModal = () => {
    setVisible(false);
  };
  //handle tab change------------------------------------------------------
  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };
  //handle image change--------------------------------------------------------
  const handleImagesChange = useCallback((images, keyImageData) => {
    const model = typePostData;
    model[keyImageData] = images;
    setTypePostData(model);
  }, []);
  //handle text change-------------------------------------------------------------------
  const handleAdditionalInfoChange = useCallback((text) => {
    setTypePostData(prevState => ({ ...prevState, Type_Post_AdditionalInfo: text }));
  }, []);
  const handleAdditionalInfoEngChange = useCallback((text) => {
    setTypePostData(prevState => ({ ...prevState, Type_Post_AdditionalInfo_Eng: text }));
  }, []);
  // handle create new type post intro---------------------
  const handleFininsh = () => {
    form.validateFields().then(values => {
      const formData = new FormData();
      // Append images to form data
      typePostData.Type_Post_Image_Pc.forEach(image => {
        formData.append('Type_Post_Image_Pc', image.originFileObj);
      });
      typePostData.Type_Post_Image_Mobile.forEach(image => {
        formData.append('Type_Post_Image_Mobile', image.originFileObj);
      });
      typePostData.Type_Post_Image_Tablet.forEach(image => {
        formData.append('Type_Post_Image_Tablet', image.originFileObj);
      });

      // Append values to form data
      const formObjectValues = Object.entries(values);
      formObjectValues.forEach(value => {
        formData.append(value[0], value[1]);
      });
      formData.append('Type_Post_AdditionalInfo', typePostData.Type_Post_AdditionalInfo);
      formData.append('Type_Post_AdditionalInfo_Eng', typePostData.Type_Post_AdditionalInfo_Eng);
      return ManageTypePostService.create(formData);
    })
      .then(result => {
        //console.log("check result", result)
        if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);
        handleSearch();
        resetField();
        hideModal();
        setActiveTabKey('generalInfo');
        openSuccessNotification({ title: 'Thông báo', message: 'Thêm mới thành công' });
      })
      .catch((error) => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({ title: 'Thông báo', message: 'Thêm mới thất bại' });
      });
  };

  const resetField = () => {
    form.resetFields();
    setTypePostData({ ...Type_PostInitData });
  };

  return (
    <>
      <Button onClick={showModal} className='admin-button__main admin-button__download-pdf'>
        <i className='fas fa-plus'></i>
        {language.MANAGE_TYPE_POST_CREATE_NEW_POST}
      </Button>
      <Modal
        title={language.MANAGE_TYPE_POST_CREATE_NEW_POST}
        visible={visible}
        width='80%'
        className='manage-news-modal admin-modal'
        onCancel={hideModal}
        footer={
          [
            <Button
              key='submit'
              type='secondary'
              onClick={hideModal}
              className='admin-button__main admin-button__delete'
            >
              {language.MANAGE_CANCEL}
            </Button>,
            <Button
              onClick={handleFininsh}
              key='cancel'
              type='primary'
              className='admin-button__main admin-button__save'
            >
              {language.MANAGE_SAVE}
            </Button>
          ]
        }
      >
        <Tabs className='manage-news-tabs' activeKey={activeTabKey} onChange={handleTabChange}>
          <TabPane tab={language.MANAGE_TYPE_POST_GENERAL_INFO} key='generalInfo'>
            <Form form={form} requiredMark={false} id='manage-type-post-info-form'
              name='manage-news-form'
              className='manage-news-form'
              labelCol={{ lg: { span: 3 }, md: { span: 24 } }}
              wrapperCol={{ lg: { span: 8 }, md: { span: 24 } }}
            >
              <Form.Item
                colon={false}
                label={language.MANAGE_TYPE_POST_TITLE}
                labelAlign='left'
                name={Type_PostFormInitialField.Type_Post_Title.name}
                rules={[{ required: Type_PostFormInitialField.Type_Post_Title.required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_TYPE_POST_TITLE_PLACEHOLDER} maxLength={200} />
              </Form.Item>

              <Form.Item
                colon={false}
                label={language.MANAGE_TYPE_POST_TITLE_ENG}
                labelAlign='left'
                name={Type_PostFormInitialField.Type_Post_Title_Eng.name}
                rules={[{ required: Type_PostFormInitialField.Type_Post_Title_Eng.required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_TYPE_POST_TITLE_ENG_PLACEHOLDER} maxLength={200} />
              </Form.Item>

              <Form.Item
                colon={false}
                label={language.MANAGE_TYPE_POST_SLUG}
                labelAlign='left'
                name={Type_PostFormInitialField.Type_Post_Slug.name}
                rules={[{ required: Type_PostFormInitialField.Type_Post_Slug.required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_TYPE_POST_SLUG} maxLength={200} />
              </Form.Item>

              <ManageTypePostStyleShowSelect
                name={Type_PostFormInitialField.Type_Post_Style_Show.name}
                label={language.MANAGE_TYPE_POST_INTRO_STYLE_SHOW}
                hasInitialValue={true}
                placeholder={language.MANAGE_TYPE_POST_INTRO_STYLE_SHOW_PLACEHOLDER}
                required={Type_PostFormInitialField.Type_Post_Style_Show.required}
                form={form} />

              <ManageTypePostStatusSelect
                name={Type_PostFormInitialField.Status.name}
                placeholder={language.MANAGE_TYPE_POST_STATUS}
                label={language.MANAGE_TYPE_POST_STATUS}
                hasInitialValue={true}
                required={Type_PostFormInitialField.Status.required}
                form={form}
              />

              <MpsDatePicker
                label={language.MANAGE_TYPE_POST_CREATED_DATE}
                name={Type_PostFormInitialField.CreatedDate.name}
                value={Date.now()}
                required={Type_PostFormInitialField.CreatedDate.required}
                disabled={true}
              />
            </Form>

          </TabPane>
          <TabPane tab={language.MANAGE_TYPE_POST_IMAGE} key='imageInfo'>
            <div style={{ display: 'flex', marginTop: '50px', textAlign: 'center' }}>
              <MpsUploadImageTypeIntroPost onChange={(value) => handleImagesChange(value, "Type_Post_Image_Pc")} images={typePostData.Type_Post_Image_Pc} max={1} for='PC' />
              <MpsUploadImageTypeIntroPost onChange={(value) => handleImagesChange(value, "Type_Post_Image_Tablet")} images={typePostData.Type_Post_Image_Tablet} max={1} for='Tablet' />
              <MpsUploadImageTypeIntroPost onChange={(value) => handleImagesChange(value, "Type_Post_Image_Mobile")} images={typePostData.Type_Post_Image_Mobile} max={1} for='Mobile' />
            </div>
          </TabPane>
          <TabPane tab={language.MANAGE_TYPE_POST_ADDITIONAL_INFO} key='additionalInfo'>
            <MpsRichTextEditor onChange={handleAdditionalInfoChange} data={typePostData.Type_Post_AdditionalInfo} />
          </TabPane>
          <TabPane tab={language.MANAGE_TYPE_POST_ADDITIONAL_INFO_ENG} key='additionalInfo_eng'>
            <MpsRichTextEditor onChange={handleAdditionalInfoEngChange} data={typePostData.Type_Post_AdditionalInfo_Eng} />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
}

export default React.memo(ManageIntroPostAdd);

import React from 'react'
import { Breadcrumb } from 'antd'

export default function AdminBreadcrum(props) {
    return (
        <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item className="admin-breadcrum-item admin-breadcrum-origin">
            {/* <i className="fas fa-home"></i> */}
            {
                props.subKey === 'sub2' ? 
                <span><i className="fas fa-dolly"></i> Quản lý sản phẩm</span> : 
                props.subKey === 'sub3' ? 
                <span><i className="far fa-newspaper"></i> Quản lý tin tức</span> :
                props.subKey === 'sub4' ? 
                <span><i className="fas fa-file-alt"></i> Quản lý tài liệu</span> : 
                <span><i className="fas fa-user"></i> Hệ thống</span>
            }
            </Breadcrumb.Item>
            <Breadcrumb.Item className="admin-breadcrum-item">{props.title}</Breadcrumb.Item>
        </Breadcrumb>
    )
}

import React from 'react'
import { Row, Col, Select, Form } from 'antd';

const { Option } = Select;
export default function ManageUserSelect(props) {
    const rs = props.select.map((item, index) => {
        return (
            <Option value={item.value} key={'option' + index}>{item.title}</Option>
        )
    });
    return (
        <Row className="admin-filed__list" gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Col className="admin-filed__list__item" lg={8} md={24} sm={24} xs={24}>
                <label>{props.title} <span className={`admin-filed__list__item__required ${props.require ? "active" : ""}`}>*</span></label>
            </Col>
            <Col className="admin-filed__list__item" lg={14} md={24} sm={24} xs={24}>
                <Form.Item
                    name={props.name}
                    rules={[{ required: props.require || false, message: `Đây là trường bắt buộc` }]}
                    className={`admin-filed ${props.className || ""}`}
                >
                    <Select
                        className="admin-input"
                        placeholder={props.placeholder}
                        allowClear={true}
                        suffixIcon={<i className="fas fa-angle-down admin-icon-input"></i>}
                    >
                        {rs}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
    )
}

import { authHeader } from '../../../../helper/authHeader';

export const ManageRecruitmentTypeService = {
  search,
};

function search(searchModel, searchOption) {
  const model = {
    searchModel,
    searchOption
  };

  const auth = authHeader();
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', auth);

  const requestOptions = {
    headers,
    method: 'POST',
    body: JSON.stringify(model)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/RecruitmentType/search`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

import { authHeader } from '../../../../helper/authHeader';

export const ManageRecruitmentService = {
  search,
  getById,
  create,
  update,
  deleteModel
};

function create(model) {
  const auth = authHeader();
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', auth);

  const requestOptions = {
    headers,
    method: 'POST',
    body: JSON.stringify(model)
  };
  return fetch(`${process.env.REACT_APP_API_URL}/Recruitment/create`, requestOptions).then(handleResponse);
}

function search(searchModel, searchOption) {
  const model = {
    searchModel,
    searchOption
  };

  const auth = authHeader();
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', auth);

  const requestOptions = {
    headers,
    method: 'POST',
    body: JSON.stringify(model)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/Recruitment/search`, requestOptions).then(handleResponse);
}

function getById(_id) {
  const auth = authHeader();
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', auth);
  const requestOptions = {
    headers,
    method: 'GET',
  };
  return fetch(`${process.env.REACT_APP_API_URL}/Recruitment/getById/${_id}`, requestOptions).then(handleResponse);
}

function update(model) {
  const auth = authHeader();
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', auth);
  const requestOptions = {
    headers,
    method: 'POST',
    body: JSON.stringify(model)
  };
  return fetch(process.env.REACT_APP_API_URL + `/Recruitment/update`, requestOptions).then(handleResponse);
}

function deleteModel(model) {
  const auth = authHeader();
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', auth);
  const requestOptions = {
    headers,
    method: 'POST',
    body: JSON.stringify(model)
  };
  return fetch(process.env.REACT_APP_API_URL + `/Recruitment/delete`, requestOptions).then(handleResponse);
}


function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

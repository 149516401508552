import React from 'react'

export default function AdminMenu(props) {
    return (
        <div className="admin-menu">
            <div className="admin-menu__heading">
                {props.title}
            </div>
            <div className="admin-menu__main">
                {props.children}
            </div>
        </div>
    )
}

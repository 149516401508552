import { formatDateTime } from '../../Common/FormatCommon';
import ManageProductEdit from '../ManageProductEdit';


export const initialFormField = {
  Product_Name: {
    Name: 'Product_Name',
    Required: true
  },
  Product_Name_Eng: {
    Name: 'Product_Name_Eng',
    Required: true
  },
  Product_Category: {
    Name: 'Product_Category',
    Required: true
  },
  Product_Detail_Info: {
    Name: 'Product_Detail_Info',
    Required: true
  },
  Product_Detail_Info_Eng: {
    Name: 'Product_Detail_Info_Eng',
    Required: true
  },
  CreatedDate: {
    Name: 'CreatedDate',
    Required: true,
  },
  Status: {
    Name: 'Status',
    Required: true
  },
};


export const productInitData = {
  _id: null,
  Product_Code: null,
  Product_Name: null,
  Product_Name_Eng: null,
  Product_Category: null,
  Product_Images: [],
  Product_Detail_Info: '',
  Product_Detail_Info_Eng: '',
  Status: null,
  CreatedDate: null,
  UpdatedDate: null,
};

export const columns = (language) => {
  return [
    {
      width: 450,
      title: language.MANAGE_PRODUCT_NAME,
      dataIndex: 'Product_Name',
      key: 'Product_Name'
    },
    {
      width: 300,
      title: language.MANAGE_PRODUCT_CATEGORY,
      dataIndex: 'Product_CategoryObject',
      key: 'Product_CategoryObject',
      render: (value) => {
        return value?.Product_Category_Name;
      }
    },
    {
      width: 300,
      title: language.MANAGE_PRODUCT_CREATED_DATE,
      dataIndex: 'CreatedDate',
      key: 'CreatedDate',
      render: (value) => {
        return formatDateTime(value);
      }
    },
    {
      title: language.MANAGE_PRODUCT_STATUS,
      dataIndex: 'Status',
      key: 'Status',
      render: (value) => {
        return value === false ? 'Ẩn' : 'Hiển thị';
      }
    },
    {
      title: '',
      dataIndex: '_id',
      render: (value) => {
        return <ManageProductEdit _id={value} />;
      }
    }
  ];
};

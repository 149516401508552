import React, { useEffect, useState, useCallback } from 'react';
import Helmet from '../../Layout/Helmet';
import AdminBreadcrum from '../Layout/AdminBreadcrumb';
import { Form, Button } from 'antd';
import AdminTable from '../Layout/AdminTable';
import { UserDisplayTable, UserAttribute, UserPlaceholder, UserInitData, UserCommon } from './Model/ManageUserModel';
import { ManageUserService } from './Service/ManageUserService';
import ManageUserSearch from './ManageUserSearch';

export default function ManageUserPage() {
  const [searchOption, setSearchOption] = useState({ page: 1, limit: 10, sort: ['CreatedAt'] });
  const [dataUser, setDataUser] = useState(null);
  const [signChanged, setSignChanged] = useState(false);
  const [form] = Form.useForm();

  const handleSearch = useCallback(async () => {
    const searchModel = form.getFieldsValue();
    const sortAscending = form.getFieldValue('User_Sort');
    const data = await ManageUserService.search(searchModel, { ...searchOption, sortAscending: sortAscending || false });

    const { pages } = data.result;
    if (pages >= searchOption.page) {
      setDataUser(data.result);
    } else {
      setSearchOption(prevState => ({ ...prevState, page: 1 }));
    }

  }, [form, searchOption]);

  const handlePagination = (currentPage, pageSizes) => {
    setSearchOption(prevState => ({ ...prevState, page: currentPage, limit: pageSizes }));
  };

  const handleSignChanged = () => {
    setSignChanged(prevSign => !prevSign);
  };

  const onHandleClearFormSearch = () => {
    form.resetFields();
  };

  useEffect(() => {
    handleSearch();
  }, [handleSearch, signChanged]);

  return dataUser && (
    <Helmet title={UserCommon.Title}>
      <AdminBreadcrum title={UserCommon.Title}></AdminBreadcrum>
      <div className="admin-content">
        <Form
          form={form}
          onFinish={handleSearch}
        >
          <ManageUserSearch
            UserAttribute={UserAttribute}
            UserPlaceholder={UserPlaceholder}
            UserInitData={UserInitData}>

          </ManageUserSearch>
          <div className="admin-button admin-button-search">
            <Button type="secondary" className="admin-button__main admin-button__delete" onClick={onHandleClearFormSearch}>
              Xóa lựa chọn
            </Button>
            <Button type="primary" htmlType="submit" className="admin-button__main admin-button__save">
              Tìm kiếm
            </Button>
          </div>
        </Form>
        <AdminTable
          data={dataUser.docs}
          columnsData={() => UserDisplayTable(handleSignChanged, UserInitData)}
          changePage={handlePagination}
          select={UserInitData.Select}
          total={dataUser.total || 0}
          current={dataUser.page}
        >
        </AdminTable>
      </div>
    </Helmet>
  );
}

import React from 'react';
import AdminMenu from '../Layout/AdminMenu';
import { Row, Col, Form, Input } from 'antd';
import ManageNewsSelect from '../ManageNews/Control/ManageNewsSelect';
import { ManageProductLanguageContext } from './ManageProductPage';
import ProductCategorySelect from './Control/ProductCategorySelect';
import MpsRangePicker from '../../MpsCotrols/MpsRangePicker/MpsRangePicker';
import MpsSortSelect from '../../MpsCotrols/MpsSelect/MpsSortSelect';


function ManageProductSearch() {
  const language = React.useContext(ManageProductLanguageContext);
  return (
    <AdminMenu title={language.MANAGE_PRODUCT_SEARCH_INFO}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 50 }}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_PRODUCT_NAME}
            name='Product_Name'
          >
            <Input placeholder={language.MANAGE_PRODUCT_NAME} />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <ManageNewsSelect label={language.MANAGE_PRODUCT_STATUS} name='Status'
            placeholder={language.MANAGE_PRODUCT_STATUS} />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <ProductCategorySelect label={language.MANAGE_PRODUCT_CATEGORY} name='Product_Category'
            placeholder={language.MANAGE_PRODUCT_CATEGORY} />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsRangePicker onChange={()=>{}} language='vi' label={language.MANAGE_PRODUCT_CREATED_DATE} name='CreatedDate' />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsSortSelect name='Product_Sort' />
        </Col>
      </Row>
    </AdminMenu>
  );
}

export default ManageProductSearch

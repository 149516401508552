import React, { useEffect, useMemo, useState } from 'react'
import Helmet1 from '../Layout/Helmet'
import Breadcrum from '../Layout/Breadcrum'
import TitleBreadcrum from '../Layout/TitleBreadcrum'
import background from '../../asset/img/img-9.jpg'
import IntroButton from './Control/IntroButton'
import { Link } from 'react-router-dom';
import { IntroCommon, IntroOrganizationCommon } from './Model/IntroModel'
import { Row } from 'antd'

export default function IntroOrganizationPage(props) {
    const { language, dataSource, typePostData } = props
    const [dimensions, setDimensions] = React.useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }
    const processDataSource = useMemo(() => {
        if (dataSource.length === 0) {
            return []
        }
        return dataSource.map((item) => {

            return {
                image: dimensions.width >= 1024 && item.Post_Intro_Image_Pc[0] ?
                    process.env.REACT_APP_API_URL + '/' + item.Post_Intro_Image_Pc[0]?.url :
                    dimensions.width >= 556 && item.Post_Intro_Image_Tablet[0] ? 
                    process.env.REACT_APP_API_URL + '/' + item.Post_Intro_Image_Tablet[0]?.url :
                    item.Post_Intro_Image_Mobile[0] && process.env.REACT_APP_API_URL + '/' + item.Post_Intro_Image_Mobile[0]?.url,
                _id: item._id,
                title: item.Post_Intro_Title,
                content: item.Post_Intro_Content
            }
        })
    }, [dataSource, language, dimensions])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
    }, []);
    // console.log("check processData", processDataSource)
    return (
        <Helmet1 title={language === "vi" ? typePostData[0]?.Type_Post_Title : typePostData[0]?.Type_Post_Title_Eng}>
            <Breadcrum data={[
                {
                    title: IntroCommon[language].title,
                    path: `/${language}${IntroCommon[language].path}`
                }, language === "vi"
                    ? typePostData[0]?.Type_Post_Title
                    : typePostData[0]?.Type_Post_Title_Eng]}>
            </Breadcrum>
            <TitleBreadcrum title={language === "vi" ?
                typePostData[0]?.Type_Post_Title :
                typePostData[0]?.Type_Post_Title_Eng}>
            </TitleBreadcrum>
            <div className="intro-generation" style={{ backgroundImage: `url(${background})` }}>
                <div className="intro-generation__overlay"></div>
                <div className="container">
                    <div className="intro-organize">
                        {
                            processDataSource && processDataSource.length > 0 && processDataSource.map((item, index) => {
                                console.log({item});
                                return (
                                    <Row className='intro-detail__container' key={item._id}>
                                        <div className={item.title.length < 50 ? "intro-organize__main__title" :
                                            "intro-organize__main__title  intro-organize__main__title-long"}>
                                            <div className="intro-organize__main__title__circle"></div>
                                            <h3>{item.title}</h3>
                                        </div>
                                        <div className="intro-detail__main" >
                                            {item.image && <div className='intro-detail__main__image'>
                                                <img src={item.image ? item.image : ''} />
                                            </div>}
                                            <div className='intro-image__container' dangerouslySetInnerHTML={{
                                                __html: item.content
                                            }} >
                                            </div>

                                        </div>
                                        <div className="intro-detail__main__decor"></div>
                                    </Row>
                                )
                            })
                        }
                    </div>
                    <div className="intro__main__button-container">
                        <Link to={`/${props.language}/intro`}>
                            <IntroButton title={IntroCommon[language].backIntro} turn="left" color="white"></IntroButton>
                        </Link>
                    </div>
                </div>
            </div>
        </Helmet1>
    )
}

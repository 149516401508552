import React from 'react'
import { Form, Input, Button } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import background from '../../../asset/img/Banner.png'
import logo from '../../../asset/img/Logo.png'
import './Css/Login.css';
import Helmet from '../../Layout/Helmet';
import { LoginService } from './Services/LoginService';
import { LOGIN_RETURN_CODE_DESCRIPTION, RESULT_CODES } from '../../../common/Constant.Common';
import { useHistory } from 'react-router-dom';
import { store } from 'react-notifications-component';

export default function LoginPage() {
    const history = useHistory();
    const onFinish = async (values) => {
        const user = await LoginService.login(values.username, values.password);
        store.addNotification({
            title: user.returnCode !== RESULT_CODES.SUCCESS ? "Error!" : "Success",
            message: `${LOGIN_RETURN_CODE_DESCRIPTION[parseInt(user.returnCode)]}`,
            type: user.returnCode !== RESULT_CODES.SUCCESS ? "danger" : "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
        if (user.returnCode === RESULT_CODES.SUCCESS) {
            if (user.token) {
                localStorage.setItem('accessToken', JSON.stringify(user.token));
                history.push('/admin/manageuser');
            }
        }
    };
    return (
        <Helmet title="Đăng nhập hệ thống">
            <div className="login" style={{ backgroundImage: `url(${background})` }}>
                <div className="login__overlay"></div>
                <div className="login__form-container">
                    <div className="login__form">
                        <img src={logo} alt="logo"></img>
                        <h1>
                            CÔNG TY CP TẬP ĐOÀN THÉP TIẾN LÊN TRANG QUẢN TRỊ
                        </h1>
                        <div className="login__form__main">
                            <Form
                                name="normal_login"
                                className="login-form"
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    name="username"
                                    rules={[{ required: true, message: 'Hãy nhập tên đăng nhập của bạn!' }]}
                                >
                                    <Input
                                        prefix={
                                            <i className="fas fa-user site-form-item-icon"></i>
                                        }
                                        placeholder="Tên đăng nhập"
                                        className="login-form-input" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Hãy nhập mật khẩu của bạn!' }]}
                                >
                                    <Input.Password
                                        prefix={
                                            <i className="fas fa-lock site-form-item-icon"></i>
                                        }
                                        className="login-form-input"
                                        placeholder="Mật khẩu"
                                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="login-form-button">
                                        Đăng nhập
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </Helmet>
    )
}

import React, { useState, useEffect } from 'react';
import { Form, Select } from 'antd';
import { ManageRecruitmentTypeService } from '../Service/ManageRecruitmentType.Service';

function ManageRecruitmentTypeSelect(props) {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const searchOption = { page: 1, limit: 10 };
    ManageRecruitmentTypeService.search({ Status: true }, searchOption).then(result => {
      const data = result.result.docs || [];
      setDataSource(data);
    })
      .catch(() => {
        // TO DO
      });
  }, []);

  useEffect(() => {
    if (!props.form) return;

    props.form.resetFields();

  }, [dataSource, props.form]);
  return (
    <Form.Item
      label={props.label}
      name={props.name}
      labelAlign='left'
      colon={false}
      initialValue={props.initialValue ? props.initialValue : null}
      rules={[{ required: props.required || false, message: `${props.warning || ''}` }]}
      className={`admin-filed ${props.className || ''}`}
    >
      <Select placeholder={props.placeholder || ''} allowClear={true}>
        {dataSource.length > 0 && dataSource.map(data => {
          return <Select.Option key={`${data.Recruitment_Type_Code}`} value={data.Recruitment_Type_Code}>
            {data.Recruitment_Type_Name}
          </Select.Option>;
        })}
      </Select>
    </Form.Item>
  );
}

export default ManageRecruitmentTypeSelect;

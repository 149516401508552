import React, { useEffect, useState, useCallback } from 'react';
import { Button, Modal, Form, Tabs, Input } from 'antd';
import ManageUnitStatusSelect from './Control/ManageUnitStatusSelect';

import { ManageUnitContext, ManageUnitLanguageContext } from './ManageUnitPage';
import { ManageUnitService } from './Service/ManageUnit.Service';

import { initialFormField, unitInitData } from './Model/ManageUnit.Model';
import { RESULT_CODES } from '../../../common/Constant.Common';
import { openErrorNotification, openSuccessNotification } from '../Common/MethodCommon';
import ManageNewsDeleteConfirm from '../ManageNews/Control/ManageNewsDeleteConfirm';
import MpsDatePicker from '../../MpsCotrols/MpsDatePicker/MpsDatePicker';

const { TabPane } = Tabs;

function ManageUnitEdit(props) {
  const language = React.useContext(ManageUnitLanguageContext);
  const handleSearch = React.useContext(ManageUnitContext);

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [unitData, setUnitData] = useState({ ...unitInitData });

  const showModal = () => {
    setVisible(true);
  };
  const hideModal = () => {
    setVisible(false);
  };


  const handleFinish = () => {

    form.validateFields().then(values => {
      const unitFormData = {}

      // Append values to form data
      const formObjectValues = Object.entries(values);
      formObjectValues.forEach(value => {
        unitFormData[value[0]]= value[1];
      });

      return ManageUnitService.update(unitFormData);
    })
      .then(result => {
        if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);
        setUnitData({ ...result.data });

        handleSearch();
        openSuccessNotification({ title: 'Thông báo', message: 'Cập nhật thành công' });
        hideModal();
      })
      .catch((error) => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({ title: 'Thông báo', message: 'Cập nhật thất bại' });
      });
  };

  const handleDelete = () => {
    const model = { _id: props._id };
    ManageUnitService.deleteModel(model).then(result => {
      if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);
        openSuccessNotification({ title: 'Thông báo', message: 'Xóa dữ liệu thành công' });
        hideModal();
        handleSearch();
      })
      .catch((error) => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({ title: 'Thông báo', message: 'Xóa dữ liệu thất bại' });
      });
  };

  useEffect(() => {
    if (!visible) return;

    ManageUnitService.getById(props._id).then(result => {
      setUnitData(result.data[0] || { ...unitInitData });
      form.resetFields();
    });
  }, [props._id, visible, form]);

  return (
    <>
      <p className="admin-button-detail" onClick={showModal}>{language.MANAGE_UNIT_VIEW_DETAIL}</p>
      <Modal
        title={language.MANAGE_UNIT_EDIT}
        visible={visible}
        width='80%'
        className='manage-unit-modal admin-modal'
        onCancel={hideModal}
        footer={
          [
            <ManageNewsDeleteConfirm
              key='delete'
              title={language.MANAGE_UNIT_CONFIRM_DELETE}
              deleteLabel={language.MANAGE_UNIT_DELETE}
              agreeLabel={language.MANAGE_UNIT_AGREE}
              disagreeLabel={language.MANAGE_UNIT_DISAGREE}
              onDelete={handleDelete}
            />,
            <Button
              onClick={handleFinish}
              key='save'
              type='primary'
              className='admin-button__main admin-button__save'
            >
              {language.MANAGE_UNIT_SAVE}
            </Button>
          ]
        }
      >
        <Tabs className='manage-unit-tabs' defaultActiveKey='generalInfo'>
          <TabPane tab={language.MANAGE_UNIT_GENERAL_INFO} key='generalInfo'>
            <Form
              form={form} requiredMark={false} id='manage-unit-edit-form'
              className='manage-unit-form'
              colon={false}
              name='manage-unit-edit-form'
              labelCol={{ lg: { span: 3 }, md: { span: 24 } }}
              wrapperCol={{ lg: { span: 8 }, md: { span: 24 } }}
            >
              <Form.Item
              initialValue={unitData.Unit_Code}
                label={language.MANAGE_UNIT_CODE}
                labelAlign='left'
                name={initialFormField.Unit_Code.Name}
                rules={[{ required: initialFormField.Unit_Code.Required || false, message: '' }]}
              >
                <Input disabled={true} maxLength={200} rows={3} />
              </Form.Item>
              <Form.Item
                initialValue={unitData.Unit_Name}
                label={language.MANAGE_UNIT_NAME_VIE}
                labelAlign='left'
                name={initialFormField.Unit_Name.Name}
                rules={[{ required: initialFormField.Unit_Name.Required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_UNIT_NAME_VIE_PLACEHOLDER} maxLength={200} rows={3} />
              </Form.Item>
              <Form.Item
                initialValue={unitData.Unit_Name_Eng}
                label={language.MANAGE_UNIT_NAME_ENG}
                labelAlign='left'
                name={initialFormField.Unit_Name_Eng.Name}
                rules={[{ required: initialFormField.Unit_Name_Eng.Required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_UNIT_NAME_ENG_PLACEHOLDER} maxLength={200} rows={3} />
              </Form.Item>
              <ManageUnitStatusSelect
                form={form}
                name='Status'
                initialValue={unitData.Status}
                label={language.MANAGE_UNIT_STATUS}
                placeholder={language.MANAGE_UNIT_STATUS_PLACEHOLDER}
                required={initialFormField.Status.Required}
                hasInitialValue={true}
              />
              <MpsDatePicker
                label={language.MANAGE_UNIT_CREATED_DATE}
                name={initialFormField.CreatedDate.Name}
                value={unitData.CreatedDate}
                required={initialFormField.CreatedDate.Required}
                disabled={false}

              />
              <Form.Item
              initialValue={unitData.CreatedPerson}
                label={language.MANAGE_UNIT_CREATED_PERSON}
                labelAlign='left'
                name={initialFormField.CreatedPerson.Name}
                rules={[{ required: initialFormField.CreatedPerson.Required || false, message: '' }]}
              >
                <Input  placeholder={language.MANAGE_UNIT_CREATED_PERSON} maxLength={200} rows={3}
                disabled={true}
                 />
              </Form.Item>
              <MpsDatePicker
                label={language.MANAGE_UNIT_UPDATED_DATE}
                name={initialFormField.UpdatedDate.Name}
                value={unitData.UpdatedDate?unitData.UpdatedDate:Date.now()}
                required={initialFormField.UpdatedDate.Required}
                disabled={true}

              />
              <Form.Item
              initialValue={unitData.UpdatedPerson}
                label={language.MANAGE_UNIT_UPDATED_PERSON}
                labelAlign='left'
                name={initialFormField.UpdatedPerson.Name}
                rules={[{ required: initialFormField.UpdatedPerson.Required || false, message: '' }]}
              >
                <Input placeholder={language.MANAGE_UNIT_UPDATED_PERSON} maxLength={200} rows={3} 
                disabled={true}

                />
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
}

export default ManageUnitEdit;


import React from 'react';
import AdminMenu from '../Layout/AdminMenu';
import { Row, Col } from 'antd';
import ManageUserInput from './Control/ManageUserInput';
import ManageUserSelect from './Control/ManageUserSelect';
import { LayoutAdminCommon } from '../Layout/Model/LayoutAdminModel';
import MpsRangePicker from '../../MpsCotrols/MpsRangePicker/MpsRangePicker';
import MpsSortSelect from '../../MpsCotrols/MpsSelect/MpsSortSelect';

export default function ManageUserSearch(props) {
  const { UserAttribute, UserPlaceholder, UserInitData } = props;
  return (
    <AdminMenu title={LayoutAdminCommon.titleMenuSearch}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 50 }}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <ManageUserInput
            name='User_Username'
            title={UserAttribute['User_Username'][0]}
            placeholder={UserPlaceholder['User_Username']}
            typeInput={UserAttribute['User_Username'][1]}
          ></ManageUserInput>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <ManageUserSelect
            name={'Status'}
            title={UserAttribute['Status'][0]}
            placeholder={UserPlaceholder['Status']}
            typeInput={UserAttribute['Status'][1]}
            select={UserInitData.Select['Status']}
          ></ManageUserSelect>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <ManageUserInput
            name={'User_Email'}
            title={UserAttribute['User_Email'][0]}
            placeholder={UserPlaceholder['User_Email']}
            typeInput={UserAttribute['User_Email'][1]}
          ></ManageUserInput>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Row className='admin-filed__list' gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Col className='admin-filed__list__item' lg={8} md={24} sm={24} xs={24}>
              <label>{UserAttribute['CreatedAt'][0]} <span className={`admin-filed__list__item__required ${props.require ? 'active' : ''}`}>*</span></label>
            </Col>
            <Col className='admin-filed__list__item' lg={14} md={24} sm={24} xs={24}>
              <MpsRangePicker onChange={()=>{}} language='vi' name='CreatedAt' />
            </Col>
          </Row>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Row className='admin-filed__list' gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Col className='admin-filed__list__item' lg={8} md={24} sm={24} xs={24}>
              <label>{'Sắp xếp'} <span className={`admin-filed__list__item__required ${props.require ? 'active' : ''}`}>*</span></label>
            </Col>
            <Col className='admin-filed__list__item' lg={14} md={24} sm={24} xs={24}>
              <MpsSortSelect name='User_Sort' label=''/>
            </Col>
          </Row>
        </Col>
      </Row>
    </AdminMenu>
  );
}

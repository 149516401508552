import React, { useEffect, useState, useCallback } from 'react';
import { Button, Modal, Form, Tabs, Input,Table } from 'antd';
import ManageNewsSelect from '../ManageNews/Control/ManageNewsSelect';
import ProductCategorySelect from './Control/ProductCategorySelect';
import MpsRichTextEditor from '../../MpsCotrols/MpsRichTextEditor/MpsRichTextEditor';
import MpsUploadImage from '../../MpsCotrols/MpsUploadImage/MpsUploadImage';

import { ManageProductContext, ManageProductLanguageContext } from './ManageProductPage';
import { ManageProductService } from './Service/ManageProduct.Service';

import { initialFormField, productInitData } from './Model/ManageProduct.Model';
import { RESULT_CODES } from '../../../common/Constant.Common';
import { openErrorNotification, openSuccessNotification } from '../Common/MethodCommon';
import ManageNewsDeleteConfirm from '../ManageNews/Control/ManageNewsDeleteConfirm';
import MpsDatePicker from '../../MpsCotrols/MpsDatePicker/MpsDatePicker';
import { LANGUAGE_STATE } from './Language/Language';
import {columnSpecification,columnSpecification2} from '../ManageSpecification/Model/ManageSpecification.Model';
import {ManageSpecificationService} from '../ManageSpecification/Service/ManageSpecification.Service'
const { TabPane } = Tabs;

function ManageProductEdit(props) {
  const [language] = useState(LANGUAGE_STATE['vi']);
  const handleSearch = React.useContext(ManageProductContext);

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [productData, setProductData] = useState({ ...productInitData });
  const [productOriginalImages, setProductOriginalImages] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);
  const [addedImages, setAddedImages] = useState([]);
  const [specification, setSpecification]=useState('')
  const [column, setColumn]=useState(columnSpecification(language))

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleAddImage = useCallback((image) => {
    setAddedImages([...image.fileList]);
  }, []);

  const handleRemoveImage = useCallback((image) => {
    const index = productOriginalImages.findIndex(item => item.name === image.name);

    if (index === -1) return;

    setRemovedImages(prevState => ([...prevState, image]));
  }, [productOriginalImages]);

  const handleDetailInfoChange = useCallback((text) => {
    setProductData(prevState => ({ ...prevState, Product_Detail_Info: text }));
  }, []);

  const handleDetailInfoEngChange = useCallback((text) => {
    setProductData(prevState => ({ ...prevState, Product_Detail_Info_Eng: text }));
  }, []);

  const handleFinish = () => {

    form.validateFields().then(values => {
      const productFormData = new FormData();

      // Append images to form data
      productData.Product_Images.forEach(image => {
        productFormData.append('Product_Images', JSON.stringify(image));
      });
      // Append new images to form data
      addedImages.forEach(image => {
        productFormData.append('Product_New_Images', image.originFileObj);
      });
      // Append removed images to form data
      removedImages.forEach(image => {
        productFormData.append('Product_Removed_Images', image.name);
      });
      // Append values to form data
      const formObjectValues = Object.entries(values);
      formObjectValues.forEach(value => {
        productFormData.append(value[0], value[1]);
      });
      productFormData.append('Product_Code', productData.Product_Code);
      productFormData.append('Product_Detail_Info', productData.Product_Detail_Info);
      productFormData.append('Product_Detail_Info_Eng', productData.Product_Detail_Info_Eng);

      return ManageProductService.update(productFormData);
    })
      .then(result => {
        if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);

        setProductOriginalImages([...result.data.Product_Images]);
        setProductData({ ...result.data });
        setAddedImages([]);
        // Xóa hình ảnh
        return ManageProductService.deleteImages({ Product_Images: removedImages });
      })
      .then(result => {
        if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);

        setRemovedImages([]);
        handleSearch();
        openSuccessNotification({ title: 'Thông báo', message: 'Cập nhật thành công' });
        hideModal();
      })
      .catch((error) => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({ title: 'Thông báo', message: 'Cập nhật thất bại' });
      });
  };

  const handleDelete = () => {
    const model = { _id: props._id };
    ManageProductService.deleteModel(model).then(result => {
      if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);

      return ManageProductService.deleteImages({ Product_Images: productData.Product_Images });
    })
      .then(result => {
        if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);

        openSuccessNotification({ title: 'Thông báo', message: 'Xóa dữ liệu thành công' });
        hideModal();
        handleSearch();
      })
      .catch((error) => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({ title: 'Thông báo', message: 'Xóa dữ liệu thất bại' });
      });
  };

  useEffect(() => {
    if (!visible) return;
    const searchOption = { page: 1, limit: 10, sortAscending:true };
    ManageProductService.getById(props._id).then(result => {
      setProductData(result.data[0] || { ...productInitData });
      setProductOriginalImages(result.data[0].Product_Images || []);
      ManageSpecificationService.search({Specification_Product:result.data[0].Product_Code},searchOption).then(result1=>{
        if(result1.result.docs[0]?.Specification_Product_Category==1){
          setColumn(columnSpecification(language))
        } 
        else{
          setColumn(columnSpecification2(language))
        } 
        setSpecification(result1.result.docs) 
      })
      form.resetFields();
    });
  }, [props._id, visible, form]);


  return (
    <>
      <p className="admin-button-detail" onClick={showModal}>{language.MANAGE_PRODUCT_VIEW_DETAIL}</p>
      <Modal
        title={language.MANAGE_PRODUCT_EDIT}
        visible={visible}
        width='80%'
        className='manage-product-modal admin-modal'
        onCancel={hideModal}
        footer={
          [
            <ManageNewsDeleteConfirm
              key='delete'
              title={language.MANAGE_PRODUCT_CONFIRM_DELETE}
              deleteLabel={language.MANAGE_PRODUCT_DELETE}
              agreeLabel={language.MANAGE_PRODUCT_AGREE}
              disagreeLabel={language.MANAGE_PRODUCT_DISAGREE}
              onDelete={handleDelete}
            />,
            <Button
              onClick={handleFinish}
              key='save'
              type='primary'
              className='admin-button__main admin-button__save'
            >
              {language.MANAGE_PRODUCT_SAVE}
            </Button>
          ]
        }
      >
        <Tabs className='manage-product-tabs' defaultActiveKey='generalInfo'>
          <TabPane tab={language.MANAGE_PRODUCT_GENERAL_INFO} key='generalInfo'>
            <Form
              form={form} requiredMark={false} id='manage-product-edit-form'
              className='manage-product-form'
              colon={false}
              name='manage-product-edit-form'
              labelCol={{ lg: { span: 3 }, md: { span: 24 } }}
              wrapperCol={{ lg: { span: 8 }, md: { span: 24 } }}
            >
              <Form.Item
                initialValue={productData.Product_Name}
                label={language.MANAGE_PRODUCT_NAME_VIE}
                labelAlign='left'
                name={initialFormField.Product_Name.Name}
                rules={[{ required: initialFormField.Product_Name.Required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_PRODUCT_NAME_VIE_PLACEHOLDER} maxLength={200} rows={3} />
              </Form.Item>
              <Form.Item
                initialValue={productData.Product_Name_Eng}
                label={language.MANAGE_PRODUCT_NAME_ENG}
                labelAlign='left'
                name={initialFormField.Product_Name_Eng.Name}
                rules={[{ required: initialFormField.Product_Name_Eng.Required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_PRODUCT_NAME_ENG_PLACEHOLDER} maxLength={200} rows={3} />
              </Form.Item>
              <ProductCategorySelect
                form={form}
                initialValue={productData.Product_Category}
                name={initialFormField.Product_Category.Name}
                label={language.MANAGE_PRODUCT_CATEGORY}
                placeholder={language.MANAGE_PRODUCT_CATEGORY_PLACEHOLDER}
                required={initialFormField.Product_Category.Required}
              />
              <ManageNewsSelect
                form={form}
                name='Status'
                initialValue={productData.Status}
                label={language.MANAGE_PRODUCT_STATUS}
                placeholder={language.MANAGE_PRODUCT_STATUS_PLACEHOLDER}
                required={initialFormField.Status.Required}
                hasInitialValue={true}
              />
              <MpsDatePicker
                label={language.MANAGE_PRODUCT_CREATED_DATE}
                name={initialFormField.CreatedDate.Name}
                value={productData.CreatedDate}
                required={initialFormField.CreatedDate.Required}
                disabled={false}

              />
            </Form>
          </TabPane>
          <TabPane tab={language.MANAGE_PRODUCT_IMAGES} key='images'>
            <MpsUploadImage
              isConvertUrl
              images={productData.Product_Images}
              onRemove={handleRemoveImage} onAddImage={handleAddImage}
              max={3}
            />
          </TabPane>
          <TabPane tab={language.MANAGE_PRODUCT_DETAIL_INFO} key='detail-info'>
            <MpsRichTextEditor onChange={handleDetailInfoChange} data={productData.Product_Detail_Info} />
          </TabPane>
          <TabPane tab={language.MANAGE_PRODUCT_DETAIL_INFO_ENG} key='detail-info-eng'>
            <MpsRichTextEditor onChange={handleDetailInfoEngChange} data={productData.Product_Detail_Info_Eng} />
          </TabPane>
  
          <TabPane tab={language.MANAGE_SPECIFICATION} key='specification'>
          <Table dataSource={specification} columns={column} />;
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
}

export default ManageProductEdit;


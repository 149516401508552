import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col } from 'antd';
import { SwapRightOutlined, CheckCircleOutlined } from '@ant-design/icons';
import Helmet1 from '../Layout/Helmet'
import { Helmet } from 'react-helmet'
import Breadcrum from '../Layout/Breadcrum';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import ModalApplyCV from './Control/ModalApplyCV';
import { numberOfPeopleUnit, RecruitCommon, recruitmentInitData } from './Model/RecruitModel';
import { ManageRecruitmentService } from '../Admin/ManageRecruitment/Service/ManageRecruitment.Service';
import { formatDateSecondType } from '../Admin/Common/FormatCommon';
import background from '../../asset/img/img-9.jpg';
import banner from '../../asset/img/Tuyendung img/04. BANNER HR/banner_tienlen_HIRING_PC.png';

export default function RecruitDetailPage(props) {
  const { id } = useParams();
  const { language } = props;
  const [modalApply, setModalApply] = useState(false);
  const [visible, setVisible] = useState(false);
  const [recruitmentData, setRecruitmentData] = useState({ ...recruitmentInitData });
  const [relatedRecruitmentData, setRelatedRecruitmentData] = useState([]);

  const handleModalCV = () => {
    setModalApply(prevProps => !prevProps);
  };

 

  useEffect(() => {
    window.scrollTo(0, 0);
    ManageRecruitmentService.getById(id).then(result => {
      setRecruitmentData(result.data[0] || { ...recruitmentInitData });

      const recruitmentType = result.data[0].Recruitment_Type || '';
      return ManageRecruitmentService.search({ Recruitment_Type: recruitmentType, Status: true }, { page: 1, limit: 10 });
    })
      .then(result => {
        let data = result.result.docs || [];
        data = data.filter(item => item._id !== parseInt(id, 10) && item.Language === language);
        setRelatedRecruitmentData(data);
      })
      .catch(() => {
        // TO DO
      });
  }, [id,language]);

  return (
    <Helmet1 title={RecruitCommon[language].title}>
      <Helmet>
        <link href={`https://product.tienlensteel.com.vn/${language}/recruit`} rel='canonical' />
      </Helmet>
      <Breadcrum data={[
        RecruitCommon[language].title
      ]}
      ></Breadcrum>
      <TitleBreadcrum title={RecruitCommon[language].title} />
      <div className='recruit-generation recruit-detail-generation' style={{ backgroundImage: `url(${background})` }}>
        <div className='recruit-generation__overlay'></div>
        <img src={banner} className='recruit-heading' alt='recruit-heading'></img>
        <div className='recruit-main'>
          <div className='container'>
            <div className='recruit-main__title'>
              <div className='recruit-main__title__circle'></div>
              <h3>{RecruitCommon[language].RecruitInfo.titleDetail}</h3>
            </div>
            <div className='recruit-main__item-container'>
              <div className='recruit-main__item'>
                <p className='recruit-main__item__title recruit-main__item__title__detail'>
                  {recruitmentData.Recruitment_Title ? recruitmentData.Recruitment_Title : ""}
                </p>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='recruit-main__item__detail'>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <i className='far fa-clock'></i>
                    {recruitmentData.Recruitment_TypeObject?.Recruitment_Type_Name}
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <i className='fas fa-user-friends'></i>
                    {`${recruitmentData.Recruitment_Quantity ? ('0' + recruitmentData.Recruitment_Quantity).slice(-2) : 0} `}
                    {language === 'vi' ? 'Người' : numberOfPeopleUnit(recruitmentData.Recruitment_Quantity)}
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <i className='fas fa-map-marker-alt'></i>
                    {recruitmentData.Recruitment_Address}
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <i className='far fa-calendar-alt'></i>
                    {`${language === 'vi' ? 'Hết hạn' : 'Expired date'} ${formatDateSecondType(recruitmentData.Recruitment_ExpiredDate)}`}
                  </Col>
                </Row>
                <div className='recruit-main__item__main'>
                  <p dangerouslySetInnerHTML={{ __html: recruitmentData.Recruitment_Detail_Info ? recruitmentData.Recruitment_Detail_Info : "" }} />
                  <div className='home__main__button home__main__button--red home__main__button-icon' onClick={() => {
                    setModalApply(true);
                  }}>
                    <span>{RecruitCommon[language].apply}</span>
                    <i className='fas fa-paper-plane'></i>
                  </div>
                </div>
              </div>
              <div className='recruit-main__item--background'></div>
            </div>
            <div className='recruit-main__title'>
              <div className='recruit-main__title__circle'></div>
              <h3>{RecruitCommon[language].RecruitInfo.titleDetailOther}</h3>
            </div>
            {relatedRecruitmentData.length > 0 && relatedRecruitmentData.map(item => (
              <div key={item._id} className='recruit-main__item-container'>
                <div className='recruit-main__item'>
                  <p className='recruit-main__item__title'>
                    {item.Recruitment_Title ? item.Recruitment_Title : ""}
                  </p>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='recruit-main__item__detail'>
                    <Col lg={12} md={12} sm={24} xs={24}>
                      <i className='far fa-clock'></i>
                      {item.Recruitment_TypeObject?.Recruitment_Type_Name}
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                      <i className='fas fa-user-friends'></i>
                      {`${item.Recruitment_Quantity ? ('0' + item.Recruitment_Quantity).slice(-2) : 0} `}
                      {language === 'vi' ? 'Người' : numberOfPeopleUnit(item.Recruitment_Quantity)}
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                      <i className='fas fa-map-marker-alt'></i>
                      {item.Recruitment_Address}
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                      <i className='far fa-calendar-alt'></i>
                      {`${language === 'vi' ? 'Hết hạn' : 'Expired date'} ${formatDateSecondType(item.Recruitment_ExpiredDate)}`}
                    </Col>
                  </Row>
                  <Link to={`${item._id}`} className='recruit-main__item__button'>
                    {RecruitCommon[language].detail}
                    <SwapRightOutlined />
                    <div className='recruit-main__item__button__line'></div>
                  </Link>
                </div>
                <div className='recruit-main__item--background'></div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {modalApply ?
        <ModalApplyCV language={language} handleModalCV={handleModalCV} onShowResult={setVisible} recruitmentData={recruitmentData} />
        : null
      }
      {visible &&
        <>
          <div className='mail__result__overlay show' />
          <div className='modal mail__result show'>
            <div className="mail__result__content">
              <div className="modal__container">
                <div className="mail__result__icon">
                  <CheckCircleOutlined className='mail__result__icon--success' />
                </div>
                <div className="mail__result__message">
                  <p>{RecruitCommon[language]['SuccessResultMessage']}</p>
                </div>
                <div className="mail__result__action">
                  <button style={{ display: 'inline-block' }}
                    className='home__main__button home__main__button--red-empty'
                    onClick={() => setVisible(false)}
                  >
                    {RecruitCommon[language]['close']}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </Helmet1>
  );
}

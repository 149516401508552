import React, { useState, useEffect } from 'react';
import { Form, Select } from 'antd';
import { statusModel } from '../Model/ManageBanner.Model';


function ManageBannerSelect(props) {
  const [dataSource] = useState(statusModel);

  useEffect(() => {
    if (!props.form) return;

    props.form.resetFields();

  }, [props.initialValue, props.form]);

  return (
    <Form.Item
      name={props.name}
      label={props.label}
      labelAlign='left'
      colon={false}
      initialValue={props.hasInitialValue ? props.initialValue ?? dataSource[0].value : null}
      rules={[{ required: props.required || false, message: `${props.warning || ''}` }]}
      className={`admin-filed ${props.className || ''}`}
    >
      <Select placeholder={props.placeholder || ''} allowClear={true}>
        {dataSource.length > 0 && dataSource.map((data, index) => {
          return <Select.Option key={`option${index}`} value={data.value}>
            {data.name}
          </Select.Option>;
        })}
      </Select>

    </Form.Item>
  );
}

export default ManageBannerSelect;


import React, { useState, useEffect } from 'react';
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './Css/ForBanner.css'

const convertImagesURL = (images = []) => {
  return images.map(image => ({ ...image, url: `${process.env.REACT_APP_API_URL}/${image.url}` }));
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function MpsUploadImageForBanner(props) {
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);

  const [images, setImages] = useState([]);

  useEffect(() => {
    if (props.isConvertUrl) {
      setImages(convertImagesURL(props.images) || []);
    } else {
      setImages(props.images || []);
    }
  }, [props.images, props.isConvertUrl]);

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const upLoadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{props.title || 'Đăng ảnh'}</div>
    </div>
  );

  const handleChange = async (file) => {
    const acceptedFileType = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/webp'];
    if (acceptedFileType.includes(file.file.type)) {
      if (!file.file.preview && file.file.originFileObj?.name) {
        file.file.preview = await getBase64(file.file.originFileObj);
        file.file.response = null;
        file.file.status = 'done';
      }

      setImages(file.fileList);

      if (props.onAddImage) {
        return props.onAddImage(file);
      }

      props.onChange(file.fileList);

    }
  };

  const handleRemove = (file) => {
    if (props.onRemove) {
      props.onRemove(file);
    }
    return true;
  };

  const handlePreview = file => {
    setPreviewImage(file.url || file.preview || file.thumbUrl);
    setPreviewVisible(true);
    setPreviewTitle(file.name);
  };

  const customRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };
  return (
    <>
      <div style={{ width: '500px' }}>{(props.for === 'PC') ? 'Ảnh banner cho máy tính' : (props.for === 'Tablet') ? 'Ảnh banner cho máy tính bảng' : "Ảnh banner cho điện thoại"}
        <div className='view__img'>{(props.for === 'PC') ? '1920 x 1080' : (props.for === 'Tablet') ? '834 x 556' : '375 x 406'}
          <Upload
            accept='.jpg, .png, .jpeg, .bmp, .webp, .gif'
            listType='picture-card'
            fileList={images}
            onPreview={handlePreview}
            onChange={handleChange}
            onRemove={handleRemove}
            customRequest={customRequest}

          >
            {images.length >= props.max ? null : upLoadButton}
          </Upload>
          <Modal
            visible={previewVisible}
            footer={null}
            title={previewTitle}
            onCancel={handleCancel}
          >
            <img alt='preview' style={{ width: '100%', objectFit: 'scale-down' }} src={previewImage} />
          </Modal>
        </div>
      </div>
    </>
  );
}

export default React.memo(MpsUploadImageForBanner);

import React from 'react';
import { Form, Select } from 'antd';

const dataSource = [
  {
    _id: 1,
    value: false,
    name: 'Mới nhất'
  },
  {
    _id: 2,
    value: true,
    name: 'Cũ nhất'
  },
];

function MpsSortSelect(props) {
  return (
    <Form.Item
      label={props.label ?? 'Sắp xếp'}
      name={props.name}
      labelAlign='left'
      colon={false}
      initialValue={false}
      rules={[{ required: props.required || false, message: `${props.message || ''}` }]}
      className={`admin-filed ${props.className || ''}`}
    >
      <Select placeholder='Sắp xếp'>
        {dataSource.length > 0 && dataSource.map(data => {
          return <Select.Option key={data._id} value={data.value}>
            {data.name}
          </Select.Option>;
        })}
      </Select>
    </Form.Item>
  );
}

export default MpsSortSelect;

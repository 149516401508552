import React, { useState, useCallback, useEffect } from 'react';
import { ManageTypePostContext, ManageTypePostLanguageContext } from './ManageIntroTypePost';
import { Button, Modal, Form, Tabs, Input } from 'antd';
import ManageTypePostDeleteConfirm from './Control/ManageTypePostDeleteConfirm';
import MpsRichTextEditor from '../../MpsCotrols/MpsRichTextEditor/MpsRichTextEditor';
import { Type_PostFormInitialField, Type_PostInitData } from './Model/ManageIntroTypePost.Model';
import ManageTypePostStatusSelect from './Control/ManageTypePostStatusSelect';
import { ManageTypePostService } from './Service/ManageTypePost.Service';
import { RESULT_CODES } from '../../../common/Constant.Common';
import { openErrorNotification, openSuccessNotification } from '../Common/MethodCommon';
import MpsUploadImageTypeIntroPost from '../../MpsCotrols/MpsUploadImage/MpsUploadImageTypeIntroPost ';
import MpsDatePicker from '../../MpsCotrols/MpsDatePicker/MpsDatePicker';
import ManageTypePostStyleShowSelect from './Control/ManageTypePostStyleShowSelect';

const { TabPane } = Tabs;

function ManageIntroPostEdit(props) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const language = React.useContext(ManageTypePostLanguageContext);
  const [typePostData, setTypePostData] = useState({ ...Type_PostInitData });

  const [productOriginalImagesPc, setProductOriginalImagesPc] = useState([]);
  const [productOriginalImagesTablet, setProductOriginalImagesTablet] = useState([]);
  const [productOriginalImagesMobile, setProductOriginalImagesMobile] = useState([]);

  const [addedImagesPc, setAddedImagesPc] = useState([]);
  const [addedImagesTablet, setAddedImagesTablet] = useState([]);
  const [addedImagesMobile, setAddedImagesMobile] = useState([]);

  const [removedImagesPc, setRemovedImagesPc] = useState([]);
  const [removedImagesTablet, setRemovedImagesTablet] = useState([]);
  const [removedImagesMobile, setRemovedImagesMobile] = useState([]);


  const handleSearch = React.useContext(ManageTypePostContext);
  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleDelete = () => {
    const model = { _id: props._id };
    ManageTypePostService.deleteModel(model).then(result => {
      if (result.returnCode !== RESULT_CODES.SUCCESS) throw result.returnCode;
      // Xóa hình ảnh
      const arrImageDelete = [
        ...typePostData.Type_Post_Image_Pc,
        ...typePostData.Type_Post_Image_Mobile,
        ...typePostData.Type_Post_Image_Tablet
      ]
      return ManageTypePostService.deleteImages({ Type_Post_Images: arrImageDelete });
    }).then(result => {
      if (result.returnCode !== RESULT_CODES.SUCCESS) throw result.returnCode;

      openSuccessNotification({ title: 'Thông báo', message: 'Xóa dữ liệu thành công' });
      hideModal();
      handleSearch();
    }).catch((error) => {
      if (error === RESULT_CODES.DATA_USING) {
        return openErrorNotification({ title: 'Thông báo', message: 'Dữ liệu đang được sử dụng' });
      }
      if (error !== RESULT_CODES.SUCCESS)
        return openErrorNotification({ title: 'Thông báo', message: 'Xóa dữ liệu thất bại' });
    });
  }
  const handleFininsh = () => {
    form.validateFields().then(values => {

      const formData = new FormData();
      //Image PC change data
      typePostData.Type_Post_Image_Pc.forEach(image => {
        formData.append('Type_Post_Image_Pc', image.originFileObj);
      });
      addedImagesPc.forEach(image => {
        formData.append('Type_Post_New_Images_Pc', image.originFileObj);
      });
      removedImagesPc.forEach(image => {
        formData.append('Type_Post_Removed_Images_Pc', image.name);
      });


      //Image Tablet change data
      typePostData.Type_Post_Image_Tablet.forEach(image => {
        formData.append('Type_Post_Image_Tablet', image.originFileObj);
      });
      addedImagesTablet.forEach(image => {
        formData.append('Type_Post_New_Images_Tablet', image.originFileObj);
      });
      removedImagesTablet.forEach(image => {
        formData.append('Type_Post_Removed_Images_Tablet', image.name);
      });


      //Image Mobile change data
      typePostData.Type_Post_Image_Mobile.forEach(image => {
        formData.append('Type_Post_Image_Mobile', image.originFileObj);
      });
      addedImagesMobile.forEach(image => {
        formData.append('Type_Post_New_Images_Mobile', image.originFileObj);
      });
      removedImagesMobile.forEach(image => {
        formData.append('Type_Post_Removed_Images_Mobile', image.name);
      });


      // Append values to form data
      const formObjectValues = Object.entries(values);
      formObjectValues.forEach(value => {
        formData.append(value[0], value[1]);
      });
      formData.append('_id', typePostData._id);
      formData.append('Type_Post_AdditionalInfo', typePostData.Type_Post_AdditionalInfo);
      formData.append('Type_Post_AdditionalInfo_Eng', typePostData.Type_Post_AdditionalInfo_Eng);

      return ManageTypePostService.update(formData);

    }).then(result => {
      if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);
      setAddedImagesPc([]);
      setAddedImagesMobile([]);
      setAddedImagesTablet([]);
      const arrImageDelete = [...removedImagesPc, ...removedImagesMobile, ...removedImagesTablet]
      // Xóa hình ảnh
      return ManageTypePostService.deleteImages({ Type_Post_Images: arrImageDelete });
    }).then(result => {
      //console.log("check result", result)
      if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);

      setRemovedImagesPc([]);
      setRemovedImagesMobile([]);
      setRemovedImagesTablet([]);
      handleSearch();
      openSuccessNotification({ title: 'Thông báo', message: 'Cập nhật thành công' });
      hideModal();

    }).catch(error => {
      if (error !== RESULT_CODES.SUCCESS)
        return openErrorNotification({ title: 'Thông báo', message: 'Cập nhật thất bại' });
    });
  };

  const handleFormValuesChange = () => {

  }

  //Onchange image pc

  const handleImagesPcChange = useCallback((images) => {
    setTypePostData(prevState => ({ ...prevState, Type_Post_Image_Pc: images }));
  }, []);

  const handleRemoveImagePc = useCallback((image) => {
    const index = productOriginalImagesPc.findIndex(item => item.name === image.name);

    if (index === -1) return;

    setRemovedImagesPc(prevState => ([...prevState, image]));
  }, [productOriginalImagesPc]);

  const handleAddImagePc = useCallback((image) => {
    setAddedImagesPc([...image.fileList]);
  }, []);

  //Onchange addtional infor
  const handleAdditionalInfoChange = useCallback((text) => {
    setTypePostData(prevState => ({ ...prevState, Type_Post_AdditionalInfo: text }));
  }, []);

  const handleAdditionalInfoEngChange = useCallback((text) => {
    setTypePostData(prevState => ({ ...prevState, Type_Post_AdditionalInfo_Eng: text }));
  }, []);

  //Onchange Image tablet
  const handleRemoveImageTablet = useCallback((image) => {
    const index = productOriginalImagesTablet.findIndex(item => item.name === image.name);

    if (index === -1) return;

    setRemovedImagesTablet(prevState => ([...prevState, image]));
  }, [productOriginalImagesTablet]);

  const handleAddImageTablet = useCallback((image) => {
    setAddedImagesTablet([...image.fileList]);
  }, []);

  const handleImageTabletChange = useCallback((images) => {
    setTypePostData(prevState => ({ ...prevState, Type_Post_Image_Tablet: images }));
  }, []);
  //Onchange Image Mobile
  const handleRemoveImageMobile = useCallback((image) => {
    const index = productOriginalImagesMobile.findIndex(item => item.name === image.name);

    if (index === -1) return;

    setRemovedImagesMobile(prevState => ([...prevState, image]));
  }, [productOriginalImagesMobile]);

  const handleAddImageMobile = useCallback((image) => {
    setAddedImagesMobile([...image.fileList]);
  }, []);

  const handleImagesMobileChange = useCallback((images) => {
    setTypePostData(prevState => ({ ...prevState, Type_Post_Image_Mobile: images }));
  }, []);

  useEffect(() => {
    if (!visible) return;
    ManageTypePostService.getById(props._id).then(result => {
      setTypePostData(result.data[0] || { ...Type_PostInitData });
      setProductOriginalImagesPc(result.data[0].Type_Post_Image_Pc || []);
      setProductOriginalImagesMobile(result.data[0].Type_Post_Image_Mobile || []);
      setProductOriginalImagesTablet(result.data[0].Type_Post_Image_Tablet || []);
      form.resetFields();
    });
  }, [props._id, visible, form]);


  return (
    <>
      <p className="admin-button-detail" onClick={showModal}>Xem chi tiết</p>

      <Modal
        title={language.MANAGE_TYPE_POST_VIEW_TITLE}
        visible={visible}
        width='80%'
        className='manage-news-modal admin-modal'
        onCancel={hideModal}
        footer={
          [
            <ManageTypePostDeleteConfirm key='cancel'
              title={language.MANAGE_CONFIRM_DELETE}
              deleteLabel={language.MANAGE_DELETE}
              agreeLabel={language.MANAGE_AGREE}
              disagreeLabel={language.MANAGE_DISAGREE}
              onDelete={handleDelete}
            />,
            <Button
              key='submit'
              type='primary'
              onClick={handleFininsh}
              className='admin-button__main admin-button__save'
            >
              {language.MANAGE_SAVE}
            </Button>
          ]
        }
      >
        <Tabs className='manage-news-tabs' defaultActiveKey='generalInfo'>
          <TabPane tab='Thông tin chung' key='generalInfo'>
            <Form form={form} requiredMark={false} id='manage-news-form'
              onValuesChange={handleFormValuesChange}
              className='manage-news-form'
              name='manage-news-form'
              labelCol={{ lg: { span: 3 }, md: { span: 24 } }}
              wrapperCol={{ lg: { span: 8 }, md: { span: 24 } }}

            >
              <Form.Item
                colon={false}
                label={language.MANAGE_TYPE_POST_TITLE}
                labelAlign='left'
                name={Type_PostFormInitialField.Type_Post_Title.name}
                rules={[{ required: Type_PostFormInitialField.Type_Post_Title.required || false, message: '' }]}
                initialValue={typePostData.Type_Post_Title}
              >
                <Input.TextArea placeholder={language.MANAGE_TYPE_POST_TITLE_PLACEHOLDER} maxLength={200} />
              </Form.Item>
              <Form.Item
                colon={false}
                label={language.MANAGE_TYPE_POST_TITLE_ENG}
                labelAlign='left'
                name={Type_PostFormInitialField.Type_Post_Title_Eng.name}
                rules={[{ required: Type_PostFormInitialField.Type_Post_Title_Eng.required || false, message: '' }]}
                initialValue={typePostData.Type_Post_Title_Eng}
              >
                <Input.TextArea placeholder={language.MANAGE_TYPE_POST_TITLE_ENG_PLACEHOLDER} maxLength={200} />
              </Form.Item>

              <Form.Item
                colon={false}
                label={language.MANAGE_TYPE_POST_SLUG}
                labelAlign='left'
                name={Type_PostFormInitialField.Type_Post_Slug.name}
                rules={[{ required: Type_PostFormInitialField.Type_Post_Slug.required || false, message: '' }]}
                initialValue={typePostData.Type_Post_Slug}
              >
                <Input.TextArea placeholder={language.MANAGE_TYPE_POST_SLUG} maxLength={200} />
              </Form.Item>
              <ManageTypePostStyleShowSelect
                name={Type_PostFormInitialField.Type_Post_Style_Show.name}
                label={language.MANAGE_TYPE_POST_INTRO_STYLE_SHOW}
                hasInitialValue={true}
                placeholder={language.MANAGE_TYPE_POST_INTRO_STYLE_SHOW_PLACEHOLDER}
                required={Type_PostFormInitialField.Type_Post_Style_Show.required}
                initialValue={typePostData.Type_Post_Style_Show}
                form={form} />
              <ManageTypePostStatusSelect
                name={Type_PostFormInitialField.Status.name}
                placeholder={language.MANAGE_TYPE_POST_STATUS}
                label={language.MANAGE_TYPE_POST_STATUS}
                hasInitialValue={true}
                required={Type_PostFormInitialField.Status.required}
                form={form}
                initialValue={typePostData.Status}
              />
              <MpsDatePicker
                label={language.MANAGE_TYPE_POST_CREATED_DATE}
                name={Type_PostFormInitialField.CreatedDate.name}
                value={typePostData.CreatedDate}
                required={Type_PostFormInitialField.CreatedDate.required}
                // disabled={true}
              />
            </Form>
          </TabPane>
          <TabPane tab={language.MANAGE_TYPE_POST_IMAGE} key='imageInfo'>
            <div style={{ display: 'flex', marginTop: '50px', textAlign: 'center' }}>
              <MpsUploadImageTypeIntroPost
                isConvertUrl
                onRemove={handleRemoveImagePc}
                onAddImage={handleAddImagePc}
                onChange={handleImagesPcChange}
                images={typePostData.Type_Post_Image_Pc}
                max={1} for='PC' />
              <MpsUploadImageTypeIntroPost
                isConvertUrl
                onRemove={handleRemoveImageTablet}
                onAddImage={handleAddImageTablet}
                onChange={handleImageTabletChange}
                images={typePostData.Type_Post_Image_Tablet}
                max={1} for='Tablet' />
              <MpsUploadImageTypeIntroPost
                isConvertUrl
                onRemove={handleRemoveImageMobile}
                onAddImage={handleAddImageMobile}
                onChange={handleImagesMobileChange}
                images={typePostData.Type_Post_Image_Mobile}
                max={1} for='Mobile' />
            </div>
          </TabPane>
          <TabPane tab={language.MANAGE_TYPE_POST_ADDITIONAL_INFO} key='additionalInfo'>
            <MpsRichTextEditor onChange={handleAdditionalInfoChange} data={typePostData.Type_Post_AdditionalInfo} />
          </TabPane>
          <TabPane tab={language.MANAGE_TYPE_POST_ADDITIONAL_INFO_ENG} key='additionalInfo_eng'>
            <MpsRichTextEditor onChange={handleAdditionalInfoEngChange} data={typePostData.Type_Post_AdditionalInfo_Eng} />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
}

export default React.memo(ManageIntroPostEdit);

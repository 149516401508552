import React, { createContext, useEffect, useState } from 'react';
import { RESULT_CODES } from '../../common/Constant.Common';
import { ManageUserService } from '../Admin/ManageUser/Service/ManageUserService';
import { useHistory } from 'react-router-dom';
export const LanguageContext = createContext();

function LanguageContextProvider({ children }) {
  const history = useHistory();
  const [language, setLanguage] = useState( 'vi');

  useEffect(() => {
    const language1=window.location.pathname.split('/')[1]
    if(language1!='vi'&&language1!='en'){
      ManageUserService.getUserLocation().then(result => {
        if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);

        const { data } = result;
        if (data) {
          if (data.country === 'VN') {
            setLanguage('vi');
          } else {
            setLanguage('en');
          }
        }
      }).catch(() => {
        setLanguage('en');
      });

    }
    else{
      setLanguage(language1)
    }
    
  }, []);

  const handleChangeLanguage = (data) => {
    setLanguage(data);
    let string1=window.location.pathname.substring(3);
        let string2=`/${data}`.concat(string1);
        // window.location.href=string2
        history.push(string2)
  };

  const contextValues = {
    language,
    handleChangeLanguage
  };

  return (
    <LanguageContext.Provider value={contextValues}>
      {children}
    </LanguageContext.Provider>
  );
}

export default LanguageContextProvider;

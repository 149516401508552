
import { formatDateTime } from '../../Common/FormatCommon';
import ManageIntroTypePostEdit from '../ManageIntroTypePostEdit';

export const statusModel = [
  { name: 'Hiển thị', value: true },
  { name: 'Ẩn', value: false },
];

export const styleShow = [
  { name: 'Hồ sơ', value: "Profile" },
  { name: 'Danh sách', value: "List" },
  { name: 'Chi tiết', value: "Details" },
  { name: 'Bản đồ', value: "Map" },
];

export const initDataImage = {
  Pc: [],
  Tablet: [],
  Mobile: []
}

export const searchModel = {
  Type_Post_Title: null,
  Type_Post_Category: null,
  Status: null,
  CreatedDate: null,
};

export const Type_PostSearchPlaceholder = {
  Type_Post_Title: 'Tiêu đề',
  Type_Post_Category: 'Thể loại',
  Status: 'Trạng thái',
};

export const Type_PostSearchLabel = {
  Type_Post_Title: 'Tiêu đề',
  Type_Post_Category: 'Thể loại',
  Status: 'Trạng thái',
  CreatedDate: 'Ngày tạo',
};

export const Type_PostInitData = {
  _id: null,
  Type_Post_Title: null,
  Type_Post_Title_Eng: null,
  Type_Post_Slug: null,
  Type_Post_Style_Show: null,
  Type_Post_Image_Pc: [],
  Type_Post_Image_Tablet: [],
  Type_Post_Image_Mobile: [],
  Type_Post_Images: [],
  Type_Post_AdditionalInfo: null,
  Type_Post_AdditionalInfo_Eng: null,
  Status: null,
  CreatedDate: null,
};

export const Type_PostFormInitialField = {
  Type_Post_Title: {
    placeholder: 'Tiêu đề (tối đa 200 ký tự)',
    required: true,
    name: 'Type_Post_Title'
  },
  Type_Post_Slug: {
    placeholder: 'Đường dẫn`(tối đa 200 ký tự)',
    required: true,
    name: 'Type_Post_Slug'
  },
  Type_Post_Style_Show: {
    placeholder: 'Kiểu hiển thị',
    required: true,
    name: 'Type_Post_Style_Show'
  },
  Type_Post_Title_Eng: {
    placeholder: 'Tiêu đề (tối đa 200 ký tự)',
    required: true,
    name: 'Type_Post_Title_Eng'
  },
  Type_Post_AdditionalInfo: {
    placeholder: 'Tiêu đề (tối đa 200 ký tự)',
    required: true,
    name: 'Type_Post_AdditionalInfo'
  },
  Type_Post_AdditionalInfo_Eng: {
    placeholder: 'Tiêu đề (tối đa 200 ký tự)',
    required: true,
    name: 'Type_Post_AdditionalInfo_Eng'
  },
  Status: {
    placeholder: 'Chọn trạng thái',
    required: true,
    label: 'Trạng thái',
    name: 'Status'
  },
  CreatedDate: {
    placeholder: '',
    required: true,
    disabled: true,
    label: 'Ngày tạo',
    name: 'CreatedDate'
  },
};

export const columns = (language) => {
  return [
    {
      title: language.MANAGE_TYPE_POST_TITLE,
      dataIndex: 'Type_Post_Title',
      key: 'Type_Post_Title'
    },
    {
      title: language.MANAGE_TYPE_POST_TITLE_ENG,
      dataIndex: 'Type_Post_Title_Eng',
      key: 'Type_Post_Title_Eng'
    },
    {
      title: language.MANAGE_TYPE_POST_INTRO_STYLE_SHOW,
      dataIndex: 'Type_Post_Style_Show',
      key: 'Type_Post_Style_Show',
      render: (value) => {
        if (!value) {
          return ""
        }
        const styleObj = styleShow.find(item => item.value === value)
        return styleObj.name;
      }
    },
    {
      title: language.MANAGE_TYPE_POST_SLUG,
      dataIndex: 'Type_Post_Slug',
      key: 'Type_Post_Slug'
    },
    {
      title: language.MANAGE_TYPE_POST_STATUS,
      dataIndex: 'Status',
      key: 'Status',
      render: (value) => {
        return value === false ? 'Ẩn' : 'Hiển thị';
      }
    },
    {
      title: language.MANAGE_TYPE_POST_CREATED_DATE,
      dataIndex: 'CreatedDate',
      key: 'CreatedDate',
      render: (value) => {
        return formatDateTime(value);
      }
    },
    {
      title: '',
      dataIndex: '_id',
      render: (value) => {
        return <ManageIntroTypePostEdit _id={value} />;
      }
    }
  ];
};



import React, { useEffect, useState, useCallback } from 'react';
import { Button, Modal, Form, Tabs, Input, Row, Col, Select } from 'antd';
import ManageUnitStatusSelect from '../ManageUnit/Control/ManageUnitStatusSelect';
import { ManageSpecificationContext, ManageSpecificationLanguageContext } from './ManageSpecificationPage';
import { ManageSpecificationService } from './Service/ManageSpecification.Service';

import { initialFormField, manageSpecificationLength, specificationInitData } from './Model/ManageSpecification.Model';
import { RESULT_CODES } from '../../../common/Constant.Common';
import { openErrorNotification, openSuccessNotification } from '../Common/MethodCommon';
import ManageNewsDeleteConfirm from '../ManageNews/Control/ManageNewsDeleteConfirm';
import MpsDatePicker from '../../MpsCotrols/MpsDatePicker/MpsDatePicker';
import ProductSelect from '../ManageProduct/Control/ProductSelect.js'
import ProductCategorySelect from '../ManageProduct/Control/ProductCategorySelect.js'

import UnitSelect from '../ManageUnit/Control/UnitSelect.js'
import ProductTypeSelect from '../ManageProductType/Control/ProductTypeSelect.js'
const { TabPane } = Tabs;

function ManageSpecificationEdit(props) {
  const language = React.useContext(ManageSpecificationLanguageContext);
  const handleSearch = React.useContext(ManageSpecificationContext);

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [specificationData, setSpecificationData] = useState({ ...specificationInitData });
  const [category, setCategory] = useState('');
  const [weight, setWeight] = useState(0);
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [weightOverUnit, setWeightOverUnit] = useState(0);
  
  const showModal = () => {
    setVisible(true);
  };
  const hideModal = () => {
    setVisible(false);
  };


  const handleFinish = () => {

    form.validateFields().then(values => {
      const specificationFormData = {}

      // Append values to form data
      const formObjectValues = Object.entries(values);
      formObjectValues.forEach(value => {
        specificationFormData[value[0]]= value[1];
      });
      // clear trong select la undefined (k update) =>> null
      if(specificationFormData.Specification_Length === undefined) {
        specificationFormData.Specification_Length = null
      }
      return ManageSpecificationService.update(specificationFormData);
    })
      .then(result => {
        if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);
        setSpecificationData({ ...result.data });

        handleSearch();
        openSuccessNotification({ title: 'Thông báo', message: 'Cập nhật thành công' });
        hideModal();
      })
      .catch((error) => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({ title: 'Thông báo', message: 'Cập nhật thất bại' });
      });
  };

  const handleDelete = () => {
    const model = { _id: props._id };
    ManageSpecificationService.deleteModel(model).then(result => {
      if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);
        openSuccessNotification({ title: 'Thông báo', message: 'Xóa dữ liệu thành công' });
        hideModal();
        handleSearch();
      })
      .catch((error) => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({ title: 'Thông báo', message: 'Xóa dữ liệu thất bại' });
      });
  };

  const handleOnChangeLength = (value) => {
    if (value === undefined) {
      setLength(null)
    }
    else {
      setLength(value)
    }
  }

  useEffect(() => {
    if (!visible) return;

    ManageSpecificationService.getById(props._id).then(result => {
      setSpecificationData(result.data[0] || { ...specificationInitData });
      setWeight(result.data[0].Specification_Weight)
      setLength(result.data[0].Specification_Length)
      setWidth(result.data[0].Specification_Width)
      setHeight(result.data[0].Specification_Height)
      setWeightOverUnit(result.data[0].Specification_Weight_Over_Unit)
      setCategory(result.data[0].Specification_Product_Category)
      form.resetFields();
    });
  }, [props._id, visible, form]);

  useEffect(()=>{
    if(category=='NSP003'){
      if(length&&weightOverUnit){
        const weight1=length*weightOverUnit;
        setWeight(weight1)
        form.setFieldsValue({Specification_Weight:weight1})
      } else {
        setWeight(null)
        form.setFieldsValue({Specification_Weight:null})
      }
    }
    else if(category=='NSP004'){
      if(height&&width&&length){
      const height1=height?height:1
      const width1=width?width:1000
      const weight1=height1*(width1/1000)*(length/1000)*7.85;
      setWeight(weight1)
      form.setFieldsValue({Specification_Weight:weight1})
      } else {
        setWeight(null)
        form.setFieldsValue({Specification_Weight:null})
      }

    }
    else if(category=='NSP005'){
      if(height&&width&&length){
        const height1=height?height:1
        const width1=width?width:1000
        const weight1=(height1*(width1/1000)*(length/1000)*7.85)+(3*(width1/1000)*(length/1000));
        setWeight(weight1)
        form.setFieldsValue({Specification_Weight:weight1})
      } else {
        setWeight(null)
        form.setFieldsValue({Specification_Weight:null})
      }
      
    }
  },[length,width,height,weightOverUnit,category])

  useEffect(()=> {
    if(weightOverUnit === '' || weightOverUnit === 0 ) {
      setWeight(0)
    }
  }, [weightOverUnit])

  return (
    <>
      <p className="admin-button-detail" onClick={showModal}>{language.MANAGE_SPECIFICATION_VIEW_DETAIL}</p>
      <Modal
        title={language.MANAGE_SPECIFICATION_EDIT}
        visible={visible}
        width='80%'
        className='manage-specification-modal admin-modal'
        onCancel={hideModal}
        footer={
          [
            <ManageNewsDeleteConfirm
              key='delete'
              title={language.MANAGE_SPECIFICATION_CONFIRM_DELETE}
              deleteLabel={language.MANAGE_SPECIFICATION_DELETE}
              agreeLabel={language.MANAGE_SPECIFICATION_AGREE}
              disagreeLabel={language.MANAGE_SPECIFICATION_DISAGREE}
              onDelete={handleDelete}
            />,
            <Button
              onClick={handleFinish}
              key='save'
              type='primary'
              className='admin-button__main admin-button__save'
            >
              {language.MANAGE_SPECIFICATION_SAVE}
            </Button>
          ]
        }
      >
        <Tabs className='manage-specification-tabs' defaultActiveKey='generalInfo'>
          <TabPane tab={language.MANAGE_SPECIFICATION_GENERAL_INFO} key='generalInfo'>
            <Form
              form={form} requiredMark={false} id='manage-specification-edit-form'
              className='manage-specification-form'
              colon={false}
              name='manage-specification-edit-form'
              labelCol={{ lg: { span: 8 }, md: { span: 24 } }}
              wrapperCol={{ lg: { span: 14 }, md: { span: 24 } }}
            >
              <Row>
               <Col lg={12} md={12} sm={24} xs={24}>

                <ProductCategorySelect
                initialValue={specificationData.Specification_Product_Category}
                  form={form}
                  name={initialFormField.Specification_Product_Category.Name}
                  label={language.MANAGE_SPECIFICATION_PRODUCT_CATEGORY}
                  placeholder={language.MANAGE_PRODUCT_CATEGORY}
                  required={initialFormField.Specification_Product_Category.Required}
                  onChange={value=>setCategory(value)}
                />
                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>

                <ProductSelect
                initialValue={specificationData.Specification_Product}
                  form={form}
                  name={initialFormField.Specification_Product.Name}
                  label={language.MANAGE_SPECIFICATION_PRODUCT}
                  placeholder={language.MANAGE_PRODUCT}
                  required={initialFormField.Specification_Product.Required}
                />
                </Col>
            {category=='NSP004'||category=='NSP005'?'':
                (<Col lg={12} md={12} sm={24} xs={24}>
              <ProductTypeSelect
            initialValue={specificationData.Specification_Product_Type}
                form={form}
                name={initialFormField.Specification_Product_Type.Name}
                label={language.MANAGE_SPECIFICATION_PRODUCT_TYPE}
                placeholder={language.MANAGE_PRODUCT_TYPE}
                required={initialFormField.Specification_Product_Type.Required}
              />
              </Col>)}
              <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
              initialValue={specificationData.Specification_Code}
                label={language.MANAGE_SPECIFICATION_CODE}
                labelAlign='left'
                name={initialFormField.Specification_Code.Name}
                rules={[{ required: initialFormField.Specification_Code.Required || false, message: '' }]}
              >
                <Input disabled={true} maxLength={200} rows={3} />
              </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                initialValue={specificationData.Specification_Name}
                label={language.MANAGE_SPECIFICATION_NAME}
                labelAlign='left'
                name={initialFormField.Specification_Name.Name}
                rules={[{ required: initialFormField.Specification_Name.Required || false, message: '' }]}
              >
                <Input placeholder={language.MANAGE_SPECIFICATION_NAME_VIE_PLACEHOLDER} maxLength={200} rows={3} />
              </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
              initialValue={specificationData.Specification_Length}
                label={language.MANAGE_SPECIFICATION_LENGTH}
                labelAlign='left'
                name={initialFormField.Specification_Length.Name}
                rules={[{ required: initialFormField.Specification_Length.Required || false, message: '' }]}
              >
              <Select onChange={handleOnChangeLength}
              placeholder={language.MANAGE_SPECIFICATION_LENGTH} allowClear={true}
              onClear={(value) => setLength(null)}
              >
                {manageSpecificationLength.length > 0 && manageSpecificationLength.map(data => {
                  return <Select.Option key={`${data.key}`} value={data.value}>
                    {data.name}
                  </Select.Option>;
                })}
              </Select>
                {/* <Input placeholder={language.MANAGE_SPECIFICATION_LENGTH} onChange={e=>setLength(e.target.value)} maxLength={200} rows={3} /> */}
              </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
              initialValue={specificationData.Specification_Width}
                label={language.MANAGE_SPECIFICATION_WIDTH}
                labelAlign='left'
                name={initialFormField.Specification_Width.Name}
                rules={[{ required: initialFormField.Specification_Width.Required || false, message: '' }]}
              >
                <Input placeholder={language.MANAGE_SPECIFICATION_WIDTH}  onChange={e=>setWidth(e.target.value)} maxLength={200} rows={3} />
              </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                 initialValue={specificationData.Specification_Height}
                label={language.MANAGE_SPECIFICATION_HEIGHT}
                labelAlign='left'
                name={initialFormField.Specification_Height.Name}
                rules={[{ required: initialFormField.Specification_Height.Required || false, message: '' }]}
              >
                <Input placeholder={language.MANAGE_SPECIFICATION_HEIGHT} onChange={e=>setHeight(e.target.value)} maxLength={200} rows={3} />
              </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
              <UnitSelect
               initialValue={specificationData.Specification_Product_Unit}
                form={form}
                name={initialFormField.Specification_Product_Unit.Name}
                label={language.MANAGE_SPECIFICATION_UNIT}
                placeholder={language.MANAGE_UNIT}
                required={initialFormField.Specification_Product_Unit.Required}
              />
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
              initialValue={specificationData.Specification_Weight_Over_Unit}
                label={language.MANAGE_SPECIFICATION_WEIGHT_OVER_UNIT}
                labelAlign='left'
                name={initialFormField.Specification_Weight_Over_Unit.Name}
                rules={[{ required: initialFormField.Specification_Weight_Over_Unit.Required || false, message: '' }]}
              >
                <Input placeholder={language.MANAGE_SPECIFICATION_WEIGHT_OVER_UNIT} 
                onChange={(e)=> {
                e.target.value === '' ? setWeightOverUnit(0) : setWeightOverUnit(e.target.value)
                }}
                maxLength={200} rows={3} />
              </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
              initialValue={specificationData.Specification_Weight}
                label={language.MANAGE_SPECIFICATION_WEIGHT}
                labelAlign='left'
                name={initialFormField.Specification_Weight.Name}
                rules={[{ required: initialFormField.Specification_Weight.Required || false, message: '' }]}
              >
                <Input disabled={true} placeholder={weight} maxLength={200} rows={3} />
              </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
              <ManageUnitStatusSelect
                form={form}
                name='Status'
                initialValue={specificationData.Status}
                label={language.MANAGE_SPECIFICATION_STATUS}
                placeholder={language.MANAGE_SPECIFICATION_STATUS_PLACEHOLDER}
                required={initialFormField.Status.Required}
                hasInitialValue={true}
              />
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
              <MpsDatePicker
                label={language.MANAGE_SPECIFICATION_CREATED_DATE}
                name={initialFormField.CreatedDate.Name}
                value={specificationData.CreatedDate}
                required={initialFormField.CreatedDate.Required}
                disabled={false}

              />
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
              initialValue={specificationData.CreatedPerson}
                label={language.MANAGE_SPECIFICATION_CREATED_PERSON}
                labelAlign='left'
                name={initialFormField.CreatedPerson.Name}
                rules={[{ required: initialFormField.CreatedPerson.Required || false, message: '' }]}
              >
                <Input placeholder={language.MANAGE_SPECIFICATION_CREATED_PERSON} value={specificationData.CreatedPerson} maxLength={200} rows={3} 
                disabled={true}

                />
              </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
              <MpsDatePicker
                label={language.MANAGE_SPECIFICATION_UPDATED_DATE}
                name={initialFormField.UpdatedDate.Name}
                value={specificationData.UpdatedDate?specificationData.UpdatedDate:Date.now()}
                required={initialFormField.UpdatedDate.Required}
                disabled={true}

              />
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
              initialValue={specificationData.UpdatedPerson}
                label={language.MANAGE_SPECIFICATION_UPDATED_PERSON}
                labelAlign='left'
                name={initialFormField.UpdatedPerson.Name}
                rules={[{ required: initialFormField.UpdatedPerson.Required || false, message: '' }]}
              >
                <Input placeholder={language.MANAGE_SPECIFICATION_UPDATED_PERSON} maxLength={200} rows={3} 
                disabled={true}

                />
              </Form.Item>
              </Col>
              </Row>
            </Form>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
}

export default ManageSpecificationEdit;


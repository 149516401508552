import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import './Css/Relation.css';
import RelationPage from './RelationPage';
import RelationDetailPage from './RelationDetailPage'

export default function RelationApp(props) {
    
    let { path } = useRouteMatch();
    const {language}=props;
    return (
        <>
            <Switch>
                <Route path={`${path}`} render={(props) => <RelationPage {...props} language={language} />} exact></Route>
                <Route path={`${path}/:_id`} render={(props) => <RelationDetailPage {...props}  language={language} />}></Route>
            </Switch>
        </>
    )
}

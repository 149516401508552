import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Breadcrum from '../Layout/Breadcrum';
import Helmet1 from '../Layout/Helmet';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import backgroundTitle from '../../asset/img/img-8.jpg';
import { Row, Col } from 'antd';
import { IntroCommon } from './Model/IntroModel';
import { SwapRightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { IntroTypeService } from './Service/IntroType.Service';
import { RESULT_CODES } from '../../common/Constant.Common';

const getMarginContainer = () => {
  const a = document.getElementsByClassName('container')[0];
  return a.currentStyle || window.getComputedStyle(a);
};
export default function IntroPage(props) {
  const [margin, setMargin] = useState('0');
  const [typePostIntroData, setTypePostIntroData] = useState([])
  const { language } = props;

  const handleResize = () => {
    setMargin(getMarginContainer().marginLeft);
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchAllTypePostIntro = useCallback(() => {
    IntroTypeService.getAllTypePostIntroActive().then(result => {
      if (result.returnCode !== RESULT_CODES.SUCCESS) return;
      //console.log("check result", result)
      setTypePostIntroData(result.data)
    })
      .catch((e) => {
        // TO DO
        console.log(e)
      });
  }, []);
  const processTypeData = useMemo(() => {
    if (typePostIntroData.length === 0) {
      return []
    }
    return typePostIntroData.map(item => {
      return {
        path: item.Type_Post_Slug,
        title: language === "vi" ? item.Type_Post_Title : item.Type_Post_Title_Eng,
        background: item.Type_Post_Image_Mobile.length > 0 ? `${process.env.REACT_APP_API_URL}/${item.Type_Post_Image_Mobile[0].url}` : "",
      }
    })
  }, [typePostIntroData, language])
  useEffect(() => {
    fetchAllTypePostIntro()
  }, [])
  return (
    <Helmet1 title={IntroCommon[language].title}>
      <Breadcrum data={[IntroCommon[language].title]}></Breadcrum>
      <TitleBreadcrum title={IntroCommon[language].titleMain} background={backgroundTitle}></TitleBreadcrum>
      <div className='intro'>
        <div className='intro__overlay' style={{
          width: `calc( 100% - ${margin})`
        }}></div>
        <div className='intro__main'>
          <div className='container'>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 30 }}>
              {
                processTypeData.map((item, index) => {
                  return (
                    <Col lg={8} md={12} sm={24} xs={24} key={index}>
                      <Link to={`/${language}${item.path}`} className='intro__main__item-container'>
                        <div className='intro__main__item' style={{ backgroundImage: `url('${item.background}')` }}>
                          <div className='intro__main__item--hover'>
                            <div className='intro__main__item__overlay'>
                            </div>
                            <div className='intro__main__item__main'>
                              <div className='intro__main__item__main__detail'>
                                <p>{item.title}</p>
                                <div className='intro__main__item__main__detail__child'>
                                  <p>
                                    {IntroCommon[language].buttonDetail}
                                  </p>
                                  <SwapRightOutlined />
                                  <div className='intro__main__item__main__detail__line'></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p>
                          {index + 1}. {item.title}
                        </p>
                      </Link>
                    </Col>
                  );
                })
              }
            </Row>
          </div>
        </div>
      </div>
    </Helmet1>
  );
}

import React, { useEffect, useState } from 'react'
import Helmet from '../Layout/Helmet'
import Breadcrum from '../Layout/Breadcrum'
import TitleBreadcrum from '../Layout/TitleBreadcrum'
import background from '../../asset/img/img-9.jpg'
import IntroButton from './Control/IntroButton'
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { IntroCommon, IntroFieldCommon } from './Model/IntroModel'
import IntroModal from './Control/IntroModal'

export default function IntroFieldPage(props) {
    const { language, dataSource, typePostData } = props;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const [modal, setModal] = useState({
        isDisplay: false,
        data: ''
    })
    const handleToggleModal = () => {
        setModal({
            ...modal,
            isDisplay: false,
        })
        document.getElementsByTagName("body")[0].style.overflow = "auto"
    }
    // console.log("check datasoure props", props)
    return (
        <Helmet title={language === "vi" ? typePostData[0]?.Type_Post_Title : typePostData[0]?.Type_Post_Title_Eng}>
            <Breadcrum data={[
                {
                    title: IntroCommon[language].title,
                    path: `/${language}${IntroCommon[language].path}`
                },
                language === "vi" ? typePostData[0]?.Type_Post_Title : typePostData[0]?.Type_Post_Title_Eng]}
            ></Breadcrum>
            <TitleBreadcrum title={language === "vi" ? typePostData[0]?.Type_Post_Title : typePostData[0]?.Type_Post_Title_Eng}></TitleBreadcrum>
            <div className="intro-generation" style={{ backgroundImage: `url(${background})` }}>
                <div className="intro-generation__overlay"></div>
                <div className="container">
                    <div className="intro-field">
                        <h2 className="intro-field__title intro-generation__title"
                            dangerouslySetInnerHTML={
                                {
                                    __html: language === "vi" ?
                                        typePostData[0]?.Type_Post_AdditionalInfo :
                                        typePostData[0]?.Type_Post_AdditionalInfo_Eng
                                }}>
                        </h2>
                        <Row gutter={{ xs: 5, sm: 10, md: 20, lg: 30 }} className="intro-field__list">
                            {
                                dataSource && dataSource.length > 0 && dataSource.map((item, index) => {
                                    return (
                                        <Col lg={8} md={12} sm={24} xs={24} key={item.title + index}>
                                            <div
                                                className="intro-field__item"
                                                onClick={() => {
                                                    setModal({
                                                        isDisplay: true,
                                                        data: item
                                                    })
                                                    document.getElementsByTagName("body")[0].style.overflow = "hidden"
                                                }}
                                            >
                                                <div className="intro-field__item__background-container">
                                                    <div className="intro-field__item__background" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL + "/" + item.Post_Intro_Image_Pc[0]?.url})` }}></div>
                                                </div>
                                                <div className="intro-field__item__detail">
                                                    <div className="intro-field__item__detail__circle"></div>
                                                    <p className="intro-field__item__detail__title" title={item.Post_Intro_Title}>{item.Post_Intro_Title}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                        <div className="intro__main__button-container intro__main__button-container--intro-field">
                            <Link to={`/${props.language}/intro`}>
                                <IntroButton title={IntroCommon[language].backIntro} color="white"></IntroButton>
                            </Link>
                        </div>
                    </div>
                </div>
                {modal.isDisplay ? <IntroModal toggle={handleToggleModal} language={language} data={modal.data}></IntroModal> : ""}
            </div>
        </Helmet >
    )
}

import { formatDateTime } from '../../Common/FormatCommon';
import ManageProductTypeEdit from '../ManageProductTypeEdit';

export const statusModel = [
  { name: 'Sử dụng', value: true },
  { name: 'Ngưng sử dụng', value: false },
];
export const initialFormField = {
  Product_Type_Code: {
    Name: 'Product_Type_Code',
    Required: true
  },
  Product_Type_Name: {
    Name: 'Product_Type_Name',
    Required: true
  },
  Product_Type_Name_Eng: {
    Name: 'Product_Type_Name_Eng',
    Required: true
  },
  CreatedDate: {
    Name: 'CreatedDate',
    Required: true,
  },
  CreatedPerson: {
    Name: 'CreatedPerson',
    Required: false,
  },
  UpdatedDate: {
    Name: 'UpdatedDate',
    Required: true,
  },
  UpdatedPerson: {
    Name: 'UpdatedPerson',
    Required: false,
  },
  Status: {
    Name: 'Status',
    Required: true
  },
};


export const productTypeInitData = {
  _id: null,
  Product_Type_Code: null,
  Product_Type_Name: null,
  Product_Type_Name_Eng: null,
  Status: null,
  CreatedDate: null,
  CreatedPerson:null,
  UpdatedDate: null,
  UpdatedPerson:null
};

export const columns = (language) => {
  return [
    {
      width: 300,
      title: language.MANAGE_PRODUCT_TYPE_CODE,
      dataIndex: 'Product_Type_Code',
      key: 'Product_Type_Code',
    },
    {
      width: 450,
      title: language.MANAGE_PRODUCT_TYPE_NAME_ENG,
      dataIndex: 'Product_Type_Name_Eng',
      key: 'Product_Type_Name_Eng'
    },
    {
      width: 450,
      title: language.MANAGE_PRODUCT_TYPE_NAME_VIE,
      dataIndex: 'Product_Type_Name',
      key: 'Product_Type_Name'
    },
    {
      width: 300,
      title: language.MANAGE_PRODUCT_TYPE_CREATED_DATE,
      dataIndex: 'CreatedDate',
      key: 'CreatedDate',
      render: (value) => {
        return formatDateTime(value);
      }
    },
    {
      width: 300,
      title: language.MANAGE_PRODUCT_TYPE_CREATED_PERSON,
      dataIndex: 'CreatedPerson',
      key: 'CreatedPerson',
    },
    {
      width: 300,
      title: language.MANAGE_PRODUCT_TYPE_UPDATED_DATE,
      dataIndex: 'UpdatedDate',
      key: 'UpdatedDate',
      render: (value) => {
        return value?formatDateTime(value):'';
      }
    },
    {
      width: 300,
      title: language.MANAGE_PRODUCT_TYPE_UPDATED_PERSON,
      dataIndex: 'UpdatedPerson',
      key: 'UpdatedPerson',
    },
    {
      title: language.MANAGE_PRODUCT_TYPE_STATUS,
      dataIndex: 'Status',
      key: 'Status',
      render: (value) => {
        return value === false ? 'Ngưng sử dụng' : 'Sử dụng';
      }
    },
    {
      title: '',
      dataIndex: '_id',
      render: (value) => {
        return <ManageProductTypeEdit _id={value} />;
      }
    }
  ];
};



import { SearchOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import backgroundTitle from '../../asset/img/img-8.jpg';
import background from '../../asset/img/img-9.jpg';
import Breadcrum from '../Layout/Breadcrum';
import Helmet from '../Layout/Helmet';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import { RelationCommon } from './Model/RelationModel';
import { SwapRightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import tgcd from '../../asset/img/tro_giup_co_dong.png';
import MpsRangePicker from '../MpsCotrols/MpsRangePicker/MpsRangePicker';
import RelationItem from './Control/RelationItem';
import { RelationService } from './Service/RelationService';

export default function RelationPage(props) {
  const [dataRelation, setDataRelation] = useState(null);
  const [dataSort, setDataSort] = useState([]);


  const [dataSearch, setDataSearch] = useState('');
  const [dataSearchShow, setDataSearchShow] = useState(null);

  const [initSort, setInitSort] = useState(null);
  const { language } = props;
  const handleValueSort = (value) => {
    setDataSort(value);
  };
  const handleSearchChange = (e) => {
    // console.log(e.target.value)
    setDataSearch(e.target.value);
  };
  const handleSearchShow = (e) => {
    setDataSearchShow(e);
  };

  useEffect(() => {
    async function getRelation() {
      const array = [];
      const dataCategory = await RelationService.getRelation_Category();
      for (let i = 0; i < dataCategory.result.length; i++) {
        array.push(RelationService.search({ CreatedAt: dataSort, Language: language, Status: true, Relation_Type: dataCategory.result[i]._id, Relation_Title: dataSearchShow }, { page: 1, limit: 3, sort: ['CreatedAt'], sortAscending: false }));
      }
      return {
        category: dataCategory,
        array,
      };
    }
    getRelation().then((data) => {
      Promise.all(data.array).then((data1) => {
        setDataRelation({
          category: data.category,
          relation: data1,
        });
      });
    });
  }, [dataSort, dataSearchShow, language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let dateNow = Date.now();
    dateNow = new Date(dateNow);
    const yearNow = dateNow.getFullYear();
    const sort = [
      {
        value: 0,
        interval: [],
        content: RelationCommon[language].initSort,
      },
    ];
    for (let i = 1; i < 4; i++) {
      sort.push({
        content: yearNow - i * 1.0 + 1,
        interval: [`${yearNow - i * 1.0 + 1}-01-01`, `${yearNow - i * 1.0 + 1}-12-30`],
        value: i,
      });
    }
    setInitSort(sort);
  }, [language]);

  const handleSearchClick = () => {
    const searchModel = { Status: true, Relation_Title: dataSearch };
    const searchOption = { page: 1, limit: 10, sort: ['CreatedAt'], sortAscending: false };

    RelationService.search(searchModel, searchOption)
      .then((result) => {
        setDataSearch(result);
      })
      .catch(() => {
        // TO DO
      });
  };

  return (
    dataRelation && (
      <Helmet title={RelationCommon[language].title}>
        <Breadcrum data={[RelationCommon[language].title]}></Breadcrum>
        <TitleBreadcrum title={RelationCommon[language].title} background={backgroundTitle}></TitleBreadcrum>
        <div className='relation-generation' style={{ backgroundImage: `url(${background})` }}>
          <div className='relation-generation__overlay'></div>
          <div className='container'>
            <div className='relation-generation__heading'>
              <div className='generation-rangedate__heading__item generation-rangedate__heading__item__input'>
                <label>{RelationCommon[language].documentRangeTime}</label>
                <MpsRangePicker language={language} onChange={(e) => handleValueSort(e)} className='generation-rangedate__heading__item__input'></MpsRangePicker>
              </div>
              <div className='generation-search__heading__item'>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSearchShow(dataSearch);
                  }}
                >
                  <input value={dataSearch} onChange={(e) => handleSearchChange(e)} type='text' placeholder={RelationCommon[language].search} name='search' />
                  <button onClick={() => handleSearchShow(dataSearch)}>
                    <SearchOutlined />
                  </button>
                </form>
              </div>
            </div>

            <div className='relation-container'>
              <div className='relation__main'>
                {dataRelation.category.result.map((item, index) => {
                  if (index === dataRelation.category.result.length - 1) return '';
                  return (
                    <div className='relation__main--list-item' key={item['Relation_Category_Title'] + index}>
                      <div className='relation__main__title'>
                        <div className='relation__main__title__main'>
                          <div className='relation__main__title__main__circle'></div>
                          <h2>{language === 'vi' ? item['Relation_Category_Title'] : item['Relation_Category_Title_ENG']}</h2>
                        </div>
                        <Link to={`relation/${item['_id']}`} className='relation__main__title__sub'>
                          <span>{RelationCommon[language].seeAll}</span>
                          <span className='relation__main__title__sub__icon'>
                            <SwapRightOutlined />
                          </span>
                          <div className='relation__main__title__sub__line'></div>
                        </Link>
                      </div>
                      <div className='relation__main__line-background'></div>
                      <div className='relation__main__item'>
                        {dataRelation.relation[index].result.total === 0 ? (
                          <p className='relation__main__item__empty'>{RelationCommon[language].empty}</p>
                        ) : (
                          dataRelation.relation[index].result.docs.map((item, index) => {
                            if (index >= 3) return null;
                            return <RelationItem key={index + item.Relation_Title} data={item} language={language}></RelationItem>;
                          })
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className='relation__help' style={{ backgroundImage: `url(${tgcd})` }}>
              <Link to='contact/?isInvestorRelation=true'>
                <div className='relation__help__content'>
                  {language === 'vi' ? dataRelation.category.result[dataRelation.category.result.length - 1].Relation_Category_Title : dataRelation.category.result[dataRelation.category.result.length - 1].Relation_Category_Title_ENG}
                  <span className='relation__help__button__icon'>
                    <SwapRightOutlined />
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </Helmet>
    )
  );
}

import React from 'react';
import CKEditor from 'ckeditor4-react';


function MpsRichTextEditor(props) {

  const handleEditorChange = event => {
    props.onChange(event.editor.getData());
  };

  return (
    <div className='mps-richtexteditor'>
      {props.disabled === true ?
        <CKEditor
          config={{
            enterMode: 3,
            language: 'vi',
            resize_minWidth: '100%',
            resize_maxHeight: 600,
            //filebrowserBrowseUrl: '/browser/browse.php',
            //filebrowserUploadUrl: '/uploader/upload.php',
            forcePasteAsPlainText: false,
            pasteFromWordRemoveStyles: false,
            pasteFromWordRemoveFontStyles: false,
            allowedContent: true,
            pasteFromWordNumberedHeadingToList: false,
            pasteFromWordPromptCleanup: false,
            pasteFilter: null,
            extraPlugins: ['justify', 'colorbutton', 'font'],
            colorButton_enableMore: true
          }}
          data={props.data || ``}
          readOnly={props.readOnly}
          onChange={handleEditorChange}
        /> :
        <CKEditor
          config={{
            enterMode: 3,
            language: 'vi',
            resize_minWidth: '100%',
            resize_maxHeight: 600,
            //filebrowserBrowseUrl: '/browser/browse.php',
            //filebrowserUploadUrl: '/uploader/upload.php',
            forcePasteAsPlainText: false,
            pasteFromWordRemoveStyles: false,
            pasteFromWordRemoveFontStyles: false,
            allowedContent: true,
            pasteFromWordNumberedHeadingToList: false,
            pasteFromWordPromptCleanup: false,
            pasteFilter: null,
            extraPlugins: ['justify', 'colorbutton', 'font', 'colordialog'],
          }}
          data={props.data || ''}
          onChange={handleEditorChange}
        />
      }
    </div>
  );
}

export default React.memo(MpsRichTextEditor);

import { authHeader } from "../../../helper/authHeader";

export const IntroTypeService = {

    getAllTypePostIntroActive,
    getAllPostIntroBySlug
};

function getAllTypePostIntroActive() {
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);

    const requestOptions = {
        headers,
        method: 'GET',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/TypePostIntro/get-all-active-type-post-intro`, requestOptions).then(handleResponse);
}

function getAllPostIntroBySlug(typeSlug, language) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);

    const requestOptions = {
        headers,
        method: 'GET',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/PostIntro/get-post-intro-by-type/${typeSlug}/${language}`, requestOptions).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

export const RecruitCommon = {
  vi: {
    path: 'vi/recruit',
    title: 'Tuyển dụng',
    seeMore: 'Xem thêm',
    required: 'Đây là trường bắt buộc',
    invalidEmail: 'Email không hợp lệ',
    detail: 'Chi tiết',
    apply: 'Gửi CV',
    back: 'Quay lại',
    close: 'Đóng',
    RecruitCulture: {
      title: 'VĂN HÓA DOANH NGHIỆP',
      content: [
        {
          title: 'TẬP ĐOÀN CUNG ỨNG THÉP HÀNG ĐẦU VIỆT NAM',
          content: 'Tập Đoàn Thép Tiến Lên hiện nay với ngành nghề chính là kinh doanh sắt thép đa dạng và có thị phần dẫn đầu trong số các đơn vị thương mại sắt thép trên toàn lãnh thổ Việt Nam. Với bề dày kinh nghiệm và uy tín hàng đầu thị trường, Tập Đoàn luôn mong muốn chiêu mộ những người lao động xuất sắc để cùng đồng hành và phát triển lâu dài.'
        },
        {
          title: 'MÔI TRƯỜNG THÂN THIỆN VÀ CHUYÊN NGHIỆP',
          content: 'Tạo ra môi trường làm việc thân thiện và chuyên nghiệp là sứ mệnh của Tập Đoàn. Tập Đoàn luôn tạo ra môi trường tốt nhất để toàn thể cán bộ công nhân viên an tâm công tác và có thể cống hiến hết sức mình. Mọi quyền lợi của cán bộ công nhân viên chức theo bộ luật lao động hiện hành đều được đảm bảo. Ngoài ra, Tập Đoàn luôn lưu tâm đến những phúc lợi hằng năm của các cán bộ công nhân viên chức.'
        },
        {
          title: 'CƠ HỘI THĂNG TIẾN VÀ PHÁT TRIỂN TỐI ĐA',
          content: 'Tập Đoàn luôn hiểu rõ khát khao phát triển bản thân và mong muốn thăng tiến trong công việc của từng cán bộ công nhân viên. Khi làm việc tại Tập Đoàn, toàn thể cán bộ công nhân viên sẽ đều có cơ hội thăng tiến trong công việc và phát triển những kiến thức và kĩ năng cần thiết để liên tục chinh phục được những tầm cao mới.',
        }
      ]
    },
    RecruitInfo: {
      title: 'THÔNG TIN TUYỂN DỤNG',
      titleDetail: 'THÔNG TIN CHI TIẾT',
      titleDetailOther: 'CÁC TIN TUYỂN DỤNG KHÁC',
      note: ` 
            Email tuyển dụng của các ứng viên sẽ được lọc và được phản hồi sau 72 tiếng kể từ thời gian gửi. 
            <br />Nếu sau 72 tiếng ứng viên chưa nhận được Email phản hồi, ứng viên hãy kiểm tra thùng thư 
            rác hoặc gửi lại hồ sơ xin việc lại cho Tập Đoàn qua website hoặc qua email 
            careers@tienlen.com.vn.
            <br /> Trân trọng.`
    },
    RecruitModal: {
      title: 'THÔNG TIN ỨNG TUYỂN',
      position: 'Vị trí ứng tuyển',
      name: 'Họ tên (*)',
      phone: 'Số điện thoại (*)',
      email: 'Email (*)',
      attachment: 'CV đính kèm (định dạng .ppt, .excel, .pdf hoặc .docx) (*)',
      message: 'Lời nhắn',
      hint: '(*) Các thông tin bắt buộc',
      bonus: 'CV ứng tuyển của các ứng viên sẽ được lọc và được phản hồi sau 72 tiếng kể từ thời gian gửi. Nếu sau 72 tiếng ứng viên chưa nhận được email phản hồi, mong ứng viên hãy gửi lại hồ sơ xin việc lại cho Tập Đoàn. </br>Trân trọng.'
    },
    SuccessResultMessage: `
      CV của bạn đã được gửi đến bộ phận tuyển dụng thành công.
      Cảm ơn bạn đã quan tâm đến vị trí ứng tuyển và nộp hồ sơ cho Tập Đoàn.
      Bộ phận tuyển dụng sẽ xem xét và liên hệ với bạn trong 72 tiếng để trả lời kết quả.
    `
  },
  en: {
    path: 'en/recruit',
    title: 'Recruitment',
    seeMore: 'See more',
    required: 'Required field',
    invalidEmail: 'Invalid email',
    detail: 'Details',
    apply: 'Send CV',
    back: 'Go Back',
    close: 'Close',
    RecruitCulture: {
      title: 'Corporate Culture',
      content: [
        {
          title: 'Vietnam\'s leading steel supplier',
          content: `Tien Len Steel hasa strong market share in the field of steel trading. With a wealth of experience 
                    and leading reputation in the market, we constantly look to add ambitious and passionate 
                    members to our growing family`
        },
        {
          title: 'Friendly and professional environment',
          content: `It is our mission to provide a friendly and professional working environment. We strive to 
                    provide an environment where in our employees can work safely and individually grow. We 
                    rigorously follow the labor law to protect and guarantee the rights and benefits of our employees. 
                    In addition, we also provide annual additional benefits aside from fundamental benefits required 
                    by law.
                    `
        },
        {
          title: 'Equal and ample opportunity for promotion and development',
          content: `We understand, value and welcome anyone with the desire to grow and the ambition to advance
                    in their field of work. We ensure that our employees all have equal opportunity to advance and
                    we guarantee each and one them a space for personal development of the necessary knowledge 
                    and skills required in their specific field of work.`,
        }
      ]
    },
    RecruitInfo: {
      title: 'Recruitment Information',
      titleDetail: 'Details information',
      titleDetailOther: 'Other job postings',
      note: ` 
            Job application sent through this website will be filtered accordingly 
            and answered within 72 hours from the time we receive your email. 
            </br>If you do not 
            receive a reply from us within 72 hours, please check your junk mail box or contact 
            us again through the contact page on our website or through email
            careers@tienlen.com.vn`
    },
    RecruitModal: {
      title: 'Application Information',
      position: 'Position',
      name: 'Full name (*)',
      phone: 'Phone number (*)',
      email: 'Email (*)',
      attachment: 'Attached CV (.ppt, .excel, .pdf or .docx format) (*)',
      message: 'Message',
      hint: '(*) Required Information',
      bonus: `Job application sent through this website will be filtered accordingly 
            and answered within 72 hours from the time we receive your email. 
            If you do not receive a reply from us within 72 hours, please check your junk mail box or contact 
            us again through the contact page on our website or through email
            careers@tienlen.com.vn`
    },
    SuccessResultMessage: `
      Your application has been sent successfully. 
      Thank you for applying for a position at Tien Len Steel. We will review your application and contact you within 72 hours.
    `
  }
};

export const recruitmentInitData = {
  _id: null,
  Recruitment_Title: null,
  Recruitment_Title_Eng: null,
  Recruitment_Type: null,
  Recruitment_Address: null,
  Recruitment_Quantity: null,
  Recruiment_Detail_Info: null,
  Recruiment_Detail_Info_Eng: null,
  Recruiment_ExpiredDate: null,
  Status: null,
  CreatedDate: null,
  UpdatedDate: null,
};

export const numberOfPeopleUnit = (quantity) => {
  return quantity > 1 ? 'People' : 'Person';
};





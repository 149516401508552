
import React from 'react'

export default function Helmet(props){
    document.title = 'Tiến Lên - '+props.title
    return (
        <div>
            {props.children }
        </div>
    )
}
import { authHeader } from "../../../../helper/authHeader";

export const ManageNewsGroupService = {
  search,
  getById,
  getBySlug,
};

function search(searchModel, searchOption) {
  const model = {
    searchModel,
    searchOption,
  };

  const auth = authHeader();
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", auth);

  const requestOptions = {
    headers,
    method: "POST",
    body: JSON.stringify(model),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/NewsGroup/search`,
    requestOptions
  ).then(handleResponse);
}

function getById(_id) {
  const auth = authHeader();
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", auth);
  const requestOptions = {
    headers,
    method: "GET",
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/NewsGroup/getById/${_id}`,
    requestOptions
  ).then(handleResponse);
}

function getBySlug(slug) {
  const auth = authHeader();
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", auth);
  const requestOptions = {
    headers,
    method: "GET",
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/NewsGroup/getBySlug/${slug}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

export const LANGUAGE_STATE = {
  'vi': {
    MANAGE_PRODUCT: 'Sản phẩm',
    MANAGE_PRODUCT_SEARCH_INFO: 'Thông tin tìm kiếm',

    MANAGE_PRODUCT_NAME: 'Tên sản phẩm',
    MANAGE_PRODUCT_CATEGORY: 'Phân loại',
    MANAGE_PRODUCT_CREATED_DATE: 'Ngày tạo',
    MANAGE_PRODUCT_SEARCH_FROM_DATE: 'Từ ngày',
    MANAGE_PRODUCT_SEARCH_TO_DATE: 'Đến ngày',
    MANAGE_PRODUCT_STATUS: 'Trạng thái',

    MANAGE_PRODUCT_NAME_VIE: 'Tên sản phẩm tiếng Việt',
    MANAGE_PRODUCT_NAME_VIE_PLACEHOLDER: 'Tên sản phẩm tiếng Việt (tối đa 200 ký tự)',
    MANAGE_PRODUCT_NAME_ENG: 'Tên sản phẩm tiếng Anh',
    MANAGE_PRODUCT_NAME_ENG_PLACEHOLDER: 'Tên sản phẩm tiếng Anh (tối đa 200 ký tự)',
    MANAGE_PRODUCT_CATEGORY_PLACEHOLDER: 'Chọn phân loại',
    MANAGE_PRODUCT_STATUS_PLACEHOLDER: 'Chọn trạng thái',
    MANAGE_PRODUCT_IMAGES: 'Hình ảnh',
    MANAGE_PRODUCT_DETAIL_INFO: 'Thông tin sản phẩm Tiếng Việt',
    MANAGE_PRODUCT_DETAIL_INFO_ENG: 'Thông tin sản phẩm Tiếng Anh',
    MANAGE_SPECIFICATION:'Thông tin quy cách',
    MANAGE_SPECIFICATION_PRODUCT_TYPE:'Chủng loại',
    MANAGE_SPECIFICATION_NAME: 'Quy cách',
    MANAGE_SPECIFICATION_LENGTH: 'Độ dài (mm)',
    MANAGE_SPECIFICATION_WIDTH: 'Bề rộng (mm)',
    MANAGE_SPECIFICATION_HEIGHT: 'Độ dày (mm)',
    MANAGE_SPECIFICATION_UNIT: 'Đơn vị',
    MANAGE_SPECIFICATION_WEIGHT_OVER_UNIT: 'Trọng lượng/m',
    MANAGE_SPECIFICATION_WEIGHT:'Khối lượng (kg)',
    MANAGE_PRODUCT_CREATE_NEW: 'Đăng sản phẩm mới',
    MANAGE_PRODUCT_EDIT: 'Xem thông tin sản phẩm',
    MANAGE_PRODUCT_GENERAL_INFO: 'Thông tin chung',
    MANAGE_PRODUCT_SELECT_DATE: 'Chọn ngày',
    MANAGE_PRODUCT_VIEW_DETAIL: 'Xem chi tiết',

    // ACTION
    MANAGE_PRODUCT_CANCEL: 'Huỷ',
    MANAGE_PRODUCT_DELETE: 'Xóa',
    MANAGE_PRODUCT_SAVE: 'Lưu',
    MANAGE_PRODUCT_AGREE: 'Có',
    MANAGE_PRODUCT_DISAGREE: 'Không',
    MANAGE_PRODUCT_CLEAR_SELECT: 'Xóa lựa chọn',
    MANAGE_PRODUCT_SEARCH: 'Tìm kiếm',

    // MESSAGE
    MANAGE_PRODUCT_CONFIRM_DELETE: 'Bạn có chắc chắn muốn xóa dữ liệu này ?',

  },
  'en': {

  },
};

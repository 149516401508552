import React, { useEffect, useRef, useState } from 'react';
import { Form, Row, Col, Input, Button, Radio, Select } from 'antd';
import { useLocation } from 'react-router-dom';
import Helmet from '../Layout/Helmet';
import Breadcrum from '../Layout/Breadcrum';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import { ContactCommon } from './Model/ContactModel';
import ContactModal from './ContactModal';
import ReCAPTCHA from 'react-google-recaptcha';
import { ContactService } from './Service/ContactService';
import { RESULT_CODES } from '../../common/Constant.Common';
import backgroundTitle from '../../asset/img/img-8.jpg';
import background from '../../asset/img/img-9.jpg';
import backgroundInfo from '../../asset/img/img-3.jpg';
import logo from '../../asset/img/Logo.png';
import './Css/Contact.css';

const { Option } = Select;

export default function ContactPage(props) {
  const { language } = props;
  const recaptchaRef = useRef(null);
  const location = useLocation();
  const [captcha, setCaptcha] = useState(null);
  const [modalContact, setmodalContact] = useState(false);
  const [radio, setRadio] = useState(1);
  const [form] = Form.useForm();
  const handleCloseContactModal = () => {
    setmodalContact(false);
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  };

  const onSubmitForm = async (values) => {
    if (!captcha) return;

    values.captcha = captcha;
    values.language = language;

    const data = radio === 1 ? await ContactService.sendMail(values) : await ContactService.sendMailRelationContact(values);
    if (data.returnCode === RESULT_CODES.SUCCESS) {
      form.resetFields();
      // Reset captcha
      if (recaptchaRef.current) recaptchaRef.current.reset();
      // Mở modal thông báo thành công
      setmodalContact(true);
    }

    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  };

  const onChangeCaptcha = (value) => {
    setCaptcha(value);
  };

  const onChangeRadio = (e) => {
    setRadio(e.target.value);
  };

  useEffect(() => {
    form.resetFields();
  }, [language, form]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const isInvestorRelation = new URLSearchParams(location.search).get('isInvestorRelation');
    if (isInvestorRelation) {
      setRadio(2);
      form.setFieldsValue({ targetContact: 2 });
    } else {
      setRadio(1);
      form.setFieldsValue({ targetContact: 1 });
    }
  }, [location.search, form]);

  return (
    <Helmet title={ContactCommon[language].title}>
      <Breadcrum data={[
        ContactCommon[language].title
      ]}
      ></Breadcrum>
      <TitleBreadcrum title={ContactCommon[language].title} background={backgroundTitle}></TitleBreadcrum>
      <div className='contact-generation' style={{ backgroundImage: `url(${background})` }}>
        <div className='contact-generation__overlay'></div>
        <div className='contact-main'>
          <div className='container'>
            <div className='contact-main__title'>
              <div className='contact-main__title__circle'></div>
              <h3>{ContactCommon[language].ContactSupport.title}</h3>
            </div>
            <Form form={form} className='contact-main__form' onFinish={onSubmitForm}
              initialValues={{ call: ContactCommon[language].ContactSupport.main.call.value[0] }}>
              <h4>{ContactCommon[language].ContactSupport.main.title}</h4>
              <div className='contact-main__form__checkbox'>
                <p className='contact-main__form-title'>
                  {ContactCommon[language].ContactSupport.main.targetContact.title}
                </p>
                <Form.Item
                  initialValue={radio}
                  name='targetContact'
                  className='contact-main__form-radio'
                  rules={[{ required: true, message: ContactCommon[language].ContactSupport.main.targetContact.warning }]}
                >
                  <Radio.Group onChange={onChangeRadio}>
                    <Radio value={1} className='contact-main__form-radio__main'>{ContactCommon[language].ContactSupport.main.targetContact.label[0]}</Radio>
                    <Radio value={2} className='contact-main__form-radio__main'>{ContactCommon[language].ContactSupport.main.targetContact.label[1]}</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <p className='contact-main__form-hint' dangerouslySetInnerHTML={{ __html: ContactCommon[language].ContactSupport.main.hint }}>
              </p>
              <div className='contact-main__form__main'>
                <div className='contact-main__form__main__field-flex'>
                  <p className='contact-main__form-title'>{ContactCommon[language].ContactSupport.main.call.title}</p>
                  <Form.Item name='call' >
                    <Select>
                      {ContactCommon[language].ContactSupport.main.call.value.map((item, index) => {
                        return <Option value={`${item}`} key={index + 'call'}>{item}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <p className='contact-main__form-title'>
                      {ContactCommon[language].ContactSupport.main.nameCompany.title}
                    </p>
                    <Form.Item
                      name='nameCompany'
                      rules={[{ required: false, message: ContactCommon[language].ContactSupport.main.nameCompany.warning }]}
                    >
                      <Input type='text'></Input>
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <p className='contact-main__form-title'>
                      {ContactCommon[language].ContactSupport.main.taxCode.title}
                    </p>
                    <Form.Item
                      name='taxCode'
                    >
                      <Input type='text'></Input>
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <p className='contact-main__form-title'>
                      {ContactCommon[language].ContactSupport.main.name.title}
                    </p>
                    <Form.Item
                      name='name'
                      rules={[{ required: true, message: ContactCommon[language].ContactSupport.main.name.warning }]}
                    >
                      <Input type='text'></Input>
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <p className='contact-main__form-title'>
                      {ContactCommon[language].ContactSupport.main.phone.title}
                    </p>
                    <Form.Item
                      name='phone'
                    >
                      <Input type='text'></Input>
                    </Form.Item>
                  </Col>
                  <Col span={24} style={{ display: `${radio === 1 ? 'none' : 'unset'}` }}>
                    <p className='contact-main__form-title'>
                      {ContactCommon[language].ContactSupport.main.idshareHolder.title}
                      <span>{ContactCommon[language].ContactSupport.main.idshareHolder.hint}</span>
                    </p>
                    <Form.Item
                      name='idshareHolder'
                    >
                      <Input type='text'></Input>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <p className='contact-main__form-title'>
                      {ContactCommon[language].ContactSupport.main.email.title}
                    </p>
                    <Form.Item
                      name='email'
                      rules={[{ required: true, type: 'email', message: ContactCommon[language].ContactSupport.main.email.warning }]}
                    >
                      <Input type='text'></Input>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <p className='contact-main__form-title'>
                      {ContactCommon[language].ContactSupport.main.message.title}
                    </p>
                    <Form.Item
                      name='message'
                      rules={[{ required: true, message: ContactCommon[language].ContactSupport.main.message.warning }]}
                    >
                      <Input.TextArea rows={6}></Input.TextArea>
                    </Form.Item>
                  </Col>
                </Row>
                <p className='contact-main__form-require'>
                  {ContactCommon[language].ContactSupport.main.note}
                </p>
                <ReCAPTCHA
                  // key={`${key.current} recaptcha`}
                  ref={recaptchaRef}
                  className='captcha'
                  sitekey='6Lerft4dAAAAAPVeYYDo7oG_2CBiC3wLVjMiymln'
                  onChange={onChangeCaptcha}
                  hl={language}
                />
              </div>
              <Button className='contact-button-submit' htmlType='submit'>
                {ContactCommon[language].ContactSupport.main.submit}
                <i className='fas fa-paper-plane'></i>
              </Button>
            </Form>
            <div className='contact-main--background'></div>
            <div className='contact-main__title'>
              <div className='contact-main__title__circle'></div>
              <h3>{ContactCommon[language].ContactMethod.title}</h3>
            </div>
            <div className='contact-main__method'>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 50 }}>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <table className='contact-main__method__table'>
                    <tbody>
                      {ContactCommon[language].ContactMethod.main.map((item, index) => {
                        if (index === 0)
                          return (
                            <tr key={index + item[0]}>
                              <th>{item[0]}</th>
                              <th>{item[1]}</th>
                            </tr>
                          );
                        return (
                          <tr key={index + item[0]}>
                            <td>{item[0]}</td>
                            <td>{item[1]}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <div className='contact-main__method__background' style={{ backgroundImage: `url(${backgroundInfo})` }}>
                    <div className='contact-main__method__fade'>

                    </div>

                  </div>
                </Col>
              </Row>
            </div>
            <div className='contact-main--background'></div>
            <div className='contact-main__title'>
              <div className='contact-main__title__circle'></div>
              <h3>{ContactCommon[language].ContactInfo.title}</h3>
            </div>
            <div className='contact-main__info'>
              <div className='contact-main__info__heading'>
                <img src={logo} alt='logo'></img>
                <div className='contact-main__info__heading__detail'>
                  <h4>{ContactCommon[language].ContactInfo.nameCompany}</h4>
                  <p>
                    <i className='fas fa-map-marker-alt'></i>
                    {ContactCommon[language].ContactInfo.address}
                  </p>
                  <p className='contact-main__info__phone'>
                    <i className='fas fa-phone'></i>
                    {ContactCommon[language].ContactInfo.phone}
                  </p>
                  <p>
                    <i className='fas fa-fax'></i>
                    {ContactCommon[language].ContactInfo.fax}
                  </p>
                </div>
              </div>
              <div className='contact-main__info__ggmap'>
              {/* <iframe style={{ 'border': 0 }} width={'100%'}
                  title='Tien Len Steel Location'
                  height={'100%'}
                  src='https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=tiến lên steel&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'>
                </iframe> */}
                <iframe
                style={{ 'border': 0 }} width={'100%'}
                  title='Tien Len Steel Location'
                  height={'100%'}
                 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4658.227468387678!2d106.86909914833561!3d10.956817296567744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174de7f638a5bd1%3A0x7538ac1aafc06690!2zQ8O0bmcgdHkgQ-G7lSBwaOG6p24gVOG6rXAgxJFvw6BuIFRow6lwIFRp4bq_biBMw6pu!5e0!3m2!1svi!2s!4v1657861438557!5m2!1svi!2s"  loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
            <div className='contact-main--background'></div>
          </div>
        </div>
      </div>
      {modalContact ?
        <ContactModal handleCloseContactModal={handleCloseContactModal} language={language}></ContactModal>
        : ''
      }
    </Helmet>
  );
}

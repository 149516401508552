import React, { useEffect, useState } from 'react'
import { HomeCommon } from '../Model/HomeModel';
import { Row, Col } from 'antd';
import { SwapRightOutlined } from "@ant-design/icons"
import { RelationService } from '../../Relation/Service/RelationService';
import moment from 'moment';



function HomeShareHolderNewsItem(props) {

    return (
        <Col lg={8} sm={24} md={12} xs={24}>
            <div className="home__main__news__item">
                <div className="home__main__news__item__img" style={{ backgroundImage: `url("${HomeCommon[props.language].ShareHolderNewsSample.main[props.index].img}")` }}></div>
                <div className="home__main__news__item__content">
                    <span>{moment(props.data.CreatedAt).format('DD/MM/YYYY')}</span>
                    <p title={props.data.Relation_Title}>{props.data.Relation_Title}</p>
                    <div className="home__main__news__item__content__detail">
                        <div className="home__main__news__item__content__detail__main">
                            <a target='_blank' href={`${process.env.REACT_APP_API_URL + `/relation/${props.data.Relation_File}`}`} className="home__main__news__item__content__detail__main__item">
                                {HomeCommon[props.language].buttonDetail}
                                <span className="home__main__button__icon">
                                    <SwapRightOutlined />
                                </span>
                            </a>
                            <div className="home__main__news__item__content__detail--line">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    )
}

export default function HomeShareHolderNews(props) {
    const [data, setData] = useState(null);
    const onHandleShowItem = () => {
        let result = null;
        result = data.map((item, index) => {
            return (<HomeShareHolderNewsItem
                key={index + item.Relation_Title}
                index={index}
                data={item}
                language={props.language}
            ></HomeShareHolderNewsItem>)
        })
        return result;
    }
    useEffect(() => {
        async function getRelation() {
            const relation = await RelationService.search({ Status: true, Language: props.language },
                { page: 1, limit: 3, sort: ['CreatedAt'], sortAscending: false })
            setData(relation.result.docs)
        }
        getRelation();
    }, [props.language])
    return data && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="center">
            {onHandleShowItem()}
        </Row>
    )
}
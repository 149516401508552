import { SearchOutlined } from '@ant-design/icons';
import { Form, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import background from '../../asset/img/img-9.jpg';
import Breadcrum from '../Layout/Breadcrum';
import Helmet1 from '../Layout/Helmet';
import PaginationMain from '../Layout/PaginationMain';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import MpsRangePicker from '../MpsCotrols/MpsRangePicker/MpsRangePicker';
import RelationItem from './Control/RelationItem';
import { RelationCommon } from './Model/RelationModel';
import { RelationService } from './Service/RelationService';

const { Option } = Select;

export default function RelationDetailPage(props) {
  const { _id } = props.match.params;
  const { language } = props;
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [data, setData] = useState(null);
  const [dataCategories, setDataCategories] = useState(null);
  const [dataSort, setDataSort] = useState([]);
  const [, setPage] = useState(1);
  const [initSort, setInitSort] = useState(null);
  const [dataSearch, setDataSearch] = useState('');
  const [dataSearchShow, setDataSearchShow] = useState(null);

  const handleValueSort = (value) => {
    setDataSort(value);
  };
  const handleSearchChange = (e) => {
    // console.log(e.target.value)
    setDataSearch(e.target.value)
  }
  const handleSearchShow = (e) => {
    setDataSearchShow(e)
  }

  const handleChangePage = (currentPage) => {
    setPage(currentPage);

    const searchModel = { Status: true, CreatedAt: dataSort, Language: language, Relation_Type: parseInt(_id, 10), Relation_Title: dataSearchShow };
    const searchOption = { page: currentPage, limit: 10, sort: ['CreatedAt'], sortAscending: false };

    RelationService.search(searchModel, searchOption).then(result => {
      setData(result.result);
    }).catch(() => {
      // TO DO
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const searchModel = { Status: true, CreatedAt: dataSort, Language: language, Relation_Type: parseInt(_id, 10), Relation_Title: dataSearchShow };
    const searchOption = { page: 1, limit: 10, sort: ['CreatedAt'], sortAscending: false };
    Promise.all([RelationService.getRelation_Category(), RelationService.search(searchModel, searchOption)]).then(result => {
      const [dataRelationCategory, dataRelation] = result;

      setDataCategories({
        current: dataRelationCategory.result.find(item => item._id === parseInt(_id, 10)),
        categories: dataRelationCategory.result
      });

      setData(dataRelation.result);
      setPage(1);

      if (formRef.current) form.resetFields();
    }).catch(() => {
      // TO DO
    });
  }, [_id, form, dataSort, dataSearchShow, language]);

  useEffect(() => {
    let dateNow = Date.now();
    dateNow = new Date(dateNow);
    const yearNow = dateNow.getFullYear();
    const sort = [
      {
        value: 0,
        interval: [],
        content: RelationCommon[language].initSort,
      }
    ];
    for (let i = 1; i < 4; i++) {
      sort.push({
        content: yearNow - i * 1.0 + 1,
        interval: [
          `${yearNow - i * 1.0 + 1}-01-01`,
          `${yearNow - i * 1.0 + 1}-12-30`,
        ],
        value: i
      });
    }
    setInitSort(sort);
  }, [language]);

  return data && dataCategories && (
    <Helmet1 title={dataCategories.current.Relation_Category_Title}>
      <Helmet>
        <link rel='canonical' href={`https://product.tienlensteel.com.vn/${language}/relation`} />
      </Helmet>
      <Breadcrum data={[
        {
          title: RelationCommon[language].title,
          path: `/${language}${RelationCommon[language].path}`
        },
        language === 'vi' ? dataCategories.current.Relation_Category_Title : dataCategories.current.Relation_Category_Title_ENG
      ]}
      ></Breadcrum>
      <TitleBreadcrum title={language === 'vi' ? dataCategories.current.Relation_Category_Title : dataCategories.current.Relation_Category_Title_ENG}></TitleBreadcrum>
      <div className='relation-generation relation-detail-generation' style={{ backgroundImage: `url(${background})` }}>
        <div className='relation-generation__overlay'></div>
        <div className='relation-category-container'>
          <div className='container'>
            <div className='relation-category'>
              {
                dataCategories.categories.map((item, index) => {
                  if (item.Relation_Category_Title_ENG === 'Contact Us') {
                    return (
                      <Link to='../contact/?isInvestorRelation=true' className={`relation-category__item ${parseInt(_id, 10) === item._id ? 'active' : ''}`}
                        key={`${index}${item.Relation_Category_Title}category`}>
                        {language === 'vi' ? item.Relation_Category_Title : item.Relation_Category_Title_ENG}
                      </Link>
                    );
                  }
                  return (
                    <Link to={`${item._id}`} className={`relation-category__item ${parseInt(_id, 10) === item._id ? 'active' : ''}`}
                      key={`${index}${item.Relation_Category_Title}category`}>
                      {language === 'vi' ? item.Relation_Category_Title : item.Relation_Category_Title_ENG}
                    </Link>
                  );
                })
              }
            </div>
            <div className='relation-category--mobile'>
              <Form form={form} id='relation-category-form' name='relation-category-form' ref={formRef}>
                <Form.Item name='Relation_Category' initialValue={parseInt(_id, 10)}>
                  <Select style={{ width: '100%' }} className='relation-category--mobile__main'>
                    {
                      dataCategories.categories.map((item, index) => {
                        if (item.Relation_Category_Title_ENG === 'Contact Us') {
                          return (
                            <Option key={`${index}${item.Relation_Category_Title}mobile`} value={item._id} className='relation-category--mobile__main__item'>
                              <Link to='../contact/?isInvestorRelation=true'>
                                {language === 'vi' ? item.Relation_Category_Title : item.Relation_Category_Title_ENG}
                              </Link>
                            </Option>
                          );
                        }
                        return (
                          <Option key={`${index}${item.Relation_Category_Title}mobile`} value={item._id} className='relation-category--mobile__main__item'>
                            <Link to={`${item._id}`}>
                              {language === 'vi' ? item.Relation_Category_Title : item.Relation_Category_Title_ENG}
                            </Link>
                          </Option>
                        );
                      })
                    }
                  </Select>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='relation-generation__heading1' >
            <h2 className='container-title'>{language === 'vi' ? dataCategories.current.Relation_Category_Title
              : dataCategories.current.Relation_Category_Title_ENG}</h2>
          </div>
          <div className='relation-generation__heading' >

            <div className='generation-rangedate__heading__item generation-rangedate__heading__item__input'>
              <label >{RelationCommon[language].documentRangeTime}</label>
              <MpsRangePicker language={language} onChange={e => handleValueSort(e)} ></MpsRangePicker>
            </div>
            <div className='generation-search__heading__item' >
              <form onSubmit={(e) => { e.preventDefault(); handleSearchShow(dataSearch) }}>
                <input value={dataSearch} onChange={e => handleSearchChange(e)} type="text" placeholder={RelationCommon[language].search} name="search" />
                <button onClick={() => handleSearchShow(dataSearch)} ><SearchOutlined /></button>
              </form>
            </div>

          </div>
          <div className='relation-container'>
            <div className='relation__main relation__main-detail'>
              <div className='relation__main__item'>
                {
                  data.docs.length > 0 ? data.docs.map((item, index) => {
                    return <RelationItem key={index + item.Relation_Title} data={item} language={language}></RelationItem>;
                  }) :
                    <p className='relation__main__item__empty'>{RelationCommon[language].empty}</p>
                }
              </div>
              <div className='relation__main__pagination'>
                {
                  data.docs.length > 0 ?
                    <PaginationMain current={data.page} total={data.total} pageSize={data.limit} onChange={handleChangePage}></PaginationMain>
                    : ''
                }
              </div>
            </div>
            <div className='relation__main__line-background relation__detail__line-background'></div>
          </div>
        </div>
      </div>
    </Helmet1>
  );
}

import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import LoginPage from '../components/Admin/Login/LoginPage';
import '../components/Admin/Layout/Css/LayoutAdmin.css'
import AdminApp from '../components/Admin/AdminApp'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

export default function Admin() {
    const { path } = useRouteMatch();
    return (
        <>
            <ReactNotification />
            <Switch>
                <Route path={`${path}/login`} component={LoginPage}></Route>
                <Route path={`${path}`} component={AdminApp}></Route>
            </Switch>
        </>
    )
}

export const getCategoryNameByLanguage = (category, language = 'vi') => {
  return language === 'vi' ? category?.News_Category_Name : category?.News_Category_Name_Eng;
};

export const getGroupNameByLanguage = (group, language = 'vi') => {
  return language === 'vi' ? group?.News_Group_Name : group?.News_Group_Name_Eng;
};

export const getNewsNameByLanguage = (news, language = 'vi') => {
  return news.News_Title
};

export const getNewsDetailByLanguage = (news, language = 'vi') => {
  return news?.News_AdditionalInfo
};

export const getNewsExcerptByLanguage = (news, language = 'vi') => {
  return news?.News_Excerpt
};

import { store } from 'react-notifications-component';

const options = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animate__animated', 'animate__fadeIn'],
  animationOut: ['animate__animated', 'animate__fadeOut'],
  dismiss: {
    duration: 3000,
    onScreen: true
  }
};

export const openSuccessNotification = ({ title = '', message = '' }) => {
  store.addNotification({
    title,
    message,
    type: 'success',
    ...options
  });
};

export const openErrorNotification = ({ title = '', message = '' }) => {
  store.addNotification({
    title,
    message,
    type: 'danger',
    ...options,
  });
};


import { formatDateTime } from "../../Common/FormatCommon";
import ManageNewsEdit from "../ManageNewsEdit";

export const statusModel = [
  { name: "Hiển thị", value: true },
  { name: "Ẩn", value: false },
];

export const searchModel = {
  News_Title: null,
  News_Category: null,
  Status: null,
  CreatedDate: null,
};

export const newsSearchPlaceholder = {
  News_Title: "Tiêu đề",
  News_Category: "Thể loại",
  Status: "Trạng thái",
};

export const newsSearchLabel = {
  News_Title: "Tiêu đề",
  News_Category: "Thể loại",
  Status: "Trạng thái",
  CreatedDate: "Ngày tạo",
};

export const newsInitData = {
  _id: null,
  News_Title: null,
  News_Title_Eng: null,
  News_Category: null,
  News_Group: null,
  News_Image: null,
  News_Images: [],
  News_Excerpt: null,
  News_Excerpt_Eng: null,
  News_AdditionalInfo: null,
  News_AdditionalInfo_Eng: null,
  Language: "",
  Status: null,
  CreatedDate: null,
  UpdatedDate: null,
};

export const newsFormInitialField = {
  News_Title: {
    placeholder: "Tiêu đề (tối đa 200 ký tự)",
    required: true,
    name: "News_Title",
  },
  News_Title_Eng: {
    placeholder: "Tiêu đề (tối đa 200 ký tự)",
    required: true,
    name: "News_Title_Eng",
  },
  News_Excerpt: {
    placeholder: "Tiêu đề (tối đa 200 ký tự)",
    required: true,
    name: "News_Excerpt",
  },
  News_Excerpt_Eng: {
    placeholder: "Tiêu đề (tối đa 200 ký tự)",
    required: true,
    name: "News_Excerpt_Eng",
  },
  News_Category: {
    placeholder: "Chọn thể loại",
    required: true,
    label: "Thể loại",
    name: "News_Category",
  },
  News_Group: {
    placeholder: "Chọn nhóm",
    required: true,
    label: "Nhóm",
    name: "News_Group",
  },
  Language: {
    placeholder: "Chọn ngôn ngữ",
    required: true,
    label: "Ngôn ngữ",
    name: "Language",
  },
  Status: {
    placeholder: "Chọn trạng thái",
    required: true,
    label: "Trạng thái",
    name: "Status",
  },
  CreatedDate: {
    placeholder: "",
    required: true,
    disabled: true,
    label: "Ngày tạo",
    name: "CreatedDate",
  },
};

export const columns = (language) => {
  return [
    {
      title: language.MANAGE_NEWS_TITLE,
      dataIndex: "News_Title",
      key: "News_Title",
    },
    {
      title: language.MANAGE_NEWS_CATEGORY,
      dataIndex: "News_CategoryObject",
      key: "News_CategoryObject",
      render: (value) => {
        return value?.News_Category_Name;
      },
    },
    {
      title: language.MANAGE_NEWS_CREATED_DATE,
      dataIndex: "CreatedDate",
      key: "CreatedDate",
      render: (value) => {
        return formatDateTime(value);
      },
    },
    {
      title: language.MANAGE_NEWS_LANGUAGE,
      dataIndex: "Language",
      key: "Language",
      render: (value) => {
        if (!value) {
          return "";
        }
        if (value === "en") {
          return "Tiếng Anh";
        }
        return "Tiếng Việt";
      },
    },
    {
      title: language.MANAGE_NEWS_STATUS,
      dataIndex: "Status",
      key: "Status",
      render: (value) => {
        return value === false ? "Ẩn" : "Hiển thị";
      },
    },
    {
      title: "",
      dataIndex: "_id",
      render: (value) => {
        return <ManageNewsEdit _id={value} />;
      },
    },
  ];
};

import { Input } from 'antd';
import React, { useState } from 'react';


InputNumberPage.propTypes = {};

function InputNumberPage(props) {
  const { onChangePage, totalPagesForFlip } = props;
  const [numberPage, setNumberPage] = useState(1);

 
  const handleChange = (number) => {
    if (number === 0) {
      setNumberPage('');
    } else {
      setNumberPage(+number);
    }
    onChangePage(number)
  }
  const setValue = (value) => {
    handleChange(value)
}


  return (
    <>
      <Input
        maxLength={totalPagesForFlip}
        width={100}
        type='number'
        value={numberPage}
        onChange={(e) => {
          const number = +e.target.value;
        //   handleChange(number)
        setValue(number)
        }}
      />
    </>
  );
}

export default InputNumberPage;

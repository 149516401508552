import { authHeader } from '../../../../helper/authHeader';

export const ManageUserService = {
    search,
    update,
    deleted,
    getUserLocation
};

const content = 'Content-Type';
const Type = 'application/json';

function search(searchModel, searchOptions) {
    const value = {
        searchModel,
        searchOptions
    };
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(value)
    };
    return fetch(process.env.REACT_APP_API_URL + `/User/search`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
}

function update(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(model)
    };
    return fetch(process.env.REACT_APP_API_URL + `/User/update`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
}

function deleted(_id) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify({
            _id
        })
    };
    return fetch(process.env.REACT_APP_API_URL + `/User/delete`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
}

function getUserLocation() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const requestOptions = {
      headers,
      method: 'GET',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/User/location`, requestOptions).then(handleResponse);
  }

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
import React, { useState, useCallback, useEffect } from "react";
import { ManageNewsContext, ManageNewsLanguageContext } from "./ManageNewsPage";
import { Button, Modal, Form, Tabs, Input } from "antd";
import { newsInitData, newsFormInitialField } from "./Model/ManageNews.Model";
import ManageNewsSelect from "./Control/ManageNewsSelect";
import MpsUploadImage from "../../MpsCotrols/MpsUploadImage/MpsUploadImage";
import MpsRichTextEditor from "../../MpsCotrols/MpsRichTextEditor/MpsRichTextEditor";
import { ManageNewsService } from "./Service/ManageNews.Service";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../Common/MethodCommon";
import { RESULT_CODES } from "../../../common/Constant.Common";
import ManageNewsDeleteConfirm from "./Control/ManageNewsDeleteConfirm";
import ManageNewsCategorySelect from "./Control/ManageNewsCategorySelect";
import ManageNewsGroupSelect from "./Control/ManageNewsGroupSelect";
import MpsDatePicker from "../../MpsCotrols/MpsDatePicker/MpsDatePicker";
import MpsLanguageSelect from "../../MpsCotrols/MpsSelect/MpsLanguageSelect";
import Metadata from "../../Metatada/Metadata";

const { TabPane } = Tabs;

function ManageNewsEdit(props) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [category, setCategory] = useState("");
  const [newsData, setNewsData] = useState({ ...newsInitData });
  const [productOriginalImages, setProductOriginalImages] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);
  const [addedImages, setAddedImages] = useState([]);
  const [metadata, setMetadata] = useState([]);

  const language = React.useContext(ManageNewsLanguageContext);
  const handleSearch = React.useContext(ManageNewsContext);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  // Xử lý lưu hình ảnh
  const handleImagesChange = useCallback((newImages) => {
    setNewsData((prevState) => ({ ...prevState, News_Images: newImages }));
  }, []);

  const handleAddImage = useCallback((image) => {
    setAddedImages([...image.fileList]);
  }, []);

  const handleRemoveImage = useCallback(
    (image) => {
      const index = productOriginalImages.findIndex(
        (item) => item.name === image.name
      );

      if (index === -1) return;

      setRemovedImages((prevState) => [...prevState, image]);
    },
    [productOriginalImages]
  );

  // Xử lý lưu thông tin thêm
  const handleAdditionalInfoChange = useCallback((text) => {
    setNewsData((prevState) => ({ ...prevState, News_AdditionalInfo: text }));
  }, []);

  const handleAdditionalInfoEngChange = useCallback((text) => {
    setNewsData((prevState) => ({
      ...prevState,
      News_AdditionalInfo_Eng: text,
    }));
  }, []);

  useEffect(() => {
    if (!visible) return;

    ManageNewsService.getById(props._id).then((result) => {
      setMetadata(result?.data[0]?.Metadata || []);
      setNewsData(result.data[0] || { ...newsInitData });
      setCategory(result.data[0].News_Category || "");
      setProductOriginalImages(result.data[0].News_Images || []);
      form.resetFields();
    });
  }, [props._id, visible, form]);

  const handleFininsh = () => {
    form
      .validateFields()
      .then((values) => {
        const formData = new FormData();

        // Append images to form data
        newsData.News_Images.forEach((image) => {
          formData.append("News_Images", JSON.stringify(image));
        });
        // Append new images to form data
        addedImages.forEach((image) => {
          formData.append("News_New_Images", image.originFileObj);
        });
        // Append removed images to form data
        removedImages.forEach((image) => {
          formData.append("News_Removed_Images", image.name);
        });
        // Append values to form data
        const formObjectValues = Object.entries(values);
        formObjectValues.forEach((value) => {
          formData.append(value[0], value[1]);
        });
        formData.append("_id", newsData._id);
        formData.append("News_AdditionalInfo", newsData.News_AdditionalInfo);
        formData.append(
          "News_AdditionalInfo_Eng",
          newsData.News_AdditionalInfo_Eng
        );
        const filterMetadata = metadata.filter(
          (tag) => tag.Meta_Key && tag.Meta_Content
        );
        formData.append("Metadata", JSON.stringify(filterMetadata));
        return ManageNewsService.update(formData);
      })
      .then((result) => {
        if (result.returnCode !== RESULT_CODES.SUCCESS)
          throw new Error(result.returnCode);

        handleSearch();
        hideModal();
        setAddedImages([]);

        // Xóa hình ảnh
        return ManageNewsService.deleteImages({ News_Images: removedImages });
      })
      .then((result) => {
        if (result.returnCode !== RESULT_CODES.SUCCESS)
          throw new Error(result.returnCode);
        setMetadata([]);
        setRemovedImages([]);
        handleSearch();
        openSuccessNotification({
          title: "Thông báo",
          message: "Cập nhật thành công",
        });
        hideModal();
      })
      .catch((error) => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({
            title: "Thông báo",
            message: "Cập nhật thất bại",
          });
      });
  };

  const handleDelete = () => {
    const model = { _id: props._id };
    ManageNewsService.deleteModel(model)
      .then((result) => {
        if (result.returnCode !== RESULT_CODES.SUCCESS)
          throw new Error(result.returnCode);
        // Xóa hình ảnh
        return ManageNewsService.deleteImages({
          News_Images: newsData.News_Images,
        });
      })
      .then((result) => {
        if (result.returnCode !== RESULT_CODES.SUCCESS)
          throw new Error(result.returnCode);

        openSuccessNotification({
          title: "Thông báo",
          message: "Xóa dữ liệu thành công",
        });
        hideModal();
        handleSearch();
      })
      .catch((error) => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({
            title: "Thông báo",
            message: "Xóa dữ liệu thất bại",
          });
      });
  };

  const handleChangeMetadata = (values) => {
    setMetadata(values);
  };

  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "News_Category") {
      setCategory(changedValues[formFieldName]);
      form.setFieldsValue({ News_Group: null });
    }
  };

  return (
    <>
      <p className="admin-button-detail" onClick={showModal}>
        Xem chi tiết
      </p>

      <Modal
        title={language.MANAGE_NEWS_VIEW_TITLE}
        visible={visible}
        width="80%"
        className="manage-news-modal admin-modal"
        onCancel={hideModal}
        footer={[
          <ManageNewsDeleteConfirm
            key="cancel"
            title={language.MANAGE_NEWS_CONFIRM_DELETE}
            deleteLabel={language.MANAGE_NEWS_DELETE}
            agreeLabel={language.MANAGE_NEWS_AGREE}
            disagreeLabel={language.MANAGE_NEWS_DISAGREE}
            onDelete={handleDelete}
          />,
          <Button
            key="submit"
            type="primary"
            onClick={handleFininsh}
            className="admin-button__main admin-button__save"
          >
            {language.MANAGE_NEWS_SAVE}
          </Button>,
        ]}
      >
        <Tabs className="manage-news-tabs" defaultActiveKey="generalInfo">
          <TabPane tab="Thông tin chung" key="generalInfo">
            <Form
              form={form}
              requiredMark={false}
              id="manage-news-form"
              onValuesChange={handleFormValuesChange}
              className="manage-news-form"
              name="manage-news-form"
              labelCol={{ lg: { span: 3 }, md: { span: 24 } }}
              wrapperCol={{ lg: { span: 8 }, md: { span: 24 } }}
            >
              <Form.Item
                colon={false}
                label={language.MANAGE_NEWS_TITLE}
                labelAlign="left"
                initialValue={newsData.News_Title}
                name={newsFormInitialField.News_Title.name}
                rules={[
                  {
                    required: newsFormInitialField.News_Title.required || false,
                    message: "",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder={language.MANAGE_NEWS_TITLE_PLACEHOLDER}
                  maxLength={200}
                />
              </Form.Item>
              {/* <Form.Item
                colon={false}
                label={language.MANAGE_NEWS_TITLE_ENG}
                labelAlign='left'
                initialValue={newsData.News_Title_Eng}
                name={newsFormInitialField.News_Title_Eng.name}
                rules={[{ required: newsFormInitialField.News_Title_Eng.required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_NEWS_TITLE_PLACEHOLDER} maxLength={200} />
              </Form.Item> */}
              <Form.Item
                colon={false}
                label={language.MANAGE_NEWS_EXCERPT}
                labelAlign="left"
                initialValue={newsData.News_Excerpt}
                name={newsFormInitialField.News_Excerpt.name}
                rules={[
                  {
                    required:
                      newsFormInitialField.News_Excerpt.required || false,
                    message: "",
                  },
                ]}
              >
                <Input.TextArea placeholder={language.MANAGE_NEWS_EXCERPT} />
              </Form.Item>
              {/* <Form.Item
                colon={false}
                label={language.MANAGE_NEWS_EXCERPT_ENG}
                labelAlign='left'
                initialValue={newsData.News_Excerpt_Eng}
                name={newsFormInitialField.News_Excerpt_Eng.name}
                rules={[{ required: newsFormInitialField.News_Excerpt_Eng.required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_NEWS_EXCERPT_ENG} />
              </Form.Item> */}
              <ManageNewsCategorySelect
                name={newsFormInitialField.News_Category.name}
                placeholder={language.MANAGE_NEWS_CATEGORY_PLACEHOLDER}
                label={language.MANAGE_NEWS_CATEGORY}
                form={form}
                initialValue={newsData.News_Category}
                required={newsFormInitialField.News_Category.required}
              />
              <ManageNewsGroupSelect
                name={newsFormInitialField.News_Group.name}
                placeholder={language.MANAGE_NEWS_GROUP_PLACEHOLDER}
                label={language.MANAGE_NEWS_GROUP}
                required={newsFormInitialField.News_Group.required}
                form={form}
                value={category}
                initialValue={newsData.News_Group}
              />
              <MpsLanguageSelect
                name={newsFormInitialField.Language.name}
                label={language.MANAGE_NEWS_LANGUAGE}
                hasInitialValue={true}
                initialValue={newsData.Language}
                form={form}
                required={newsFormInitialField.Language.required}
              />
              <ManageNewsSelect
                name={newsFormInitialField.Status.name}
                placeholder={language.MANAGE_NEWS_GROUP_PLACEHOLDER}
                label={language.MANAGE_NEWS_STATUS}
                hasInitialValue={true}
                initialValue={newsData.Status}
                required={newsFormInitialField.Status.required}
                form={form}
                dataSource={["Hiển thị", "Ẩn"]}
              />
              <MpsDatePicker
                label={language.MANAGE_NEWS_CREATED_DATE}
                name={newsFormInitialField.CreatedDate.name}
                value={newsData.CreatedDate}
                required={newsFormInitialField.CreatedDate.required}
                disabled={false}
              />
            </Form>
          </TabPane>
          <TabPane tab={language.MANAGE_NEWS_IMAGE} key="imageInfo">
            <MpsUploadImage
              isConvertUrl
              images={newsData.News_Images}
              onChange={handleImagesChange}
              onRemove={handleRemoveImage}
              onAddImage={handleAddImage}
              title={language.MANAGE_NEWS_UPLOAD_IMAGE}
              max={1}
            />
          </TabPane>
          <TabPane
            tab={language.MANAGE_NEWS_ADDITIONAL_INFO}
            key="additionalInfo"
          >
            <MpsRichTextEditor
              onChange={handleAdditionalInfoChange}
              data={newsData.News_AdditionalInfo}
            />
          </TabPane>
          <TabPane tab="Metadata" key="metadata">
            <Metadata metadata={metadata} onChange={handleChangeMetadata} />
          </TabPane>
          {/* <TabPane tab={language.MANAGE_NEWS_ADDITIONAL_INFO_ENG} key='additionalInfo_eng'>
            <MpsRichTextEditor onChange={handleAdditionalInfoEngChange} data={newsData.News_AdditionalInfo_Eng} />
          </TabPane> */}
        </Tabs>
      </Modal>
    </>
  );
}

export default React.memo(ManageNewsEdit);

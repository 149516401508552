import React from 'react';
import AdminMenu from '../Layout/AdminMenu';
import { Row, Col } from 'antd';
import ManageRelationInput from './Control/ManageRelationInput';
import ManageRelationSelect from './Control/ManageRelationSelect';
import { LayoutAdminCommon } from '../Layout/Model/LayoutAdminModel';
import MpsRangePicker from '../../MpsCotrols/MpsRangePicker/MpsRangePicker';
import MpsSortSelect from '../../MpsCotrols/MpsSelect/MpsSortSelect';
import MpsLanguageSelect from '../../MpsCotrols/MpsSelect/MpsLanguageSelect';

export default function ManageRelationSearch(props) {
  const { RelationAttribute, RelationPlaceholder, initModelRelation } = props;
  return (
    <AdminMenu title={LayoutAdminCommon.titleMenuSearch}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 50 }}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <ManageRelationInput
            name='Relation_Title'
            title={RelationAttribute['Relation_Title'][0]}
            placeholder={RelationPlaceholder['Relation_Title']}
            typeInput={RelationAttribute['Relation_Title'][1]}
          >
          </ManageRelationInput>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <ManageRelationSelect
            name='Status'
            title={RelationAttribute['Status'][0]}
            placeholder={RelationPlaceholder['Status']}
            typeInput={RelationAttribute['Status'][1]}
            select={initModelRelation.Select['Status']}
          >
          </ManageRelationSelect>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <ManageRelationSelect
            name='Relation_Type'
            title={RelationAttribute['Relation_Type'][0]}
            placeholder={RelationPlaceholder['Relation_Type']}
            typeInput={RelationAttribute['Relation_Type'][1]}
            select={initModelRelation.Select['Relation_Type']}
          >
          </ManageRelationSelect>
        </Col>

        <Col lg={12} md={12} sm={24} xs={24}>
          <Row className='admin-filed__list' gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Col className='admin-filed__list__item' lg={8} md={24} sm={24} xs={24}>
              <label>{RelationAttribute['Language'][0]}<span className={`admin-filed__list__item__required ${props.require ? 'active' : ''}`}>*</span></label>
            </Col>
            <Col className='admin-filed__list__item' lg={14} md={24} sm={24} xs={24}>
              <MpsLanguageSelect name='Language' />
            </Col>
          </Row>
        </Col>

        <Col lg={12} md={12} sm={24} xs={24}>
          <Row className='admin-filed__list' gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Col className='admin-filed__list__item' lg={8} md={24} sm={24} xs={24}>
              <label>{RelationAttribute['CreatedAt'][0]} <span className={`admin-filed__list__item__required ${props.require ? 'active' : ''}`}>*</span></label>
            </Col>
            <Col className='admin-filed__list__item' lg={14} md={24} sm={24} xs={24}>
              <MpsRangePicker onChange={() => { }} language='vi' name='CreatedAt' />
            </Col>
          </Row>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Row className='admin-filed__list' gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Col className='admin-filed__list__item' lg={8} md={24} sm={24} xs={24}>
              <label>{'Sắp xếp'} <span className={`admin-filed__list__item__required ${props.require ? 'active' : ''}`}>*</span></label>
            </Col>
            <Col className='admin-filed__list__item' lg={14} md={24} sm={24} xs={24}>
              <MpsSortSelect name='Relation_Sort' label='' />
            </Col>
          </Row>
        </Col>
      </Row>
    </AdminMenu >
  );
}

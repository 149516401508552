import React from 'react';
import AdminMenu from '../Layout/AdminMenu';
import { Row, Col, Form, Input } from 'antd';
import { ManageRecruitmentLanguageContext } from './ManageRecruitmentPage.jsx';
import ManageNewsSelect from '../ManageNews/Control/ManageNewsSelect';
import ManageRecruitmentTypeSelect from './Control/ManageRecruitmentTypeSelect';
import MpsRangePicker from '../../MpsCotrols/MpsRangePicker/MpsRangePicker';
import MpsSortSelect from '../../MpsCotrols/MpsSelect/MpsSortSelect';
import MpsLanguageSelect from '../../MpsCotrols/MpsSelect/MpsLanguageSelect';

function ManageRecruitmentSearch() {
  const language = React.useContext(ManageRecruitmentLanguageContext);

  return (
    <AdminMenu title={language.MANAGE_RECRUITMENT_SEARCH_INFO}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 50 }}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_RECRUITMENT_SEARCH_TITLE}
            name='Recruitment_Title'
          >
            <Input placeholder={language.MANAGE_RECRUITMENT_SEARCH_TITLE_PLACEHOLDER} />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <ManageNewsSelect label={language.MANAGE_RECRUITMENT_STATUS} name='Status'
            placeholder={language.MANAGE_RECRUITMENT_STATUS} />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <ManageRecruitmentTypeSelect label={language.MANAGE_RECRUITMENT_SEARCH_TYPE} name='Recruitment_Type'
            placeholder={language.MANAGE_RECRUITMENT_SEARCH_TYPE_PLACEHOLDER} />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsLanguageSelect name='Language' label={language.MANAGE_RECRUITMENT_LANGUAGE} />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsRangePicker onChange={() => { }} language='vi' label={language.MANAGE_RECRUITMENT_CREATED_DATE} name='CreatedDate' />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsSortSelect name='Recruitment_Sort' />
        </Col>
      </Row>
    </AdminMenu>
  );
}

export default React.memo(ManageRecruitmentSearch);

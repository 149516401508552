import React, { useState } from 'react';
import { Row, Col, Form, Modal, Button } from 'antd';
import ManageRelationSelect from './Control/ManageRelationSelect';
import ManageRelationTextArea from './Control/ManageRelationTextArea';
import ManageRelationFile from './Control/ManageRelationFile';
import { CREATE_RETURN_CODE_DESCRIPTION, RESULT_CODES } from '../../../common/Constant.Common';
import { ManageRelationService } from './Service/ManageRelationService';
import { RelationAttribute, RelationCommon, RelationPlaceholder } from './Model/ManageRelationModel';
import { openErrorNotification, openSuccessNotification } from '../Common/MethodCommon';
import MpsDatePicker from '../../MpsCotrols/MpsDatePicker/MpsDatePicker';
import MpsLanguageSelect from '../../MpsCotrols/MpsSelect/MpsLanguageSelect';

export default function ManageRelationAdd(props) {
  const { initModelRelation } = props;
  const [form] = Form.useForm();
  const [fileUpload, setFileUpload] = useState(null);

  const onCreate = async () => {
    const values = await form.validateFields();
    let data;

    try {
      if (!!fileUpload) {
        const formData = new FormData();
        const newValue = Object.entries(values);
        formData.append('myFile', fileUpload);
        newValue.forEach(item => {
          formData.append(item[0], item[1]);
        });
        data = await ManageRelationService.create(formData);
      } else {
        data = await ManageRelationService.create(values);
      }

      if (data.returnCode !== RESULT_CODES.SUCCESS)
        return openErrorNotification({ title: 'Thông báo', message: CREATE_RETURN_CODE_DESCRIPTION[parseInt(data.returnCode, 10)] });

      props.signChanged();
      handleResetForm();
      props.onClose();
      openSuccessNotification({ title: 'Thông báo', message: CREATE_RETURN_CODE_DESCRIPTION[parseInt(data.returnCode, 10)] });
    } catch (error) {
      // TO DO
    }
  };

  const handleFileUpload = (file) => {
    setFileUpload(file);
  };

  const handleResetForm = () => {
    form.resetFields();
    setFileUpload(null);
  };
  return (
    <Form
      // initialValues={props.data}
      form={form}
    >
      <Modal
        destroyOnClose
        title={RelationCommon.TitleCreateModal}
        visible={props.visible}
        onOk={props.onClose}
        onCancel={props.onClose}
        width='80%'
        className='admin-modal'
        footer={[
          <Button
            key='back'
            type='primary'
            onClick={() => {
              props.onClose();
            }}
            className='admin-button__main admin-button__delete'
          >
            Hủy
          </Button>,
          <Button
            key='submit'
            type='primary'
            htmlType='submit'
            className='admin-button__main'
            onClick={onCreate}
          >
            Lưu
          </Button>,
        ]}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} key='main-form-input-admin'>
          <Col lg={12} md={12} sm={24} xs={24}>
            <ManageRelationTextArea
              name='Relation_Title'
              title={RelationAttribute['Relation_Title'][0]}
              placeholder={RelationPlaceholder['Relation_Title']}
              typeInput={RelationAttribute['Relation_Title'][1]}
              require={true}
            />
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <ManageRelationFile
              fileUpload={fileUpload}
              name='Relation_File'
              title={RelationAttribute['Relation_File'][0]}
              placeholder={RelationPlaceholder['Relation_File']}
              typeInput={RelationAttribute['Relation_File'][1]}
              handleFileUpload={handleFileUpload}
              require={true}
            ></ManageRelationFile>
          </Col>
          {/* <Col lg={12} md={12} sm={24} xs={24}>
            <ManageRelationTextArea
              name='Relation_Title_ENG'
              title={RelationAttribute['Relation_Title_ENG'][0]}
              placeholder={RelationPlaceholder['Relation_Title_ENG']}
              typeInput={RelationAttribute['Relation_Title_ENG'][1]}
              require={true}
            ></ManageRelationTextArea>
          </Col> 
          <Col lg={12} md={12} sm={24} xs={24}>

          </Col>*/}
          <Col lg={12} md={12} sm={24} xs={24}>
            <Row className='admin-filed__list' gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
              <Col className='admin-filed__list__item' lg={8} md={24} sm={24} xs={24}>
                <label>{RelationAttribute['Language'][0]} <span className={`admin-filed__list__item__required active`}>*</span></label>
              </Col>
              <Col className='admin-filed__list__item' lg={14} md={24} sm={24} xs={24}>
                <MpsLanguageSelect
                  name={'Language'}
                  required={true}
                  hasInitialValue={true}
                  form={form}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>

          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <ManageRelationSelect
              name='Status'
              title={RelationAttribute['Status'][0]}
              placeholder={RelationPlaceholder['Status']}
              typeInput={RelationAttribute['Status'][1]}
              select={initModelRelation.Select['Status']}
              require={true}
            >
            </ManageRelationSelect>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>

          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <ManageRelationSelect
              name='Relation_Type'
              title={RelationAttribute['Relation_Type'][0]}
              placeholder={RelationPlaceholder['Relation_Type']}
              typeInput={RelationAttribute['Relation_Type'][1]}
              select={initModelRelation.Select['Relation_Type']}
              require={true}
            />
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>

          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Row className='admin-filed__list' gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
              <Col className='admin-filed__list__item' lg={8} md={24} sm={24} xs={24}>
                <label>{RelationAttribute['CreatedAt'][0]} <span className={`admin-filed__list__item__required active`}>*</span></label>
              </Col>
              <Col className='admin-filed__list__item' lg={14} md={24} sm={24} xs={24}>
                <MpsDatePicker
                  name='CreatedAt'
                  message='Đây là trường bắt buộc'
                  value={Date.now()}
                  required={true}
                  disabled={true}

                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </Form>
  );
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { CaretDownOutlined, MenuOutlined, CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import { HeaderCommon } from './Model/LayoutModel';
import { HomeCommon } from '../Home/Model/HomeModel';
import VI from '../../asset/img/LANG_VI.png';
import EN from '../../asset/img/LANG_EN.png';
import logo from '../../asset/img/Logo.png';
import { IntroTypeService } from '../Intro/Service/IntroType.Service';
import { RESULT_CODES } from '../../common/Constant.Common';

const getMarginPaddingContainer = () => {
  const a = document.getElementsByClassName('container')[0];
  return a.currentStyle || window.getComputedStyle(a);
};

export default function Header(props) {
  const { pathname } = useLocation();
  const headerRef = useRef(null);
  const { language } = props;
  const [menuMobile, setMenuMobile] = useState(false);
  const [navMobileChild, setNavMobileChild] = useState(null);
  const [navSubMobileChild, setNavSubMobileChild] = useState(null);
  const [fakebg, setFakebg] = useState('0');
  const [typePostIntroData, setTypePostIntroData] = useState([]);
  const pathnameSplit = pathname.split('/');
  const pathnameTemp = [pathnameSplit[1], pathnameSplit[2]];
  const pathnameFinal = `/${pathnameTemp.join(pathnameSplit[2] === undefined ? '' : '/')}`;
  const checkpath = (check) => check.path === pathnameFinal;
  const activeNav = HeaderCommon[language].HeaderTitleMain.findIndex(checkpath);
  const home = pathname === `/${language}` || pathname === `/${language}/` ? true : false;

  const handleResize = () => {
    setFakebg(`${getMarginPaddingContainer().marginLeft} + ${getMarginPaddingContainer().paddingLeft}`);
  };

  const handleOpenMobileMenu = (value) => {
    if (value) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
      headerRef.current.classList.add('header__home__mobile--white');
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'unset';
      headerRef.current.classList.remove('header__home__mobile--white');
    }
    setMenuMobile(value);
  };

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.scrollY;

    if (headerRef.current) {
      headerRef.current.classList.add('header__home--white');
    }
    if (currentScrollPos < 10) {
      headerRef.current.classList.remove('header__home--white');
    }
  }, []);

  useEffect(() => {
    if (home) {
      window.addEventListener('scroll', handleScroll);
    }
    if (headerRef.current) {
      headerRef.current.classList.remove('header__home--white');
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [home, handleScroll]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const fetchAllTypePostIntro = useCallback(() => {
    IntroTypeService.getAllTypePostIntroActive()
      .then((result) => {
        if (result.returnCode !== RESULT_CODES.SUCCESS) return;
        //console.log("check result", result)
        setTypePostIntroData(result.data);
      })
      .catch((e) => {
        // TO DO
        console.log(e);
      });
  }, []);
  const processTypeData = useMemo(() => {
    if (typePostIntroData.length === 0) {
      return [];
    }
    return typePostIntroData.map((item) => {
      return {
        path: '/' + language + item.Type_Post_Slug,
        title: item.Type_Post_Title,
        title_eng: item.Type_Post_Title_Eng,
        // background: item.Type_Post_Images.length > 0 ? `${process.env.REACT_APP_API_URL}/${item.Type_Post_Images[0].url}` : "",
      };
    });
  }, [typePostIntroData, language]);
  useEffect(() => {
    fetchAllTypePostIntro();
  }, []);

  return (
    <header ref={headerRef} className={`${home ? 'header__home' : 'header'}`}>
      <div className='container'>
        <div className='header__contain'>
          <Link to={`${HomeCommon[language].path}`} className='header__logo'>
            <img src={logo} alt='logo' />
          </Link>

          <div className='header__content'>
            <div className='header__content__heading'>
              {HeaderCommon[language].HeaderTitleHeading.map((item, index) => {
                return (
                  <Link key={`${index}${item.title}`} to={item.path} className='header__content__heading__item'>
                    <p>{item.title}</p>
                  </Link>
                );
              })}
              <div className='header__content__heading__item'>
                <img
                  src={language === 'vi' ? VI : EN}
                  alt='FLAG'
                  onClick={() => {
                    if (language === 'vi') {
                      props.handleSignLanguage('en');
                    } else {
                      props.handleSignLanguage('vi');
                    }
                  }}
                />
              </div>
              <div className='header__content__heading__item__fake' style={{ width: `calc(${fakebg})` }}></div>
            </div>
            <div className='header__content__category'>
              {HeaderCommon[language].HeaderTitleMain.map((item, index) => {
                if (item.path === '/vi/intro' || item.path === '/en/intro') {
                  return (
                    <div className={`header__content__category__item-container`} key={`${index}${item.title}`}>
                      <Link to={`${item.path}`} className={`header__content__category__item ${activeNav === index ? 'active' : ''}`}>
                        <p>{item.title}</p>
                        <CaretDownOutlined />
                      </Link>
                      <div className={`header__content__category__child ${index === HeaderCommon[language].HeaderTitleMain.length - 1 ? 'header__content__category__child--last' : ''}`}>
                        {processTypeData.map((item2, index2) => {
                          return item2?.path ? (
                            <Link to={`${item2.path}`} key={`${index2}${item2.title}`} className='header__content__category__child__item' onClick={() => setMenuMobile(false)}>
                              <p>{language === 'vi' ? item2.title : item2.title_eng}</p>
                            </Link>
                          ) : (
                            <div className='header__sub-menu-container' key={`${index2}${item2.title}`}>
                              <div onClick={() => setMenuMobile(false)} className='header__content__category__child__item flex item-center cursor-pointer'>
                                <p className='mr-8 transform-unset'>{language === 'vi' ? item2.title : item2.title_eng}</p>
                                <CaretDownOutlined />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                }
                if (!!item.sub ?? item.sub.length > 0)
                  return (
                    <div className={`header__content__category__item-container`} key={`${index}${item.title}`}>
                      <Link to={`${item.path}`} className={`header__content__category__item ${activeNav === index ? 'active' : ''}`}>
                        <p>{item.title}</p>
                        <CaretDownOutlined />
                      </Link>
                      <div className={`header__content__category__child ${index === HeaderCommon[language].HeaderTitleMain.length - 1 ? 'header__content__category__child--last' : ''}`}>
                        {item.sub.map((item2, index2) => {
                          return item2?.path ? (
                            <Link to={item2.path} key={`${index2}${item2.title}`} className='header__content__category__child__item' onClick={() => setMenuMobile(false)}>
                              <p>{item2.title}</p>
                            </Link>
                          ) : (
                            <div className='header__sub-menu-container' key={`${index2}${item2.title}`}>
                              <div onClick={() => setMenuMobile(false)} className='header__content__category__child__item flex item-center cursor-pointer'>
                                <p className='mr-8 transform-unset'>{item2.title}</p>
                                <CaretDownOutlined />
                              </div>
                              <div className='header__sub-menu-item'>
                                {item2.sub &&
                                  item2.sub.length > 0 &&
                                  item2.sub.map((item3, index3) => {
                                    return (
                                      <Link to={item3.path} key={`${index3}${item3.title}`} className='header__content__category__child__item' onClick={() => setMenuMobile(false)}>
                                        <p className='translate-x-8'>{item3.title}</p>
                                      </Link>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                if (item.link)
                  return (
                    <div className='header__content__category__item-container' key={`${index}${item.title}`}>
                      <a href={item.link} target='_blank' rel='noopener noreferrer' className={`header__content__category__item ${activeNav === index ? 'active' : ''}`}>
                        <p>{item.title}</p>
                      </a>
                    </div>
                  );
                return (
                  <div className='header__content__category__item-container' key={`${index}${item.title}`}>
                    <Link to={item.path} className={`header__content__category__item ${activeNav === index ? 'active' : ''}`}>
                      <p>{item.title}</p>
                    </Link>
                  </div>
                );
              })}
            </div>

            <div className='header__content__category__item--mobile'>
              {HeaderCommon[language].HeaderTitleHeading.map((item, index) => {
                return (
                  <Link key={`${index}${item.title}`} onClick={() => handleOpenMobileMenu(false)} to={item.path} className='header__content__heading__item'>
                    <p>{item.title}</p>
                  </Link>
                );
              })}
              <div className='header__content__heading__item'>
                <img
                  src={language === 'vi' ? VI : EN}
                  alt='FLAG'
                  onClick={() => {
                    if (language === 'vi') {
                      props.handleSignLanguage('en');
                    } else {
                      props.handleSignLanguage('vi');
                    }
                  }}
                />
              </div>
              {menuMobile ? <CloseOutlined onClick={() => handleOpenMobileMenu(!menuMobile)} className='menu__icon' /> : <MenuOutlined onClick={() => handleOpenMobileMenu(!menuMobile)} className='menu__icon' />}
            </div>
          </div>
          <div className='header__menu-mobile' style={{ display: !menuMobile ? 'none' : 'block' }}>
            <div className='header__menu-mobile__list'>
              {HeaderCommon[language].HeaderTitleMain.map((item, index) => {
                if (item.path === '/vi/intro' || item.path === '/en/intro') {
                  return (
                    <div key={`${index}${item.title}`}>
                      <div
                        className={`header__menu-mobile__list__detail ${activeNav === index ? 'active' : ''}`}
                        onClick={() => {
                          if (!navMobileChild || navMobileChild !== item.title) setNavMobileChild(item.title);
                          else setNavMobileChild(null);
                        }}
                      >
                        <p>{item.title}</p>
                        {item.title === navMobileChild ? <i className='fas fa-chevron-up' /> : <i className='fas fa-chevron-down' />}
                      </div>
                      <div className={`header__menu-mobile__list__child ${item.title === navMobileChild ? 'active' : ''}`}>
                        {processTypeData.map((item2, index2) => {
                          return item2?.path ? (
                            <Link to={item2.path} key={`${index2}${item2.title}`} className='header__menu-mobile__list__child__item' onClick={() => handleOpenMobileMenu(false)}>
                              <p>{language === 'vi' ? item2.title : item2.title_eng}</p>
                            </Link>
                          ) : (
                            <div className='header__mobile__sub-menu-container' key={`${index2}${item2.title}`}>
                              <div
                                className='header__menu-mobile__list__child__item flex justify-between item-center'
                                onClick={() => {
                                  if (!navSubMobileChild || navSubMobileChild !== item2.title) setNavSubMobileChild(item2.title);
                                  else setNavSubMobileChild(null);
                                }}
                              >
                                <p className='mr-8'>{language === 'vi' ? item2.title : item2.title_eng}</p>
                                {item2.title === navSubMobileChild ? <i className='fas fa-chevron-up' /> : <i className='fas fa-chevron-down' />}
                              </div>
                              <div style={{ display: item2.title === navSubMobileChild ? 'block' : 'none' }}>
                                {item2.sub &&
                                  item2.sub.length > 0 &&
                                  item2.sub.map((item3, index3) => {
                                    return (
                                      <Link to={item3.path} key={`${index3}${item3.title}`} className='header__menu-mobile__list__child__item' onClick={() => handleOpenMobileMenu(false)}>
                                        <p className='translate-x-8'>{item3.title}</p>
                                      </Link>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                }
                if (!!item.sub ?? item.sub.length > 0)
                  return (
                    <div key={`${index}${item.title}`}>
                      <div
                        className={`header__menu-mobile__list__detail ${activeNav === index ? 'active' : ''}`}
                        onClick={() => {
                          if (!navMobileChild || navMobileChild !== item.title) setNavMobileChild(item.title);
                          else setNavMobileChild(null);
                        }}
                      >
                        <p>{item.title}</p>
                        {item.title === navMobileChild ? <i className='fas fa-chevron-up' /> : <i className='fas fa-chevron-down' />}
                      </div>
                      <div className={`header__menu-mobile__list__child ${item.title === navMobileChild ? 'active' : ''}`}>
                        {item.sub.map((item2, index2) => {
                          return item2?.path ? (
                            <Link to={item2.path} key={`${index2}${item2.title}`} className='header__menu-mobile__list__child__item' onClick={() => handleOpenMobileMenu(false)}>
                              <p>{item2.title}</p>
                            </Link>
                          ) : (
                            <div className='header__mobile__sub-menu-container' key={`${index2}${item2.title}`}>
                              <div
                                className='header__menu-mobile__list__child__item flex justify-between item-center'
                                onClick={() => {
                                  if (!navSubMobileChild || navSubMobileChild !== item2.title) setNavSubMobileChild(item2.title);
                                  else setNavSubMobileChild(null);
                                }}
                              >
                                <p className='mr-8'>{item2.title}</p>
                                {item2.title === navSubMobileChild ? <i className='fas fa-chevron-up' /> : <i className='fas fa-chevron-down' />}
                              </div>
                              <div style={{ display: item2.title === navSubMobileChild ? 'block' : 'none' }}>
                                {item2.sub &&
                                  item2.sub.length > 0 &&
                                  item2.sub.map((item3, index3) => {
                                    return (
                                      <Link to={item3.path} key={`${index3}${item3.title}`} className='header__menu-mobile__list__child__item' onClick={() => handleOpenMobileMenu(false)}>
                                        <p className='translate-x-8'>{item3.title}</p>
                                      </Link>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                if (item.link)
                  return (
                    <a key={index + item.title} href={item.link} target='_blank' rel='noopener noreferrer' className={`header__menu-mobile__list__detail ${activeNav === index ? 'active' : ''}`} onClick={() => handleOpenMobileMenu(false)}>
                      <p>{item.title}</p>
                    </a>
                  );
                return (
                  <Link key={index + item.title} to={item.path} className={`header__menu-mobile__list__detail ${activeNav === index ? 'active' : ''}`} onClick={() => handleOpenMobileMenu(false)}>
                    <p>{item.title}</p>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

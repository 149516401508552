import React, { useState, useEffect, useCallback } from 'react';
import { Form, Button } from 'antd';
import Helmet from '../../Layout/Helmet';
import AdminBreadcrum from '../Layout/AdminBreadcrumb';
import AdminTable from '../Layout/AdminTable';
import ManageUnitSearch from './ManageUnitSearch';

import { RESULT_CODES } from '../../../common/Constant.Common';
import { LANGUAGE_STATE } from './Language/Language';

import './Css/Style.css';
import ManageUnitAdd from './ManageUnitAdd';
import { ManageUnitService } from './Service/ManageUnit.Service';
import { columns } from './Model/ManageUnit.Model';

export const ManageUnitContext = React.createContext();
export const ManageUnitLanguageContext = React.createContext();

function ManageUnitPage() {
  const [form] = Form.useForm();
  const [searchOption, setSearchOption] = useState({ page: 1, limit: 10 });
  const [dataSource, setDataSource] = useState([]);
  const [language] = useState(LANGUAGE_STATE['vi']);

  const clearSearchForm = () => {
    form.resetFields();
  };

  const handleSearch = useCallback(() => {
    const searchModel = form.getFieldsValue();
    const sortAscending = form.getFieldValue('Unit_Sort');
    ManageUnitService.search(searchModel, { ...searchOption, sortAscending }).then(result => {
      if (result.returnCode !== RESULT_CODES.SUCCESS) return;

      const { pages } = result.result;
      if (pages >= searchOption.page) {
        setDataSource(result.result);
      } else {
        setSearchOption(prevState => ({ ...prevState, page: 1 }));
      }
    })
      .catch(() => {
        // TO DO
      });
  }, [form, searchOption]);

  const handlePagination = (currentPage, pageSizes) => {
    setSearchOption(prevState => ({ ...prevState, page: currentPage, limit: pageSizes }));
  };

  useEffect(() => {
    handleSearch();
  }, [handleSearch, searchOption]);

  return (
    <ManageUnitLanguageContext.Provider value={language}>
      <Helmet title={language.MANAGE_UNIT}>
        <AdminBreadcrum title={language.MANAGE_UNIT} subKey = 'sub2'/>
        <ManageUnitContext.Provider value={handleSearch}>
          <div className='admin-content'>
            <Form form={form} onFinish={handleSearch} labelCol={{ lg: { span: 8 } }} wrapperCol={{ lg: { span: 14 } }} >
              <ManageUnitSearch />
              <div className='admin-button admin-button-search'>
                <Button type='secondary' onClick={clearSearchForm} className='admin-button__main admin-button__delete'>
                  {language.MANAGE_UNIT_CLEAR_SELECT}
                </Button>
                <Button type='primary' htmlType='submit' onClick={handleSearch} className='admin-button__main admin-button__save'>
                  {language.MANAGE_UNIT_SEARCH}
                </Button>
              </div>
            </Form>

            <div className='admin-button'>
              <ManageUnitAdd />
            </div>

            <AdminTable
              dataSource={dataSource.docs || []}
              columns={columns(language)}
              current={searchOption.page}
              changePage={handlePagination}
              rowKey='_id'
              total={dataSource.total || 0}
            />

          </div>
        </ManageUnitContext.Provider>
      </Helmet>
    </ManageUnitLanguageContext.Provider>
  );
}

export default ManageUnitPage;


import React, { useEffect, useState } from 'react';
import { SwapRightOutlined } from '@ant-design/icons';
import { Link, useRouteMatch } from 'react-router-dom';
import { HomeCommon } from '../Model/HomeModel';
import { useWindowDimensions } from '../../Intro/CustomHook/useWindowDimensions';

export default function HomeSliderItem(props) {
  const { width } = useWindowDimensions();
  const [background, setBackground] = useState(null);
  const { path } = useRouteMatch();

  useEffect(() => {

    if (width > 1024) {
      return setBackground(props.background)
    } else {
      if (width <= 1024 && width > 576) {
        return setBackground(props.tabletBg);
      }
    }
    setBackground(props.mobileBackground);
  }, [width, props.tabletBg, props.background, props.mobileBackground]);

  return (
    <div className='home__main__intro__list__item' >
      <div className='home__main__intro__list__item__img' style={{ backgroundImage: `url('${background}')` }}>
        <div className='home__main__intro__list__item-container'>
          <div className='home__main__intro__list__item__overlay'>
          </div>
          <div className='home__main__intro__list__item__main'>
            <div className='home__main__intro__list__item__main__detail'>
              <p>{props.title}</p>
              <div className='home__main__intro__list__item__main__detail__child'>
                <Link to={`${path}${props.path}`}>
                  {HomeCommon[props.language].buttonDetail}
                  <SwapRightOutlined />
                </Link>
                <div className='home__main__intro__list__item__main__detail__line'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Link to={`${path}${props.path}`}>
        <div>{parseInt(props.index, 10) + 1}. &nbsp;</div>
        <div>{props.title}</div>
      </Link>
    </div>
  );
}


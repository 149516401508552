import React from 'react';
import AdminMenu from '../Layout/AdminMenu';
import { Row, Col, Form, Input, Select } from 'antd';
import ManageUnitStatusSelect from '../ManageUnit/Control/ManageUnitStatusSelect';
import { ManageSpecificationLanguageContext } from './ManageSpecificationPage';
import MpsRangePicker from '../../MpsCotrols/MpsRangePicker/MpsRangePicker';
import MpsSortSelect from '../../MpsCotrols/MpsSelect/MpsSortSelect';
import ProductCategorySelect from '../ManageProduct/Control/ProductCategorySelect.js'
import { manageSpecificationLength } from './Model/ManageSpecification.Model';

function ManageSpecificationSearch() {
  const language = React.useContext(ManageSpecificationLanguageContext);
  return (
    <AdminMenu title={language.MANAGE_SPECIFICATION_SEARCH_INFO}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 50 }}>
      <Col lg={12} md={12} sm={24} xs={24}>
      <ProductCategorySelect
                  name='Specification_Product_Category'
                  label={language.MANAGE_SPECIFICATION_PRODUCT_CATEGORY}
                  placeholder={language.MANAGE_SPECIFICATION_PRODUCT_CATEGORY}
                />
                </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_SPECIFICATION_CODE}
            name='Specification_Code'
          >
            <Input placeholder={language.MANAGE_SPECIFICATION_CODE} />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_SPECIFICATION_NAME}
            name='Specification_Name'
          >
            <Input placeholder={language.MANAGE_SPECIFICATION_NAME} />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_SPECIFICATION_LENGTH}
            name='Specification_Length'
          >
            <Select placeholder={language.MANAGE_SPECIFICATION_LENGTH} allowClear={true}>
              {manageSpecificationLength.length > 0 && manageSpecificationLength.map(data => {
                return <Select.Option key={`${data.key}`} value={data.value}>
                  {data.name}
                </Select.Option>;
              })}
            </Select>
            {/* <Input placeholder={language.MANAGE_SPECIFICATION_LENGTH} /> */}
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_SPECIFICATION_WIDTH}
            name='Specification_Width'
          >
            <Input placeholder={language.MANAGE_SPECIFICATION_WIDTH} />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_SPECIFICATION_HEIGHT}
            name='Specification_Height'
          >
            <Input placeholder={language.MANAGE_SPECIFICATION_HEIGHT} />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_SPECIFICATION_UNIT}
            name='Specification_Unit'
          >
            <Input placeholder={language.MANAGE_SPECIFICATION_UNIT} />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_SPECIFICATION_WEIGHT_OVER_UNIT}
            name='Specification_Weight_Over_Unit'
          >
            <Input placeholder={language.MANAGE_SPECIFICATION_WEIGHT_OVER_UNIT} />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <ManageUnitStatusSelect label={language.MANAGE_SPECIFICATION_STATUS} name='Status'
            placeholder={language.MANAGE_SPECIFICATION_STATUS} />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsRangePicker onChange={()=>{}}  language='vi' label={language.MANAGE_SPECIFICATION_CREATED_DATE} name='CreatedDate' />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_SPECIFICATION_CREATED_PERSON}
            name='CreatedPerson'
          >
            <Input placeholder={language.MANAGE_SPECIFICATION_CREATED_PERSON} />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsRangePicker onChange={()=>{}} language='vi' label={language.MANAGE_SPECIFICATION_UPDATED_DATE} name='UpdatedDate' />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_SPECIFICATION_UPDATED_PERSON}
            name='UpdatedPerson'
          >
            <Input placeholder={language.MANAGE_SPECIFICATION_UPDATED_PERSON} />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsSortSelect name='Specification_Sort' />
        </Col>
      </Row>
    </AdminMenu>
  );
}

export default ManageSpecificationSearch

import React, { memo } from 'react';
import AdminMenu from '../Layout/AdminMenu';
import { Row, Col, Form, Input } from 'antd';
import ManageNewsSelect from './Control/ManageNewsSelect';
import ManageNewsCategorySelect from './Control/ManageNewsCategorySelect';
import MpsRangePicker from '../../MpsCotrols/MpsRangePicker/MpsRangePicker'
import { ManageNewsLanguageContext } from './ManageNewsPage';
import MpsSortSelect from '../../MpsCotrols/MpsSelect/MpsSortSelect';
import MpsLanguageSelect from '../../MpsCotrols/MpsSelect/MpsLanguageSelect';

function ManageNewsSearch() {
  const language = React.useContext(ManageNewsLanguageContext);
  return (
    <AdminMenu title={language.MANAGE_NEWS_SEARCH_INFO}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 50 }}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_NEWS_TITLE}
            name='News_Title'
          >
            <Input placeholder={language.MANAGE_NEWS_TITLE} />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <ManageNewsCategorySelect
            label={language.MANAGE_NEWS_CATEGORY}
            name='News_Category' placeholder={language.MANAGE_NEWS_CATEGORY_PLACEHOLDER}
          />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsLanguageSelect name='Language' label={language.MANAGE_NEWS_LANGUAGE} />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <ManageNewsSelect label={language.MANAGE_NEWS_STATUS} name='Status'
            placeholder={language.MANAGE_NEWS_STATUS_PLACEHOLDER} dataSource={['Hiển thị', 'Ẩn']} />
        </Col>

        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsRangePicker onChange={() => { }} language='vi' label={language.MANAGE_NEWS_CREATED_DATE} name='CreatedDate' />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsSortSelect name='News_Sort' />
        </Col>
      </Row>
    </AdminMenu>
  );
}

export default memo(ManageNewsSearch);


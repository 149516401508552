import React, { useState, useCallback } from 'react';
import { Button, Modal, Form, Tabs, Input } from 'antd';
import { ManageRecruitmentContext, ManageRecruitmentLanguageContext } from './ManageRecruitmentPage';
import { initialFormField, recruitmentInitData } from './Model/ManageRecruitment.Model';
import ManageRecruitmentTypeSelect from './Control/ManageRecruitmentTypeSelect';
import { momentDateTime } from '../Common/FormatCommon';
import ManageNewsSelect from '../ManageNews/Control/ManageNewsSelect';
import MpsRichTextEditor from '../../MpsCotrols/MpsRichTextEditor/MpsRichTextEditor';
import { ManageRecruitmentService } from './Service/ManageRecruitment.Service';
import { RESULT_CODES } from '../../../common/Constant.Common';
import { openErrorNotification, openSuccessNotification } from '../Common/MethodCommon';
import MpsDatePicker from '../../MpsCotrols/MpsDatePicker/MpsDatePicker';
import MpsLanguageSelect from '../../MpsCotrols/MpsSelect/MpsLanguageSelect';

const { TabPane } = Tabs;

function ManageRecruitmentAdd() {
  const language = React.useContext(ManageRecruitmentLanguageContext);
  const handleSearch = React.useContext(ManageRecruitmentContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [recruitmentData, setRecruitmentData] = useState({ ...recruitmentInitData });
  const [activeTabKey, setActiveTabKey] = useState('generalInfo');

  const showModal = () => {
    form.setFieldsValue({ CreatedDate: momentDateTime(Date.now()) });
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const resetField = () => {
    form.resetFields();
    setRecruitmentData({ ...recruitmentInitData });
  };

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  const handleFinish = () => {
    form.validateFields().then(values => {
      const model = { ...recruitmentData, ...values };

      return ManageRecruitmentService.create(model);
    })
      .then(result => {
        if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);

        handleSearch();
        resetField();
        hideModal();
        setActiveTabKey('generalInfo');
        openSuccessNotification({ title: 'Thông báo', message: 'Thêm mới thành công' });
      })
      .catch((error) => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({ title: 'Thông báo', message: 'Thêm mới thất bại' });
      });
  };

  const handleDetailInfoChange = useCallback((text) => {
    setRecruitmentData(prevState => ({ ...prevState, Recruitment_Detail_Info: text }));
  }, []);

  const handleDetailInfoEngChange = useCallback((text) => {
    setRecruitmentData(prevState => ({ ...prevState, Recruitment_Detail_Info_Eng: text }));
  }, []);
  return (
    <>
      <Button onClick={showModal} className='admin-button__main admin-button__download-pdf'>
        <i className='fas fa-plus'></i>
        {language.MANAGE_RECRUITMENT_CREATE_NEW}
      </Button>
      <Modal
        title={language.MANAGE_RECRUITMENT_CREATE_NEW}
        visible={visible}
        width='80%'
        className='manage-recruitment-modal admin-modal'
        onCancel={hideModal}
        footer={
          [
            <Button
              key='submit'
              type='secondary'
              onClick={hideModal}
              className='admin-button__main admin-button__delete'
            >
              {language.MANAGE_RECRUITMENT_CANCEL}
            </Button>,
            <Button
              onClick={handleFinish}
              key='cancel'
              type='primary'
              className='admin-button__main admin-button__save'
            >
              {language.MANAGE_RECRUITMENT_SAVE}
            </Button>
          ]
        }
      >
        <Tabs className='manage-recruitment-tabs' activeKey={activeTabKey} onChange={handleTabChange}>
          <TabPane tab={language.MANAGE_RECRUITMENT_GENERAL_INFO} key='generalInfo'>
            <Form
              form={form} requiredMark={false} id='manage-recruitment-add-form'
              className='manage-recruitment-form'
              // onValuesChange={handleFormValuesChange}
              colon={false}
              name='manage-recruitment-add-form'
              labelCol={{ lg: { span: 3 }, md: { span: 24 } }}
              wrapperCol={{ lg: { span: 8 }, md: { span: 24 } }}
            >
              <Form.Item
                label={language.MANAGE_RECRUITMENT_TITLE}
                labelAlign='left'
                name={initialFormField.Recruitment_Title.Name}
                rules={[{ required: initialFormField.Recruitment_Title.Required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_RECRUITMENT_TITLE_PLACEHOLDER} maxLength={200} />
              </Form.Item>
              {/* <Form.Item
                label={language.MANAGE_RECRUITMENT_TITLE_ENG}
                labelAlign='left'
                name={initialFormField.Recruitment_Title_Eng.Name}
                rules={[{ required: initialFormField.Recruitment_Title_Eng.Required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_RECRUITMENT_TITLE_ENG_PLACEHOLDER} maxLength={200} />
              </Form.Item> */}
              <ManageRecruitmentTypeSelect
                form={form}
                name={initialFormField.Recruitment_Type.Name}
                label={language.MANAGE_RECRUITMENT_TYPE}
                placeholder={language.MANAGE_RECRUITMENT_TYPE_PLACEHOLDER}
                required={initialFormField.Recruitment_Type.Required}
              />
              <Form.Item
                label={language.MANAGE_RECRUITMENT_QUANTITY}
                labelAlign='left'
                name={initialFormField.Recruitment_Quantity.Name}
                rules={[{ required: initialFormField.Recruitment_Quantity.Required || false, message: '' }]}
              >
                <Input type='number' placeholder={language.MANAGE_RECRUITMENT_QUANTITY} min={0} />
              </Form.Item>
              <Form.Item
                label={language.MANAGE_RECRUITMENT_ADDRESS}
                labelAlign='left'
                name={initialFormField.Recruitment_Address.Name}
                rules={[{ required: initialFormField.Recruitment_Address.Required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_RECRUITMENT_ADDRESS} maxLength={200} />
              </Form.Item>
              <MpsDatePicker
                label={language.MANAGE_RECRUITMENT_EXPIRED_DATE}
                name={initialFormField.Recruitment_ExpiredDate.Name}
                value={Date.now()}
                required={initialFormField.Recruitment_ExpiredDate.Required}
              />
              <MpsLanguageSelect
                name={initialFormField.Language.name}
                label={language.MANAGE_RECRUITMENT_LANGUAGE}
                required={initialFormField.Language.required}
                hasInitialValue={true}
                form={form}
              />
              <ManageNewsSelect
                form={form}
                name='Status'
                label={language.MANAGE_RECRUITMENT_STATUS}
                placeholder={language.MANAGE_RECRUITMENT_STATUS_PLACEHOLDER}
                required={initialFormField.Status.Required}
                hasInitialValue={true}
              />
              <MpsDatePicker
                label={language.MANAGE_RECRUITMENT_CREATED_DATE}
                name={initialFormField.CreatedDate.Name}
                value={Date.now()}
                required={initialFormField.CreatedDate.Required}
                disabled={true}
              />
            </Form>
          </TabPane>
          <TabPane tab={language.MANAGE_RECRUITMENT_DETAIL_INFO} key='detail-info'>
            <MpsRichTextEditor onChange={handleDetailInfoChange} data={recruitmentData.Recruitment_Detail_Info} />
          </TabPane>
          {/* <TabPane tab={language.MANAGE_RECRUITMENT_DETAIL_INFO_ENG} key='detail-info-eng'>
            <MpsRichTextEditor onChange={handleDetailInfoEngChange} data={recruitmentData.Recruitment_Detail_Info_Eng} />
          </TabPane> */}
        </Tabs>
      </Modal>
    </>
  );
}

export default ManageRecruitmentAdd;


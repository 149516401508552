import React, { useState, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { SwapRightOutlined } from "@ant-design/icons";
import { ManageNewsService } from "../../Admin/ManageNews/Service/ManageNews.Service";
import { formatDateTimeSecondType } from "../../Admin/Common/FormatCommon";
import { NEWS_LANGUAGE } from "../../News/Language/Language";
import { getNewsNameByLanguage } from "../../News/Helper/Helper";

const searchOption = { page: 1, limit: 3 };

function lowerCaseFirstLetter(str) {
  return str[0].toLowerCase() + str.slice(1);
}

function HomeNewsEventItem({ data, language }) {
  const { path } = useRouteMatch();

  return (
    <div className="home__main__news-event__item">
      <div className="home__main__news-event__item__content">
        <div className="home__main__news-event__item__content__sub">
          {formatDateTimeSecondType(data.CreatedDate)} |
          <Link to={`${path}/news/${data.News_GroupObject.News_Group_Slug}`}>
            {language === "vi"
              ? ` Tin ${lowerCaseFirstLetter(
                  data.News_GroupObject.News_Group_Name
                )}`
              : ` ${data.News_GroupObject.News_Group_Name_Eng}`}
          </Link>
        </div>
        <p className="home__main__news-event__item__content__main">
          {getNewsNameByLanguage(data, language)}
        </p>
      </div>
      <Link
        to={`${path}/news/${data.News_GroupObject.News_Group_Slug}/${data.News_Slug}`}
        className="home__main__news-event__item__content__detail"
      >
        <p>{NEWS_LANGUAGE[language]["viewDetail"]}</p>
        <span className="home__main__button__icon">
          <SwapRightOutlined />
        </span>
        <div className="home__main__news-event__item__content__detail--line"></div>
      </Link>
    </div>
  );
}

export default function HomeNewsEvent({ language }) {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const fecthNews = async () => {
      try {
        const result = await ManageNewsService.search(
          { Status: true, Language: language },
          searchOption
        );
        const newsData = result.result.docs || [];
        setNews(newsData);
      } catch (_) {
        // TO DO
      }
    };

    fecthNews();
  }, [language]);

  return (
    <div className="home__main__news-event">
      {news.map((item, index) => {
        return (
          <HomeNewsEventItem
            key={`${index}${item.News_Title}`}
            data={item}
            language={language}
          />
        );
      })}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SwapLeftOutlined } from '@ant-design/icons';
import Helmet1 from '../Layout/Helmet';
import Helmet from 'react-helmet';
import Breadcrum from '../Layout/Breadcrum';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import background from '../../asset/img/img-9.jpg';
import { Row, Col, Skeleton } from 'antd';
import { ManageNewsService } from '../Admin/ManageNews/Service/ManageNews.Service';
import { ManageNewsGroupService } from '../Admin/ManageNews/Service/ManageNewsGroup.Service';
import { formatDateTimeSecondType } from '../Admin/Common/FormatCommon';
import { NEWS_LANGUAGE } from './Language/Language';
import { getGroupNameByLanguage, getNewsDetailByLanguage, getNewsNameByLanguage } from './Helper/Helper';
import noImages from '../../asset/img/default_news_image.jpg';
import RenderMeta from '../Metatada/RenderMeta';

const groupSearchOption = { page: 1, limit: 15 };
const newsSearchOption = { page: 1, limit: 6 };

export default function NewsDetail(props) {
  const { News_Slug, News_Group_Slug } = props.match.params;
  const [image, setImage] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [flagLoading, setFlagLoading] = useState(false);
  const [post, setPost] = useState(null);
  const [subCategory, setSubCategory] = useState(null);

  const handleFetchSubCateAndPost = async () => {
    try {
      setFlagLoading(true);
      const [responseSubCate, responsePost] = await Promise.all([ManageNewsGroupService.getBySlug(News_Group_Slug), ManageNewsService.getBySlug(News_Slug)]);
      const responsePostList = await ManageNewsService.search(
        {
          News_Group: responseSubCate.data.News_Group_Code,
          Language: props.language,
          Status: true,
        },
        newsSearchOption
      );
      setRelatedPosts(responsePostList?.result?.docs?.filter((post) => post.News_Slug !== News_Slug) || []);
      const thumbnail = responsePost.data?.News_Images[0]?.name ? `${process.env.REACT_APP_API_URL}/news/${responsePost.data?.News_Images[0]?.name}` : noImages;
      setImage(thumbnail);
      setPost(responsePost.data || null);
      setSubCategory(responseSubCate.data || null);
      setFlagLoading(false);
    } catch (error) {
      console.log(error);
      setFlagLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    handleFetchSubCateAndPost();
  }, [News_Slug, News_Group_Slug, props.language]);

  return (
    <>
      {flagLoading ? (
         <div className='container'> <Skeleton active /></div>
       
      ) : (
        <>
          {subCategory && post && (
            <>
              <RenderMeta metadata={post.Metadata || []} />
              <Breadcrum
                data={[
                  {
                    title: NEWS_LANGUAGE[props.language]['title'],
                    path: `/${props.language}${NEWS_LANGUAGE[props.language]['path']}`,
                  },
                  {
                    title: getGroupNameByLanguage(subCategory, props.language),
                    path: `/${props.language}/news/${subCategory?.News_Group_Slug}`,
                  },
                  getNewsNameByLanguage(post, props.language),
                ]}
              />
              <TitleBreadcrum title={getGroupNameByLanguage(subCategory, props.language)} />
              <div className='news-generation' style={{ backgroundImage: `url(${background})` }}>
                <div className='news-generation__overlay'></div>
                <div className='container'>
                  <div className='news-detail'>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col lg={16} md={24} sm={24} xs={24}>
                        <div className='news-detail__main'>
                          <p className='news-detail__main__time'>{formatDateTimeSecondType(post.CreatedDate)}</p>
                          <h1>{getNewsNameByLanguage(post, props.language)}</h1>
                          <div className='news-detail__main__img' style={{ backgroundImage: `url(${image})` }}></div>
                          <div
                            className='news-detail__main__content'
                            dangerouslySetInnerHTML={{
                              __html: getNewsDetailByLanguage(post, props.language),
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg={8} md={24} sm={24} xs={24}>
                        <div className='news-detail__sub'>
                          <h3>{NEWS_LANGUAGE[props.language]['relatedNews']}</h3>
                          {relatedPosts.map((item) => {
                            return (
                              <div className='news-detail__sub__item' key={`${item._id}`}>
                                <p className='news-detail__sub__item__time'>{formatDateTimeSecondType(item.CreatedDate)}</p>
                                <h4>{getNewsNameByLanguage(item, props.language)}</h4>
                                <Link className='recruit-main__item__button news-detail__sub__item__button' to={`${item.News_Slug}`}>
                                  {NEWS_LANGUAGE[props.language]['viewDetail']}
                                  <span role='img' aria-label='swap-right' className='anticon anticon-swap-right'>
                                    <svg viewBox='0 0 1024 1024' focusable='false' data-icon='swap-right' width='1em' height='1em' fill='currentColor' aria-hidden='true'>
                                      <path
                                        d={`M873.1 596.2l-164-208A32 32 0 00684 376h-64.8c-6.7 0-10.4 7.7-6.3 13l144.3 
                                      183H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h695.9c26.8 0 41.7-30.8 25.2-51.8z`}
                                      ></path>
                                    </svg>
                                  </span>
                                  <div className='recruit-main__item__button__line'></div>
                                </Link>
                              </div>
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                    <Link to='../../news' className='   news-group-button-back' style={{ bottom: -50 }}>
                      <div className='news-group-button-back__icon'>
                        <SwapLeftOutlined />
                      </div>
                      {NEWS_LANGUAGE[props.language]['returnToNewsPage']}
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

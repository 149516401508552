import React from "react";
import { Form, DatePicker } from "antd";
import "moment/locale/vi";
import locale from "antd/lib/date-picker/locale/vi_VN";
import { momentDateTime } from "../../Admin/Common/FormatCommon";
import ProductTypeSelect from "../../Admin/ManageProductType/Control/ProductTypeSelect";

function MpsDatePicker({ label, name, value, required, message, disabled }) {
  return (
    <Form.Item
      colon={false}
      label={label || ""}
      labelAlign="left"
      name={name}
      initialValue={momentDateTime(value)}
      rules={[{ required: required || false, message: message || "" }]}
    >
      <DatePicker
        placeholder="Chọn ngày"
        showTime={true}
        locale={locale}
        format="DD-MM-YYYY HH:mm:ss"
        style={{ width: "100%" }}
        disabled={disabled}
      />
    </Form.Item>
  );
}

export default MpsDatePicker;

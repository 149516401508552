import React, { useState, useEffect, useCallback } from 'react';
import { Form, Select } from 'antd';
import { ManageTypePostService } from '../Service/ManageTypePost.Service';

const searchModel = {};
const searchOption = { page: 1, limit: 10 };

function ManagePostTypeSelect(props) {
  const [dataSource, setDataSource] = useState([]);

  const fetchDataSelection = useCallback(() => {
    if (props.status === true) {
      ManageTypePostService.search(searchModel, searchOption).then(result => {
        const data = result.result.docs || [];
        setDataSource(data);
      })
    } else {
      ManageTypePostService.getAllTypePostIntroActive().then(result => {
        const data = result.data || [];
        setDataSource(data);
      })
    }

  }, [props.status])

  useEffect(() => {
    fetchDataSelection()
  }, [fetchDataSelection]);

  useEffect(() => {
    if (!props.form) return;

    props.form.resetFields();

  }, [dataSource, props.form]);
  // console.log("check datasource", dataSource)
  return (
    <Form.Item
      label={props.label}
      name={props.name}
      labelAlign='left'
      colon={false}
      initialValue={props.initialValue ? props.initialValue : null}
      rules={[{ required: props.required || false, message: `${props.warning || ''}` }]}
      className={`admin-filed ${props.className || ''}`}
    >
      <Select placeholder={props.placeholder || ''} allowClear={true}>
        {dataSource.length > 0 && dataSource.map(data => {
          return <Select.Option key={`${data._id}`} value={data._id}>
            {data.Type_Post_Title}
          </Select.Option>;
        })}
      </Select>
    </Form.Item>
  );
}

export default React.memo(ManagePostTypeSelect);


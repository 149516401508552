export const LANGUAGE_STATE = {
  'vi': {
    MANAGE_SPECIFICATION: 'Quy cách sản phẩm',
    MANAGE_SPECIFICATION_SEARCH_INFO: 'Thông tin tìm kiếm',
    MANAGE_SPECIFICATION_CODE: 'Mã quy cách',
    MANAGE_SPECIFICATION_NAME: 'Quy cách',
    MANAGE_SPECIFICATION_CATEGORY: 'Phân loại',
    MANAGE_SPECIFICATION_CREATED_DATE: 'Ngày tạo',
    MANAGE_SPECIFICATION_CREATED_PERSON: 'Người tạo',
    MANAGE_SPECIFICATION_UPDATED_DATE: 'Ngày cập nhật',
    MANAGE_SPECIFICATION_UPDATED_PERSON: 'Người cập nhật',
    MANAGE_SPECIFICATION_SEARCH_FROM_DATE: 'Từ ngày',
    MANAGE_SPECIFICATION_SEARCH_TO_DATE: 'Đến ngày',
    MANAGE_SPECIFICATION_STATUS: 'Trạng thái',
    MANAGE_SPECIFICATION_PRODUCT_CATEGORY:'Loại hình',
    MANAGE_SPECIFICATION_PRODUCT: 'Sản phẩm',
    MANAGE_SPECIFICATION_PRODUCT_TYPE: 'Chủng loại',
    
    MANAGE_SPECIFICATION_NAME_VIE: 'Tên quy cách tiếng Việt',
    MANAGE_SPECIFICATION_NAME_VIE_PLACEHOLDER: 'Tên quy cách tiếng Việt (tối đa 200 ký tự)',
    MANAGE_SPECIFICATION_NAME_ENG: 'Tên quy cách tiếng Anh',
    MANAGE_SPECIFICATION_NAME_ENG_PLACEHOLDER: 'Tên quy cách tiếng Anh (tối đa 200 ký tự)',
    MANAGE_SPECIFICATION_CATEGORY_PLACEHOLDER: 'Chọn phân loại',
    MANAGE_SPECIFICATION_STATUS_PLACEHOLDER: 'Chọn trạng thái',
    MANAGE_SPECIFICATION_IMAGES: 'Hình ảnh',
    MANAGE_SPECIFICATION_DETAIL_INFO: 'Thông tin quy cách Tiếng Việt',
    MANAGE_SPECIFICATION_DETAIL_INFO_ENG: 'Thông tin quy cách Tiếng Anh',
    MANAGE_SPECIFICATION_LENGTH: 'Độ dài (mm)',
    MANAGE_SPECIFICATION_WIDTH: 'Bề rộng (mm)',
    MANAGE_SPECIFICATION_HEIGHT: 'Độ dày (mm)',
    MANAGE_SPECIFICATION_UNIT: 'Đơn vị tính',
    MANAGE_SPECIFICATION_WEIGHT_OVER_UNIT: 'Trọng lượng/m',
    MANAGE_SPECIFICATION_WEIGHT:'Khối lượng (kg/cây)',

    MANAGE_SPECIFICATION_CREATE_NEW: 'Đăng quy cách mới',
    MANAGE_SPECIFICATION_EDIT: 'Xem thông tin quy cách',
    MANAGE_SPECIFICATION_GENERAL_INFO: 'Thông tin chung',
    MANAGE_SPECIFICATION_SELECT_DATE: 'Chọn ngày',
    MANAGE_SPECIFICATION_VIEW_DETAIL: 'Xem chi tiết',

    // ACTION
    MANAGE_SPECIFICATION_CANCEL: 'Huỷ',
    MANAGE_SPECIFICATION_DELETE: 'Xóa',
    MANAGE_SPECIFICATION_SAVE: 'Lưu',
    MANAGE_SPECIFICATION_AGREE: 'Có',
    MANAGE_SPECIFICATION_DISAGREE: 'Không',
    MANAGE_SPECIFICATION_CLEAR_SELECT: 'Xóa lựa chọn',
    MANAGE_SPECIFICATION_SEARCH: 'Tìm kiếm',

    // MESSAGE
    MANAGE_SPECIFICATION_CONFIRM_DELETE: 'Bạn có chắc chắn muốn xóa dữ liệu này ?',

  },
  'en': {

  },
};

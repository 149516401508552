import React, { useState } from "react";
import { Row, Col, Form, Modal, Button, Spin } from "antd";
import ManageDocumentSelect from "./Control/ManageDocumentSelect";
import ManageDocumentTextArea from "./Control/ManageDocumentTextArea";
import ManageDocumentFile from "./Control/ManageDocumentFile";
import {
  CREATE_RETURN_CODE_DESCRIPTION,
  RESULT_CODES,
} from "../../../common/Constant.Common";
import { ManageDocumentService } from "./Service/ManageDocumentService";
import {
  DocumentAttribute,
  DocumentCommon,
  DocumentPlaceholder,
} from "./Model/ManageDocumentModel";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../Common/MethodCommon";
import MpsDatePicker from "../../MpsCotrols/MpsDatePicker/MpsDatePicker";
import MpsLanguageSelect from "../../MpsCotrols/MpsSelect/MpsLanguageSelect";

export default function ManageDocumentAdd(props) {
  const { initModelDocument } = props;
  const [form] = Form.useForm();
  const [fileUpload, setFileUpload] = useState(null);
  const [spining, setSpining] = useState(false);
  const onCreate = async () => {
    const values = await form.validateFields();
    let data;
    setSpining(true);
    try {
      if (!!fileUpload) {
        const formData = new FormData();
        const newValue = Object.entries(values);
        formData.append("myFile", fileUpload);
        newValue.forEach((item) => {
          formData.append(item[0], item[1]);
        });
        data = await ManageDocumentService.create(formData);
      } else {
        data = await ManageDocumentService.create(values);
      }

      if (data.returnCode !== RESULT_CODES.SUCCESS) {
        setSpining(false);
        return openErrorNotification({
          title: "Thông báo",
          message:
            CREATE_RETURN_CODE_DESCRIPTION[parseInt(data.returnCode, 10)],
        });
      }
      // console.log(data.data.Document_File)
      const file = {
        fileName: data.data?.Document_File,
      };
      await ManageDocumentService.createImagePdf(file);
      props.signChanged();
      handleResetForm();
      setSpining(false);
      props.onClose();
      openSuccessNotification({
        title: "Thông báo",
        message: CREATE_RETURN_CODE_DESCRIPTION[parseInt(data.returnCode, 10)],
      });
    } catch (error) {
      setSpining(false);
    }
  };

  const handleFileUpload = (file) => {
    setFileUpload(file);
  };

  const handleResetForm = () => {
    form.resetFields();
    setFileUpload(null);
  };
  return (
    <Form initialValues={props.data} form={form}>
      <Modal
        destroyOnClose
        title={DocumentCommon.TitleCreateModal}
        visible={props.visible}
        onOk={props.onClose}
        onCancel={props.onClose}
        width="80%"
        className="admin-modal"
        footer={[
          <Button
            key="back"
            type="primary"
            onClick={() => {
              props.onClose();
            }}
            className="admin-button__main admin-button__delete"
          >
            Hủy
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            className="admin-button__main"
            onClick={onCreate}
          >
            Lưu
          </Button>,
        ]}
      >
        <Spin spinning={spining}>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            key="main-form-input-admin"
          >
            <Col lg={12} md={12} sm={24} xs={24}>
              <ManageDocumentTextArea
                name="Document_Title"
                title={DocumentAttribute["Document_Title"][0]}
                placeholder={DocumentPlaceholder["Document_Title"]}
                typeInput={DocumentAttribute["Document_Title"][1]}
                require={true}
              />
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <ManageDocumentFile
                fileUpload={fileUpload}
                name="Document_File"
                title={DocumentAttribute["Document_File"][0]}
                placeholder={DocumentPlaceholder["Document_File"]}
                typeInput={DocumentAttribute["Document_File"][1]}
                handleFileUpload={handleFileUpload}
                require={true}
              ></ManageDocumentFile>
            </Col>
            {/* <Col lg={12} md={12} sm={24} xs={24}>
            <ManageDocumentTextArea
              name='Document_Title_ENG'
              title={DocumentAttribute['Document_Title_ENG'][0]}
              placeholder={DocumentPlaceholder['Document_Title_ENG']}
              typeInput={DocumentAttribute['Document_Title_ENG'][1]}
              require={true}
            ></ManageDocumentTextArea>
          </Col> */}

            <Col lg={12} md={12} sm={24} xs={24}>
              <Row
                className="admin-filed__list"
                gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
              >
                <Col
                  className="admin-filed__list__item"
                  lg={8}
                  md={24}
                  sm={24}
                  xs={24}
                >
                  <label>
                    {DocumentAttribute["Language"][0]}{" "}
                    <span
                      className={`admin-filed__list__item__required active`}
                    >
                      *
                    </span>
                  </label>
                </Col>
                <Col
                  className="admin-filed__list__item"
                  lg={14}
                  md={24}
                  sm={24}
                  xs={24}
                >
                  <MpsLanguageSelect
                    name={"Language"}
                    required={true}
                    hasInitialValue={true}
                    form={form}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}></Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <ManageDocumentSelect
                name="Status"
                title={DocumentAttribute["Status"][0]}
                placeholder={DocumentPlaceholder["Status"]}
                typeInput={DocumentAttribute["Status"][1]}
                select={initModelDocument.Select["Status"]}
                require={true}
              ></ManageDocumentSelect>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}></Col>
            {/* <Col lg={12} md={12} sm={24} xs={24}>
            <ManageDocumentSelect
              name='Relation_Type'
              title={DocumentAttribute['Relation_Type'][0]}
              placeholder={DocumentPlaceholder['Relation_Type']}
              typeInput={DocumentAttribute['Relation_Type'][1]}
              select={initModelDocument.Select['Relation_Type']}
              require={true}
            />
          </Col> */}
            {/* <Col lg={12} md={12} sm={24} xs={24}>

          </Col> */}
            <Col lg={12} md={12} sm={24} xs={24}>
              <Row
                className="admin-filed__list"
                gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
              >
                <Col
                  className="admin-filed__list__item"
                  lg={8}
                  md={24}
                  sm={24}
                  xs={24}
                >
                  <label>
                    {DocumentAttribute["CreatedAt"][0]}{" "}
                    <span
                      className={`admin-filed__list__item__required active`}
                    >
                      *
                    </span>
                  </label>
                </Col>
                <Col
                  className="admin-filed__list__item"
                  lg={14}
                  md={24}
                  sm={24}
                  xs={24}
                >
                  <MpsDatePicker
                    name="CreatedAt"
                    message="Đây là trường bắt buộc"
                    value={Date.now()}
                    required={true}
                    disabled={true}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </Form>
  );
}

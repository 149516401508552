import React from 'react';
import { Row, Col, Form, Input } from 'antd';

function ManageRelationFile(props) {
  const handleChangeFileUpload = (e) => {
    props.handleFileUpload(e.target.files[0]);
  };

  return (
    <Row className='admin-filed__list' gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
      <Col className='admin-filed__list__item' lg={8} md={24} sm={24} xs={24}>
        <label>{props.title} <span className={`admin-filed__list__item__required ${props.require ? 'active' : ''}`}>*</span></label>
      </Col>
      <Col className='admin-filed__list__item' lg={14} md={24} sm={24} xs={24}>
        <Form.Item
          name={props.name}
          rules={[{ required: props.require || false, message: `Đây là trường bắt buộc` }]}
          className={`admin-filed ${props.className || ''}`}
        >
          <div className='admin-input-upload-file'>
            <Input
              className='admin-input'
              disabled
              value={props.fileUpload?.name || ''}
              placeholder={props.placeholder}
            />
            <label htmlFor='upload-file' className='admin-input-upload-file__browser'>
              Browse
              <Input type='file' id='upload-file' name='myFile' accept='.pdf' onChange={handleChangeFileUpload} />
            </label>
          </div>
        </Form.Item>
      </Col>
    </Row>
  );
}

export default ManageRelationFile;

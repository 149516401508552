
import { Modal } from "antd";
import React from "react";
import { FaTimes } from "react-icons/fa";
import { pdfjs } from "react-pdf";
import { LANGUAGE_STATE } from "../../Admin/ManageNews/Language/Language";
// import './App.scss';
import FlipPages from "../Control/FlipPages";
import "../Css/Relation.css";
import { DownloadDocumentCommon } from "../Model/DownloadDocumentModel";
pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.js";

export default function AppFlipPages(props) {
  const { url_1, language_1 } = props;
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [language] = React.useState(LANGUAGE_STATE["vi"]);

  const handlerShowModal = () => {
    setIsOpenModal((prev) => !prev);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 30,
      }}
    >
      <a
        className="relation-item__main__button--view"
        onClick={handlerShowModal}
      >
        {DownloadDocumentCommon[language_1].view}
      </a>
      <Modal
        className="box"
        visible={isOpenModal}
        title={null}
        onCancel={() => handlerShowModal()}
        footer={null}
        closeIcon={<FaTimes size={40} style={{color:'#fff',marginTop:20,marginRight:20}} />}
      >
        <FlipPages isOpenModal={isOpenModal} url_1={url_1} />
      </Modal>
    </div>
  );
}

import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { SwapRightOutlined } from "@ant-design/icons";
import { formatDateTimeSecondType } from "../../Admin/Common/FormatCommon";
import {
  getNewsExcerptByLanguage,
  getNewsNameByLanguage,
} from "../Helper/Helper";
import noImages from "../../../asset/img/default_news_image.jpg";

const NewsListGroupItemMain = (props) => {
  const { data, language } = props;

  const image = data?.News_Images?.[0]?.name
    ? `${process.env.REACT_APP_API_URL}/news/${data?.News_Images?.[0]?.name}`
    : noImages;

  return data ? (
    <Link
      to={`${data.News_GroupObject.News_Group_Slug}/${data.News_Slug}`}
      className="news-group__main__item__detail__main"
    >
      <Row>
        <Col lg={12} md={12} sm={12} xs={24}>
          <div
            className="news-group__main__item__detail__main__img"
            style={{ backgroundImage: `url('${image || ""}')`, width: "100%" }}
          ></div>
        </Col>
        <Col lg={12} md={12} sm={12} xs={24}>
          <div className="news-group__main__item__detail__main__content">
            <p className="news-group__main__item__detail__main__content__time">
              {formatDateTimeSecondType(data.CreatedDate)}
            </p>
            <h3>{getNewsNameByLanguage(data, language)}</h3>
            <p className="news-group__main__item__detail__main__content__main">
              {getNewsExcerptByLanguage(data, language)}
            </p>
          </div>
          <div className="news-group__main__item__detail__main__detail">
            {props.viewDetail}
            <div className="news-group__main__item__detail__main__detail__icon">
              <SwapRightOutlined />
            </div>
            <div className="news-group__main__item__detail__main__detail__line" />
          </div>
        </Col>
      </Row>
    </Link>
  ) : null;
};
const NewsListGroupItemMainNoImage = (props) => {
  const { data, language } = props;

  return data ? (
    <Link
      to={`${data.News_GroupObject.News_Group_Slug}/${data.News_Slug}`}
      className="news-group__main__item__detail__main"
    >
      <Row>
        <div className="news-group__main__item__detail__main__content__noImage">
          <p className="news-group__main__item__detail__main__content__time">
            {formatDateTimeSecondType(data.CreatedDate)}
          </p>
          <h3>{getNewsNameByLanguage(data, language)}</h3>
          <div className="news-group__main__item__detail__main__detail">
            {props.viewDetail}
            <div className="news-group__main__item__detail__main__detail__icon">
              <SwapRightOutlined />
            </div>
            <div className="news-group__main__item__detail__main__detail__line" />
          </div>
        </div>
      </Row>
    </Link>
  ) : null;
};

const NewsListGroupItemSub = (props) => {
  const { data, language } = props;

  const image = data?.News_Images?.[0]?.name
    ? `${process.env.REACT_APP_API_URL}/news/${data?.News_Images?.[0]?.name}`
    : noImages;

  return data ? (
    <Link
      to={`${data.News_GroupObject.News_Group_Slug}/${data.News_Slug}`}
      className="news-group__main__item__detail__sub"
    >
      <Row>
        <Col lg={4} md={4} sm={8} xs={24}>
          <div
            className="news-group__main__item__detail__sub__img"
            style={{ backgroundImage: `url('${image || ""}')` }}
          ></div>
        </Col>
        <Col lg={20} md={20} sm={16} xs={24}>
          <div className="news-group__main__item__detail__main__content">
            <p className="news-group__main__item__detail__main__content__time">
              {formatDateTimeSecondType(data.CreatedDate)}
            </p>
            <h3>{getNewsNameByLanguage(data, language)}</h3>
            <p className="news-group__main__item__detail__main__content__sub">
              {getNewsExcerptByLanguage(data, language)}
            </p>
          </div>
          <div className="news-group__main__item__detail__main__detail">
            {props.viewDetail}
            <div className="news-group__main__item__detail__main__detail__icon">
              <SwapRightOutlined />
            </div>
            <div className="news-group__main__item__detail__main__detail__line"></div>
          </div>
        </Col>
      </Row>
    </Link>
  ) : null;
};
export function NewsListGroupNoImage(props) {
  const { data, language } = props;
  return (
    <div className="news__main__group">
      {data.map((item, index) => {
        return (
          <div  key={`${item._id}`} className="news__main__group__noImage">
            <NewsListGroupItemMainNoImage
              language={language}
              viewDetail={props.viewDetail}
              data={item}
            />
          </div>
        );
      })}
    </div>
  );
}

export default function NewsListGroup(props) {
  const { data, language } = props;
  return (
    <div className="news-list-group">
      <div className="news__main__group">
        <div className="news__main__item__detail news__main__group__item__detail">
          <NewsListGroupItemMain
            language={language}
            viewDetail={props.viewDetail}
            data={data[0]}
          />
        </div>
      </div>
      <div className="news__main__group">
        {data.map((item, index) => {
          if (index > 0)
            return (
              <div
                key={`${item._id}`}
                className="news__main__item__detail news__main__group__item__detail"
              >
                <NewsListGroupItemSub
                  language={language}
                  viewDetail={props.viewDetail}
                  data={item}
                />
              </div>
            );
          return null;
        })}
      </div>
    </div>
  );
}


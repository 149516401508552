import React, { useContext, useEffect, useState } from "react";
import { useRouteMatch, Route, Switch } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import MessageContact from "../components/Layout/MessageContact";
import HomePage from "../components/Home/HomePage";
import IntroApp from "../components/Intro/IntroApp";
import NewsApp from "../components/News/NewsApp";
import RelationApp from "../components/Relation/RelationApp";
import RecruitApp from "../components/Recruit/RecruitApp";
import ContactPage from "../components/Contact/ContactPage";
import DownloadDocumentApp from "../components/DownloadDocument/DownloadDocumentApp";
import { LanguageContext } from "../components/ContextProvider/LanguageContextProvider";
import { useHistory } from "react-router-dom";

export default function Index(props) {
  const { language='vi', handleChangeLanguage } = useContext(LanguageContext);
  const history = useHistory();

  const { path } = useRouteMatch();
  if (window.location.pathname == "/") {
    history.push(`/${language}`);
  }
  // console.log("index", language);
  return (
    <>
      <Header handleSignLanguage={handleChangeLanguage} language={language} />
      <MessageContact language={language}></MessageContact>
      <Switch>
        <Route
          exact
          path={`/${language}`}
          render={(props) => <HomePage {...props} language={language} />}
        />
        <Route
          path={`/${language}/contact`}
          render={(props) => <ContactPage {...props} language={language} />}
        />
        <Route
          path={`/${language}/intro`}
          render={(props) => <IntroApp {...props} language={language} />}
        />

        <Route
          path={`/${language}/relation`}
          render={(props) => <RelationApp {...props} language={language} />}
        />

        <Route
          path={`/${language}/news`}
          render={(props) => <NewsApp {...props} language={language} />}
        />

        <Route
          path={`/${language}/recruit`}
          render={(props) => <RecruitApp {...props} language={language} />}
        />
        <Route
          path={`/${language}/DownloadDocument`}
          render={(props) => (
            <DownloadDocumentApp {...props} language={language} />
          )}
        />
      </Switch>
      <Footer language={language} />
    </>
  );
}

import React, { useState, useCallback } from 'react';
import { Button, Modal, Form, Tabs, Input } from 'antd';
import { bannerInitData, newsFormInitialField } from './Model/ManageBanner.Model';
import ManageBannerSelect from './Control/ManageBannerSelect';
import ManageBannerTargetSelect from './Control/ManageBannerTargetSelect';
import MpsUploadImageForBanner from '../../MpsCotrols/MpsUploadImage/MpsUploadImageForBanner';
import MpsRichTextEditor from '../../MpsCotrols/MpsRichTextEditor/MpsRichTextEditor';
import { ManageBannerService } from './Service/ManageBanner.Service';
import { RESULT_CODES } from '../../../common/Constant.Common';
import { openSuccessNotification, openErrorNotification } from '../Common/MethodCommon';
// import ManageNewsCategorySelect from './Control/ManageNewsCategorySelect';
// import ManageNewsGroupSelect from './Control/ManageNewsGroupSelect';
import { ManageBannerContext, ManageNewsLanguageContext } from './ManageBannerPage';
import { momentDateTime } from '../Common/FormatCommon';
import MpsDatePicker from '../../MpsCotrols/MpsDatePicker/MpsDatePicker';
import MpsLanguageSelect from '../../MpsCotrols/MpsSelect/MpsLanguageSelect';

const { TabPane } = Tabs;

function ManageBannerAdd(props) {
  const language = props.language
  const handleSearch = React.useContext(ManageBannerContext);

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [category, setCategory] = useState('');
  const [newsData, setNewsData] = useState({ ...bannerInitData });
  const [count, setCount] = useState(1);
  const [activeTabKey, setActiveTabKey] = useState('generalInfo');

  const showModal = () => {
    form.setFieldsValue({ CreatedDate: momentDateTime(Date.now()) });
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  const handleFininsh = () => {
    form.validateFields().then(values => {

      const formData = new FormData();

      // Append images to form data
      newsData.Banner_Images_PC.forEach(image => {
        formData.append('Banner_Images_PC', image.originFileObj);
      });

      newsData.Banner_Images_Tablet.forEach(image => {
        formData.append('Banner_Images_Tablet', image.originFileObj);
      });

      newsData.Banner_Images_Mobile.forEach(image => {
        formData.append('Banner_Images_Mobile', image.originFileObj);
      });

      // Append values to form data
      const formObjectValues = Object.entries(values);
      formObjectValues.forEach(value => {
        formData.append(value[0], value[1]);
      });
      formData.append('Banner_Slogan', newsData.Banner_Slogan);
      formData.append('Banner_Slogan_Eng', newsData.Banner_Slogan_Eng);
      return ManageBannerService.create(formData);
    })
      .then(result => {
        if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);

        handleSearch();
        resetField();
        hideModal();
        setActiveTabKey('generalInfo');
        openSuccessNotification({ title: 'Thông báo', message: 'Thêm mới thành công' });
      })
      .catch((error) => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({ title: 'Thông báo', message: 'Thêm mới thất bại' });
      });
  };

  const handleImagesChange_PC = useCallback((images) => {
    setNewsData(prevState => ({ ...prevState, Banner_Images_PC: images }));
  }, []);

  const handleImagesChange_Tablet = useCallback((images) => {
    setNewsData(prevState => ({ ...prevState, Banner_Images_Tablet: images }));
  }, []);

  const handleImagesChange_Mobile = useCallback((images) => {
    setNewsData(prevState => ({ ...prevState, Banner_Images_Mobile: images }));
  }, []);

  const handleSloganChange = useCallback((text) => {
    setNewsData(prevState => ({ ...prevState, Banner_Slogan: text }));
  }, []);

  const handleSloganEngChange = useCallback((text) => {
    setNewsData(prevState => ({ ...prevState, Banner_Slogan_Eng: text }));
  }, []);

  // const handleAdditionalInfoChange = useCallback((text) => {
  //   setNewsData(prevState => ({ ...prevState, News_AdditionalInfo: text }));
  // }, []);

  // const handleAdditionalInfoEngChange = useCallback((text) => {
  //   setNewsData(prevState => ({ ...prevState, News_AdditionalInfo_Eng: text }));
  // }, []);

  // const handleFormValuesChange = (changedValues) => {
  //   const formFieldName = Object.keys(changedValues)[0];
  //   if (formFieldName === 'News_Category') {
  //     setCount(2);
  //     setCategory(changedValues[formFieldName]);
  //     form.setFieldsValue({ News_Group: null });
  //   }
  // };

  const resetField = () => {
    form.resetFields();
    // setCategory(form.getFieldValue('News_Category'));
    setNewsData({ ...bannerInitData });
  };

  return (
    <>
      <Button onClick={showModal} className='admin-button__main admin-button__download-pdf'>
        <i className='fas fa-plus'></i>
        {language.MANAGE_NEWS_CREATE_NEW_POST}
      </Button>
      <Modal
        title={language.MANAGE_NEWS_CREATE_NEW_POST}
        visible={visible}
        width='80%'
        className='manage-news-modal admin-modal'
        onCancel={hideModal}
        footer={
          [
            <Button
              key='submit'
              type='secondary'
              onClick={hideModal}
              className='admin-button__main admin-button__delete'
            >
              {language.MANAGE_NEWS_CANCEL}
            </Button>,
            <Button
              onClick={handleFininsh}
              key='cancel'
              type='primary'
              className='admin-button__main admin-button__save'
            >
              {language.MANAGE_NEWS_SAVE}
            </Button>
          ]
        }
      >
        <Tabs className='manage-news-tabs' activeKey={activeTabKey} onChange={handleTabChange}>
          <TabPane tab={language.MANAGE_NEWS_GENERAL_INFO} key='generalInfo'>
            <Form form={form} requiredMark={false} id='manage-news-form'
              //onValuesChange={handleFormValuesChange}
              name='manage-news-form'
              className='manage-news-form'
              labelCol={{ lg: { span: 3 }, md: { span: 24 } }}
              wrapperCol={{ lg: { span: 8 }, md: { span: 24 } }}
            >
              <Form.Item
                colon={false}
                label={language.MANAGE_NEWS_TITLE}
                labelAlign='left'
                name={newsFormInitialField.Banner_Title.name}
                rules={[{ required: newsFormInitialField.Banner_Title.required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_NEWS_TITLE_PLACEHOLDER} maxLength={200} />
              </Form.Item>
              {/* <Form.Item
                colon={false}
                label={language.MANAGE_NEWS_TITLE_ENG}
                labelAlign='left'
                name={newsFormInitialField.Banner_Title_Eng.name}
                rules={[{ required: newsFormInitialField.Banner_Title_Eng.required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_NEWS_TITLE_ENG_PLACEHOLDER} maxLength={200} />
              </Form.Item> */}
              {/* <Form.Item
                colon={false}
                label={language.MANAGE_BANNER_SLOGAN}
                labelAlign='left'
                name={newsFormInitialField.Banner_Slogan.name}
                rules={[{ required: newsFormInitialField.Banner_Slogan.required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_BANNER_SLOGAN}/>
              </Form.Item>
              <Form.Item
                colon={false}
                label={language.MANAGE_BANNER_SLOGAN_ENG}
                labelAlign='left'
                name={newsFormInitialField.Banner_Slogan_Eng.name}
                rules={[{ required: newsFormInitialField.Banner_Slogan_Eng.required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_BANNER_SLOGAN_ENG}/>
              </Form.Item> */}
              <ManageBannerTargetSelect
                name={newsFormInitialField.Banner_Target.name}
                placeholder={language.MANAGE_BANNER_TARGET_PLACEHOLDER}
                label={language.MANAGE_BANNER_TARGET}
                hasInitialValue={true}
                required={newsFormInitialField.Banner_Target.required}
                form={form}
              />
              <Form.Item
                colon={false}
                label={language.MANAGE_BANNER_LINK}
                labelAlign='left'
                name={newsFormInitialField.Banner_Link.name}
                rules={[{ required: newsFormInitialField.Banner_Link.required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_BANNER_LINK} />
              </Form.Item>

              <MpsLanguageSelect
                name={newsFormInitialField.Language.name}
                label={language.MANAGE_BANNER_LANGUAGE}
                hasInitialValue={true}
                form={form}
              />

              <ManageBannerSelect
                name={newsFormInitialField.Status.name}
                placeholder={language.MANAGE_NEWS_GROUP_PLACEHOLDER}
                label={language.MANAGE_NEWS_STATUS}
                hasInitialValue={true}
                required={newsFormInitialField.Status.required}
                form={form}
              />
              <MpsDatePicker
                label={language.MANAGE_NEWS_CREATED_DATE}
                name={newsFormInitialField.CreatedDate.name}
                value={Date.now()}
                required={newsFormInitialField.CreatedDate.required}
                disabled={true}

              />
            </Form>
          </TabPane>
          <TabPane tab={language.MANAGEBANNER_SLOGAN} key='addslogan'>
            <div style={{ marginBottom: '20px' }}>{language.MANAGE_BANNER_SLOGAN}</div>
            <MpsRichTextEditor onChange={handleSloganChange} data={newsData.Banner_Slogan} />
            {/* <div style={{ margin: '20px 0px 20px 0px' }}>{language.MANAGE_BANNER_SLOGAN_ENG}</div>
            <MpsRichTextEditor onChange={handleSloganEngChange} data={newsData.Banner_Slogan_Eng} /> */}
          </TabPane>
          <TabPane tab={language.MANAGE_NEWS_IMAGE} key='imageInfo'>
            <div style={{ display: 'flex', marginTop: '50px', textAlign: 'center' }}>
              <MpsUploadImageForBanner onChange={handleImagesChange_PC} images={newsData.Banner_Images_PC} max={1} for='PC' />
              <MpsUploadImageForBanner onChange={handleImagesChange_Tablet} images={newsData.Banner_Images_Tablet} max={1} for='Tablet' />
              <MpsUploadImageForBanner onChange={handleImagesChange_Mobile} images={newsData.Banner_Images_Mobile} max={1} for='Mobile' />
            </div>
          </TabPane>

          {/* <TabPane tab={language.MANAGE_NEWS_ADDITIONAL_INFO_ENG} key='additionalInfo_eng'>
            <MpsRichTextEditor onChange={handleAdditionalInfoEngChange} data={newsData.News_AdditionalInfo_Eng} />
          </TabPane> */}
        </Tabs>
      </Modal>
    </>
  );
}

export default React.memo(ManageBannerAdd);

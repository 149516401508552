import React, { useCallback, useEffect, useMemo, useState } from 'react';
import HomeSliderIntroItem from './HomeSliderIntroItem';
import { SwapRightOutlined } from '@ant-design/icons';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { RESULT_CODES } from '../../../common/Constant.Common';
import { IntroTypeService } from '../../Intro/Service/IntroType.Service';
import { useWindowDimensions } from '../../Intro/CustomHook/useWindowDimensions';
const NextArrow = (props) => {
  return (
    <div className='arrow-slider-intro arrow-slider-intro--next' onClick={props.onClick}>
      <SwapRightOutlined />
    </div>
  );
};
const PrevArrow = (props) => {
  return (
    <div className='arrow-slider-intro arrow-slider-intro--prev' onClick={props.onClick}>
      <SwapRightOutlined />
    </div>
  );
};


export default function HomeSliderIntro(props) {
  const { language } = props

  const [typePostIntroData, setTypePostIntroData] = useState([])
  const { width } = useWindowDimensions()

  const fetchAllTypePostIntro = useCallback(() => {
    IntroTypeService.getAllTypePostIntroActive().then(result => {
      if (result.returnCode !== RESULT_CODES.SUCCESS) return;
      setTypePostIntroData(result.data)
    })
      .catch((e) => {
        // TO DO
        console.log(e)
      });
  }, []);
  const processTypeData = useMemo(() => {
    if (typePostIntroData.length === 0) {
      return []
    }
    return typePostIntroData.map(item => {
      return {
        _id: item._id,
        path: item.Type_Post_Slug,
        title: language === "vi" ? item.Type_Post_Title : item.Type_Post_Title_Eng,
        background: item.Type_Post_Image_Pc.length > 0 ? `${process.env.REACT_APP_API_URL}/${item.Type_Post_Image_Pc[0].url}` : "",
        mobilebg: item.Type_Post_Image_Mobile.length > 0 ? `${process.env.REACT_APP_API_URL}/${item.Type_Post_Image_Mobile[0].url}` : "",
        tableBg: item.Type_Post_Image_Tablet.length > 0 ? `${process.env.REACT_APP_API_URL}/${item.Type_Post_Image_Tablet[0].url}` : "",
      }
    })
  }, [typePostIntroData, language])

  //Xu ly so luong slider duoc show o moi kich thuoc man hinh
  const slideToShow = useMemo(() => {
    if (width > 1000) {
      //Xu ly truong hop co it hon 3 loai thu vien se vo layout
      if (processTypeData.length > 3) {
        return 3
      }
      return processTypeData.length
    } else {
      if (width > 576 && width < 1000) {
        return 2
      }
    }
    return 1
  }, [width, processTypeData])

  const settings = useMemo(() => {
    return {
      nextArrow: <NextArrow></NextArrow>,
      prevArrow: <PrevArrow></PrevArrow>,
      infinite: true,
      speed: 500,
      slidesToShow: slideToShow,
      slidesToScroll: 1,
      dots: false,
    }
  }, [slideToShow])


  useEffect(() => {
    fetchAllTypePostIntro()
  }, [])
  const onHandleShowItemSlider = () => {
    let result = null;
    if (processTypeData) {
      result = processTypeData.map((item, index) => {
        return (
          <HomeSliderIntroItem
            key={item._id}
            index={index}
            background={item.background}
            mobileBackground={item.mobilebg}
            tabletBg={item.tableBg}
            title={item.title}
            path={item.path}
            language={props.language}
          ></HomeSliderIntroItem>
        );
      });
    }
    return result;
  };
  return (
    <Slider {...settings} >
      {onHandleShowItemSlider()}
    </Slider>
  );
}


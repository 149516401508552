import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import NewsPage from "./NewsPage";
import "./Css/News.css";
import NewsGroup from "./NewsGroup";
import NewsDetail from "./NewsDetail";

export default function NewsApp({ language }) {
  const { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={(props) => <NewsPage {...props} language={language} />}
        />
        <Route
          exact
          path={`${path}/:News_Group_Slug`}
          render={(props) => <NewsGroup {...props} language={language} />}
        />
        <Route
          exact
          path={`${path}/:News_Group_Slug/:News_Slug`}
          render={(props) => <NewsDetail {...props} language={language} />}
        />
      </Switch>
    </>
  );
}

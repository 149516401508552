import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { SwapRightOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import HomeSliderIntro from './Control/HomeSliderIntro';
import HomeShareHolderNews from './Control/HomeShareHolderNews';
import HomeNewsEvent from './Control/HomeNewsEvent';
import { HomeCommon } from './Model/HomeModel';
import BGNews from '../../asset/img/BGnews.png';
import HomeMoreInfo from './Control/HomeMoreInfo';
import { ManageBannerService } from '../Admin/ManageBanner/Service/ManageBanner.Service';

const getMarginContainer = () => {
    const a = document.getElementsByClassName('container')[0];
    return a.currentStyle || window.getComputedStyle(a);
};
export default function HomeContent(props) {
    const [margin, setMargin] = useState('0');
    const { language } = props;
    const { path } = useRouteMatch();
    const [dataSource, setDataSource] = useState ([])


    const handleResize = () => {
        setMargin(getMarginContainer().marginLeft);
    };

       
    useEffect (() => {
        const searchOption = {
        limit: 10,
        page: 1
        }
        const searchModel = {
        News_Sort: false
        }
        ManageBannerService.getImagesForLandingPage (searchModel, searchOption).then (result => {
        setDataSource (result?.result?.docs)
        })
    }, [])


    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div className='home__main'>
            <div className='home__main__child--heading'>
                <div className='home__main__child'>
                    <div className='container'>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='home__main__heading'  style = {{marginTop: (dataSource.length === 0)?'80px':'0px'}}>
                            <Col lg={12} md={14} sm={12} xs={24}>
                                <div className='home__main__title'>
                                    <div className='home__main__title__circle home__main__title__circle--red'></div>
                                    <h2 className='home__main__title__main'>
                                        {HomeCommon[language].sliderIntro.title}
                                    </h2>
                                </div>
                            </Col>
                            <Col lg={12} md={10} sm={12} xs={24}>
                                <Link to={`${path}/intro`} className='home__main__button-container'>
                                    <div className='home__main__button home__main__button--red'>
                                        <span>{HomeCommon[language].buttonSeeMore}</span>
                                        <span className='home__main__button__icon'>
                                            <SwapRightOutlined />
                                        </span>
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='home__main__child home__main__child--intro'>
                    <div className='home__main__intro__overlay' style={{
                        width: `calc( 100% - ${margin})`
                    }}>
                    </div>
                    <div className='container'>
                        <div className='home__main__intro__list'>
                            <HomeSliderIntro language={language} slider={HomeCommon[language].sliderIntro.main}></HomeSliderIntro>
                        </div>
                    </div>
                </div>
            </div>
            <div className='home__main__child--center'>
                <div className='home__main__child--center__overlay' style={{
                    width: `calc( 100% - ${margin})`
                }}>
                </div>
                <div className='home__main__child'>
                    <div className='container'>
                        <div className='home__main__news-container' style={{
                            // width: `calc( 100% - ${margin})`
                        }}>
                            <div className='home__main__news__overlay' style={{ backgroundImage: `url(${BGNews})` }}></div>
                        </div>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='home__main__heading'>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <div className='home__main__title'>
                                    <div className='home__main__title__circle home__main__title__circle--red'></div>
                                    <h2 className='home__main__title__main'>
                                        {HomeCommon[language].ShareHolderNewsSample.title}
                                    </h2>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Link to={`${path}/relation`} className='home__main__button-container'>
                                    <div className='home__main__button home__main__button--red'>
                                        <span>{HomeCommon[language].buttonSeeMore}</span>
                                        <span className='home__main__button__icon'>
                                            <SwapRightOutlined />
                                        </span>
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                        <div className='home__main__news'>
                            <HomeShareHolderNews language={props.language}></HomeShareHolderNews>
                        </div>
                    </div>
                </div>
            </div>
            <div className='home__main__child'>
                <div className='container'>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='home__main__heading'>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <div className='home__main__title'>
                                <div className='home__main__title__circle home__main__title__circle--red'></div>
                                <h2 className='home__main__title__main'>
                                    {HomeCommon[language].NewsEvent.title}
                                </h2>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <Link to={`${path}/news`} className='home__main__button-container'>
                                <div className='home__main__button home__main__button--red'>
                                    <span>{HomeCommon[language].buttonSeeMore}</span>
                                    <span className='home__main__button__icon'>
                                        <SwapRightOutlined />
                                    </span>
                                </div>
                            </Link>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='home__main__child home__main__child--news'>
                <div className='home__main__news-event--overlay' style={{
                    backgroundColor: '#FDF2F2',
                    width: `calc(100% - ${margin} - ${margin})`
                }}
                ></div>
                <div className='container'>
                    <HomeNewsEvent language={language} />
                </div>
            </div>
            <div className='home__main__child'>
                <div className='container'>
                    <HomeMoreInfo language={language} typeSlug={"achieve"} maxPost={2} />
                </div>
            </div>
        </div>
    );
}

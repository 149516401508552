export const NEWS_LANGUAGE = {
  'vi': {
    path: '/news',
    title: 'Tin tức và Sự kiện',
    sortTitle: 'Sắp xếp theo',
    sortData: [
      {
        value: 'newest',
        content: 'Tin mới nhất'
      },
      {
        value: 'oldest',
        content: 'Tin cũ nhất'
      },
      // {
      //   value: 'popular',
      //   content: 'Tin phổ biến'
      // },
    ],
    viewAll: 'Xem tất cả',
    viewDetail: 'Chi tiết',
    returnToNewsPage: 'Về trang tin tức',
    relatedNews: 'Tin liên quan',
    documentRangeTime:"Các tin tức trong thời gian",
    search:"Nhập tiêu đề hoặc nội dung cần tìm ",
    detailTitle1: 'TIN TỨC THÔNG TIN',
    newestNews:'TIN MỚI NHẤT',
    commonNews:'TIN PHỔ BIẾN',
    fromDate: 'Từ ngày',
    toDate:'Đến ngày',
  },
  'en': {
    path: '/news',
    title: 'News and Media',
    sortTitle: 'Sort by',
    sortData: [
      {
        value: 'newest',
        content: 'Newest'
      },
      {
        value: 'oldest',
        content: 'Oldest'
      },
      // {
      //   value: 'popular',
      //   content: 'Popular'
      // },
    ],
    viewAll: 'View all',
    viewDetail: 'Details',
    returnToNewsPage: 'Back',
    relatedNews: 'Related news',
    documentRangeTime:"Time range",
    search:"Enter the title or content ... ",
    detailTitle1: 'TIN TỨC THÔNG TIN',
    newestNews:'LATEST NEWS',
    commonNews:'COMMON NEWS',
    fromDate: 'From date',
    toDate:'To date',
  },
};

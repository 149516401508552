
import { formatDateTime } from '../../Common/FormatCommon';
import ManageBannerEdit from '../ManageBannerEdit';

export const statusModel = [
  { name: 'Hiển thị', value: true },
  { name: 'Ẩn', value: false },
];

export const targetModel = [
  { name: 'Trang giới thiệu', value: true },
  { name: 'Trang sản phẩm', value: false },
];

export const searchModel = {
  Banner_Title: null,
  Banner_Slogan: null,
  Banner_Link: null,
  Banner_Target: null,
  Status: null,
  CreatedDate: null,
};

export const newsSearchPlaceholder = {
  Banner_Title: 'Tiêu đề',
  Status: 'Trạng thái',
};

export const newsSearchLabel = {
  Banner_Title: 'Tiêu đề',
  Status: 'Trạng thái',
  CreatedDate: 'Ngày tạo',
};

export const bannerInitData = {
  _id: null,
  Banner_Title: null,
  // Banner_Title_Eng: null,
  Banner_Slogan: '',
  Banner_Slogan_Eng: '',
  Banner_Images_PC: [],
  Banner_Images_Tablet: [],
  Banner_Images_Mobile: [],
  Banner_Link: '',
  Banner_Target: null,
  Language: '',
  Status: null,
  CreatedDate: null,
};

export const newsFormInitialField = {
  Banner_Title: {
    placeholder: 'Tiêu đề (tối đa 200 ký tự)',
    required: true,
    name: 'Banner_Title'
  },
  // Banner_Title_Eng: {
  //   placeholder: 'Tiêu đề (tối đa 200 ký tự)',
  //   required: true,
  //   name: 'Banner_Title_Eng'
  // },
  Banner_Slogan: {
    placeholder: 'Slogan',
    required: true,
    name: 'Banner_Slogan'
  },
  Banner_Slogan_Eng: {
    placeholder: 'Slogan',
    required: true,
    name: 'Banner_Slogan_Eng'
  },
  Banner_Link: {
    placeholder: 'Đường dẫn',
    required: false,
    name: 'Banner_Link'
  },
  Banner_Target: {
    placeholder: 'Chọn nơi hiển thị',
    required: true,
    label: 'Nơi hiển thị',
    name: 'Banner_Target'
  },
  Language: {
    placeholder: 'Chọn ngôn ngữ',
    required: true,
    label: 'Ngôn ngữ',
    name: 'Language'
  },
  Status: {
    placeholder: 'Chọn trạng thái',
    required: true,
    label: 'Trạng thái',
    name: 'Status'
  },
  CreatedDate: {
    placeholder: '',
    required: true,
    disabled: true,
    label: 'Ngày tạo',
    name: 'CreatedDate'
  },
};

export const columns = (language) => {
  return [
    {
      title: language.MANAGE_BANNER_TITLE,
      dataIndex: 'Banner_Title',
      key: 'Banner_Title'
    },
    {
      title: language.MANAGEBANNER_SLOGAN,
      dataIndex: 'Banner_Slogan',
      key: 'Banner_Slogan',
      render: (value) => {
        return <div dangerouslySetInnerHTML={{ __html: (value) }}></div>;
      }
    },
    {
      title: language.MANAGE_BANNER_TARGET,
      dataIndex: 'Banner_Target',
      key: 'Banner_Target',
      render: (value) => {
        return value === false ? 'Trang sản phẩm' : 'Trang giới thiệu';
      }
    },
    {
      title: language.MANAGE_BANNER_LINK,
      dataIndex: 'Banner_Link',
      key: 'Banner_Link',
    },
    {
      title: language.MANAGE_BANNER_LANGUAGE,
      dataIndex: 'Language',
      key: 'Language',
      render: (value) => {
        if (!value) {
          return ''
        }
        if (value === 'en') {
          return 'Tiếng Anh'
        }
        return 'Tiếng Việt'
      }
    },
    {
      title: language.MANAGE_NEWS_CREATED_DATE,
      dataIndex: 'CreatedDate',
      key: 'CreatedDate',
      render: (value) => {
        return formatDateTime(value);
      }
    },
    {
      title: language.MANAGE_NEWS_STATUS,
      dataIndex: 'Status',
      key: 'Status',
      render: (value) => {
        return value === false ? 'Ẩn' : 'Hiển thị';
      }
    },
    {
      title: '',
      dataIndex: '_id',
      render: (value) => {
        return <ManageBannerEdit _id={value} />;
      }
    }
  ];
};



import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { SwapRightOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { IntroTypeService } from '../../Intro/Service/IntroType.Service';
import { RESULT_CODES } from '../../../common/Constant.Common';
import { HomeCommon } from '../Model/HomeModel';
import { openErrorNotification } from '../../Admin/Common/MethodCommon';


export default function HomeMoreInfo(props) {
    const { language, typeSlug, maxPost } = props;
    const [typePostData, setTypePostData] = useState([]);
    const [dataSource, setDataSource] = useState([])

    const fetchTypeData = useCallback(async () => {
        try {
            const res = await IntroTypeService.getAllPostIntroBySlug(typeSlug, language)
            // console.log("check res", res)
            if (res.returnCode === RESULT_CODES.SUCCESS) {
                const postData = res.data?.postIntro.slice(0, maxPost)
                setDataSource(postData)
                setTypePostData(res.data?.type_post)
            }
        } catch (error) {
            console.log(error)
            openErrorNotification({ title: 'Thông báo', message: 'Dữ liệu thất bại' })
        }

    }, [typeSlug, language])

    const processDataSource = useMemo(() => {
        if (dataSource.length === 0) {
            return []
        }
        return dataSource.map((item, index) => {
            return {
                _id: item._id,
                title: item.Post_Intro_Title,
                content: item.Post_Intro_Content
            }
        })
    }, [dataSource, language])

    const processTypePostData = useMemo(() => {
        if (typePostData.length === 0) {
            return {
                path: ""
            }
        }
        return {
            path: language + typePostData[0].Type_Post_Slug
        }
    }, [typePostData, language])


    useEffect(() => {
        fetchTypeData();
    }, [language]);
    // console.log("check dartasourcae", processDataSource)
    return (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            {
                processDataSource && processDataSource.length > 0 && processDataSource.map(item => {
                    return (
                        <Col lg={12} md={24} sm={24} xs={24} key={item._id}>
                            <div className='home__main__child__small-heading'>
                                <h3>{item.title}</h3>
                                <Link to={processTypePostData.path} className='home__main__child__small-heading__detail'>
                                    <div className='home__main__child__small-heading__detail__line'></div>
                                    <p>
                                        {HomeCommon[language].buttonDetail}
                                    </p>
                                    <SwapRightOutlined />
                                </Link>
                            </div>
                            <div className='home__main__child__small-main-container'>
                                <div className='home__main__child__small-background'></div>
                                <div className='home__main__child__small-main' dangerouslySetInnerHTML={{ __html: item.content }}>
                                </div>
                            </div>
                        </Col>
                    )
                })
            }
        </Row>
    );
}

import { authHeader } from "../../../../helper/authHeader";

export const LoginService = {
    login,
    getUserInfor,
    logout,
    // getUser,
    // getAuthorizeUser,
    // getUserInfor,
    // getCurrentLanguage,
    // checkLogout,
    // checkInfor
};
const content = 'Content-Type';
const Type = 'application/json';

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };
    return fetch(process.env.REACT_APP_API_URL + `/User/signInAdmin`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        })
}

function getUserInfor() {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'GET',
    };
    return fetch(process.env.REACT_APP_API_URL + `/User/getUserInfor`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('accessToken');
    // localStorage.removeItem('userInfor');
}
// function checkLogout() {
//     if(!localStorage.getItem('userinfor')) {
//         history.push('/login');
//         return true;
//     }
//     return false;
// }
// function checkInfor() {
//     return localStorage.getItem('userinfor') ? true : false;
// }
// function getUser(model, language) {
//     const requestOptions = {
//         method: 'POST',
//         headers: { ...authHeader(), 'Content-Type': 'application/json' },
//         body: JSON.stringify(model)
//     };
//     return fetch(API_URL + `/Authentication/getAuthorizeUser`, requestOptions).then(handleResponse)
//         .then(userinfor => {
//             // login successful if there's a jwt token in the response
//             if (userinfor) {
//                 // store user details and jwt token in local storage to keep user logged in between page refreshes
//                 if (!!language) {
//                     userinfor[0].User.System_User_Language = language;
//                 }
//                 localStorage.setItem('userinfor', JSON.stringify(userinfor));
//             }
//         });

// }

// async function getAuthorizeUser(model, language) {
//     await getUser(model, language);
//     return JSON.parse(localStorage.getItem('userinfor'));
// }

// function getUserInfor() {
//     return JSON.parse(localStorage.getItem('userinfor'));
// }
// function getCurrentLanguage() {
//     var module = JSON.parse(localStorage.getItem('userinfor'));
//     var languageAvailable = jsonQuery('[**]User[System_User_Language]', { data: module }).value[0];
//     return languageAvailable;
// }

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
import { Row, Col } from 'antd';
import React from 'react'
import { CooperationUnit, FooterCommon } from './Model/LayoutModel';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function UniCoop(props) {
    const { language } = props;
    const onHandleShowItemFooter = () => {
        let result = null;
        result = CooperationUnit.map((item, index) => {
            return (<div key={index} className="home__main__unit__item">
                <img src={item.src} alt="unit" style={{
                    height: "107px",
                    width: item.width ? item.width : "inherit"
                }}></img>
            </div>)
        })
        return result;
    }
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }

        ]
    };
    return (
        <div className="home__main__child">
            <div className="container">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="home__main__heading">
                    <Col>
                        <div className="home__main__heading">
                            <div className="home__main__title">
                                <h2 className="home__main__title__main home__main__title--unit">
                                   {FooterCommon[language].CooperationUnitTitle}
                                </h2>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Slider {...settings} className="home__main__unit">
                    {onHandleShowItemFooter()}
                </Slider>
            </div>
        </div>
    )
}

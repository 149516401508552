import React, { memo, useState } from 'react';
import AdminMenu from '../Layout/AdminMenu';
import { Row, Col, Form, Input } from 'antd';
import ManageBannerSelect from './Control/ManageBannerSelect';
import ManageBannerTargetSelect from './Control/ManageBannerTargetSelect';
// import ManageNewsCategorySelect from './Control/ManageNewsCategorySelect';
import MpsRangePicker from '../../MpsCotrols/MpsRangePicker/MpsRangePicker'
import { ManageNewsLanguageContext } from './ManageBannerPage';
import MpsSortSelect from '../../MpsCotrols/MpsSelect/MpsSortSelect';
import MpsLanguageSelect from '../../MpsCotrols/MpsSelect/MpsLanguageSelect';

function ManageBannerSearch(props) {
  const language = React.useContext(ManageNewsLanguageContext);
  return (
    <AdminMenu title={language.MANAGE_BANNER_SEARCH_INFO}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 50 }}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_NEWS_TITLE}
            name='Banner_Title'
          >
            <Input placeholder={language.MANAGE_NEWS_TITLE} />
          </Form.Item>
        </Col>

        {/* <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGEBANNER_SLOGAN}
            name='Banner_Slogan'
          >
            <Input placeholder={language.MANAGEBANNER_SLOGAN} />
          </Form.Item>
        </Col> */}
        <Col lg={12} md={12} sm={24} xs={24}>
          <ManageBannerTargetSelect label={language.MANAGE_BANNER_TARGET} name='Banner_Target'
            placeholder={language.MANAGE_BANNER_TARGET_PLACEHOLDER} dataSource={['Trang giới thiệu', 'Trang sản phẩm']} />
        </Col>

        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_BANNER_LINK}
            name='Banner_Link'
          >
            <Input placeholder={language.MANAGE_BANNER_LINK} />
          </Form.Item>
        </Col>

        <Col lg={12} md={12} sm={24} xs={24}>
          <ManageBannerSelect label={language.MANAGE_NEWS_STATUS} name='Status'
            placeholder={language.MANAGE_NEWS_STATUS_PLACEHOLDER} dataSource={['Hiển thị', 'Ẩn']} />
        </Col>
        {/* <Col lg={12} md={12} sm={24} xs={24}>
          <ManageNewsCategorySelect
            label={language.MANAGE_NEWS_CATEGORY}
            name='News_Category' placeholder={language.MANAGE_NEWS_CATEGORY_PLACEHOLDER}
          />
        </Col> */}
        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsLanguageSelect name='Language' label={language.MANAGE_BANNER_LANGUAGE} />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsRangePicker onChange={() => { }} language='vi' label={language.MANAGE_NEWS_CREATED_DATE} name='CreatedDate' />
        </Col>

        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsSortSelect name='News_Sort' />
        </Col>
      </Row>
    </AdminMenu>
  );
}

export default memo(ManageBannerSearch);


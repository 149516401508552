import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Collapse } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import UniCoop from './UnitCoop';
import { contactMedia, FooterCommon } from './Model/LayoutModel';
import background from '../../asset/img/footer.jpg';
import { openErrorNotification } from '../Admin/Common/MethodCommon';
import { IntroTypeService } from '../Intro/Service/IntroType.Service';
import { RESULT_CODES } from '../../common/Constant.Common';

const { Panel } = Collapse;

const SubCollapseFooter = ({ item }) => {
  return (
    <Collapse expandIconPosition='right' key={item.title} ghost expandIcon={({ isActive }) => {
      return isActive ? <i className='fas fa-chevron-up' /> : <i className='fas fa-chevron-down' />;
    }}>
      <Panel header={item.title} key={item.title} className='footer__mobile__nav__sub'>
        {item.sub.map((subItem, subIndex) => {

          return (
            <Link to={subItem.path} className='footer__mobile__nav__sub footer__mobile__nav__sub__sub' key={`footer__mobile__nav__sub__${subIndex}`}>
              {subItem.title}
            </Link>
          );
        })}
      </Panel>
    </Collapse>
  );
};

const CollapseFooter = ({ item, language, processDataSource }) => {
  if (item === FooterCommon[language].FooterTitle[0]) {
    return (
      <Collapse expandIconPosition='right' ghost expandIcon={({ isActive }) => {
        return isActive ? <i className='fas fa-chevron-up' /> : <i className='fas fa-chevron-down' />;
      }}>
        <Panel header={item.title} className='footer__mobile__nav__main'>
          {processDataSource.map((subItem, subIndex) => {
            return (
              <Link to={subItem.path} className='footer__mobile__nav__sub' key={`footer__mobile__nav__sub__${subIndex}`}>
                {subItem.title}
              </Link>
            );
          })}
        </Panel>
      </Collapse>
    );
  }
  return (
    <Collapse expandIconPosition='right' ghost expandIcon={({ isActive }) => {
      return isActive ? <i className='fas fa-chevron-up' /> : <i className='fas fa-chevron-down' />;
    }}>
      <Panel header={item.title} className='footer__mobile__nav__main'>
        {item.sub.map((subItem, subIndex) => {
          if (subItem.sub) {
            return (
              <SubCollapseFooter item={subItem} key={`footer__mobile__nav__sub__sub__${subItem}`} />
            );
          }
          if (subItem.link) {
            return (
              <a href={subItem.link} className='footer__mobile__nav__sub'
                target='_blank' rel='noopener noreferrer'
                key={`footer__mobile__nav__sub__${subIndex}`}
              >
                {subItem.title}
              </a>
            );
          }
          return (
            <Link to={subItem.path} className='footer__mobile__nav__sub' key={`footer__mobile__nav__sub__${subIndex}`}>
              {subItem.title}
            </Link>
          );
        })}
      </Panel>
    </Collapse>
  );
};

export default function Footer(props) {
  const { language } = props;
  const [dataSource, setDataSource] = useState([])

  const fetchDataSource = useCallback(async () => {
    try {
      const res = await IntroTypeService.getAllTypePostIntroActive()
      if (res.returnCode === RESULT_CODES.SUCCESS) {
        setDataSource(res.data)
      }
    } catch (error) {
      console.log(error)
      openErrorNotification({ title: 'Thông báo', message: 'Dữ liệu thất bại' })
    }
  }, [])

  const processDataSource = useMemo(() => {
    if (dataSource.length === 0) {
      return []
    }
    return dataSource.map(item => {
      return {
        _id: item._id,
        path: "/" + language + item.Type_Post_Slug,
        title: language === "vi" ? item.Type_Post_Title : item.Type_Post_Title_Eng
      }
    })
  }, [language, dataSource])

  useEffect(() => {
    fetchDataSource()
  }, [])

  const onHandleShowItemFooter = () => {
    let result = null;
    result = FooterCommon[language].FooterTitle.map((item, index) => {
      if (index === 0) {
        return (<Col lg={6} md={12} sm={12} xs={24} key={index + item.title}>
          <div className='footer__main__item'>
            <h3>{item.title}</h3>
            {processDataSource.map((itemSub, index) => {
              return (
                <Link to={`${itemSub.path}`} key={itemSub._id}>
                  {itemSub.title}
                </Link>
              );
            })}
          </div>
        </Col>)
      }
      return (
        <Col lg={6} md={12} sm={12} xs={24} key={index + item.title}>
          <div className='footer__main__item'>
            <h3>{item.title}</h3>
            {item.sub.map((itemSub, index) => {
              if (itemSub.sub && itemSub.sub.length > 0) {
                return (
                  <div key={index + item.title} className='footer__main__item__sub'>
                    {itemSub.title}
                    <div className={`footer__main__item__sub__item active`}>
                      {itemSub.sub.map((itemSubchild, index) => {
                        return (
                          <Link to={`${itemSubchild.path}`} key={index + itemSubchild.title}>
                            <div className='footer__main__item__sub__title'>
                              <CaretDownOutlined />
                              <p>
                                {itemSubchild.title}
                              </p>
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                );
              }
              if (itemSub.link)
                return (
                  <a href={`${itemSub.link}`} key={index + item.title}
                    target='_blank' rel='noopener noreferrer'
                  >
                    {itemSub.title}
                  </a>
                );
              return (
                <Link to={`${itemSub.path}`} key={index + item.title}>
                  {itemSub.title}
                </Link>
              );
            })}
          </div>
        </Col>
      );
    });
    return result;
  };

  return (
    <footer>
      <UniCoop language={language}></UniCoop>
      {/* <RegisterMail></RegisterMail> */}
      <div className='footer' style={{ backgroundImage: `url('${background}')` }}>
        <div className='footer__overlay'></div>
        <div className='container'>
          <Row className='footer__main footer__desktop' gutter={{ xs: 10, sm: 15, md: 20, lg: 35 }}>
            {onHandleShowItemFooter()}
          </Row>
          <Row className='footer__ending footer__desktop' gutter={{ xs: 10, sm: 15, md: 20, lg: 35 }}>
            <Col lg={16} md={16} sm={24} xs={24}>
              <div className='footer__ending__item'>
                <div className='footer__ending__item__logo'>
                  <img src={FooterCommon[language].FooterEnding.logo} alt='logo'></img>
                </div>
                <div className='footer__ending__item__detail'>
                  <h4 className='footer__ending__item__title'>
                    {FooterCommon[language].FooterEnding.title}
                  </h4>
                  <div className='footer__ending__item__detail__item'>
                    <i className='fas fa-map-marker-alt'></i>
                    <a href="https://goo.gl/maps/1d47FRPXZmn22rZ57" target="_blank" style={{ color: 'black' }}>
                      <p>
                        {FooterCommon[language].FooterEnding.address}
                      </p>
                    </a>
                  </div>
                  <div className='footer__ending__item__detail__item'>
                    <i className="fas fa-phone" ></i>
                    <a href={`tel:${FooterCommon[language].FooterEnding.phone}`} style={{ color: 'black' }}>
                      <p>{FooterCommon[language].FooterEnding.phone}</p>
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <div className='footer__ending__item footer__ending__item--media'>
                <a
                  href={`${contactMedia.facebook}`}
                  target='_blank' rel='noopener noreferrer'>
                  <div className='footer__ending__item__icon'>
                    <i className='fab fa-facebook-f'></i>
                  </div>
                </a>
                <a href={`mailto:${contactMedia.email}`}>
                  <div className='footer__ending__item__icon'>
                    <i className='fas fa-envelope'></i>
                  </div>
                </a>
                <a href={`tel:${contactMedia.phone}`}>
                  <div className='footer__item-media__icon'>
                    <i className='fas fa-phone-alt'></i>
                  </div>
                </a>
              </div>
            </Col>
          </Row>

          <div className='footer__mobile'>
            <div className='footer__mobile__nav'>
              {FooterCommon[language].FooterTitle.map((item, index) => {
                if (item.link) {
                  return (
                    <a href={item.link} className='footer__mobile__nav__main' key={`footer__mobile__nav__main__${index}`}>
                      {item.title}
                    </a>
                  );
                } else {
                  if (item.sub) {
                    return (
                      <CollapseFooter item={item} key={item.title} language={language} processDataSource={processDataSource} />
                    );
                  }
                  return (
                    <Link to={item.path} className='footer__mobile__nav__main' key={`footer__mobile__nav__main__${index}`}>
                      {item.title}
                    </Link>
                  );
                }
              })}
            </div>
            <div className="footer__mobile__company">
              <img src={FooterCommon[language].FooterEnding.logo} alt='logo'></img>
              <div className='footer__mobile__company__info'>
                <h4>{FooterCommon[language].FooterEnding.title}</h4>
                <p className='footer__mobile__company__info__flex'>
                  <i className='fas fa-map-marker-alt' ></i>
                  <a href="https://goo.gl/maps/1d47FRPXZmn22rZ57" target="_blank" style={{ color: 'black' }}>
                    <span dangerouslySetInnerHTML={{ __html: FooterCommon[language].FooterEnding.address }}>
                    </span>
                  </a>
                </p>
                <p className='footer__item__phone-number'>
                  <i className='fas fa-phone' ></i>
                  <a href={`tel:${FooterCommon[language].FooterEnding.phone}`} style={{ color: 'black' }}>
                    {FooterCommon[language].FooterEnding.phone}
                  </a>
                </p>
              </div>
            </div>
            <div className="footer__mobile__media">
              <a href={`${contactMedia.facebook}`} target='_blank' rel='noopener noreferrer'>
                <div className='footer__item-media__icon'>
                  <i className='fab fa-facebook-f' ></i>
                </div>
              </a>
              <a href={`mailto:${contactMedia.email}`}>
                <div className='footer__item-media__icon'>
                  <i className='fas fa-envelope'></i>
                </div>
              </a>
              <a href={`tel:${contactMedia.phone}`}>
                <div className='footer__item-media__icon'>
                  <i className='fas fa-phone-alt'></i>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='footer__policy'>
        <div className='container'>
          <div className='footer__policy__main'>
            <h2>
              Copyright Tien Len Steel Corporation Joint Stock Company. All rights reserved
            </h2>
          </div>
        </div>
      </div>
    </footer>
  );
}

import React from "react";
import { Row, Col } from "antd";
import { Link, useRouteMatch } from "react-router-dom";
import { formatDateTimeSecondType } from "../../Admin/Common/FormatCommon";
import { getNewsNameByLanguage } from "../Helper/Helper";
import noImages from "../../../asset/img/default_news_image.jpg";

const NewsListItemMain = (props) => {
  const { data, language } = props;
  const { path } = useRouteMatch();
  const thumbnail = data?.News_Images?.[0]?.name
    ? `${process.env.REACT_APP_API_URL}/news/${data?.News_Images[0]?.name}`
    : noImages;

  return (
    <Link
      to={`${path}/${data?.News_GroupObject.News_Group_Slug}/${data?.News_Slug}`}
      className="news__main__item__detail__main"
    >
      <div
        className="news__main__item__detail__main__img"
        style={{ backgroundImage: `url('${thumbnail || ""}')` }}
      ></div>
      <div className="news__main__item__detail__main__content">
        <p>{formatDateTimeSecondType(data?.CreatedDate)}</p>
        <h3>{getNewsNameByLanguage(data, language)}</h3>
      </div>
    </Link>
  );
};
const NewsListItemSub = (props) => {
  const { data, language } = props;
  const { path } = useRouteMatch();
  const thumbnail = data?.News_Images?.[0]?.name
    ? `${process.env.REACT_APP_API_URL}/news/${data.News_Images?.[0]?.name}`
    : noImages;
  return (
    <Link
      to={`${path}/${data?.News_GroupObject.News_Group_Slug}/${data?.News_Slug}`}
      className="news__main__item__detail__sub"
    >
      <div
        className="news__main__item__detail__sub__img"
        style={{ backgroundImage: `url('${thumbnail || ""}')` }}
      ></div>
      <div className="news__main__item__detail__sub__content">
        <p>{formatDateTimeSecondType(data?.CreatedDate)}</p>
        <h3>{getNewsNameByLanguage(data, language)}</h3>
      </div>
    </Link>
  );
};
export default function NewsList(props) {
  const { data, language } = props;
  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col lg={12} md={12} sm={24} xs={24}>
        {data[0] && <NewsListItemMain language={language} data={data[0]} />}
      </Col>
      <Col lg={12} md={12} sm={24} xs={24}>
        <div className="news__main__item__detail__sub-container">
          {data.map((item, index) => {
            if (index > 3 || index === 0) return null;
            else
              return (
                <NewsListItemSub
                  language={language}
                  data={item}
                  key={item._id}
                />
                
              );
          })}
        </div>
      </Col>
    </Row>
  );
}

import { formatDateTime,formatNumber } from '../../Common/FormatCommon';
import ManageSpecificationEdit from '../ManageSpecificationEdit';
import {PRODUCT_CATEGORY} from '../../../../common/Constant.Common'

export const statusModel = [
  { name: 'Sử dụng', value: true },
  { name: 'Ngưng sử dụng', value: false },
];
export const manageSpecificationLength = [
  {key: '6000', value: 6000, name: '6000'},
  {key: '8000', value: 8000, name: '8000'},
  {key: '11700', value: 11700, name: '11700'},
  {key: '12000', value: 12000, name: '12000'},
  {key: '12500', value: 12500, name: '12500'},
]
export const initialFormField = {
  Specification_Code: {
    Name: 'Specification_Code',
    Required: true
  },
  Specification_Name: {
    Name: 'Specification_Name',
    Required: true
  },
  Specification_Length: {
    Name: 'Specification_Length',
    Required: false,
  },
  Specification_Width: {
    Name: 'Specification_Width',
    Required: false
  },
  Specification_Height: {
    Name: 'Specification_Height',
    Required: false
  },
  Specification_Weight_Over_Unit: {
    Name: 'Specification_Weight_Over_Unit',
    Required: false
  },
  Specification_Weight: {
    Name: 'Specification_Weight',
    Required: false
  },
  Specification_Product_Category: {
    Name: 'Specification_Product_Category',
    Required: true
  },
  Specification_Product: {
    Name: 'Specification_Product',
    Required: true
  },
  Specification_Product_Type: {
    Name: 'Specification_Product_Type',
    Required: false
  },
  Specification_Product_Unit: {
    Name: 'Specification_Product_Unit',
    Required: true
  },
  CreatedDate: {
    Name: 'CreatedDate',
    Required: false,
  },
  CreatedPerson: {
    Name: 'CreatedPerson',
    Required: false,
  },
  UpdatedDate: {
    Name: 'UpdatedDate',
    Required: false,
  },
  UpdatedPerson: {
    Name: 'UpdatedPerson',
    Required: false,
  },
  Status: {
    Name: 'Status',
    Required: true
  }
};


export const specificationInitData = {
  _id: null,
  Specification_Code: null,
  Specification_Name: null,
  Specification_Length: null,
  Specification_Width: null,
  Specification_Height: null,
  Specification_Weight_Over_Unit: null,
  Specification_Weight:null,
  Specification_Product_Category:null,
  Specification_Product:null,
  Specification_Product_Type:null,
  Specification_Product_Unit:null,
  Status: null,
  CreatedDate: null,
  CreatedPerson:null,
  UpdatedDate: null,
  UpdatedPerson:null
};

export const columns = (language) => {
  return [
    {
      width: 200,
      title: language.MANAGE_SPECIFICATION_PRODUCT_CATEGORY,
      dataIndex: 'Specification_Product_Category',
      key: 'Specification_Product_Category',
    },
    {
      width: 200,
      title: language.MANAGE_SPECIFICATION_PRODUCT,
      dataIndex: 'Specification_Product',
      key: 'Specification_Product',

    },
    {
      width: 200,
      title: language.MANAGE_SPECIFICATION_PRODUCT_TYPE,
      dataIndex: 'Specification_Product_Type',
      key: 'Specification_Product_Type',
      
    },
    
    {
      width: 200,
      title: language.MANAGE_SPECIFICATION_CODE,
      dataIndex: 'Specification_Code',
      key: 'Specification_Code',
    },
    {
      width: 200,
      title: language.MANAGE_SPECIFICATION_NAME,
      dataIndex: 'Specification_Name',
      key: 'Specification_Name',
    },
    {
      width: 200,
      title: language.MANAGE_SPECIFICATION_LENGTH,
      dataIndex: 'Specification_Length',
      key: 'Specification_Length',
    },
    {
      width: 200,
      title: language.MANAGE_SPECIFICATION_WIDTH,
      dataIndex: 'Specification_Width',
      key: 'Specification_Width',
    },
    {
      width: 200,
      title: language.MANAGE_SPECIFICATION_HEIGHT,
      dataIndex: 'Specification_Height',
      key: 'Specification_Height',
    },
    {
      width: 200,
      title: language.MANAGE_SPECIFICATION_UNIT,
      dataIndex: 'Specification_Product_Unit',
      key: 'Specification_Product_Unit'
    },
    {
      width: 200,
      title: language.MANAGE_SPECIFICATION_WEIGHT_OVER_UNIT,
      dataIndex: 'Specification_Weight_Over_Unit',
      key: 'Specification_Weight_Over_Unit',
    },
    {
      width: 200,
      title: language.MANAGE_SPECIFICATION_WEIGHT,
      dataIndex: 'Specification_Weight',
      key: 'Specification_Weight',
      render:value=> formatNumber(value)
    },
    {
      width: 200,
      title: language.MANAGE_SPECIFICATION_CREATED_DATE,
      dataIndex: 'CreatedDate',
      key: 'CreatedDate',
      render: (value) => {
        return formatDateTime(value);
      }
    },
    {
      width: 200,
      title: language.MANAGE_SPECIFICATION_CREATED_PERSON,
      dataIndex: 'CreatedPerson',
      key: 'CreatedPerson',
    },
    {
      width: 200,
      title: language.MANAGE_SPECIFICATION_UPDATED_DATE,
      dataIndex: 'UpdatedDate',
      key: 'UpdatedDate',
      render: (value) => {
        return value?formatDateTime(value):'';
      }
    },
    {
      width: 200,
      title: language.MANAGE_SPECIFICATION_UPDATED_PERSON,
      dataIndex: 'UpdatedPerson',
      key: 'UpdatedPerson',
    },
    {
      title: language.MANAGE_SPECIFICATION_STATUS,
      dataIndex: 'Status',
      key: 'Status',
      render: (value) => {
        return value === false ? 'Ngưng sử dụng' : 'Sử dụng';
      }
    },
    {
      title: '',
      dataIndex: '_id',
      render: (value) => {
        return <ManageSpecificationEdit _id={value} />;
      }
    }
  ];
};


export const columnSpecification = (language) => {
  return [
    {
      width: 300,
      title: language.MANAGE_SPECIFICATION_NAME,
      dataIndex: 'Specification_Code',
      key: 'Specification_Code',
      render:value=> <div style={{ textAlign: 'left' }}>{value}</div>,
      align:'center'

    },
    {
      width: 300,
      title: language.MANAGE_SPECIFICATION_PRODUCT_TYPE,
      dataIndex: 'Specification_Product_Type',
      key: 'Specification_Product_Type',
      render:value=> <div style={{ textAlign: 'left' }}>{value}</div>,
      align:'center'
    },
    {
      width: 300,
      title: language.MANAGE_SPECIFICATION_UNIT,
      dataIndex: 'Specification_Product_Unit',
      key: 'Specification_Product_Unit',
      render:value=> <div style={{ textAlign: 'left' }}>{value}</div>,
      align:'center'
    },
    {
      width: 300,
      title: language.MANAGE_SPECIFICATION_LENGTH,
      dataIndex: 'Specification_Length',
      key: 'Specification_Length',
      render:value=> <div style={{ textAlign: 'right' }}>{value}</div>,
      align:'center'
    },
    {
      width: 300,
      title: language.MANAGE_SPECIFICATION_WEIGHT_OVER_UNIT,
      dataIndex: 'Specification_Weight_Over_Unit',
      key: 'Specification_Weight_Over_Unit',
      render:value=> <div style={{ textAlign: 'right' }}>{value}</div>,
      align:'center'
    },
    {
      width: 300,
      title: language.MANAGE_SPECIFICATION_WEIGHT,
      dataIndex: 'Specification_Weight',
      key: 'Specification_Weight',
      render:value=> <div style={{ textAlign: 'right' }}>{formatNumber(value)}</div>,
      align:'center'

    }



  ];
};

export const columnSpecification2 = (language) => {
  return [
    {
      width: 300,
      title: language.MANAGE_SPECIFICATION_NAME,
      dataIndex: 'Specification_Code',
      key: 'Specification_Code',
      render:value=> <div style={{ textAlign: 'left' }}>{value}</div>,
      align:'center'

    },
    {
      width: 300,
      title: language.MANAGE_SPECIFICATION_LENGTH,
      dataIndex: 'Specification_Length',
      key: 'Specification_Length',
      render:value=> <div style={{ textAlign: 'right' }}>{value}</div>,
      align:'center'
    },
    {
      width: 300,
      title: language.MANAGE_SPECIFICATION_WIDTH,
      dataIndex: 'Specification_Width',
      key: 'Specification_Width',
      render:value=> <div style={{ textAlign: 'right' }}>{value}</div>,
      align:'center'
    },
    {
      width: 300,
      title: language.MANAGE_SPECIFICATION_HEIGHT,
      dataIndex: 'Specification_Height',
      key: 'Specification_Height',
      render:value=> <div style={{ textAlign: 'right' }}>{value}</div>,
      align:'center'
    },
    {
      width: 300,
      title: language.MANAGE_SPECIFICATION_UNIT,
      dataIndex: 'Specification_Product_Unit',
      key: 'Specification_Product_Unit',
      render:value=> <div style={{ textAlign: 'left' }}>{value}</div>,
      align:'center'
    },

    {
      width: 300,
      title: language.MANAGE_SPECIFICATION_WEIGHT,
      dataIndex: 'Specification_Weight',
      key: 'Specification_Weight',
      render:value=> <div style={{ textAlign: 'right' }}>{formatNumber(value)}</div>,
      align:'center'

    }



  ];
};

import React, { useCallback, useEffect, useState } from 'react';
import { Form, Button, Modal, Row, Col,Spin } from 'antd';
import ManageDocumentSelect from './Control/ManageDocumentSelect';
import ManageNewsDeleteConfirm from '../ManageNews/Control/ManageNewsDeleteConfirm';
import ManageDocumentFileView from './Control/ManageDocumentFileView';
import ManageDocumentTextArea from './Control/ManageDocumentTextArea';
import MpsDatePicker from '../../MpsCotrols/MpsDatePicker/MpsDatePicker';
import { DocumentAttribute, DocumentCommon, DocumentInitData, DocumentPlaceholder } from './Model/ManageDocumentModel';
import { ManageDocumentService } from './Service/ManageDocumentService';
import { store } from 'react-notifications-component';
import { DELETE_RETURN_CODE_DESCRIPTION, RESULT_CODES, UPDATE_RETURN_CODE_DESCRIPTION } from '../../../common/Constant.Common';
import { openErrorNotification, openSuccessNotification } from '../Common/MethodCommon';
import MpsLanguageSelect from '../../MpsCotrols/MpsSelect/MpsLanguageSelect';

function ManageDocumentEdit(props) {
  const [form] = Form.useForm();
  const [initModelDocument, setInitModelDocument] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileUpload, setFileUpload] = useState('');
  const [newFile, setNewFile] = useState(null);
  const [spining, setSpining] = useState(false);

  const onUpdate = () => {
    setSpining(true)
    form.validateFields().then(values => {
      const formData = new FormData();

      formData.append('Document_New_File', newFile);

      const formObjectValues = Object.entries(values);
      formObjectValues.forEach(value => {
        formData.append(value[0], value[1]);
      });
      formData.append('_id', props.data._id);
      formData.delete('Document_File');
      formData.append('Document_File', props.data.Document_File);
      
      return ManageDocumentService.update(formData);

    }).then(result => {
      const oldFile = newFile ? props.data.Document_File : '';
      // Xoá file cũ nếu user đổi file
      ManageDocumentService.deleteFile({ Document_Old_File: oldFile });

      setSpining(false)
      setNewFile(null);

      if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);
      
      openSuccessNotification({ title: 'Success', message: UPDATE_RETURN_CODE_DESCRIPTION[parseInt(result.returnCode, 10)] });
      props.signChanged();
      handleClose();
    }).catch((error) => {
      setSpining(false)
      if (error !== RESULT_CODES.SUCCESS)
        return openErrorNotification({ title: 'Error', message: UPDATE_RETURN_CODE_DESCRIPTION[parseInt(error, 10)] });
    });
  };

  const onDelete = useCallback(async () => {
    const data = await ManageDocumentService.deleted(props.data._id);
    props.signChanged();
    store.addNotification({
      title: data.returnCode !== RESULT_CODES.SUCCESS ? 'Error!' : 'Success',
      message: `${DELETE_RETURN_CODE_DESCRIPTION[parseInt(data.returnCode, 10)]}`,
      type: data.returnCode !== RESULT_CODES.SUCCESS ? 'danger' : 'success',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  }, [props]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    fileUpload && URL.revokeObjectURL(fileUpload);
    const fileUrl = process.env.REACT_APP_API_URL + `/document/${props.data['Document_File']}`;
    //const fileUrl = props.data.Document_File;
    setFileUpload(fileUrl);
    setNewFile(null);
    setIsModalVisible(false);
  };

  const handleFileUpload = (file) => {
    fileUpload && URL.revokeObjectURL(fileUpload);
    const fileUrl = URL.createObjectURL(file);
    setNewFile(file);
    setFileUpload(fileUrl);
  };

  useEffect(() => {
    const initData = {
      ...DocumentInitData,
      Select: { ...DocumentInitData.Select }
    };
    const fileUrl = process.env.REACT_APP_API_URL + `/document/${props.data['Document_File']}`;
    setFileUpload(fileUrl);
    setInitModelDocument(initData);
  }, [props.data]);

  return initModelDocument && (
    <>
      <p
        className='admin-button-detail'
        onClick={() => showModal()}
      >
        Xem chi tiết
      </p>

      <Modal
        title={DocumentCommon.TitleMainModal}
        visible={isModalVisible}
        onOk={form.submit}
        onCancel={handleClose}
        width='80%'
        className='admin-modal'
        destroyOnClose={true}
        footer={[
          <ManageNewsDeleteConfirm
            key='delete'
            title='Bạn có chắc chắn muốn xóa dữ liệu này ?'
            deleteLabel='Xóa'
            agreeLabel='Có'
            disagreeLabel='Không'
            onDelete={onDelete}
          />,
          <Button
            type='primary'
            // form='form-modal-view'
            onClick={onUpdate}
            htmlType='submit'
            className='admin-button__main'>
            Lưu
          </Button>,
        ]}
      >
        <Form
          form={form}
          id='manage-recruitment-edit-form'
          name='manage-recruitment-edit-form'
        // initialValues={props.data}
        >
         <Spin spinning={spining}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col lg={12} md={12} sm={24} xs={24}>
              <ManageDocumentTextArea
                name={'Document_Title'}
                title={DocumentAttribute['Document_Title'][0]}
                placeholder={DocumentPlaceholder['Document_Title']}
                value={props.data.Document_Title}
                typeInput={DocumentAttribute['Document_Title'][1]}
                require={true}
              >
              </ManageDocumentTextArea>
              {/* <ManageDocumentTextArea
                name='Document_Title_ENG'
                title={DocumentAttribute['Document_Title_ENG'][0]}
                placeholder={DocumentPlaceholder['Document_Title_ENG']}
                typeInput={DocumentAttribute['Document_Title_ENG'][1]}
                value={props.data.Document_Title_ENG}
                require={true}
              ></ManageDocumentTextArea> */}
              {/* <ManageDocumentSelect
                name={'Relation_Type'}
                title={DocumentAttribute['Relation_Type'][0]}
                placeholder={DocumentPlaceholder['Relation_Type']}
                value={props.data.Relation_Type}
                typeInput={DocumentAttribute['Relation_Type'][1]}
                select={initModelDocument.Select['Relation_Type']}
                require={true}
              >
              </ManageDocumentSelect> */}
              <Row className='admin-filed__list' gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                <Col className='admin-filed__list__item' lg={8} md={24} sm={24} xs={24}>
                  <label>{DocumentAttribute['Language'][0]} <span className={`admin-filed__list__item__required active`}>*</span></label>
                </Col>
                <Col className='admin-filed__list__item' lg={14} md={24} sm={24} xs={24}>
                  <MpsLanguageSelect
                    name={'Language'}
                    required={true}
                    hasInitialValue={true}
                    initialValue={props.data.Language}
                    form={form}
                  />
                </Col>
              </Row>
              <ManageDocumentSelect
                name={'Status'}
                title={DocumentAttribute['Status'][0]}
                placeholder={DocumentPlaceholder['Status']}
                value={props.data.Status}
                typeInput={DocumentAttribute['Status'][1]}
                select={initModelDocument.Select['Status']}
                require={true}
              >
              </ManageDocumentSelect>
              <Row className='admin-filed__list' gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                <Col className='admin-filed__list__item' lg={8} md={24} sm={24} xs={24}>
                  <label>{DocumentAttribute['CreatedAt'][0]} <span className={`admin-filed__list__item__required active`}>*</span></label>
                </Col>
                <Col className='admin-filed__list__item' lg={14} md={24} sm={24} xs={24}>
                  <MpsDatePicker
                    name='CreatedAt'
                    message='Đây là trường bắt buộc'
                    value={props.data.CreatedAt}
                    required={true}
                    disabled={false}

                  />
                </Col>
              </Row>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <ManageDocumentFileView
                name={'Document_File'}
                title={DocumentAttribute['Document_File'][0]}
                placeholder={DocumentPlaceholder['Document_File']}
                value={fileUpload}
                typeInput={DocumentAttribute['Document_File'][1]}
                select={DocumentInitData.Select['Document_File']}
                handleFileUpload={handleFileUpload}
              />
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              {/* <ManageDocumentFile
                fileUpload={fileUpload}
                name='Document_File'
                title={'Cập nhật file PDF'}
                placeholder={DocumentPlaceholder['Document_File']}
                typeInput={DocumentAttribute['Document_File'][1]}
                handleFileUpload={handleFileUpload}
              /> */}
            </Col>
          </Row>
          </Spin>
        </Form>
      </Modal>
    </>
  );
}

export default ManageDocumentEdit;

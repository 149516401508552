import { authHeader } from "../../../../helper/authHeader";

export const ChangePasswordService = {
    changePassword
    // logout,
    // getUser,
    // getAuthorizeUser,
    // getUserInfor,
    // getCurrentLanguage,
    // checkLogout,
    // checkInfor
};

const content = 'Content-Type';
const Type = 'application/json';

function changePassword(oldpassword, password, captcha) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify({ oldpassword, password, captcha })
    };
    return fetch(process.env.REACT_APP_API_URL + `/User/changePassword`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import './Css/Recruit.css';
import RecruitPage from './RecruitPage';
import RecruitDetailPage from './RecruitDetailPage'

export default function RecruitApp(props) {
    let { path } = useRouteMatch();
    const { language } = props;
    return (
        <>
            <Switch>
                <Route path={`${path}`} render={(props) => <RecruitPage {...props} language={language} />} exact></Route>
                <Route path={`${path}/:id`} render={(props) => <RecruitDetailPage {...props} language={language} />}></Route>
            </Switch>
        </>
    )
}

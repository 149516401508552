import ManageRelationEdit from '../ManageRelationEdit';
import moment from 'moment';

export const RelationCommon = {
    Title: 'Quan hệ cổ đông',
    TitleMainModal: 'Xem thông tin quan hệ cổ đông',
    TitleCreateModal: 'Tải lên file pdf',
};

export const RelationInitData = {
    Relation_Title: '',
    Relation_Title_ENG: '',
    Relation_Type: '',
    Relation_File: '',
    Language: '',
    Status: '',
    Select:
    {
        Relation_Type: [],
        Status: [
            {
                value: false,
                title: 'Ẩn'
            },
            {
                value: true,
                title: 'Hiển thị'
            }
        ]
    }
};

export const RelationPlaceholder = {
    Relation_Title: 'Tiêu đề (tối đa 200 ký tự)',
    Relation_Title_ENG: 'Tiêu đề (tối đa 200 ký tự)',
    Relation_Type: 'Chọn thể loại',
    Relation_File: 'Tên File',
    CreatedAt: 'Ngày tạo',
    Status: 'Trạng thái'
};

export const RelationAttribute = {
    Relation_Title: ['Tiêu đề ', 'text'],
    Relation_Title_ENG: ['Tiêu đề Tiếng Anh', 'text'],
    Relation_Type: ['Thể loại', 'select'],
    Relation_File: ['File pdf', 'file'],
    CreatedAt: ['Ngày tạo', 'text'],
    Status: ['Trạng thái', 'select'],
    Language: ['Ngôn ngữ', 'select'],
};

export const RelationDisplayTable = (signChanged, initModel) => {
    return [
        {
            title: 'Tiêu đề ',
            dataIndex: 'Relation_Title',
            key: 'Relation_Title'
        },
        {
            title: 'Thể loại',
            dataIndex: 'Relation_Type',
            key: 'Relation_Type',
            // responsive: ['sm'],
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'CreatedAt',
            key: 'CreatedAt',
            render: (value) => {
                return moment(value).format('DD-MM-YYYY HH:mm:ss');
            },
            // responsive: ['md'],
        },
        {
            title: 'Ngôn ngữ',
            dataIndex: 'Language',
            key: 'Language',
            render: (value) => {
                if (!value) {
                    return ''
                }
                if (value === 'en') {
                    return 'Tiếng Anh'
                }
                return 'Tiếng Việt'
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'Status',
            key: 'Status',
            // responsive: ['lg'],
        },
        {
            title: '',
            dataIndex: 'detail',
            key: '_id',
            render: (_, record) => {
                const newData = { ...record };
                if (!!initModel) {
                    const newValue = Object.keys(initModel.Select);
                    for (let i = 0; i < newValue.length; i++) {
                        newData[newValue[i]] = initModel.Select[newValue[i]].find(itemSelect => {
                            return itemSelect.title === record[newValue[i]];
                        }).value;
                    }
                    return (
                        <ManageRelationEdit key={newData._id} _id={newData._id} data={newData} signChanged={signChanged} />
                    );
                }
            }
        },
    ];
}

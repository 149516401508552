
import { formatDateTime } from '../../Common/FormatCommon';
import ManageIntroPostEdit from '../ManageIntroPostEdit';

export const statusModel = [
  { name: 'Hiển thị', value: true },
  { name: 'Ẩn', value: false },
];

export const searchModel = {
  Post_Intro_Title: null,
  Post_Intro_Type: null,
  Status: null,
  CreatedDate: null,
};

export const postSearchPlaceholder = {
  Post_Intro_Title: 'Tiêu đề',
  Post_Intro_Type: 'Thể loại',
  Status: 'Trạng thái',
};

export const postIntroInitData = {
  _id: null,
  Post_Intro_Type: null,
  Post_Intro_Title: null,
  Post_Intro_Title_Eng: null,
  Post_Intro_Content: null,
  Post_Intro_Image_Pc: [],
  Post_Intro_Image_Tablet: [],
  Post_Intro_Image_Mobile: [],
  Post_Intro_Content_Eng: null,
  Post_Intro_Excerpt: null,
  Post_Intro_Excerpt_Eng: null,
  Post_Intro_AdditionalInfo: null,
  Post_Intro_AdditionalInfo_Eng: null,
  Language: '',
  Status: null,
  CreatedDate: null,
  UpdatedDate: null,
};

export const postIntroFormInitialField = {
  Post_Intro_Type: {
    placeholder: 'The loai',
    required: true,
    name: 'Post_Intro_Type'
  },
  Post_Intro_Title: {
    placeholder: 'Tiêu đề (tối đa 200 ký tự)',
    required: true,
    name: 'Post_Intro_Title'
  },
  Post_Intro_Title_Eng: {
    placeholder: 'Tiêu đề (tối đa 200 ký tự)',
    required: true,
    name: 'Post_Intro_Title_Eng'
  },
  Post_Intro_Excerpt: {
    placeholder: 'Tiêu đề (tối đa 200 ký tự)',
    required: true,
    name: 'Post_Intro_Excerpt'
  },
  Post_Intro_Excerpt_Eng: {
    placeholder: 'Tiêu đề (tối đa 200 ký tự)',
    required: true,
    name: 'Post_Intro_Excerpt_Eng'
  },
  Post_Intro_Content: {
    placeholder: 'Noi dung chinh',
    required: true,
    name: 'Post_Intro_Content'
  },
  Post_Intro_Content_Eng: {
    placeholder: 'Noi dung chinh',
    required: true,
    name: 'Post_Intro_Content_Eng'
  },
  Language: {
    placeholder: 'Chọn ngôn ngữ',
    required: true,
    label: 'Ngôn ngữ',
    name: 'Language'
  },
  Status: {
    placeholder: 'Chọn trạng thái',
    required: true,
    label: 'Trạng thái',
    name: 'Status'
  },
  CreatedDate: {
    placeholder: '',
    required: true,
    disabled: true,
    label: 'Ngày tạo',
    name: 'CreatedDate'
  },
};

export const columns = (language) => {
  return [
    {
      title: language.MANAGE_POST_INTRO_TITLE,
      dataIndex: 'Post_Intro_Title',
      key: 'Post_Intro_Title'
    },
    // {
    //   title: language.MANAGE_POST_INTRO_TITLE_ENG,
    //   dataIndex: 'Post_Intro_Title_Eng',
    //   key: 'Post_Intro_Title_Eng'
    // },
    {
      title: language.MANAGE_POST_INTRO_TYPE,
      dataIndex: 'Type_Post_Intro_Object',
      key: 'Type_Post_Intro_Object',
      render: (value) => {
        return value.Type_Post_Title;
      }
    },
    {
      title: language.MANAGE_POST_LANGUAGE,
      dataIndex: 'Language',
      key: 'Language',
      render: (value) => {
        if (!value) {
          return ''
        }
        if (value === 'en') {
          return 'Tiếng Anh'
        }
        return 'Tiếng Việt'
      }
    },
    {
      title: language.MANAGE_NEWS_STATUS,
      dataIndex: 'Status',
      key: 'Status',
      render: (value) => {
        return value === false ? 'Ẩn' : 'Hiển thị';
      }
    },
    {
      title: language.MANAGE_NEWS_CREATED_DATE,
      dataIndex: 'CreatedDate',
      key: 'CreatedDate',
      render: (value) => {
        return formatDateTime(value);
      }
    },
    {
      title: '',
      dataIndex: '_id',
      render: (value) => {
        return <ManageIntroPostEdit _id={value} />;
      }
    }
  ];
};



import React, { useState } from 'react';
import { Form, Input, Spin } from 'antd';
import { RecruitCommon } from '../Model/RecruitModel';
import { RecruitService } from '../Service/RecruitService';
import { RESULT_CODES } from '../../../common/Constant.Common';

export default function ModalApplyCV(props) {
  const { language, handleModalCV, recruitmentData, onShowResult } = props;
  const [form] = Form.useForm();
  const [fileUpload, setFileUpload] = useState(null);
  const [spinning, setSpinning] = useState(false);

  const handleChangeFileUpload = (e) => {
    setFileUpload(e.target.files[0]);
  };

  const handleClose = () => {
    if (spinning) {
      return;
    }
    handleModalCV();
  };

  const onSubmit = async (values) => {
    if (!fileUpload) {
      return;
    }
    setSpinning(true);
    // const formData = new FormData();
    const formData = {};
    formData.name=values.name
    formData.phone=values.phone

    formData.email=values.email

    formData.fileName=values.fileName
    formData.message=values.message

    formData.position= language === 'vi' ? recruitmentData.Recruitment_Title : recruitmentData.Recruitment_Title_Eng;
  formData.cv= fileUpload;
    formData.language= language;
    
    const data = await RecruitService.sendMail(formData);
    console.log('data1', data)
    if (data.returnCode === RESULT_CODES.SUCCESS) {
      onShowResult(true);
      handleModalCV();
    }
    setSpinning(false);
  };

  const validateFile = () => {
    const acceptedFileType = [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ];
    try {
      if (!acceptedFileType.includes(fileUpload.type)) {
        const message = language === 'vi' ? 'Không đúng định dạng' : 'File type is not accepted';
        return Promise.reject(new Error(message));
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject('');
    }
  };

  return (
    <div className='modal-cv-overlay'
      onClick={handleClose}>
      <Form form={form} className='modal-cv' onFinish={onSubmit} onClick={e => e.stopPropagation()}>
        <div className='modal-cv-heading'>
          <h4>{RecruitCommon[language].RecruitModal.title}</h4>
        </div>
        <div className='modal-cv-main'>
          <p>{RecruitCommon[language].RecruitModal.position}</p>
          <h4>{language === 'vi' ? recruitmentData.Recruitment_Title : recruitmentData.Recruitment_Title_Eng}</h4>
          <p>{RecruitCommon[language].RecruitModal.name}</p>
          <Form.Item
            name='name'
            rules={[{ required: true, message: RecruitCommon[language].required }]}
          >
            <Input type='text' />
          </Form.Item>
          <p>{RecruitCommon[language].RecruitModal.phone}</p>
          <Form.Item
            name='phone'
            rules={[{ required: true, message: RecruitCommon[language].required }]}>
            <Input type='text' />
          </Form.Item>
          <p>{RecruitCommon[language].RecruitModal.email}</p>
          <Form.Item
            name='email'
            rules={[{ required: true, type: 'email', message: RecruitCommon[language].invalidEmail }]}
          >
            <Input type='text' />
          </Form.Item>
          <p>{RecruitCommon[language].RecruitModal.attachment}</p>
          <Form.Item
            name='fileName'
            rules={
              [
                { required: true, message: RecruitCommon[language].required },
                { validator: validateFile, validateTrigger: 'onChange' },
              ]
            }
          >
            <Input type='file' name='myFile' accept='.pdf,.xlsx,.pptx,.docx' onChange={handleChangeFileUpload} className='recruit-upfile' />
          </Form.Item>
          <p>{RecruitCommon[language].RecruitModal.message}</p>
          <Form.Item name='message'>
            <Input.TextArea type='text' rows={4}></Input.TextArea>
          </Form.Item>
          <p className='modal-cv-main-hint'>{RecruitCommon[language].RecruitModal.hint}</p>
          <p className='modal-cv-main-bonus' dangerouslySetInnerHTML={{ __html: RecruitCommon[language].RecruitModal.bonus }}></p>
        </div>
        <div className='modal-cv-submit'>
          <div className='home__main__button  modal-cv-submit--button home__main__button--red-empty'
            onClick={handleClose}
          >
            <span>{RecruitCommon[language].back}</span>
          </div>
          <Spin spinning={spinning}>
            <button type='submit' className='home__main__button home__main__button--red home__main__button-icon modal-cv-submit--button-main'>
              <span>{RecruitCommon[language].apply}</span>
              <i className='fas fa-paper-plane'></i>
            </button>
          </Spin>
        </div>
      </Form>
    </div>
  );
}


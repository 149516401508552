import React from 'react'
import { Row, Col, Input, Form } from 'antd'
import '../Css/ChangePasswordField.css'
export default function ChangePasswordField(props) {
    return (
        <Form.Item
            name={props.name}
            rules={!props.dependencies ?
                [
                    {
                        required: props.require,
                        message: `${props.warning}`,
                    }
                ] :
                [
                    {
                        required: props.require,
                        message: `${props.warning}`,
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue(props.dependencies) === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Mật khẩu xác nhận chưa chính xác'));
                        },
                    }),
                ]
            }
            className={`admin-change-password-field ${props.className}`}
        >
            <Row className="admin-change-password-field__list" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="admin-change-password-field__list__item" span={10}>
                    <label>{props.title} <span className={`admin-change-password-field__list__item__required ${props.require ? "active" : ""}`}>*</span></label>
                </Col>
                <Col className="admin-change-password-field__list__item" span={14}>
                    <Input
                        type="password"
                        placeholder={props.placeholder}
                    />
                </Col>
            </Row>
        </Form.Item>
    )
}

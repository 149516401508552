import React from 'react'
import { Row, Col, Input, Form, } from 'antd'
const { TextArea } = Input;
export default function ManageRelationTextArea(props) {
    return (
        <Row className="admin-filed__list" gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Col className="admin-filed__list__item" lg={8} md={24} sm={24} xs={24}>
                <label>{props.title} <span className={`admin-filed__list__item__required ${props.require ? "active" : ""}`}>*</span></label>
            </Col>
            <Col className="admin-filed__list__item" lg={14} md={24} sm={24} xs={24}>
                <Form.Item
                    name={props.name}
                    initialValue={props.value || null}
                    rules={[{ required: props.require || false, message: `Đây là trường bắt buộc` }]}
                    className={`admin-filed ${props.className || ""}`}
                >
                    <TextArea
                        className="admin-input"
                        placeholder={props.placeholder}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                </Form.Item>
            </Col>
        </Row>
    )
}

import ManageDocumentEdit from '../ManageDocumentEdit';
import moment from 'moment';

export const DocumentCommon = {
    Title: 'Tài liệu',
    TitleMainModal: 'Xem tài liệu',
    TitleCreateModal: 'Tải lên file pdf',
};

export const DocumentInitData = {
    Document_Title: '',
    Document_Title_ENG: '',
    // Relation_Type: '',
    Document_File: '',
    Status: '',
    Select:
    {
        // Relation_Type: [],
        Status: [
            {
                value: false,
                title: 'Ẩn'
            },
            {
                value: true,
                title: 'Hiển thị'
            }
        ]
    }
};

export const DocumentPlaceholder = {
    Document_Title: 'Tiêu đề ',
    Document_Title_ENG: 'Tiêu đề Tiếng Anh',
    //Relation_Type: 'Chọn thể loại',
    Document_File: 'Tên File',
    CreatedAt: 'Ngày tạo',
    Status: 'Trạng thái'
};

export const DocumentAttribute = {
    Document_Title: ['Tiêu đề ', 'text'],
    Document_Title_ENG: ['Tiêu đề Tiếng Anh', 'text'],
    // Relation_Type: ['Thể loại', 'select'],
    Document_File: ['File pdf', 'file'],
    CreatedAt: ['Ngày tạo', 'text'],
    Status: ['Trạng thái', 'select'],
    Language: ['Ngôn ngữ', 'select']
};

export const DocumentDisplayTable = (signChanged, initModel) => {
    return [
        {
            title: 'Tiêu đề ',
            dataIndex: 'Document_Title',
            key: 'Document_Title',
        },
        // {
        //     title: 'Tiêu đề Tiếng Anh',
        //     dataIndex: 'Document_Title_ENG',
        //     key: 'Document_Title_ENG',
        // },
        {
            title: 'Ngôn ngữ',
            dataIndex: 'Language',
            key: 'Language',
            render: (value) => {
                if (!value) {
                    return ''
                }
                if (value === 'en') {
                    return 'Tiếng Anh'
                }
                return 'Tiếng Việt'
            }
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'CreatedAt',
            key: 'CreatedAt',
            render: (value) => {
                return moment(value).format('DD-MM-YYYY HH:mm:ss');
            },
            // responsive: ['md'],
        },
        {
            title: 'Trạng thái',
            dataIndex: 'Status',
            key: 'Status',
            // responsive: ['lg'],
        },
        {
            title: '',
            dataIndex: 'detail',
            key: '_id',
            render: (_, record) => {
                const newData = { ...record };
                if (!!initModel) {
                    const newValue = Object.keys(initModel.Select);
                    for (let i = 0; i < newValue.length; i++) {
                        newData[newValue[i]] = initModel.Select[newValue[i]].find(itemSelect => {
                            return itemSelect?.title === record[newValue[i]];
                        })?.value;
                    }
                    return (
                        <ManageDocumentEdit key={newData._id} _id={newData._id} data={newData} signChanged={signChanged} />
                    );
                }
            }
        },
    ];
}

import moment from 'moment';

export function formatDate(value) {
  return moment(value).format('DD-MM-YYYY');
}

export function formatDateSecondType(value) {
  return moment(value).format('DD/MM/YYYY');
}

export function formatDateTime(value) {
  return moment(value).format('DD-MM-YYYY HH:mm:ss');
}

export function formatDateTimeSecondType(value) {
  return moment(value).format('DD/MM/YYYY - HH:mm:ss');
}

export function momentDateTime(value) {
  return moment(new Date(value));
}

export function formatNumber(value) {
  return value?(value%1==0?value:value.toFixed(2)):''
}

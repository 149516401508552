export const DownloadDocumentCommon = {
    vi: {
        path: '/DownloadDocument',
        download: "Tải về",
        view: "Xem",
        sort: "Sắp xếp theo năm",
        empty: "Tài liệu đang cập nhật",
        seeAll: "Xem tất cả",
        initSort: "Tất cả",
        documentRangeTime: "Các tài liệu trong thời gian",
        search: "Nhập tiêu đề cần tìm ",
        detailTitle: 'CÔNG BỐ THÔNG TIN',
        Titile_Helmet: 'Tài liệu'
    },
    en: {
        path: '/DownloadDocument',
        download: "Download",
        view: "View",
        sort: "Sort by year",
        empty: "Document is updating",
        seeAll: "View all",
        initSort: "All",
        documentRangeTime: "Time range",
        search: "Enter title or content ... ",
        detailTitle: 'IR NEWS',
        Titile_Helmet: 'Documents'
    }
}

export const limitPageLoad = 13

export const lastPageWillLoadNext = 7


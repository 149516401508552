export const LANGUAGE_STATE = {
  'vi': {
    MANAGE_UNIT: 'Đơn vị tính',
    MANAGE_UNIT_SEARCH_INFO: 'Thông tin tìm kiếm',
    MANAGE_UNIT_CODE: 'Mã đơn vị tính',
    MANAGE_UNIT_NAME: 'Tên đơn vị tính',
    MANAGE_UNIT_CATEGORY: 'Phân loại',
    MANAGE_UNIT_CREATED_DATE: 'Ngày tạo',
    MANAGE_UNIT_CREATED_PERSON: 'Người tạo',
    MANAGE_UNIT_UPDATED_DATE: 'Ngày cập nhật',
    MANAGE_UNIT_UPDATED_PERSON: 'Người cập nhật',
    MANAGE_UNIT_SEARCH_FROM_DATE: 'Từ ngày',
    MANAGE_UNIT_SEARCH_TO_DATE: 'Đến ngày',
    MANAGE_UNIT_STATUS: 'Trạng thái',

    MANAGE_UNIT_NAME_VIE: 'Tên đơn vị tính tiếng Việt',
    MANAGE_UNIT_NAME_VIE_PLACEHOLDER: 'Tên đơn vị tính tiếng Việt (tối đa 200 ký tự)',
    MANAGE_UNIT_NAME_ENG: 'Tên đơn vị tính tiếng Anh',
    MANAGE_UNIT_NAME_ENG_PLACEHOLDER: 'Tên đơn vị tính tiếng Anh (tối đa 200 ký tự)',
    MANAGE_UNIT_CATEGORY_PLACEHOLDER: 'Chọn phân loại',
    MANAGE_UNIT_STATUS_PLACEHOLDER: 'Chọn trạng thái',
    MANAGE_UNIT_IMAGES: 'Hình ảnh',
    MANAGE_UNIT_DETAIL_INFO: 'Thông tin đơn vị tính Tiếng Việt',
    MANAGE_UNIT_DETAIL_INFO_ENG: 'Thông tin đơn vị tính Tiếng Anh',
    

    MANAGE_UNIT_CREATE_NEW: 'Đăng đơn vị tính mới',
    MANAGE_UNIT_EDIT: 'Xem thông tin đơn vị tính',
    MANAGE_UNIT_GENERAL_INFO: 'Thông tin chung',
    MANAGE_UNIT_SELECT_DATE: 'Chọn ngày',
    MANAGE_UNIT_VIEW_DETAIL: 'Xem chi tiết',

    // ACTION
    MANAGE_UNIT_CANCEL: 'Huỷ',
    MANAGE_UNIT_DELETE: 'Xóa',
    MANAGE_UNIT_SAVE: 'Lưu',
    MANAGE_UNIT_AGREE: 'Có',
    MANAGE_UNIT_DISAGREE: 'Không',
    MANAGE_UNIT_CLEAR_SELECT: 'Xóa lựa chọn',
    MANAGE_UNIT_SEARCH: 'Tìm kiếm',

    // MESSAGE
    MANAGE_UNIT_CONFIRM_DELETE: 'Bạn có chắc chắn muốn xóa dữ liệu này ?',

  },
  'en': {

  },
};

import React, { useState, useCallback, useEffect } from 'react';
import { Button, Modal, Form, Tabs, Input } from 'antd';
import { ManageRecruitmentContext, ManageRecruitmentLanguageContext } from './ManageRecruitmentPage';
import { initialFormField, recruitmentInitData } from './Model/ManageRecruitment.Model';
import ManageRecruitmentTypeSelect from './Control/ManageRecruitmentTypeSelect';
import ManageNewsSelect from '../ManageNews/Control/ManageNewsSelect';
import MpsRichTextEditor from '../../MpsCotrols/MpsRichTextEditor/MpsRichTextEditor';
import ManageNewsDeleteConfirm from '../ManageNews/Control/ManageNewsDeleteConfirm';
import { ManageRecruitmentService } from './Service/ManageRecruitment.Service';
import { RESULT_CODES } from '../../../common/Constant.Common';
import { openErrorNotification, openSuccessNotification } from '../Common/MethodCommon';
import MpsDatePicker from '../../MpsCotrols/MpsDatePicker/MpsDatePicker';
import MpsLanguageSelect from '../../MpsCotrols/MpsSelect/MpsLanguageSelect';

const { TabPane } = Tabs;

function ManageRecruitmentEdit(props) {
  const language = React.useContext(ManageRecruitmentLanguageContext);
  const handleSearch = React.useContext(ManageRecruitmentContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [recruitmentData, setRecruitmentData] = useState({ ...recruitmentInitData });

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleDetailInfoChange = useCallback((text) => {
    setRecruitmentData(prevState => ({ ...prevState, Recruitment_Detail_Info: text }));
  }, []);

  const handleDetailInfoEngChange = useCallback((text) => {
    setRecruitmentData(prevState => ({ ...prevState, Recruitment_Detail_Info_Eng: text }));
  }, []);

  const handleFininsh = () => {
    form.validateFields().then(values => {
      const model = { ...recruitmentData, ...values };

      return ManageRecruitmentService.update(model);
    })
      .then(result => {
        if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);

        hideModal();
        openSuccessNotification({ title: 'Thông báo', message: 'Cập nhật thành công' });
        handleSearch();

      })
      .catch((error) => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({ title: 'Thông báo', message: 'Cập nhật thất bại' });
      });
  };

  const handleDelete = () => {
    const model = { _id: props._id };
    ManageRecruitmentService.deleteModel(model).then(result => {
      if (result.returnCode === RESULT_CODES.ERROR) throw new Error(result.returnCode);

      openSuccessNotification({ title: 'Thông báo', message: 'Xóa dữ liệu thành công' });
      hideModal();
      handleSearch();
    })
      .catch(() => {
        openErrorNotification({ title: 'Thông báo', message: 'Xóa dữ liệu thất bại' });
      });
  };

  useEffect(() => {
    if (!visible) return;

    ManageRecruitmentService.getById(props._id).then(result => {
      setRecruitmentData(result.data[0] || { ...recruitmentInitData });
      form.resetFields();
    });
  }, [props._id, visible, form]);

  return (
    <>
      <p className="admin-button-detail" onClick={showModal}>{language.MANAGE_RECRUITMENT_VIEW_DETAIL}</p>
      <Modal
        title={language.MANAGE_RECRUITMENT_EDIT}
        visible={visible}
        width='80%'
        className='manage-recruitment-modal admin-modal'
        onCancel={hideModal}
        footer={
          [
            <ManageNewsDeleteConfirm
              key='delete'
              title={language.MANAGE_RECRUITMENT_CONFIRM_DELETE}
              deleteLabel={language.MANAGE_RECRUITMENT_DELETE}
              agreeLabel={language.MANAGE_RECRUITMENT_AGREE}
              disagreeLabel={language.MANAGE_RECRUITMENT_DISAGREE}
              onDelete={handleDelete}
            />,
            <Button
              onClick={handleFininsh}
              key='save'
              type='primary'
              className='admin-button__main admin-button__save'
            >
              {language.MANAGE_RECRUITMENT_SAVE}
            </Button>
          ]
        }
      >
        <Tabs className='manage-recruitment-tabs' defaultActiveKey='generalInfo'>
          <TabPane tab={language.MANAGE_RECRUITMENT_GENERAL_INFO} key='generalInfo'>
            <Form
              form={form} requiredMark={false} id='manage-recruitment-add-form'
              className='manage-recruitment-form'
              colon={false}
              name='manage-recruitment-add-form'
              labelCol={{ lg: { span: 3 }, md: { span: 24 } }}
              wrapperCol={{ lg: { span: 8 }, md: { span: 24 } }}
            >
              <Form.Item
                initialValue={recruitmentData.Recruitment_Title}
                label={language.MANAGE_RECRUITMENT_TITLE}
                labelAlign='left'
                name={initialFormField.Recruitment_Title.Name}
                rules={[{ required: initialFormField.Recruitment_Title.Required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_RECRUITMENT_TITLE_PLACEHOLDER} maxLength={200} />
              </Form.Item>
              {/* <Form.Item
                initialValue={recruitmentData.Recruitment_Title_Eng}
                label={language.MANAGE_RECRUITMENT_TITLE_ENG}
                labelAlign='left'
                name={initialFormField.Recruitment_Title_Eng.Name}
                rules={[{ required: initialFormField.Recruitment_Title_Eng.Required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_RECRUITMENT_TITLE_ENG_PLACEHOLDER} maxLength={200} />
              </Form.Item> */}
              <ManageRecruitmentTypeSelect
                form={form}
                name={initialFormField.Recruitment_Type.Name}
                label={language.MANAGE_RECRUITMENT_TYPE}
                placeholder={language.MANAGE_RECRUITMENT_TYPE_PLACEHOLDER}
                required={initialFormField.Recruitment_Type.Required}
                initialValue={recruitmentData.Recruitment_Type}
              />
              <Form.Item
                initialValue={recruitmentData.Recruitment_Quantity}
                label={language.MANAGE_RECRUITMENT_QUANTITY}
                labelAlign='left'
                name={initialFormField.Recruitment_Quantity.Name}
                rules={[{ required: initialFormField.Recruitment_Quantity.Required || false, message: '' }]}
              >
                <Input type='number' placeholder={language.MANAGE_RECRUITMENT_QUANTITY} min={0} />
              </Form.Item>
              <Form.Item
                initialValue={recruitmentData.Recruitment_Address}
                label={language.MANAGE_RECRUITMENT_ADDRESS}
                labelAlign='left'
                name={initialFormField.Recruitment_Address.Name}
                rules={[{ required: initialFormField.Recruitment_Address.Required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_RECRUITMENT_ADDRESS} maxLength={200} />
              </Form.Item>
              <MpsDatePicker
                label={language.MANAGE_RECRUITMENT_EXPIRED_DATE}
                name={initialFormField.Recruitment_ExpiredDate.Name}
                value={recruitmentData.Recruitment_ExpiredDate}
                required={initialFormField.Recruitment_ExpiredDate.Required}

              />
              <MpsLanguageSelect
                name={initialFormField.Language.name}
                label={language.MANAGE_RECRUITMENT_LANGUAGE}
                hasInitialValue={true}
                initialValue={recruitmentData.Language}
                form={form}
                required={initialFormField.Language.required}
              />
              <ManageNewsSelect
                form={form}
                name='Status'
                label={language.MANAGE_RECRUITMENT_STATUS}
                placeholder={language.MANAGE_RECRUITMENT_STATUS_PLACEHOLDER}
                required={initialFormField.Status.Required}
                hasInitialValue={true}
                initialValue={recruitmentData.Status}
              />
              <MpsDatePicker
                label={language.MANAGE_RECRUITMENT_CREATED_DATE}
                name={initialFormField.CreatedDate.Name}
                value={recruitmentData.CreatedDate}
                required={initialFormField.CreatedDate.Required}
                disabled={false}

              />
            </Form>
          </TabPane>
          <TabPane tab={language.MANAGE_RECRUITMENT_DETAIL_INFO} key='detail-info'>
            <MpsRichTextEditor onChange={handleDetailInfoChange} data={recruitmentData.Recruitment_Detail_Info} />
          </TabPane>
          {/* <TabPane tab={language.MANAGE_RECRUITMENT_DETAIL_INFO_ENG} key='detail-info-eng'>
            <MpsRichTextEditor onChange={handleDetailInfoEngChange} data={recruitmentData.Recruitment_Detail_Info_Eng} />
          </TabPane> */}
        </Tabs>
      </Modal>
    </>
  );
}

export default ManageRecruitmentEdit;


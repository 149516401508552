import React, { useState, useEffect } from 'react';
import { Form, Select } from 'antd';
import { ManageNewsCategoryService } from '../Service/ManageNewsCategory.Service';

const searchModel = {};
const searchOption = { page: 1, limit: 10 };

function ManageNewsCategorySelect(props) {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    ManageNewsCategoryService.search(searchModel, searchOption).then(result => {
      const data = result.result.docs || [];
      setDataSource(data);
    })
      .catch(() => {
        // TO DO
      });
  }, []);

  useEffect(() => {
    if (!props.form) return;

    props.form.resetFields();

  }, [dataSource, props.form]);

  return (
    <Form.Item
      label={props.label}
      name={props.name}
      labelAlign='left'
      colon={false}
      initialValue={props.initialValue ? props.initialValue : null}
      rules={[{ required: props.required || false, message: `${props.warning || ''}` }]}
      className={`admin-filed ${props.className || ''}`}
    >
      <Select placeholder={props.placeholder || ''} allowClear={true}>
        {dataSource.length > 0 && dataSource.map(data => {
          return <Select.Option key={`${data.News_Category_Code}`} value={data.News_Category_Code}>
            {data.News_Category_Name}
          </Select.Option>;
        })}
      </Select>
    </Form.Item>
  );
}

export default React.memo(ManageNewsCategorySelect);


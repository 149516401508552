import React, { useState, useEffect, useCallback } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Row, Col } from 'antd';
import { SwapRightOutlined } from '@ant-design/icons';
import Helmet from '../Layout/Helmet';
import Breadcrum from '../Layout/Breadcrum';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import { RecruitCommon, numberOfPeopleUnit } from './Model/RecruitModel';

import background from '../../asset/img/img-9.jpg';
import banner from '../../asset/img/Tuyendung img/04. BANNER HR/banner_tienlen_HIRING_PC.png';
import backgroundTitle from '../../asset/img/img-8.jpg';
import backgroundCultural1 from '../../asset/img/Tuyendung img/05. THUMB HR/thumb_HR_01.png';
import backgroundCultural2 from '../../asset/img/Tuyendung img/05. THUMB HR/thumb_HR_02.jpg';
import backgroundCultural3 from '../../asset/img/Tuyendung img/05. THUMB HR/thumb_HR_03.jpeg';
import { ManageRecruitmentService } from '../Admin/ManageRecruitment/Service/ManageRecruitment.Service';
import { RESULT_CODES } from '../../common/Constant.Common';
import { formatDateSecondType } from '../Admin/Common/FormatCommon';

export default function RecruitPage(props) {
  const { language } = props;
  const [searchOption, setSearchOption] = useState({ page: 1, limit: 5 });
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  let { path } = useRouteMatch();

  const handleSearch = useCallback(() => {
    ManageRecruitmentService.search({ Status: true, Language: language }, searchOption).then(result => {
      if (result.returnCode === RESULT_CODES.ERROR) throw new Error('');

      const data = result.result.docs || [];
      setDataSource(data);
      setTotal(result.result.total);
    })
      .catch(() => {
        // TO DO
      });
  }, [searchOption, language]);

  const handleLoadMore = () => {
    setSearchOption(prevState => {
      return { ...prevState, limit: prevState.limit + 5 };
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);


  return (
    <Helmet title={RecruitCommon[language].title}>
      <Breadcrum data={[RecruitCommon[language].title]} />
      <TitleBreadcrum title={RecruitCommon[language].title} background={backgroundTitle} />
      <div className='recruit-generation' style={{ backgroundImage: `url(${background})` }}>
        <div className='recruit-generation__overlay'></div>
        <img src={banner} className='recruit-heading' alt='recruit-heading'></img>
        <div className='recruit-main'>
          <div className='container'>
            <div className='recruit-main__title'>
              <div className='recruit-main__title__circle'></div>
              <h3>{RecruitCommon[language].RecruitInfo.title}</h3>
            </div>
            {dataSource.length > 0 ? dataSource.map((item) => (
              <div key={item._id} className='recruit-main__item-container'>
                <div className='recruit-main__item'>
                  <p className='recruit-main__item__title'>
                    {item.Recruitment_Title}
                  </p>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='recruit-main__item__detail'>
                    <Col lg={12} md={12} sm={24} xs={24}>
                      <i className='far fa-clock'></i>
                      {item.Recruitment_TypeObject.Recruitment_Type_Name}
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                      <i className='fas fa-user-friends'></i>
                      {`${item.Recruitment_Quantity ? ('0' + item.Recruitment_Quantity).slice(-2) : 0} `}
                      {language === 'vi' ? 'Người' : numberOfPeopleUnit(item.Recruitment_Quantity)}
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                      <i className='fas fa-map-marker-alt'></i>
                      {item.Recruitment_Address}
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                      <i className='far fa-calendar-alt'></i>
                      {`${language === 'vi' ? 'Hết hạn' : 'Expired date'} ${formatDateSecondType(item.Recruitment_ExpiredDate)}`}
                    </Col>
                  </Row>
                  <Link to={`recruit/${item._id}`} className='recruit-main__item__button'>
                    {RecruitCommon[language].detail}
                    <SwapRightOutlined />
                    <div className='recruit-main__item__button__line'></div>
                  </Link>
                </div>
                <div className='recruit-main__item--background'></div>
              </div>
            ))
              : <div className='recruit-main__item-container'>
                <div className='recruit-main__item'>
                  <p className='recruit-main__item__title recruit-main__item__title-EMPTY'>
                    NEW JOB
                  </p>
                  <div className='recruit-main__item__content-EMPTY'>
                    COMING SOON
                  </div>
                </div>
                <div className='recruit-main__item--background'></div>
              </div>
            }

            {dataSource.length !== total && <div onClick={handleLoadMore} className='home__main__button home__main__button--red recruit-button-item-main'>
              <span>{RecruitCommon[language].seeMore}</span>
            </div>}

            <div className='recruit-main__title'>
              <div className='recruit-main__title__circle'></div>
              <h3>{RecruitCommon[language].RecruitCulture.title}</h3>
            </div>
            <div className='recruit-cultural'>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col lg={12} md={12} sm={24} xs={24} className='recruit-cultural__item'>
                  <div className='recruit-cultural__background' style={{ backgroundImage: `url(${backgroundCultural1})` }}>

                  </div>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24} className='recruit-cultural__item'>
                  <div className='recruit-cultural__item__main'>

                    <h4>{RecruitCommon[language].RecruitCulture.content[0].title}</h4>
                    <p>
                      {RecruitCommon[language].RecruitCulture.content[0].content}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='recruit-cultural__col-reverse'>
                <Col lg={12} md={12} sm={24} xs={24} className='recruit-cultural__item'>
                  <div className='recruit-cultural__item__main'>
                    <h4>{RecruitCommon[language].RecruitCulture.content[1].title}</h4>
                    <p>
                      {RecruitCommon[language].RecruitCulture.content[1].content}
                    </p>
                  </div>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24} className='recruit-cultural__item'>
                  <div className='recruit-cultural__background' style={{ backgroundImage: `url(${backgroundCultural2})` }}>

                  </div>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col lg={12} md={12} sm={24} xs={24} className='recruit-cultural__item'>
                  <div className='recruit-cultural__background' style={{ backgroundImage: `url(${backgroundCultural3})` }}>

                  </div>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24} className='recruit-cultural__item'>
                  <div className='recruit-cultural__item__main'>
                    <h4>{RecruitCommon[language].RecruitCulture.content[2].title}</h4>
                    <p>
                      {RecruitCommon[language].RecruitCulture.content[2].content}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className='recruit-main__item--background'></div>
          </div>
        </div>
      </div>
    </Helmet>
  );
}

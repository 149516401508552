import React from 'react';
import { SwapLeftOutlined } from '@ant-design/icons';

export default function IntroButton(props) {
    return (
        <div className={`intro__main__button intro__main__button--${props.color}`}>
            {props.turn && props.turn === 'left' ?
                <>
                    <span className='intro__main__button__icon'>
                        <SwapLeftOutlined />
                    </span>
                    <span>{props.title}</span>
                </>
                : <>
                    <span className='intro__main__button__icon'>
                        <SwapLeftOutlined />
                    </span>
                    <span>{props.title}</span>
                </>}
        </div>
    );
}

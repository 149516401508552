import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { IntroTypeService } from './Service/IntroType.Service'
import { RESULT_CODES } from '../../common/Constant.Common'
import { openErrorNotification } from '../Admin/Common/MethodCommon'
import IntroProfileStyleShow from './IntroProfileStyleShow'
import IntroDetailStyleShow from './IntroDetailStyleShow'
import IntroMapStyleShow from './IntroMapStyleShow'
import IntroListStyleShow from './IntroListStyleShow'
import { styleShow } from '../Admin/ManageIntroTypePost/Model/ManageIntroTypePost.Model';
export default function IntroTypePostDetail(props) {
    const { language } = props;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const [modal, setModal] = useState({
        isDisplay: false,
        data: ''
    });
    const [dataSource, setDataSource] = useState([]);
    const [typePostData, setTypePostData] = useState([]);
    const history = useHistory();
    const handleToggleModal = () => {
        setModal({
            ...modal,
            isDisplay: false,
        })
        document.getElementsByTagName("body")[0].style.overflow = "auto"
    }
    const { typeSlug } = useParams()
    const fetchPostIntroBySlug = async () => {
        const res = await IntroTypeService.getAllPostIntroBySlug(typeSlug, language)
        if (res.returnCode === RESULT_CODES.SUCCESS) {
            setDataSource(res.data?.postIntro)
            setTypePostData(res.data?.type_post)
        } else {
            openErrorNotification({ title: 'Thông báo', message: 'Lấy dữ liệu thất bại' })
        }
    }

    const processTypeSlugData = useMemo(() => {
        if (typePostData.length === 0) {
            return []
        }
        return typePostData[0].Type_Post_Slug.split('/')
    }, [typePostData])

    useEffect(() => {
        fetchPostIntroBySlug()
    }, [typeSlug, language])
    const uiRendering = useCallback(() => {
        if (processTypeSlugData.includes(typeSlug) && typePostData[0]?.Type_Post_Style_Show === styleShow[0].value) {
            return <IntroProfileStyleShow language={language} dataSource={dataSource} typePostData={typePostData} />
        }
        if (processTypeSlugData.includes(typeSlug) && typePostData[0]?.Type_Post_Style_Show === styleShow[1].value) {
            return <IntroListStyleShow language={language} dataSource={dataSource} typePostData={typePostData} />
        }
        if (processTypeSlugData.includes(typeSlug) && typePostData[0].Type_Post_Style_Show === styleShow[2].value) {
            return (
                <IntroDetailStyleShow language={language} dataSource={dataSource} typePostData={typePostData} />
            )
        }
        if (processTypeSlugData.includes(typeSlug) && typePostData[0]?.Type_Post_Style_Show === styleShow[3].value) {
            return (
                <IntroMapStyleShow language={language} dataSource={dataSource} typePostData={typePostData} />
            )
        }
        return <div></div>
    }, [typePostData, processTypeSlugData, language])

    return (
        uiRendering()
    )
}

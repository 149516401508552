import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../ContextProvider/LanguageContextProvider";

export default function RenderMeta({ metadata = [] }) {
  const { language } = useContext(LanguageContext);

  const checkMetaType = metadata.map((tag) => {
    switch (tag.Meta_Key) {
      case "title":
        return (
          <Helmet key={tag.key}>
            <title>{tag.Meta_Content}</title>
            <meta name="twitter:title" content={tag.Meta_Content} />
            <meta property="og:title" content={tag.Meta_Content} />
            <meta property="og:image:alt" content={tag.Meta_Content} />
            <meta name="twitter:image:alt" content={tag.Meta_Content} />
          </Helmet>
        );
      case "description":
        return (
          <Helmet key={tag.key}>
            <meta name="description" content={tag.Meta_Content} />
            <meta property="og:description" content={tag.Meta_Content} />
            <meta name="twitter:description" content={tag.Meta_Content} />
          </Helmet>
        );
      case "url":
        return (
          <Helmet key={tag.key}>
            <meta property="og:url" content={tag.Meta_Content} />
          </Helmet>
        );
      case "image":
        return (
          <Helmet key={tag.key}>
            <meta property="og:image:url" content={tag.Meta_Content} />
            <meta property="og:image:width" content="800" />
            <meta property="og:image:height" content="600" />
            <meta name="twitter:image" content={tag.Meta_Content} />
          </Helmet>
        );
      case "type":
        return (
          <Helmet key={tag.key}>
            <meta name="og:type" content={tag.Meta_Content} />
          </Helmet>
        );
      case "canonical":
        return (
          <Helmet key={tag.key}>
            <link rel="canonical" href={tag.Meta_Content} />;
          </Helmet>
        );
      case "json-ld":
        return (
          <Helmet key={tag.key}>
            <script type="application/ld+json">
              {JSON.stringify(JSON.parse(tag.Meta_Content))}
            </script>
          </Helmet>
        );
      default:
        return (
          <Helmet key={tag.key}>
            <meta
              key={tag.key}
              name={tag.Meta_Key}
              content={tag.Meta_Content}
            />
          </Helmet>
        );
      // code block
    }
  });

  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary" />
        <meta name="geo.region" content="VN" />
        <meta name="geo.placename" content="Bi&ecirc;n H&ograve;a City" />
        <meta name="geo.position" content="10.954523;106.871202" />
        <meta name="ICBM" content="10.954523, 106.871202" />
      </Helmet>
      {language === "vi" ? (
        <Helmet>
          <link
            rel="alternate"
            hreflang="vi-VN"
            href={`${process.env.REACT_APP_HOST}/vi`}
          />
          <meta http-equiv={`content-language`} content={`vi-vn`} />
          <meta property="og:locale" content="vi" />
        </Helmet>
      ) : (
        <Helmet>
          <link
            rel="alternate"
            hreflang="en-US"
            href={`${process.env.REACT_APP_HOST}/en`}
          />
          <meta http-equiv={`content-language`} content={`en-us`} />
          <meta property="og:locale" content="en" />
        </Helmet>
      )}
      {checkMetaType}
    </>
  );
}

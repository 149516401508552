import React, { useState, useCallback } from 'react';
import { Button, Modal, Form, Tabs, Input } from 'antd';
import ManageNewsSelect from '../ManageNews/Control/ManageNewsSelect';
import ProductCategorySelect from './Control/ProductCategorySelect';
import MpsRichTextEditor from '../../MpsCotrols/MpsRichTextEditor/MpsRichTextEditor';
import MpsUploadImage from '../../MpsCotrols/MpsUploadImage/MpsUploadImage';

import { ManageProductContext, ManageProductLanguageContext } from './ManageProductPage';
import { ManageProductService } from './Service/ManageProduct.Service';

import { initialFormField, productInitData } from './Model/ManageProduct.Model';
import { momentDateTime } from '../Common/FormatCommon';
import { RESULT_CODES } from '../../../common/Constant.Common';
import { openErrorNotification, openSuccessNotification } from '../Common/MethodCommon';
import MpsDatePicker from '../../MpsCotrols/MpsDatePicker/MpsDatePicker';

const { TabPane } = Tabs;

function ManageProductAdd() {
  const language = React.useContext(ManageProductLanguageContext);
  const handleSearch = React.useContext(ManageProductContext);

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [productData, setProductData] = useState({ ...productInitData });
  const [activeTabKey, setActiveTabKey] = useState('generalInfo');

  const showModal = () => {
    form.setFieldsValue({ CreatedDate: momentDateTime(Date.now()) });
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const resetField = () => {
    form.resetFields();
    setProductData({ ...productInitData });
  };

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  const handleImagesChange = useCallback((images) => {
    setProductData(prevState => ({ ...prevState, Product_Images: images }));
  }, []);

  const handleDetailInfoChange = useCallback((text) => {
    setProductData(prevState => ({ ...prevState, Product_Detail_Info: text }));
  }, []);

  const handleDetailInfoEngChange = useCallback((text) => {
    setProductData(prevState => ({ ...prevState, Product_Detail_Info_Eng: text }));
  }, []);

  const handleFinish = () => {

    form.validateFields().then(values => {
      const productFormData = new FormData();

      // Append images to form data
      productData.Product_Images.forEach(image => {
        productFormData.append('Product_Images', image.originFileObj);
      });

      // Append values to form data
      const formObjectValues = Object.entries(values);
      formObjectValues.forEach(value => {
        productFormData.append(value[0], value[1]);
      });
      productFormData.append('Product_Detail_Info', productData.Product_Detail_Info);
      productFormData.append('Product_Detail_Info_Eng', productData.Product_Detail_Info_Eng);

      return ManageProductService.create(productFormData);
    })
      .then(result => {
        if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);

        handleSearch();
        resetField();
        hideModal();
        setActiveTabKey('generalInfo');
        openSuccessNotification({ title: 'Thông báo', message: 'Thêm mới thành công' });
      })
      .catch(error => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({ title: 'Thông báo', message: 'Thêm mới thất bại' });
      });

  };

  return (
    <>
      <Button onClick={showModal} className='admin-button__main admin-button__download-pdf'>
        <i className='fas fa-plus'></i>
        {language.MANAGE_PRODUCT_CREATE_NEW}
      </Button>
      <Modal
        title={language.MANAGE_PRODUCT_CREATE_NEW}
        visible={visible}
        width='80%'
        className='manage-product-modal admin-modal'
        onCancel={hideModal}
        footer={
          [
            <Button
              key='submit'
              type='secondary'
              onClick={hideModal}
              className='admin-button__main admin-button__delete'
            >
              {language.MANAGE_PRODUCT_CANCEL}
            </Button>,
            <Button
              onClick={handleFinish}
              key='cancel'
              type='primary'
              className='admin-button__main admin-button__save'
            >
              {language.MANAGE_PRODUCT_SAVE}
            </Button>
          ]
        }
      >
        <Tabs className='manage-product-tabs' activeKey={activeTabKey} onChange={handleTabChange}>
          <TabPane tab={language.MANAGE_PRODUCT_GENERAL_INFO} key='generalInfo'>
            <Form
              form={form} requiredMark={false} id='manage-product-add-form'
              className='manage-product-form'
              colon={false}
              name='manage-product-add-form'
              labelCol={{ lg: { span: 3 }, md: { span: 24 } }}
              wrapperCol={{ lg: { span: 8 }, md: { span: 24 } }}
            >
              <Form.Item
                label={language.MANAGE_PRODUCT_NAME_VIE}
                labelAlign='left'
                name={initialFormField.Product_Name.Name}
                rules={[{ required: initialFormField.Product_Name.Required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_PRODUCT_NAME_VIE_PLACEHOLDER} maxLength={200} rows={3} />
              </Form.Item>
              <Form.Item
                label={language.MANAGE_PRODUCT_NAME_ENG}
                labelAlign='left'
                name={initialFormField.Product_Name_Eng.Name}
                rules={[{ required: initialFormField.Product_Name_Eng.Required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_PRODUCT_NAME_ENG_PLACEHOLDER} maxLength={200} rows={3} />
              </Form.Item>
              <ProductCategorySelect
                form={form}
                name={initialFormField.Product_Category.Name}
                label={language.MANAGE_PRODUCT_CATEGORY}
                placeholder={language.MANAGE_PRODUCT_CATEGORY_PLACEHOLDER}
                required={initialFormField.Product_Category.Required}
              />
              <ManageNewsSelect
                form={form}
                name='Status'
                label={language.MANAGE_PRODUCT_STATUS}
                placeholder={language.MANAGE_PRODUCT_STATUS_PLACEHOLDER}
                required={initialFormField.Status.Required}
                hasInitialValue={true}
              />
              <MpsDatePicker
                label={language.MANAGE_PRODUCT_CREATED_DATE}
                name={initialFormField.CreatedDate.Name}
                value={Date.now()}
                required={initialFormField.CreatedDate.Required}
                disabled={true}

              />
            </Form>
          </TabPane>
          <TabPane tab={language.MANAGE_PRODUCT_IMAGES} key='images'>
            <MpsUploadImage images={productData.Product_Images} onChange={handleImagesChange} max={3} />
          </TabPane>
          <TabPane tab={language.MANAGE_PRODUCT_DETAIL_INFO} key='detail-info'>
            <MpsRichTextEditor onChange={handleDetailInfoChange} data={productData.Product_Detail_Info} />
          </TabPane>
          <TabPane tab={language.MANAGE_PRODUCT_DETAIL_INFO_ENG} key='detail-info-eng'>
            <MpsRichTextEditor onChange={handleDetailInfoEngChange} data={productData.Product_Detail_Info_Eng} />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
}

export default ManageProductAdd;


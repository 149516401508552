import React from 'react'
import { Row, Col, Input, Form, } from 'antd'

export default function ManageDocumentInput(props) {
    return (
        <Row className="admin-filed__list" gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Col className="admin-filed__list__item" lg={8} md={24} sm={24} xs={24}>
                <label>{props.title} <span className={`admin-filed__list__item__required ${props.require ? "active" : ""}`}>*</span></label>
            </Col>
            <Col className="admin-filed__list__item" lg={14} md={24} sm={24} xs={24}>
                <Form.Item
                    name={props.name}
                    rules={[{ required: props.require || false, message: `Đây là trường bắt buộc` }]}
                    className={`admin-filed ${props.className || ""}`}
                >
                    <Input
                        className="admin-input"
                        value={props.value}
                        placeholder={props.placeholder}
                        type={props.typeInput}
                        disabled={props.disabled}
                    />
                </Form.Item>
            </Col>
        </Row>
    )
}

import { Button, Form, Input, Popconfirm, Select, Table } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
const EditableContext = React.createContext(null);
const { Option } = Select;

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

function renderOption(data) {
  return (
    <Option key={data.id} value={data.Meta_Tag_Code}>
      {data.Meta_Tag_Name}
    </Option>
  );
}

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  type,
  readOnly,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const [metaTags, setMetaTags] = useState([
    { id: 0, Meta_Tag_Code: "title", Meta_Tag_Name: "Title" },
    { id: 1, Meta_Tag_Code: "description", Meta_Tag_Name: "Description" },
    { id: 2, Meta_Tag_Code: "url", Meta_Tag_Name: "URL" },
    { id: 9, Meta_Tag_Code: "canonical", Meta_Tag_Name: "Canonical" },
    { id: 3, Meta_Tag_Code: "image", Meta_Tag_Name: "URL Image" },
    { id: 4, Meta_Tag_Code: "keywords", Meta_Tag_Name: "Keywords" },
    { id: 4, Meta_Tag_Code: "author", Meta_Tag_Name: "Author" },
    { id: 5, Meta_Tag_Code: "type", Meta_Tag_Name: "Type" },
    { id: 6, Meta_Tag_Code: "category", Meta_Tag_Name: "Category" },
    { id: 6, Meta_Tag_Code: "robots", Meta_Tag_Name: "Robots" },
    { id: 8, Meta_Tag_Code: "json-ld", Meta_Tag_Name: "JSON-LD schema" },
    { id: 7, Meta_Tag_Code: "viewport", Meta_Tag_Name: "Viewport" },
  ]);
  const [metaTag, setMetaTag] = useState({
    Meta_Tag_Code: "",
    Meta_Tag_Name: "",
  });
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    // form.setFieldsValue({
    //   [dataIndex]: record[dataIndex],
    // });
  };

  const handleSelect = (value) => {
    const temp = {
      Meta_Key: value,
      Meta_Content: "",
    };
    handleSave({ ...record, ...temp });
  };

  const save = (e) => {
    toggleEdit();
    handleSave({ ...record, Meta_Content: e.target.value });
  };

  const renderCell = () => {
    if (editing) {
      if (type === "select") {
        return (
          <Select
            style={{ width: "100%" }}
            value={record.Meta_Key ? record.Meta_Key : null}
            onChange={handleSelect}
            disabled={readOnly}
            options={metaTags.map((tag) => ({
              label: tag.Meta_Tag_Name,
              value: tag.Meta_Tag_Code,
            }))}
          ></Select>
        );
      }
      if (type === "input") {
        return (
          <Form.Item style={{ margin: 0 }}>
            <Input.TextArea
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              disabled={readOnly}
              autoSize={{ minRows: 3, maxRows: 5 }}
              defaultValue={record.Meta_Content}
            />
          </Form.Item>
        );
      }
    } else {
      if (type === "select") {
        return (
          <Select
            value={record.Meta_Key ? record.Meta_Key : null}
            onChange={handleSelect}
            allowClear
            disabled={readOnly}
            options={metaTags.map((tag) => ({
              label: tag.Meta_Tag_Name,
              value: tag.Meta_Tag_Code,
            }))}
            style={{ width: "100%" }}
          ></Select>
        );
      } else {
        return (
          <div
            className="editable-cell-value-wrap"
            style={{ minHeight: 40, paddingRight: 24 }}
            onClick={toggleEdit}
          >
            {children}
          </div>
        );
      }
    }
  };
  return <td {...restProps}>{editable ? renderCell() : children}</td>;
};

const Metadata = (props) => {
  const [metadataList, setMetadata] = useState([
    { Meta_Key: "title", Meta_Content: "abc" },
  ]);
  const [count, setCount] = useState(0);
  const [readOnly, setReadOnly] = useState(props.readOnly || false);

  const handleDelete = (key) => {
    const newMetadataList = metadataList.filter((item) => item.key !== key);
    setMetadata(newMetadataList);
    props.onChange(newMetadataList);
  };

  const defaultColumns = [
    {
      title: "Action",
      dataIndex: "Action",
      align: "center",
      width: "10%",
      type: null,
      render: (_, record) =>
        metadataList.length >= 1 ? (
          props.readOnly ? (
            <>
              <span
                style={{ color: "red", cursor: "pointer", fontSize: "20px" }}
              >
                <MdDeleteOutline />
              </span>{" "}
            </>
          ) : (
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record.key)}
            >
              <span
                style={{ color: "red", cursor: "pointer", fontSize: "20px" }}
              >
                <MdDeleteOutline />
              </span>
            </Popconfirm>
          )
        ) : null,
    },
    {
      title: "Meta key",
      dataIndex: "Meta_Key",
      width: "20%",
      editable: true,
      type: "select",
    },
    {
      width: "70%",
      title: "Meta content",
      dataIndex: "Meta_Content",
      editable: true,
      type: "input",
    },
  ];

  useEffect(() => {
    setMetadata(props.metadata);
    setReadOnly(props.readOnly ? true : false);
    setCount(props.metadata.length || 0);
  }, [props.metadata, props.readOnly]);

  const handleAdd = () => {
    const newTag = {
      key: count,
      Meta_Key: "",
      Meta_Content: "",
    };
    setMetadata([...metadataList, newTag]);
    setCount((prev) => prev + 1);
  };

  const handleSave = (row) => {
    const newMetadataList = [...metadataList];
    const index = newMetadataList.findIndex((item) => row.key === item.key);
    const item = newMetadataList[index];
    newMetadataList.splice(index, 1, {
      ...item,
      ...row,
    });
    setMetadata(newMetadataList);
    props.onChange(newMetadataList);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
        type: col.type,
        readOnly: readOnly,
      }),
    };
  });
  return (
    <div>
      <Button
        onClick={handleAdd}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        Thêm thẻ meta
      </Button>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={metadataList}
        columns={columns}
      />
    </div>
  );
};
export default Metadata;

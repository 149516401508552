import React, { useState, useCallback } from 'react';
import { Button, Modal, Form, Tabs, Input } from 'antd';
import ManageUnitStatusSelect from '../ManageUnit/Control/ManageUnitStatusSelect';
import MpsRichTextEditor from '../../MpsCotrols/MpsRichTextEditor/MpsRichTextEditor';
import MpsUploadImage from '../../MpsCotrols/MpsUploadImage/MpsUploadImage';

import { ManageProductTypeContext, ManageProductTypeLanguageContext } from './ManageProductTypePage';
import { ManageProductTypeService } from './Service/ManageProductType.Service';

import { initialFormField, productTypeInitData } from './Model/ManageProductType.Model';
import { momentDateTime } from '../Common/FormatCommon';
import { RESULT_CODES } from '../../../common/Constant.Common';
import { openErrorNotification, openSuccessNotification } from '../Common/MethodCommon';
import MpsDatePicker from '../../MpsCotrols/MpsDatePicker/MpsDatePicker';

const { TabPane } = Tabs;

function ManageProductTypeAdd() {
  const language = React.useContext(ManageProductTypeLanguageContext);
  const handleSearch = React.useContext(ManageProductTypeContext);

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('generalInfo');

  const showModal = () => {
    form.setFieldsValue({ CreatedDate: momentDateTime(Date.now()) });
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const resetField = () => {
    form.resetFields();
  };

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  const handleFinish = () => {

    form.validateFields().then(values => {
      const productTypeFormData = {}

      // Append values to form data
      const formObjectValues = Object.entries(values);
      formObjectValues.forEach(value => {
        productTypeFormData[value[0]]=value[1]
      });

      return ManageProductTypeService.create(productTypeFormData);
    })
      .then(result => {
        if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);

        handleSearch();
        resetField();
        hideModal();
        setActiveTabKey('generalInfo');
        openSuccessNotification({ title: 'Thông báo', message: 'Thêm mới thành công' });
      })
      .catch(error => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({ title: 'Thông báo', message: 'Thêm mới thất bại' });
      });

  };

  return (
    <>
      <Button onClick={showModal} className='admin-button__main admin-button__download-pdf'>
        <i className='fas fa-plus'></i>
        {language.MANAGE_PRODUCT_TYPE_CREATE_NEW}
      </Button>
      <Modal
        title={language.MANAGE_PRODUCT_TYPE_CREATE_NEW}
        visible={visible}
        width='80%'
        className='manage-product-category-modal admin-modal'
        onCancel={hideModal}
        footer={
          [
            <Button
              key='submit'
              type='secondary'
              onClick={hideModal}
              className='admin-button__main admin-button__delete'
            >
              {language.MANAGE_PRODUCT_TYPE_CANCEL}
            </Button>,
            <Button
              onClick={handleFinish}
              key='cancel'
              type='primary'
              className='admin-button__main admin-button__save'
            >
              {language.MANAGE_PRODUCT_TYPE_SAVE}
            </Button>
          ]
        }
      >
        <Tabs className='manage-product-category-tabs' activeKey={activeTabKey} onChange={handleTabChange}>
          <TabPane tab={language.MANAGE_PRODUCT_TYPE_GENERAL_INFO} key='generalInfo'>
            <Form
              form={form} requiredMark={false} id='manage-product-category-add-form'
              className='manage-product-category-form'
              colon={false}
              name='manage-product-category-add-form'
              labelCol={{ lg: { span: 3 }, md: { span: 24 } }}
              wrapperCol={{ lg: { span: 8 }, md: { span: 24 } }}
            >
               <Form.Item
                label={language.MANAGE_PRODUCT_TYPE_CODE}
                labelAlign='left'
                name={initialFormField.Product_Type_Code.Name}
                rules={[{ required: initialFormField.Product_Type_Code.Required || false, message: '' }]}
              >
                <Input placeholder={language.MANAGE_PRODUCT_TYPE_CODE} maxLength={200} rows={3} />
              </Form.Item>
              <Form.Item
                label={language.MANAGE_PRODUCT_TYPE_NAME_VIE}
                labelAlign='left'
                name={initialFormField.Product_Type_Name.Name}
                rules={[{ required: initialFormField.Product_Type_Name.Required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_PRODUCT_TYPE_NAME_VIE_PLACEHOLDER} maxLength={200} rows={3} />
              </Form.Item>
              <Form.Item
                label={language.MANAGE_PRODUCT_TYPE_NAME_ENG}
                labelAlign='left'
                name={initialFormField.Product_Type_Name_Eng.Name}
                rules={[{ required: initialFormField.Product_Type_Name_Eng.Required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_PRODUCT_TYPE_NAME_ENG_PLACEHOLDER} maxLength={200} rows={3} />
              </Form.Item>
              <ManageUnitStatusSelect
                form={form}
                name='Status'
                label={language.MANAGE_PRODUCT_TYPE_STATUS}
                placeholder={language.MANAGE_PRODUCT_TYPE_STATUS_PLACEHOLDER}
                required={initialFormField.Status.Required}
                hasInitialValue={true}
              />
              <MpsDatePicker
                label={language.MANAGE_PRODUCT_TYPE_CREATED_DATE}
                name={initialFormField.CreatedDate.Name}
                value={Date.now()}
                required={initialFormField.CreatedDate.Required}
                disabled={true}

              />
              <Form.Item
                label={language.MANAGE_PRODUCT_TYPE_CREATED_PERSON}
                labelAlign='left'
                name={initialFormField.CreatedPerson.Name}
                rules={[{ required: initialFormField.CreatedPerson.Required || false, message: '' }]}
                disabled={true}

                
              >
                <Input placeholder={language.MANAGE_PRODUCT_TYPE_CREATED_PERSON}  maxLength={200} rows={3} 
                disabled={true}

                />
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
}

export default ManageProductTypeAdd;


import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DownloadDocumentCommon } from '../Model/DownloadDocumentModel';
import AppFlipPages from './AppFlipPages'

export default function DownloadDocumentItem(props) {
    const { data, language } = props;
    return (
        <div className='relation-item'>
            <div className='relation-item__time'>
                {
                    moment(data.CreatedAt).format('DD/MM/YYYY - HH:mm:ss')
                }
            </div>
            <div className='relation-item__main'>
                <a
                    href={`${process.env.REACT_APP_API_URL + `/document/${data.Document_File}`}`}
                    target='_blank' rel='noopener noreferrer'
                    className='relation-item__main__title'>
                    {data.Document_Title}
                </a>
                <div className='relation-item__main--flex'>
                    <AppFlipPages language_1={language}
                        titleFlip={data.Document_Title}
                        titleFlip_ENG={data.Document_Title_ENG}
                        url_1={`${process.env.REACT_APP_API_URL + `/document/${data.Document_File}`}`}
                    />
                    <a href={`${process.env.REACT_APP_API_URL + `/document/${data.Document_File}`}`}
                        download
                        target='_blank' rel='noopener noreferrer'
                        className='relation-item__main__button'
                    >
                        {DownloadDocumentCommon[language].download}
                        <div className='relation-item__main__button__icon'>
                            <DownloadOutlined />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

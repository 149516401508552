import React, { useEffect, useState } from "react";
import { NewsCommon } from "./Model/NewsModel";
import Helmet from "../Layout/Helmet";
import Breadcrum from "../Layout/Breadcrum";
import TitleBreadcrum from "../Layout/TitleBreadcrum";
import { useParams, Link, useRouteMatch } from "react-router-dom";
import NewsSortSelect from "./Control/NewsSortSelect";
import background from "../../asset/img/img-9.jpg";
import NewsListGroup from "./Control/NewsListGroup";
import { NewsListGroupNoImage } from "./Control/NewsListGroup";
import PaginationMain from "../Layout/PaginationMain";
import { SwapLeftOutlined } from "@ant-design/icons";
import { ManageNewsService } from "../Admin/ManageNews/Service/ManageNews.Service";
import { NEWS_LANGUAGE } from "./Language/Language";
import { getGroupNameByLanguage } from "./Helper/Helper";
import MpsRangePicker from "../MpsCotrols/MpsRangePicker/MpsRangePicker";
import { SearchOutlined } from "@ant-design/icons";
import { formatDateTimeSecondType } from "../Admin/Common/FormatCommon";
import {
  getNewsExcerptByLanguage,
  getNewsNameByLanguage,
} from "./Helper/Helper";
import { ManageNewsGroupService } from "../Admin/ManageNews/Service/ManageNewsGroup.Service";

const groupSearchOption = { page: 1, limit: 15 };
const newsSearchOption = {
  page: 1,
  limit: 6,
  getImages: true,
  sort: ["CreatedAt"],
  sortAscending: false,
};
export default function NewsGroup({ language }) {
  const { path } = useRouteMatch();
  const News_Group_Slug = useParams().News_Group_Slug;
  const [searchOption, setSearchOption] = useState({
    page: 1,
    limit: 4,
    getImages: true,
    sortAscending: false,
  });

  const [dataSearch, setDataSearch] = useState("");
  const [dataSearchShow, setDataSearchShow] = useState(null);
  const [dataSort, setDataSort] = useState([]);

  //
  const [subCategory, setSubCategory] = useState(null);
  const [postList, setPostList] = useState([]);

  const handleValueSort = (value) => {
    setDataSort(value);
  };
  const handleSearchChange = (e) => {
    setDataSearch(e.target.value);
  };
  const handleSearchShow = (e) => {
    setDataSearchShow(e);
  };

  const handleFetchSubCategoryAndPosts = async () => {
    const responseSubCate = await ManageNewsGroupService.getBySlug(
      News_Group_Slug
    );
    const subCategory = responseSubCate.data || null;
    const responsePosts = await ManageNewsService.search(
      {
        News_Group: subCategory?.News_Group_Code,
        CreatedDate: dataSort,
        News_Title: dataSearchShow,
        Status: true,
        Language: language,
      },
      searchOption
    );
    const postList = responsePosts?.result || [];

    setSubCategory(subCategory);
    setPostList(postList);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    handleFetchSubCategoryAndPosts();
  }, [News_Group_Slug, searchOption, dataSort, dataSearchShow, language]);

  const handlePagination = (currentPage, pageSize) => {
    setSearchOption((prevState) => ({
      ...prevState,
      page: currentPage,
      limit: pageSize,
    }));
  };

  const handleSortChange = (changedValue) => {
    switch (changedValue) {
      case "newest":
        setSearchOption((prevState) => ({
          ...prevState,
          sortAscending: false,
        }));
        break;
      case "oldest":
        setSearchOption((prevState) => ({ ...prevState, sortAscending: true }));
        break;
      default:
        break;
    }
  };
  return (
    <Helmet title={getGroupNameByLanguage(subCategory, language) || ""}>
      <Breadcrum
        data={[
          {
            title: NEWS_LANGUAGE[language]["title"],
            path: `/${language}${NEWS_LANGUAGE[language]["path"]}`,
          },
          getGroupNameByLanguage(subCategory, language) || "",
        ]}
      />
      <TitleBreadcrum
        title={getGroupNameByLanguage(subCategory, language) || ""}
      />
      <div
        className="news-generation"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="news-generation__overlay"></div>
        <div className="container">
          <div className="news-generation__heading2">
            <h2 className="container-title">
              {getGroupNameByLanguage(subCategory, language) || ""}
            </h2>
          </div>
          <div className="news-generation__heading1">
            <label>{NEWS_LANGUAGE[language].documentRangeTime}</label>
          </div>
          <div className="news-generation__heading">
            <div className="generation-rangedate__heading__item">
              <MpsRangePicker
                language={language}
                onChange={(e) => handleValueSort(e)}
                className="generation-rangedate__heading__item__input"
              ></MpsRangePicker>
            </div>
            <div className="news-generation-search__heading__item">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSearchShow(dataSearch);
                }}
              >
                <input
                  value={dataSearch}
                  onChange={(e) => handleSearchChange(e)}
                  type="text"
                  placeholder={NEWS_LANGUAGE[language].search}
                  name="search"
                />
                <button onClick={() => handleSearchShow(dataSearch)}>
                  <SearchOutlined />
                </button>
              </form>
            </div>
          </div>
          <div className="news-container">
            <div className="news-group">
              <div className="news__main">
                <NewsListGroup
                  language={language}
                  data={postList?.docs || []}
                  viewDetail={NEWS_LANGUAGE[language]["viewDetail"]}
                />
                <div className="news__main__pagination" style={{marginTop:20}}>
                  <PaginationMain
                    current={searchOption.page}
                    total={postList?.total || searchOption.limit}
                    pageSize={searchOption.limit}
                    onChange={handlePagination}
                  />
                </div>
              </div>
            </div>
            <div className="news-relative-container">
              <div className="news-relative">
                <h2>{NEWS_LANGUAGE[language].newestNews}</h2>
                <NewsListGroupNoImage
                  language={language}
                  data={postList?.docs || []}
                  viewDetail={NEWS_LANGUAGE[language]["viewDetail"]}
                />
              </div>
              <div className="news-relative2">
                <h2>{NEWS_LANGUAGE[language].commonNews}</h2>
                <NewsListGroupNoImage
                  language={language}
                  data={postList?.docs || []}
                  viewDetail={NEWS_LANGUAGE[language]["viewDetail"]}
                />
              </div>
            </div>
          </div>
          <Link 
          style={{bottom:-50}}
          to="../news"
           className="news-group-button-back">
            <div className="news-group-button-back__icon">
              <SwapLeftOutlined />
            </div>
            {NEWS_LANGUAGE[language]["returnToNewsPage"]}
          </Link>
        </div>
      </div>
    </Helmet>
  );
}

import React, { useState, useCallback } from "react";
import { Button, Modal, Form, Tabs, Input } from "antd";
import { newsInitData, newsFormInitialField } from "./Model/ManageNews.Model";
import ManageNewsSelect from "./Control/ManageNewsSelect";
import MpsUploadImage from "../../MpsCotrols/MpsUploadImage/MpsUploadImage";
import MpsRichTextEditor from "../../MpsCotrols/MpsRichTextEditor/MpsRichTextEditor";
import { ManageNewsService } from "./Service/ManageNews.Service";
import { RESULT_CODES } from "../../../common/Constant.Common";
import {
  openSuccessNotification,
  openErrorNotification,
} from "../Common/MethodCommon";
import ManageNewsCategorySelect from "./Control/ManageNewsCategorySelect";
import ManageNewsGroupSelect from "./Control/ManageNewsGroupSelect";
import { ManageNewsContext, ManageNewsLanguageContext } from "./ManageNewsPage";
import { momentDateTime } from "../Common/FormatCommon";
import MpsDatePicker from "../../MpsCotrols/MpsDatePicker/MpsDatePicker";
import MpsLanguageSelect from "../../MpsCotrols/MpsSelect/MpsLanguageSelect";
import Metadata from "../../Metatada/Metadata";

const { TabPane } = Tabs;

function ManageNewsAdd() {
  const language = React.useContext(ManageNewsLanguageContext);
  const handleSearch = React.useContext(ManageNewsContext);

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [category, setCategory] = useState("");
  const [newsData, setNewsData] = useState({ ...newsInitData });
  const [count, setCount] = useState(1);
  const [activeTabKey, setActiveTabKey] = useState("generalInfo");
  const [metadata, setMetadata] = useState([]);

  const showModal = () => {
    form.setFieldsValue({ CreatedDate: momentDateTime(Date.now()) });
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  const handleChangeMetadata = (values) => {
    setMetadata(values);
  };

  const handleFininsh = () => {
    form
      .validateFields()
      .then((values) => {
        const formData = new FormData();

        // Append images to form data
        newsData.News_Images.forEach((image) => {
          formData.append("News_Images", image.originFileObj);
        });

        // Append values to form data
        const formObjectValues = Object.entries(values);
        formObjectValues.forEach((value) => {
          formData.append(value[0], value[1]);
        });
        formData.append("News_AdditionalInfo", newsData.News_AdditionalInfo);
        formData.append(
          "News_AdditionalInfo_Eng",
          newsData.News_AdditionalInfo_Eng
        );
        const filterMetadata = metadata.filter(
          (tag) => tag.Meta_Key && tag.Meta_Content
        );
        formData.append("Metadata", JSON.stringify(filterMetadata));
        return ManageNewsService.create(formData);
      })
      .then((result) => {
        if (result.returnCode !== RESULT_CODES.SUCCESS)
          throw new Error(result.returnCode);

        handleSearch();
        resetField();
        hideModal();
        setMetadata([]);
        setActiveTabKey("generalInfo");
        openSuccessNotification({
          title: "Thông báo",
          message: "Thêm mới thành công",
        });
      })
      .catch((error) => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({
            title: "Thông báo",
            message: "Thêm mới thất bại",
          });
      });
  };

  const handleImagesChange = useCallback((images) => {
    setNewsData((prevState) => ({ ...prevState, News_Images: images }));
  }, []);

  const handleAdditionalInfoChange = useCallback((text) => {
    setNewsData((prevState) => ({ ...prevState, News_AdditionalInfo: text }));
  }, []);

  const handleAdditionalInfoEngChange = useCallback((text) => {
    setNewsData((prevState) => ({
      ...prevState,
      News_AdditionalInfo_Eng: text,
    }));
  }, []);

  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "News_Category") {
      setCount(2);
      setCategory(changedValues[formFieldName]);
      form.setFieldsValue({ News_Group: null });
    }
  };

  const resetField = () => {
    form.resetFields();
    setCategory(form.getFieldValue("News_Category"));
    setNewsData({ ...newsInitData });
  };

  return (
    <>
      <Button
        onClick={showModal}
        className="admin-button__main admin-button__download-pdf"
      >
        <i className="fas fa-plus"></i>
        {language.MANAGE_NEWS_CREATE_NEW_POST}
      </Button>
      <Modal
        title={language.MANAGE_NEWS_CREATE_NEW_POST}
        visible={visible}
        width="80%"
        className="manage-news-modal admin-modal"
        onCancel={hideModal}
        footer={[
          <Button
            key="submit"
            type="secondary"
            onClick={hideModal}
            className="admin-button__main admin-button__delete"
          >
            {language.MANAGE_NEWS_CANCEL}
          </Button>,
          <Button
            onClick={handleFininsh}
            key="cancel"
            type="primary"
            className="admin-button__main admin-button__save"
          >
            {language.MANAGE_NEWS_SAVE}
          </Button>,
        ]}
      >
        <Tabs
          className="manage-news-tabs"
          activeKey={activeTabKey}
          onChange={handleTabChange}
        >
          <TabPane tab={language.MANAGE_NEWS_GENERAL_INFO} key="generalInfo">
            <Form
              form={form}
              requiredMark={false}
              id="manage-news-form"
              onValuesChange={handleFormValuesChange}
              name="manage-news-form"
              className="manage-news-form"
              labelCol={{ lg: { span: 3 }, md: { span: 24 } }}
              wrapperCol={{ lg: { span: 8 }, md: { span: 24 } }}
            >
              <Form.Item
                colon={false}
                label={language.MANAGE_NEWS_TITLE}
                labelAlign="left"
                name={newsFormInitialField.News_Title.name}
                rules={[
                  {
                    required: newsFormInitialField.News_Title.required || false,
                    message: "",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder={language.MANAGE_NEWS_TITLE_PLACEHOLDER}
                  maxLength={200}
                />
              </Form.Item>
              {/* <Form.Item
                colon={false}
                label={language.MANAGE_NEWS_TITLE_ENG}
                labelAlign='left'
                name={newsFormInitialField.News_Title_Eng.name}
                rules={[{ required: newsFormInitialField.News_Title_Eng.required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_NEWS_TITLE_ENG_PLACEHOLDER} maxLength={200} />
              </Form.Item> */}
              <Form.Item
                colon={false}
                label={language.MANAGE_NEWS_EXCERPT}
                labelAlign="left"
                name={newsFormInitialField.News_Excerpt.name}
                rules={[
                  {
                    required:
                      newsFormInitialField.News_Excerpt.required || false,
                    message: "",
                  },
                ]}
              >
                <Input.TextArea placeholder={language.MANAGE_NEWS_EXCERPT} />
              </Form.Item>
              {/* <Form.Item
                colon={false}
                label={language.MANAGE_NEWS_EXCERPT_ENG}
                labelAlign='left'
                name={newsFormInitialField.News_Excerpt_Eng.name}
                rules={[{ required: newsFormInitialField.News_Excerpt_Eng.required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_NEWS_EXCERPT_ENG} />
              </Form.Item> */}
              <ManageNewsCategorySelect
                name={newsFormInitialField.News_Category.name}
                placeholder={language.MANAGE_NEWS_CATEGORY_PLACEHOLDER}
                label={language.MANAGE_NEWS_CATEGORY}
                form={form}
                initialValue={null}
                required={newsFormInitialField.News_Category.required}
              />
              <ManageNewsGroupSelect
                name={newsFormInitialField.News_Group.name}
                placeholder={language.MANAGE_NEWS_GROUP_PLACEHOLDER}
                label={language.MANAGE_NEWS_GROUP}
                required={newsFormInitialField.News_Group.required}
                value={category}
                onCountChange={setCount}
                initialValue={null}
                count={count}
                form={form}
              />
              <MpsLanguageSelect
                name={newsFormInitialField.Language.name}
                label={language.MANAGE_NEWS_LANGUAGE}
                required={newsFormInitialField.Language.required}
                hasInitialValue={true}
                form={form}
              />
              <ManageNewsSelect
                name={newsFormInitialField.Status.name}
                placeholder={language.MANAGE_NEWS_GROUP_PLACEHOLDER}
                label={language.MANAGE_NEWS_STATUS}
                hasInitialValue={true}
                required={newsFormInitialField.Status.required}
                form={form}
              />
              <MpsDatePicker
                label={language.MANAGE_NEWS_CREATED_DATE}
                name={newsFormInitialField.CreatedDate.name}
                value={Date.now()}
                required={newsFormInitialField.CreatedDate.required}
                disabled={true}
              />
            </Form>
          </TabPane>
          <TabPane tab={language.MANAGE_NEWS_IMAGE} key="imageInfo">
            <MpsUploadImage
              onChange={handleImagesChange}
              images={newsData.News_Images}
              max={1}
            />
          </TabPane>
          <TabPane
            tab={language.MANAGE_NEWS_ADDITIONAL_INFO}
            key="additionalInfo"
          >
            <MpsRichTextEditor
              onChange={handleAdditionalInfoChange}
              data={newsData.News_AdditionalInfo}
            />
          </TabPane>
          <TabPane tab="Metadata" key="metadata">
            <Metadata metadata={metadata} onChange={handleChangeMetadata} />
          </TabPane>
          {/* <TabPane tab={language.MANAGE_NEWS_ADDITIONAL_INFO_ENG} key='additionalInfo_eng'>
            <MpsRichTextEditor onChange={handleAdditionalInfoEngChange} data={newsData.News_AdditionalInfo_Eng} />
          </TabPane> */}
        </Tabs>
      </Modal>
    </>
  );
}

export default React.memo(ManageNewsAdd);


import banner1 from '../../../asset/img/001_Banner/banner-1.jpg';
import banner2 from '../../../asset/img/001_Banner/banner-2.jpg';
import banner3 from '../../../asset/img/001_Banner/banner-3.jpg';
import banner4 from '../../../asset/img/001_Banner/banner-4.jpg';

// Banner Tablet
import bannerTablet1 from '../../../asset/img/001_Banner/banner_tienlen-v3-tablet.jpg';
import bannerTablet2 from '../../../asset/img/001_Banner/banner_tienlen-v3-tablet-2.jpg';
import bannerTablet3 from '../../../asset/img/001_Banner/banner_tienlen-v3-tablet-3.jpg';
import bannerTablet4 from '../../../asset/img/001_Banner/banner_tienlen-v3-tablet-4.jpg';

// Banner Mobile
import bannerMobile1 from '../../../asset/img/001_Banner/banner_tienlen-v3-mobile.jpg';
import bannerMobile2 from '../../../asset/img/001_Banner/banner_tienlen-v3-mobile-2.jpg';
import bannerMobile3 from '../../../asset/img/001_Banner/banner_tienlen-v3-mobile-3.jpg';
import bannerMobile4 from '../../../asset/img/001_Banner/banner_tienlen-v3-mobile-4.jpg';

// Intro thumbnail
import intro1 from '../../../asset/img/001_Thumbnail/Gioithieu_thumbnail_1.png';
import intro2 from '../../../asset/img/001_Thumbnail/Gioithieu_thumbnail_2.png';
import intro3 from '../../../asset/img/001_Thumbnail/Gioithieu_thumbnail_3.png';
import intro4 from '../../../asset/img/001_Thumbnail/Gioithieu_thumbnail_5.png';
import intro5 from '../../../asset/img/001_Thumbnail/Gioithieu_thumbnail_4.png';
import intro6 from '../../../asset/img/001_Thumbnail/Gioithieu_thumbnail_6.png';

// Intro mobile background
import introMobile1 from '../../../asset/img/001_Thumbnail/thumbnail-mobile-01.png';
import introMobile2 from '../../../asset/img/001_Thumbnail/thumbnail-mobile-02.png';
import introMobile3 from '../../../asset/img/001_Thumbnail/thumbnail-mobile-03.png';
import introMobile4 from '../../../asset/img/001_Thumbnail/thumbnail-mobile-04.png';
import introMobile5 from '../../../asset/img/001_Thumbnail/thumbnail-mobile-05.png';
import introMobile6 from '../../../asset/img/001_Thumbnail/thumbnail-mobile-06.png';


// Shareholder thumbnail
import share1 from '../../../asset/img/001_Thumbnail/Tincodong_thumbnail_1.png';
import share2 from '../../../asset/img/001_Thumbnail/Tincodong_thumbnail_2.png';
import share3 from '../../../asset/img/001_Thumbnail/Tincodong_thumbnail_3.png';

import prize1 from '../../../asset/img/001_Giai thuong/bang-cap-11_11.jpg';
import prize2 from '../../../asset/img/001_Giai thuong/bang-cap-11_04.jpg';
import prize3 from '../../../asset/img/001_Giai thuong/bang-cap-11_07.jpg';
import prize4 from '../../../asset/img/001_Giai thuong/bang-cap-11_05.jpg';

import standard from '../../../asset/img/tieuchuan.png';

export const HomeCommon = {
  vi: {
    title: 'Trang chủ',
    hiddenTitle: 'Thép Tiến Lên',
    hiddenSubTitle: 'chung tay kiến tạo',
    buttonSeeMore: 'Xem thêm',
    buttonDownloadDocument: 'Tải tài liệu',
    buttonDetail: 'Chi tiết',
    childTitleStandard: 'TIÊU CHUẨN CHẤT LƯỢNG',
    buttonBanner: 'Tìm hiểu thêm',
    childTitlePrize: 'CÁC GIẢI THƯỞNG',
    path: '/',
    slider: [
      {
        title: 'THÉP TIẾN LÊN',
        subtitle: 'CHUNG TAY KIẾN TẠO TƯƠNG LAI',
        background: banner1,
        backgroundTablet: bannerTablet1,
        backgroundMobile: bannerMobile1
      },
      // {
      //   title: 'THÉP TIẾN LÊN',
      //   subtitle: 'CHUNG TAY KIẾN TẠO TƯƠNG LAI',
      //   background: banner2,
      //   backgroundTablet: bannerTablet2,
      //   backgroundMobile: bannerMobile2
      // },
      // {
      //   title: 'THÉP TIẾN LÊN',
      //   subtitle: 'CHUNG TAY KIẾN TẠO TƯƠNG LAI',
      //   background: banner3,
      //   backgroundTablet: bannerTablet3,
      //   backgroundMobile: bannerMobile3
      // },
      // {
      //   title: 'THÉP TIẾN LÊN',
      //   subtitle: 'CHUNG TAY KIẾN TẠO TƯƠNG LAI',
      //   background: banner4,
      //   backgroundTablet: bannerTablet4,
      //   backgroundMobile: bannerMobile4
      // },
    ],
    childTitleStandardImage: [
      {
        src: standard
      },
    ],
    childTitlePrizeImage: [
      {
        src: prize1,
        width: 110
      },
      {
        src: prize2,
        width: 106
      },
      {
        src: prize3,
        width: 111,
      },
      {
        src: prize4,
        width: 163,
      }
    ],
    sliderIntro: {
      title: 'GIỚI THIỆU VỀ TẬP ĐOÀN',
      main: [
        {
          path: '/intro/about',
          title: 'TỔNG QUAN VỀ TẬP ĐOÀN',
          background: intro1,
          mobilebg: introMobile1,
        },
        {
          path: '/intro/field',
          title: 'LĨNH VỰC KINH DOANH',
          background: intro2,
          mobilebg: introMobile2,
        },
        {
          path: '/intro/organization',
          title: 'SƠ ĐỒ TỔ CHỨC',
          background: intro3,
          mobilebg: introMobile3,
        },
        {
          path: '/intro/agency',
          title: 'ĐƠN VỊ TRỰC THUỘC',
          background: intro4,
          mobilebg: introMobile4,
        },
        {
          path: '/intro/achieve',
          title: 'THÀNH TÍCH - CHỨNG NHẬN',
          background: intro5,
          mobilebg: introMobile5,
        },
        {
          path: '/intro/values',
          title: 'Lịch sử hình thành - Tầm nhìn - Giá trị cốt lõi',
          background: intro6,
          mobilebg: introMobile6,
        }
      ]
    },
    ShareHolderNewsSample: {
      title: 'TIN CỔ ĐÔNG',
      main: [
        {
          img: share1,
        },
        {
          img: share2,
        },
        {
          img: share3,
        }
      ]
    },
    NewsEvent: {
      title: 'TIN TỨC & SỰ KIỆN',
      main: [
        {
          category: 'Tin khác',
          title: 'Giá thép xây dựng hôm nay 28/5: Bật tăng trở lại trên sàn giao dịch Thượng Hải',
          createdAt: '29/05/2021 - 08:41',
          path: '/abx'
        },
        {
          category: 'Tin Thị trường sắt thép',
          title: 'Nhôm, thép Việt sắp khó \'lọt cửa\' vào Hoa Kỳ?',
          createdAt: '29/05/2021 - 08:41',
          path: '/abx'
        }, {
          category: 'Tin khác',
          title: 'Giá thép xây dựng hôm nay 28/5: Bật tăng trở lại trên sàn giao dịch Thượng Hải',
          createdAt: '29/05/2021 - 08:41',
          path: '/abx'
        }
      ]
    }
  },
  en: {
    title: 'Home',
    hiddenTitle: 'Tien len Steel',
    hiddenSubTitle: 'Constructing a future',
    buttonSeeMore: 'See more',
    buttonDownloadDocument: 'Download Documents',
    buttonDetail: 'Details',
    childTitleStandard: 'Quality certificate',
    buttonBanner: 'Learn more',
    childTitlePrize: 'Awards and Honors',
    path: '/',
    slider: [
      {
        title: 'Tien Len Steel',
        subtitle: 'Constructing a future with you',
        background: banner1,
        backgroundTablet: bannerTablet1,
        backgroundMobile: bannerMobile1
      },
      // {
      //   title: 'Tien Len Steel',
      //   subtitle: 'Constructing a future with you',
      //   background: banner2,
      //   backgroundTablet: bannerTablet2,
      //   backgroundMobile: bannerMobile2
      // },
      // {
      //   title: 'Tien Len Steel',
      //   subtitle: 'Constructing a future with you',
      //   background: banner3,
      //   backgroundTablet: bannerTablet3,
      //   backgroundMobile: bannerMobile3
      // },
      // {
      //   title: 'Tien Len Steel',
      //   subtitle: 'Constructing a future with you',
      //   background: banner4,
      //   backgroundTablet: bannerTablet4,
      //   backgroundMobile: bannerMobile4
      // },
    ],
    childTitleStandardImage: [
      {
        src: standard
      },
    ],
    childTitlePrizeImage: [
      {
        src: prize1,
        width: 110
      },
      {
        src: prize2,
        width: 106
      },
      {
        src: prize3,
        width: 111,
      },
      {
        src: prize4,
        width: 163,
      }
    ],
    sliderIntro: {
      title: 'About us',
      main: [
        {
          path: '/intro/about',
          title: 'General Introduction',
          background: intro1,
          mobilebg: introMobile1,
        },
        {
          path: '/intro/field',
          title: 'Business Areas',
          background: intro2,
          mobilebg: introMobile2,
        },
        {
          path: '/intro/organization',
          title: 'Organization Chart',
          background: intro3,
          mobilebg: introMobile3,
        },
        {
          path: '/intro/agency',
          title: 'Affiliated companies',
          background: intro4,
          mobilebg: introMobile4,
        },
        {
          path: '/intro/achieve',
          title: 'Awards and Honors',
          background: intro5,
          mobilebg: introMobile5,
        },
        {
          path: '/intro/values',
          title: 'Milestone, Vision, Core Values',
          background: intro6,
          mobilebg: introMobile6,
        }
      ]
    },
    ShareHolderNewsSample: {
      title: 'Shareholder news',
      main: [
        {
          img: share1,
        },
        {
          img: share2,
        },
        {
          img: share3,
        }
      ]
    },
    NewsEvent: {
      title: 'News and Media',
      main: [
        {
          category: 'Tin khác',
          title: 'Giá thép xây dựng hôm nay 28/5: Bật tăng trở lại trên sàn giao dịch Thượng Hải',
          createdAt: '29/05/2021 - 08:41',
          path: '/abx'
        },
        {
          category: 'Tin Thị trường sắt thép',
          title: 'Nhôm, thép Việt sắp khó \'lọt cửa\' vào Hoa Kỳ?',
          createdAt: '29/05/2021 - 08:41',
          path: '/abx'
        }, {
          category: 'Tin khác',
          title: 'Giá thép xây dựng hôm nay 28/5: Bật tăng trở lại trên sàn giao dịch Thượng Hải',
          createdAt: '29/05/2021 - 08:41',
          path: '/abx'
        }
      ]
    }
  }
};


import React, { useEffect, useState } from 'react';
import { Table } from 'antd';

export default function AdminTable(props) {
    const [data, setData] = useState([]);
    useEffect(() => {
        function handleChangeValuetoTitle() {
            const attributeSelect = Object.keys(props.select);
            const dataChange = props.data.map(item => {
                let newData;
                for (let i = 0; i < attributeSelect.length; i++) {

                    newData = {
                        ...newData,
                        [attributeSelect[i]]: props.select[attributeSelect[i]].find(itemSelect => {
                            return itemSelect.value === item[attributeSelect[i]];
                        })?.title
                    };
                }
                return {
                    ...item,
                    ...newData,
                };
            });
            setData(dataChange);
        }
        if (!props.select) return;
        handleChangeValuetoTitle();
    }, [props.select, props.data]);

    useEffect(() => {
        if (!props.dataSource) return;
        setData(props.dataSource);
    }, [props.dataSource]);
    return (
        <>
            <Table
                columns={props.columns || props.columnsData()}
                dataSource={data}
                className='admin-table'
                rowKey={props.rowKey || '_id'}
                scroll={{ x: 'max-content' }}
                pagination={{
                    total: props.total || data.length,
                    current: props.current || 1,
                    onChange: (page, size) => {
                        props.changePage(page, size);
                    },
                    showSizeChanger: true,
                    position: ['bottomCenter'],
                }}
            />
        </>
    );
}

import React, { useState, useEffect, useCallback } from 'react';
import { Form, Button } from 'antd';
import Helmet from '../../Layout/Helmet';
import AdminBreadcrum from '../Layout/AdminBreadcrumb';
import AdminTable from '../Layout/AdminTable';
import ManageRecruitmentSearch from './ManageRecruitmentSearch';
import ManageRecruitmentAdd from './ManageRecruitmentAdd';
import { ManageRecruitmentService } from './Service/ManageRecruitment.Service';
import { RESULT_CODES } from '../../../common/Constant.Common';
import { LANGUAGE_STATE } from './Language/Language';
import { columns } from '../ManageRecruitment/Model/ManageRecruitment.Model';
import './Css/Style.css';

export const ManageRecruitmentContext = React.createContext();
export const ManageRecruitmentLanguageContext = React.createContext();

function ManageRecruitmentPage() {
  const [form] = Form.useForm();
  const [searchOption, setSearchOption] = useState({ page: 1, limit: 10 });
  const [dataSource, setDataSource] = useState([]);
  const [language] = useState(LANGUAGE_STATE['vi']);

  const clearSearchForm = () => {
    form.resetFields();
  };

  const handleSearch = useCallback(() => {
    const searchModel = form.getFieldsValue();
    const sortAscending = form.getFieldValue('Recruitment_Sort');
    ManageRecruitmentService.search(searchModel, { ...searchOption, sortAscending }).then(result => {
      if (result.returnCode !== RESULT_CODES.SUCCESS) return;

      const { pages } = result.result;
      if (pages >= searchOption.page) {
        setDataSource(result.result);
      } else {
        setSearchOption(prevState => ({ ...prevState, page: 1 }));
      }
    })
      .catch(() => {
        // TO DO
      });
  }, [form, searchOption]);

  const handlePagination = (currentPage, pageSizes) => {
    setSearchOption({ page: currentPage, limit: pageSizes });
  };

  useEffect(() => {
    handleSearch();
  }, [handleSearch, searchOption]);
  return (
    <ManageRecruitmentLanguageContext.Provider value={language}>
      <Helmet title={language.MANAGE_RECRUITMENT}>
        <AdminBreadcrum title={language.MANAGE_RECRUITMENT} subKey = 'sub3'/>
        <ManageRecruitmentContext.Provider value={handleSearch}>
          <div className='admin-content'>
            <Form form={form} onFinish={handleSearch} labelCol={{ lg: { span: 8 } }} wrapperCol={{ lg: { span: 14 } }} >
              <ManageRecruitmentSearch />
              <div className='admin-button admin-button-search'>
                <Button type='secondary' onClick={clearSearchForm} className='admin-button__main admin-button__delete'>
                  {language.MANAGE_RECRUITMENT_CLEAR_SELECT}
                </Button>
                <Button type='primary' htmlType='submit' onClick={handleSearch} className='admin-button__main admin-button__save'>
                  {language.MANAGE_RECRUITMENT_SEARCH}
                </Button>
              </div>
            </Form>

            <div className='admin-button'>
              <ManageRecruitmentAdd />
            </div>

            <AdminTable
              dataSource={dataSource.docs || []}
              columns={columns(language)}
              current={searchOption.page}
              changePage={handlePagination}
              rowKey='_id'
              total={dataSource.total || 0}
            />
          </div>
        </ManageRecruitmentContext.Provider>
      </Helmet>
    </ManageRecruitmentLanguageContext.Provider>
  );
}

export default ManageRecruitmentPage;


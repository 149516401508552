import React, { useState } from 'react';
import { Button, Modal } from 'antd';

function ManageNewsDeleteConfirm({ onDelete, title, deleteLabel, agreeLabel, disagreeLabel }) {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };
  return (
    <>
      <Button
        key='cancel'
        type='danger'
        onClick={showModal}
        className='admin-button__main admin-button__main--red'
      >
        {deleteLabel}
      </Button>
      <Modal
        title={title}
        visible={visible}
        centered
        onCancel={hideModal}
        footer={
          [
            <Button
              key='cancel'
              type='secondary'
              onClick={onDelete}
              className='admin-button__main admin-button__delete'
            >
              {agreeLabel}
            </Button>,
            <Button
              key='submit'
              type='primary'
              onClick={hideModal}
              className='admin-button__main admin-button__save'
            >
              {disagreeLabel}
            </Button>
          ]
        }
      >
      </Modal>
    </>
  );
}

export default ManageNewsDeleteConfirm;

import React from 'react';
import AdminMenu from '../Layout/AdminMenu';
import { Row, Col, Form, Input } from 'antd';
import ManageUnitStatusSelect from './Control/ManageUnitStatusSelect';
import { ManageUnitLanguageContext } from './ManageUnitPage';
import MpsRangePicker from '../../MpsCotrols/MpsRangePicker/MpsRangePicker';
import MpsSortSelect from '../../MpsCotrols/MpsSelect/MpsSortSelect';

function ManageUnitSearch() {
  const language = React.useContext(ManageUnitLanguageContext);
  return (
    <AdminMenu title={language.MANAGE_UNIT_SEARCH_INFO}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 50 }}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_UNIT_CODE}
            name='Unit_Code'
          >
            <Input placeholder={language.MANAGE_UNIT_CODE} />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_UNIT_NAME_ENG}
            name='Unit_Name_Eng'
          >
            <Input placeholder={language.MANAGE_UNIT_NAME_ENG} />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_UNIT_NAME_VIE}
            name='Unit_Name'
          >
            <Input placeholder={language.MANAGE_UNIT_NAME_VIE} />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <ManageUnitStatusSelect label={language.MANAGE_UNIT_STATUS} name='Status'
            placeholder={language.MANAGE_UNIT_STATUS} />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsRangePicker onChange={()=>{}}  language='vi' label={language.MANAGE_UNIT_CREATED_DATE} name='CreatedDate' />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_UNIT_CREATED_PERSON}
            name='CreatedPerson'
          >
            <Input placeholder={language.MANAGE_UNIT_CREATED_PERSON} />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsRangePicker onChange={()=>{}} language='vi' label={language.MANAGE_UNIT_UPDATED_DATE} name='UpdatedDate' />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_UNIT_UPDATED_PERSON}
            name='UpdatedPerson'
          >
            <Input placeholder={language.MANAGE_UNIT_UPDATED_PERSON} />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsSortSelect name='Unit_Sort' />
        </Col>
      </Row>
    </AdminMenu>
  );
}

export default ManageUnitSearch

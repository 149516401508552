import { authHeader } from "../../../../helper/authHeader";

export const ManageNewsService = {
  search,
  create,
  getById,
  getBySlug,
  getImages,
  update,
  deleteModel,
  deleteImages,
};

function create(model) {
  const auth = authHeader();
  const headers = new Headers();
  headers.append("Authorization", auth);

  const requestOptions = {
    headers,
    method: "POST",
    body: model,
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/News/create`,
    requestOptions
  ).then(handleResponse);
}

function search(searchModel, searchOption) {
  const model = {
    searchModel,
    searchOption,
  };

  const auth = authHeader();
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", auth);

  const requestOptions = {
    headers,
    method: "POST",
    body: JSON.stringify(model),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/News/search`,
    requestOptions
  ).then(handleResponse);
}

function getById(_id) {
  const auth = authHeader();
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", auth);
  const requestOptions = {
    headers,
    method: "GET",
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/News/getById/${_id}`,
    requestOptions
  ).then(handleResponse);
}

function getBySlug(slug) {
  const auth = authHeader();
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", auth);
  const requestOptions = {
    headers,
    method: "GET",
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/News/getBySlug/${slug}`,
    requestOptions
  ).then(handleResponse);
}

function getImages(_id) {
  const auth = authHeader();
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", auth);
  const requestOptions = {
    headers,
    method: "GET",
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/News/images/${_id}`,
    requestOptions
  ).then(handleResponse);
}

function update(model) {
  const auth = authHeader();
  const headers = new Headers();
  headers.append("Authorization", auth);
  const requestOptions = {
    headers,
    method: "POST",
    body: model,
  };
  return fetch(
    process.env.REACT_APP_API_URL + `/News/update`,
    requestOptions
  ).then(handleResponse);
}

function deleteModel(model) {
  const auth = authHeader();
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", auth);
  const requestOptions = {
    headers,
    method: "POST",
    body: JSON.stringify(model),
  };
  return fetch(
    process.env.REACT_APP_API_URL + `/News/delete`,
    requestOptions
  ).then(handleResponse);
}

function deleteImages(model) {
  const auth = authHeader();
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", auth);

  const requestOptions = {
    headers,
    method: "POST",
    body: JSON.stringify(model),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/News/deleteImages`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

import React from 'react';
import { Pagination } from 'antd';
export default function PaginationMain({ current, total, onChange, pageSize }) {
    return (
        <div className="pagination-main">
            <Pagination
                onChange={onChange}
                showSizeChanger
                current={current}
                defaultCurrent={current}
                pageSize={pageSize || 10}
                total={total}
            />
        </div>
    );
}

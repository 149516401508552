import React, { useState, useCallback, useEffect } from "react";
import {
  ManageBannerContext,
  ManageNewsLanguageContext,
} from "./ManageBannerPage";
import { Button, Modal, Form, Tabs, Input } from "antd";
import {
  bannerInitData,
  newsFormInitialField,
} from "./Model/ManageBanner.Model";
import ManageBannerSelect from "./Control/ManageBannerSelect";
import ManageBannerTargetSelect from "./Control/ManageBannerTargetSelect";
import MpsUploadImageForBanner from "../../MpsCotrols/MpsUploadImage/MpsUploadImageForBanner";
import MpsRichTextEditor from "../../MpsCotrols/MpsRichTextEditor/MpsRichTextEditor";
import { ManageBannerService } from "./Service/ManageBanner.Service";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../Common/MethodCommon";
import { RESULT_CODES } from "../../../common/Constant.Common";
import ManageBannerDeleteConfirm from "./Control/ManageBannerDeleteConfirm";
import MpsDatePicker from "../../MpsCotrols/MpsDatePicker/MpsDatePicker";
import MpsLanguageSelect from "../../MpsCotrols/MpsSelect/MpsLanguageSelect";

const { TabPane } = Tabs;

function ManageBannerEdit(props) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [newsData, setNewsData] = useState({ ...bannerInitData });
  const [productOriginalImages_PC, setProductOriginalImages_PC] = useState([]);
  const [productOriginalImages_Tablet, setProductOriginalImages_Tablet] =
    useState([]);
  const [productOriginalImages_Mobile, setProductOriginalImages_Mobile] =
    useState([]);
  const [removedImages_PC, setRemovedImages_PC] = useState([]);
  const [removedImages_Tablet, setRemovedImages_Tablet] = useState([]);
  const [removedImages_Mobile, setRemovedImages_Mobile] = useState([]);
  const [addedImages_PC, setAddedImages_PC] = useState([]);
  const [addedImages_Tablet, setAddedImages_Tablet] = useState([]);
  const [addedImages_Mobile, setAddedImages_Mobile] = useState([]);

  const language = React.useContext(ManageNewsLanguageContext);
  const handleSearch = React.useContext(ManageBannerContext);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  // Xử lý lưu hình ảnh
  const handleImagesChange_PC = useCallback((images) => {
    setNewsData((prevState) => ({ ...prevState, Banner_Images_PC: images }));
  }, []);

  const handleImagesChange_Tablet = useCallback((images) => {
    setNewsData((prevState) => ({
      ...prevState,
      Banner_Images_Tablet: images,
    }));
  }, []);

  const handleImagesChange_Mobile = useCallback((images) => {
    setNewsData((prevState) => ({
      ...prevState,
      Banner_Images_Mobile: images,
    }));
  }, []);

  const handleAddImage_PC = useCallback((image) => {
    setAddedImages_PC([...image.fileList]);
  }, []);

  const handleAddImage_Tablet = useCallback((image) => {
    setAddedImages_Tablet([...image.fileList]);
  }, []);

  const handleAddImage_Mobile = useCallback((image) => {
    setAddedImages_Mobile([...image.fileList]);
  }, []);

  const handleRemoveImage_PC = useCallback(
    (image) => {
      //     const index = productOriginalImages_PC.findIndex(item => item.name === image.name);s
      //     if (index === -1) return;
      setRemovedImages_PC((prevState) => [...prevState, image]);
    },
    [productOriginalImages_PC]
  );

  const handleRemoveImage_Tablet = useCallback(
    (image) => {
      // const index = productOriginalImages_Tablet.findIndex(item => item.name === image.name);
      // if (index === -1) return;
      setRemovedImages_Tablet((prevState) => [...prevState, image]);
    },
    [productOriginalImages_Tablet]
  );

  const handleRemoveImage_Mobile = useCallback(
    (image) => {
      // const index = productOriginalImages_Mobile.findIndex(item => item.name === image.name);
      // if (index === -1) return;
      setRemovedImages_Mobile((prevState) => [...prevState, image]);
    },
    [productOriginalImages_Mobile]
  );

  const handleSloganChange = useCallback((text) => {
    setNewsData((prevState) => ({ ...prevState, Banner_Slogan: text }));
  }, []);

  const handleSloganEngChange = useCallback((text) => {
    setNewsData((prevState) => ({ ...prevState, Banner_Slogan_Eng: text }));
  }, []);

  useEffect(() => {
    if (!visible) return;

    ManageBannerService.getById(props._id).then((result) => {
      setNewsData(result.data[0] || { ...bannerInitData });
      setProductOriginalImages_PC(result?.data[0]?.Benner_Images_PC || []);
      setProductOriginalImages_Tablet(
        result?.data[0]?.Benner_ImagesTablet || []
      );
      setProductOriginalImages_Mobile(
        result?.data[0]?.Benner_ImagesMobile || []
      );
      form.resetFields();
    });
  }, [props._id, visible, form]);

  const handleFininsh = () => {
    form
      .validateFields()
      .then((values) => {
        const formData = new FormData();

        // Append images to form data
        newsData.Banner_Images_PC.forEach((image) => {
          formData.append("Banner_Old_Images_PC", JSON.stringify(image));
        });

        newsData.Banner_Images_Tablet.forEach((image) => {
          formData.append("Banner_Old_Images_Tablet", JSON.stringify(image));
        });

        newsData.Banner_Images_Mobile.forEach((image) => {
          formData.append("Banner_Old_Images_Mobile", JSON.stringify(image));
        });

        // Append new images to form data
        addedImages_PC.forEach((image) => {
          formData.append("Banner_Images_PC", image.originFileObj);
        });

        addedImages_Tablet.forEach((image) => {
          formData.append("Banner_Images_Tablet", image.originFileObj);
        });

        addedImages_Mobile.forEach((image) => {
          formData.append("Banner_Images_Mobile", image.originFileObj);
        });

        // Append removed images to form data
        removedImages_PC.forEach((image) => {
          formData.append("Banner_Removed_Images_PC", image.name);
        });
        removedImages_Tablet.forEach((image) => {
          formData.append("Banner_Removed_Images_Tablet", image.name);
        });
        removedImages_Mobile.forEach((image) => {
          formData.append("Banner_Removed_Images_Mobile", image.name);
        });
        // Append values to form data
        const formObjectValues = Object.entries(values);

        formObjectValues.forEach((value) => {
          formData.append(value[0], value[1]);
        });
        formData.append("_id", newsData._id);
        formData.append("Banner_Slogan", newsData.Banner_Slogan);
        formData.append("Banner_Slogan_Eng", newsData.Banner_Slogan_Eng);
        return ManageBannerService.update(formData);
      })
      .then((result) => {
        if (result.returnCode !== RESULT_CODES.SUCCESS)
          throw new Error(result.returnCode);

        handleSearch();
        hideModal();
        setAddedImages_PC([]);
        setAddedImages_Tablet([]);
        setAddedImages_Mobile([]);

        // Xóa hình ảnh
        return ManageBannerService.deleteImages({
          Banner_Images_PC: removedImages_PC,
          Banner_Images_Tablet: removedImages_Tablet,
          Banner_Images_Mobile: removedImages_Mobile,
        });
      })
      .then((result) => {
        if (result.returnCode !== RESULT_CODES.SUCCESS)
          throw new Error(result.returnCode);

        setRemovedImages_PC([]);
        setRemovedImages_Tablet([]);
        setRemovedImages_Mobile([]);
        handleSearch();
        openSuccessNotification({
          title: "Thông báo",
          message: "Cập nhật thành công",
        });
        hideModal();
      })
      .catch((error) => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({
            title: "Thông báo",
            message: "Cập nhật thất bại",
          });
      });
  };

  const handleDelete = () => {
    const model = { _id: props._id };
    ManageBannerService.deleteModel(model)
      .then((result) => {
        if (result.returnCode !== RESULT_CODES.SUCCESS)
          throw new Error(result.returnCode);
        // Xóa hình ảnh
        return ManageBannerService.deleteImages({
          Banner_Images_PC: newsData.Banner_Images_PC,
          Banner_Images_Tablet: newsData.Banner_Images_Tablet,
          Banner_Images_Mobile: newsData.Banner_Images_Mobile,
        });
      })
      .then((result) => {
        if (result.returnCode !== RESULT_CODES.SUCCESS)
          throw new Error(result.returnCode);

        openSuccessNotification({
          title: "Thông báo",
          message: "Xóa dữ liệu thành công",
        });
        hideModal();
        handleSearch();
      })
      .catch((error) => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({
            title: "Thông báo",
            message: "Xóa dữ liệu thất bại",
          });
      });
  };

  return (
    <>
      <p className="admin-button-detail" onClick={showModal}>
        Xem chi tiết
      </p>

      <Modal
        title={language.MANAGE_NEWS_VIEW_TITLE}
        visible={visible}
        width="80%"
        className="manage-news-modal admin-modal"
        onCancel={hideModal}
        footer={[
          <ManageBannerDeleteConfirm
            key="cancel"
            title={language.MANAGE_NEWS_CONFIRM_DELETE}
            deleteLabel={language.MANAGE_NEWS_DELETE}
            agreeLabel={language.MANAGE_NEWS_AGREE}
            disagreeLabel={language.MANAGE_NEWS_DISAGREE}
            onDelete={handleDelete}
          />,
          <Button
            key="submit"
            type="primary"
            onClick={handleFininsh}
            className="admin-button__main admin-button__save"
          >
            {language.MANAGE_NEWS_SAVE}
          </Button>,
        ]}
      >
        <Tabs className="manage-news-tabs" defaultActiveKey="generalInfo">
          <TabPane tab="Thông tin chung" key="generalInfo">
            <Form
              form={form}
              requiredMark={false}
              id="manage-news-form"
              // onValuesChange={handleFormValuesChange}
              className="manage-news-form"
              name="manage-news-form"
              labelCol={{ lg: { span: 3 }, md: { span: 24 } }}
              wrapperCol={{ lg: { span: 8 }, md: { span: 24 } }}
            >
              <Form.Item
                colon={false}
                label={language.MANAGE_NEWS_TITLE}
                labelAlign="left"
                initialValue={newsData.Banner_Title}
                name={newsFormInitialField.Banner_Title.name}
                rules={[
                  {
                    required:
                      newsFormInitialField.Banner_Title.required || false,
                    message: "",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder={language.MANAGE_NEWS_TITLE_PLACEHOLDER}
                  maxLength={200}
                />
              </Form.Item>
              {/* <Form.Item
                colon={false}
                label={language.MANAGE_NEWS_TITLE_ENG}
                labelAlign='left'
                initialValue={newsData.Banner_Title_Eng}
                name={newsFormInitialField.Banner_Title_Eng.name}
                rules={[{ required: newsFormInitialField.Banner_Title_Eng.required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_NEWS_TITLE_PLACEHOLDER} maxLength={200} />
              </Form.Item> */}
              {/* <Form.Item
                colon={false}
                label={language.MANAGE_BANNER_SLOGAN}
                initialValue={newsData.Banner_Slogan}
                labelAlign='left'
                name={newsFormInitialField.Banner_Slogan.name}
                rules={[{ required: newsFormInitialField.Banner_Slogan.required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_BANNER_SLOGAN}/>
              </Form.Item>
              <Form.Item
                colon={false}
                label={language.MANAGE_BANNER_SLOGAN_ENG}
                initialValue={newsData.Banner_Slogan_Eng}
                labelAlign='left'
                name={newsFormInitialField.Banner_Slogan_Eng.name}
                rules={[{ required: newsFormInitialField.Banner_Slogan_Eng.required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_BANNER_SLOGAN_ENG}/>
              </Form.Item> */}
              <ManageBannerTargetSelect
                name={newsFormInitialField.Banner_Target.name}
                placeholder={language.MANAGE_BANNER_TARGET_PLACEHOLDER}
                label={language.MANAGE_BANNER_TARGET}
                hasInitialValue={true}
                initialValue={newsData.Banner_Target}
                required={newsFormInitialField.Banner_Target.required}
                form={form}
              />
              <Form.Item
                colon={false}
                label={language.MANAGE_BANNER_LINK}
                labelAlign="left"
                initialValue={newsData.Banner_Link}
                name={newsFormInitialField.Banner_Link.name}
                rules={[
                  {
                    required:
                      newsFormInitialField.Banner_Link.required || false,
                    message: "",
                  },
                ]}
              >
                <Input.TextArea placeholder={language.MANAGE_BANNER_LINK} />
              </Form.Item>
              {/* <ManageNewsCategorySelect
                name={newsFormInitialField.Banner_Category.name}
                placeholder={language.MANAGE_NEWS_CATEGORY_PLACEHOLDER}
                label={language.MANAGE_NEWS_CATEGORY}
                form={form}
                initialValue={newsData.Banner_Category}
                required={newsFormInitialField.Banner_Category.required}
              />
              <ManageNewsGroupSelect
                name={newsFormInitialField.Banner_Group.name}
                placeholder={language.MANAGE_NEWS_GROUP_PLACEHOLDER}
                label={language.MANAGE_NEWS_GROUP}
                required={newsFormInitialField.Banner_Group.required}
                form={form}
                value={category}
                initialValue={newsData.Banner_Group}
              /> */}
              <MpsLanguageSelect
                name={newsFormInitialField.Language.name}
                label={language.MANAGE_BANNER_LANGUAGE}
                hasInitialValue={true}
                initialValue={newsData.Language}
                form={form}
                required={newsFormInitialField.Language.required}
              />

              <ManageBannerSelect
                name={newsFormInitialField.Status.name}
                placeholder={language.MANAGE_NEWS_GROUP_PLACEHOLDER}
                label={language.MANAGE_NEWS_STATUS}
                hasInitialValue={true}
                initialValue={newsData.Status}
                required={newsFormInitialField.Status.required}
                form={form}
                // dataSource={['Hiển thị', 'Ẩn']}
              />
              <MpsDatePicker
                label={language.MANAGE_NEWS_CREATED_DATE}
                name={newsFormInitialField.CreatedDate.name}
                value={newsData.CreatedDate}
                required={newsFormInitialField.CreatedDate.required}
                disabled={false}
              />
            </Form>
          </TabPane>
          <TabPane tab={language.MANAGEBANNER_SLOGAN} key="addslogan">
            <div style={{ marginBottom: "20px" }}>
              {language.MANAGE_BANNER_SLOGAN}
            </div>
            <MpsRichTextEditor
              onChange={handleSloganChange}
              data={newsData.Banner_Slogan}
            />
            {/* <div style={{ margin: '20px 0px 20px 0px' }}>{language.MANAGE_BANNER_SLOGAN_ENG}</div>
            <MpsRichTextEditor onChange={handleSloganEngChange} data={newsData.Banner_Slogan_Eng} /> */}
          </TabPane>
          <TabPane tab={language.MANAGE_NEWS_IMAGE} key="imageInfo">
            <div
              style={{
                display: "flex",
                marginTop: "50px",
                textAlign: "center",
              }}
            >
              <MpsUploadImageForBanner
                isConvertUrl
                images={newsData.Banner_Images_PC}
                onChange={handleImagesChange_PC}
                onRemove={handleRemoveImage_PC}
                onAddImage={handleAddImage_PC}
                title={language.MANAGE_NEWS_UPLOAD_IMAGE}
                max={1}
                for="PC"
              />
              <MpsUploadImageForBanner
                isConvertUrl
                images={newsData.Banner_Images_Tablet}
                onChange={handleImagesChange_Tablet}
                onRemove={handleRemoveImage_Tablet}
                onAddImage={handleAddImage_Tablet}
                title={language.MANAGE_NEWS_UPLOAD_IMAGE}
                max={1}
                for="Tablet"
              />
              <MpsUploadImageForBanner
                isConvertUrl
                images={newsData.Banner_Images_Mobile}
                onChange={handleImagesChange_Mobile}
                onRemove={handleRemoveImage_Mobile}
                onAddImage={handleAddImage_Mobile}
                title={language.MANAGE_NEWS_UPLOAD_IMAGE}
                max={1}
                for="Mobile"
              />
            </div>
          </TabPane>
          {/* <TabPane tab={language.MANAGE_NEWS_ADDITIONAL_INFO} key='additionalInfo'>
            <MpsRichTextEditor onChange={handleAdditionalInfoChange} data={newsData.News_AdditionalInfo} />
          </TabPane>
          <TabPane tab={language.MANAGE_NEWS_ADDITIONAL_INFO_ENG} key='additionalInfo_eng'>
            <MpsRichTextEditor onChange={handleAdditionalInfoEngChange} data={newsData.News_AdditionalInfo_Eng} />
          </TabPane> */}
        </Tabs>
      </Modal>
    </>
  );
}

export default React.memo(ManageBannerEdit);

import React, { useCallback, useEffect, useState } from 'react';
import Helmet from '../../Layout/Helmet';
import AdminBreadcrum from '../Layout/AdminBreadcrumb';
import { Form, Button } from 'antd';
import AdminTable from '../Layout/AdminTable';
import { RelationDisplayTable, RelationAttribute, RelationInitData, RelationPlaceholder, RelationCommon } from './Model/ManageRelationModel';
import { ManageRelationService } from './Service/ManageRelationService';
import ManageRelationSearch from './ManageRelationSearch';
import ManageRelationAdd from './ManageRelationAdd';
import { LayoutAdminCommon } from '../Layout/Model/LayoutAdminModel';

export default function ManageRelationPage() {
  const [dataRelation, setDataRelation] = useState(null);
  const [searchOption, setSearchOption] = useState({ page: 1, limit: 10, sort: ['CreatedAt'] });
  const [signChanged, setSignChanged] = useState(false);
  const [visibleModalCreate, setVisibleModalCreate] = useState(false);
  const [initModelRelation, setInitModelRelation] = useState(null);
  const [form] = Form.useForm();

  const handleSearch = useCallback(async () => {
    const searchModel = form.getFieldsValue();
    const sortAscending = form.getFieldValue('Relation_Sort');
    const data = await ManageRelationService.search(searchModel, { ...searchOption, sortAscending: sortAscending || false });

    const { pages } = data.result;
    if (pages >= searchOption.page) {
      setDataRelation(data.result);
    } else {
      setSearchOption(prevState => ({ ...prevState, page: 1 }));
    }
  }, [form, searchOption]);

  const handlePagination = (currentPage, pageSizes) => {
    setSearchOption(prevState => ({ ...prevState, page: currentPage, limit: pageSizes }));
  };

  const handleSignChanged = () => {
    setSignChanged(prevSign => !prevSign);
  };

  const onHandleClearFormSearch = () => {
    form.resetFields();
  };

  const handleCloseModalCreate = () => {
    setVisibleModalCreate(false);
  };

  useEffect(() => {
    ManageRelationService.getRelation_Category().then(data => {
      const initData = {
        ...RelationInitData,
        Select:
        {
          ...RelationInitData.Select,
          Relation_Type: data.result.map((item) => {
            return {
              value: item._id,
              title: item.Relation_Category_Title
            };
          }),
        }
      };
      setInitModelRelation(initData);
    });
  }, []);

  useEffect(() => {
    handleSearch();
  }, [handleSearch, signChanged]);

  return dataRelation && initModelRelation && (
    <Helmet title={RelationCommon.Title}>
      <AdminBreadcrum title={RelationCommon.Title} subKey = 'sub4'></AdminBreadcrum>
      <div className='admin-content'>
        <Form
          form={form}
          onFinish={handleSearch}
        >
          <ManageRelationSearch
            initModelRelation={initModelRelation}
            RelationAttribute={RelationAttribute}
            RelationPlaceholder={RelationPlaceholder}
          >
          </ManageRelationSearch>
          <div className='admin-button admin-button-search'>
            <Button type='secondary' className='admin-button__main admin-button__delete' onClick={onHandleClearFormSearch}>
              {LayoutAdminCommon.buttonDeleteSearch}
            </Button>
            <Button type='primary' htmlType='submit' className='admin-button__main admin-button__save'>
              {LayoutAdminCommon.buttonSaveSearch}
            </Button>
          </div>
        </Form>
        <div className='admin-button'>
          <Button htmlType='submit' className='admin-button__main admin-button__download-pdf' onClick={() => setVisibleModalCreate(true)}>
            <i className='fas fa-file-upload'></i>
            {RelationCommon.TitleCreateModal}
          </Button>
        </div>
        <AdminTable
          data={dataRelation.docs}
          columnsData={() => RelationDisplayTable(handleSignChanged, initModelRelation)}
          changePage={handlePagination}
          select={initModelRelation.Select}
          total={dataRelation.total || 0}
          current={dataRelation.page}
        >
        </AdminTable>
        <ManageRelationAdd
          initModelRelation={initModelRelation}
          data={RelationInitData}
          visible={visibleModalCreate}
          onClose={handleCloseModalCreate}
          signChanged={handleSignChanged}
        />
      </div>
    </Helmet>
  );
}

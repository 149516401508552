export const LANGUAGE_STATE = {
  'vi': {
    MANAGE_PRODUCT_TYPE: 'Chủng loại sản phẩm',
    MANAGE_PRODUCT_TYPE_SEARCH_INFO: 'Thông tin tìm kiếm',
    MANAGE_PRODUCT_TYPE_CODE: 'Mã chủng loại',
    MANAGE_PRODUCT_TYPE_NAME: 'Tên chủng loại',
    MANAGE_PRODUCT_TYPE_CATEGORY: 'Phân loại',
    MANAGE_PRODUCT_TYPE_CREATED_DATE: 'Ngày tạo',
    MANAGE_PRODUCT_TYPE_CREATED_PERSON: 'Người tạo',
    MANAGE_PRODUCT_TYPE_UPDATED_DATE: 'Ngày cập nhật',
    MANAGE_PRODUCT_TYPE_UPDATED_PERSON: 'Người cập nhật',
    MANAGE_PRODUCT_TYPE_SEARCH_FROM_DATE: 'Từ ngày',
    MANAGE_PRODUCT_TYPE_SEARCH_TO_DATE: 'Đến ngày',
    MANAGE_PRODUCT_TYPE_STATUS: 'Trạng thái',

    MANAGE_PRODUCT_TYPE_NAME_VIE: 'Tên chủng loại tiếng Việt',
    MANAGE_PRODUCT_TYPE_NAME_VIE_PLACEHOLDER: 'Tên chủng loại tiếng Việt (tối đa 200 ký tự)',
    MANAGE_PRODUCT_TYPE_NAME_ENG: 'Tên chủng loại tiếng Anh',
    MANAGE_PRODUCT_TYPE_NAME_ENG_PLACEHOLDER: 'Tên chủng loại tiếng Anh (tối đa 200 ký tự)',
    MANAGE_PRODUCT_TYPE_CATEGORY_PLACEHOLDER: 'Chọn phân loại',
    MANAGE_PRODUCT_TYPE_STATUS_PLACEHOLDER: 'Chọn trạng thái',
    MANAGE_PRODUCT_TYPE_IMAGES: 'Hình ảnh',
    MANAGE_PRODUCT_TYPE_DETAIL_INFO: 'Thông tin chủng loại Tiếng Việt',
    MANAGE_PRODUCT_TYPE_DETAIL_INFO_ENG: 'Thông tin chủng loại Tiếng Anh',

    MANAGE_PRODUCT_TYPE_CREATE_NEW: 'Đăng chủng loại mới',
    MANAGE_PRODUCT_TYPE_EDIT: 'Xem thông tin chủng loại',
    MANAGE_PRODUCT_TYPE_GENERAL_INFO: 'Thông tin chung',
    MANAGE_PRODUCT_TYPE_SELECT_DATE: 'Chọn ngày',
    MANAGE_PRODUCT_TYPE_VIEW_DETAIL: 'Xem chi tiết',

    // ACTION
    MANAGE_PRODUCT_TYPE_CANCEL: 'Huỷ',
    MANAGE_PRODUCT_TYPE_DELETE: 'Xóa',
    MANAGE_PRODUCT_TYPE_SAVE: 'Lưu',
    MANAGE_PRODUCT_TYPE_AGREE: 'Có',
    MANAGE_PRODUCT_TYPE_DISAGREE: 'Không',
    MANAGE_PRODUCT_TYPE_CLEAR_SELECT: 'Xóa lựa chọn',
    MANAGE_PRODUCT_TYPE_SEARCH: 'Tìm kiếm',

    // MESSAGE
    MANAGE_PRODUCT_TYPE_CONFIRM_DELETE: 'Bạn có chắc chắn muốn xóa dữ liệu này ?',

  },
  'en': {

  },
};

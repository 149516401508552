import React from "react";
import "moment/locale/vi";
import { Form, DatePicker } from "antd";
import locale from "antd/lib/date-picker/locale/vi_VN";
import { NEWS_LANGUAGE } from "../../News/Language/Language.js";
function MpsRangePicker(props) {
  const handleChange = (e) => {
    props.onChange(e);
  };
  return (
    <Form.Item
      name={props.name}
      label={props.label || ""}
      labelAlign="left"
      colon={false}
    >
      <DatePicker.RangePicker
        locale={locale}
        placeholder={[
          NEWS_LANGUAGE[props.language].fromDate,
          NEWS_LANGUAGE[props.language].toDate,
        ]}
        suffixIcon={<i className="far fa-calendar-alt admin-icon-input"></i>}
        style={{ width: "100%", textAlign: "center" }}
        className="date-picker-custom"
        format="DD-MM-YYYY"
        allowClear={true}
        onChange={handleChange}
        separator="-"
        clearIcon={
          <p className="custom-clearIcon">
            {props.language == "vi" ? "Đặt lại" : "Reset"}
          </p>
        }
      />
    </Form.Item>
  );
}

export default MpsRangePicker;

import React, { memo } from 'react';
import AdminMenu from '../Layout/AdminMenu';
import { Row, Col, Form, Input } from 'antd';
import ManagePostIntroStatusSelect from './Control/ManagePostIntroStatusSelect';
import MpsRangePicker from '../../MpsCotrols/MpsRangePicker/MpsRangePicker'
import { ManagePostIntroLanguageContext } from './ManageIntroPostPage';
import MpsSortSelect from '../../MpsCotrols/MpsSelect/MpsSortSelect';
import ManagePostTypeSelect from '../ManageIntroTypePost/Control/ManagePostTypeSelect';
import MpsLanguageSelect from '../../MpsCotrols/MpsSelect/MpsLanguageSelect';

function ManageIntroPostSearch() {
  const language = React.useContext(ManagePostIntroLanguageContext);
  return (
    <AdminMenu title={language.MANAGE_POST_INTRO_SEARCH_INFO}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 50 }}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_POST_INTRO_TITLE}
            name='Post_Intro_Title'
          >
            <Input placeholder={language.MANAGE_POST_INTRO_TITLE} />
          </Form.Item>
        </Col>

        {/* <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelAlign='left'
            colon={false}
            label={language.MANAGE_POST_INTRO_TITLE_ENG}
            name='Post_Intro_Title_Eng'
          >
            <Input placeholder={language.MANAGE_POST_INTRO_TITLE_ENG} />
          </Form.Item>
        </Col> */}

        <Col lg={12} md={12} sm={24} xs={24}>
          <ManagePostTypeSelect status={true}
            name='Post_Intro_Type'
            label={language.MANAGE_POST_INTRO_TYPE}
            placeholder={language.MANAGE_POST_INTRO_TYPE_PLACEHOLDER} />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsLanguageSelect name='Language' label={language.MANAGE_POST_LANGUAGE} />
        </Col>

        <Col lg={12} md={12} sm={24} xs={24}>
          <ManagePostIntroStatusSelect label={language.MANAGE_NEWS_STATUS} name='Status'
            placeholder={language.MANAGE_NEWS_STATUS_PLACEHOLDER} dataSource={['Hiển thị', 'Ẩn']} />
        </Col>

        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsRangePicker onChange={() => { }} language='vi' label={language.MANAGE_NEWS_CREATED_DATE} name='CreatedDate' />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <MpsSortSelect name='Post_Intro_Soft' />
        </Col>
      </Row>
    </AdminMenu>
  );
}

export default memo(ManageIntroPostSearch);


import React, { useEffect, useState, useCallback } from 'react';
import { Button, Modal, Form, Tabs, Input } from 'antd';
import ManageUnitStatusSelect from '../ManageUnit/Control/ManageUnitStatusSelect';
import MpsRichTextEditor from '../../MpsCotrols/MpsRichTextEditor/MpsRichTextEditor';
import MpsUploadImage from '../../MpsCotrols/MpsUploadImage/MpsUploadImage';

import { ManageProductTypeContext, ManageProductTypeLanguageContext } from './ManageProductTypePage';
import { ManageProductTypeService } from './Service/ManageProductType.Service';

import { initialFormField, productTypeInitData } from './Model/ManageProductType.Model';
import { RESULT_CODES } from '../../../common/Constant.Common';
import { openErrorNotification, openSuccessNotification } from '../Common/MethodCommon';
import ManageNewsDeleteConfirm from '../ManageNews/Control/ManageNewsDeleteConfirm';
import MpsDatePicker from '../../MpsCotrols/MpsDatePicker/MpsDatePicker';

const { TabPane } = Tabs;

function ManageProductTypeEdit(props) {
  const language = React.useContext(ManageProductTypeLanguageContext);
  const handleSearch = React.useContext(ManageProductTypeContext);

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [productTypeData, setProductTypeData] = useState({ ...productTypeInitData });


  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };


  const handleFinish = () => {

    form.validateFields().then(values => {
      const productTypeFormData = {}

      // Append values to form data
      const formObjectValues = Object.entries(values);
      formObjectValues.forEach(value => {
        productTypeFormData[value[0]]= value[1];
      });

      return ManageProductTypeService.update(productTypeFormData);
    })
      .then(result => {
        if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);
        setProductTypeData({ ...result.data });
        handleSearch();
        openSuccessNotification({ title: 'Thông báo', message: 'Cập nhật thành công' });
        hideModal();
      })
      .catch((error) => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({ title: 'Thông báo', message: 'Cập nhật thất bại' });
      });
  };

  const handleDelete = () => {
    const model = { _id: props._id };
    ManageProductTypeService.deleteModel(model)
      .then(result => {
        if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);
        openSuccessNotification({ title: 'Thông báo', message: 'Xóa dữ liệu thành công' });
        hideModal();
        handleSearch();
      })
      .catch((error) => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({ title: 'Thông báo', message: 'Xóa dữ liệu thất bại' });
      });
  };

  useEffect(() => {
    if (!visible) return;

    ManageProductTypeService.getById(props._id).then(result => {
      setProductTypeData(result.data[0] || { ...productTypeInitData });
      form.resetFields();
    });
  }, [props._id, visible, form]);

  return (
    <>
      <p className="admin-button-detail" onClick={showModal}>{language.MANAGE_PRODUCT_TYPE_VIEW_DETAIL}</p>
      <Modal
        title={language.MANAGE_PRODUCT_TYPE_EDIT}
        visible={visible}
        width='80%'
        className='manage-product-category-modal admin-modal'
        onCancel={hideModal}
        footer={
          [
            <ManageNewsDeleteConfirm
              key='delete'
              title={language.MANAGE_PRODUCT_TYPE_CONFIRM_DELETE}
              deleteLabel={language.MANAGE_PRODUCT_TYPE_DELETE}
              agreeLabel={language.MANAGE_PRODUCT_TYPE_AGREE}
              disagreeLabel={language.MANAGE_PRODUCT_TYPE_DISAGREE}
              onDelete={handleDelete}
            />,
            <Button
              onClick={handleFinish}
              key='save'
              type='primary'
              className='admin-button__main admin-button__save'
            >
              {language.MANAGE_PRODUCT_TYPE_SAVE}
            </Button>
          ]
        }
      >
        <Tabs className='manage-product-category-tabs' defaultActiveKey='generalInfo'>
          <TabPane tab={language.MANAGE_PRODUCT_TYPE_GENERAL_INFO} key='generalInfo'>
            <Form
              form={form} requiredMark={false} id='manage-product-category-edit-form'
              className='manage-product-category-form'
              colon={false}
              name='manage-product-category-edit-form'
              labelCol={{ lg: { span: 3 }, md: { span: 24 } }}
              wrapperCol={{ lg: { span: 8 }, md: { span: 24 } }}
            >
              <Form.Item
              initialValue={productTypeData.Product_Type_Code}
                label={language.MANAGE_PRODUCT_TYPE_CODE}
                labelAlign='left'
                name={initialFormField.Product_Type_Code.Name}
                rules={[{ required: initialFormField.Product_Type_Code.Required || false, message: '' }]}
              >
                <Input disabled={true} maxLength={200} rows={3} />
              </Form.Item>
              <Form.Item
                initialValue={productTypeData.Product_Type_Name}
                label={language.MANAGE_PRODUCT_TYPE_NAME_VIE}
                labelAlign='left'
                name={initialFormField.Product_Type_Name.Name}
                rules={[{ required: initialFormField.Product_Type_Name.Required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_PRODUCT_TYPE_NAME_VIE_PLACEHOLDER} maxLength={200} rows={3} />
              </Form.Item>
              <Form.Item
                initialValue={productTypeData.Product_Type_Name_Eng}
                label={language.MANAGE_PRODUCT_TYPE_NAME_ENG}
                labelAlign='left'
                name={initialFormField.Product_Type_Name_Eng.Name}
                rules={[{ required: initialFormField.Product_Type_Name_Eng.Required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_PRODUCT_TYPE_NAME_ENG_PLACEHOLDER} maxLength={200} rows={3} />
              </Form.Item>
              <ManageUnitStatusSelect
                form={form}
                name='Status'
                initialValue={productTypeData.Status}
                label={language.MANAGE_PRODUCT_TYPE_STATUS}
                placeholder={language.MANAGE_PRODUCT_TYPE_STATUS_PLACEHOLDER}
                required={initialFormField.Status.Required}
                hasInitialValue={true}
              />
              <MpsDatePicker
                label={language.MANAGE_PRODUCT_TYPE_CREATED_DATE}
                name={initialFormField.CreatedDate.Name}
                value={productTypeData.CreatedDate}
                required={initialFormField.CreatedDate.Required}
                disabled={false}

              />
              <Form.Item
              initialValue={productTypeData.CreatedPerson}
                label={language.MANAGE_PRODUCT_TYPE_CREATED_PERSON}
                labelAlign='left'
                name={initialFormField.CreatedPerson.Name}
                rules={[{ required: initialFormField.CreatedPerson.Required || false, message: '' }]}
              >
                <Input placeholder={language.MANAGE_PRODUCT_TYPE_CREATED_PERSON}  maxLength={200} rows={3} 
                disabled={true}

                />
              </Form.Item>
              <MpsDatePicker
                label={language.MANAGE_PRODUCT_TYPE_UPDATED_DATE}
                name={initialFormField.UpdatedDate.Name}
                value={productTypeData.UpdatedDate?productTypeData.UpdatedDate:Date.now()}
                required={initialFormField.UpdatedDate.Required}
                disabled={true}

              />
              <Form.Item
              initialValue={productTypeData.UpdatedPerson}
                label={language.MANAGE_PRODUCT_TYPE_UPDATED_PERSON}
                labelAlign='left'
                name={initialFormField.UpdatedPerson.Name}
                rules={[{ required: initialFormField.UpdatedPerson.Required || false, message: '' }]}
                disabled={true}
               
              >
                <Input  placeholder={language.MANAGE_PRODUCT_TYPE_UPDATED_PERSON} maxLength={200} rows={3}
                disabled={true}
                 />
              </Form.Item>
            </Form>
          </TabPane>
         
        </Tabs>
      </Modal>
    </>
  );
}

export default ManageProductTypeEdit;


import ManageUserEdit from '../ManageUserEdit'
import moment from 'moment';

export const UserCommon = {
    Title: 'Người dùng',
    TitleMainModel: 'Xem thông tin người dùng',
}

export const UserInitData = {
    User_Username: '',
    User_Email: '',
    User_Phone: '',
    User_Address: '',
    User_City: '',
    User_District: '',
    User_Wards: '',
    User_Password: '',
    Status: '',
    User_Gender: '',
    User_Call: '',
    Select: {
        Status: [
            {
                value: false,
                title: 'Ngừng kích hoạt'
            },
            {
                value: true,
                title: 'Kích hoạt'
            }
        ],
        User_Gender: [
            {
                value: 'male',
                title: 'Nam',
            },
            {
                value: 'female',
                title: 'Nữ'
            }
        ],
        User_Call: [
            {
                value: 'Anh',
                title: 'Anh'
            },
            {
                value: 'Chị',
                title: 'Chị'
            },
            {
                title: 'Mr.',
                value: 'Mr.'
            },
            {
                title: 'Ms.',
                value: 'Ms.'
            },
            {
                title: 'Mrs.',
                value: 'Mrs.'
            }
        ]
    }
}

export const UserPlaceholder = {
    User_Username: 'Họ và tên',
    User_Email: 'Email',
    User_Phone: 'Điện thoại',
    User_Address: 'Địa chỉ giao hàng',
    User_Gender: 'Giới tính',
    User_Call: 'Danh xưng',
    User_City: 'Tỉnh thành',
    User_District: 'Quận huyện',
    User_Wards: 'Phường xã',
    User_Password: 'Mật khẩu đăng nhập',
    CreatedAt: 'Ngày tạo',
    Status: 'Trạng thái'
}

export const UserAttribute = {
    User_Username: ['Họ và tên', 'text'],
    User_Email: ['Email', 'text'],
    User_Phone: ['Điện thoại', 'text'],
    User_Address: ['Địa chỉ giao hàng', 'text'],
    User_Gender: ['Giới tính', 'select'],
    User_Call: ['Danh xưng', 'select'],
    User_City: ['Tỉnh thành', 'text'],
    User_District: ['Quận huyện', 'text'],
    User_Wards: ['Phường xã', 'text'],
    User_Password: ['Mật khẩu đăng nhập', 'password'],
    CreatedAt: ['Ngày tạo', 'text'],
    Status: ['Trạng thái', 'select'],
}

export const UserDisplayTable = (signChanged, initModel) => {
    return [
        {
            title: 'Họ và tên',
            dataIndex: 'User_Username',
            key: 'User_Username',
        },
        {
            title: 'Email',
            dataIndex: 'User_Email',
            key: 'User_Email',
            responsive: ['sm'],
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'CreatedAt',
            key: 'CreatedAt',
            render: (value) => {
                return moment(value).format('DD-MM-YYYY HH:mm:ss');
            },
            responsive: ['md'],
        },
        {
            title: 'Trạng thái',
            dataIndex: 'Status',
            key: 'Status',
            responsive: ['lg'],
        },
        {
            title: '',
            dataIndex: 'detail',
            key: 'detail',
            render: (text, record) => {
                let newData = { ...record };
                if (!!initModel) {
                    let newValue = Object.keys(initModel.Select);
                    for (let i = 0; i < newValue.length; i++) {
                        newData[newValue[i]] = initModel.Select[newValue[i]].find(itemSelect => {
                            return itemSelect.title === record[newValue[i]]
                        }).value
                    }
                    newData.CreatedAt = moment(newData.CreatedAt).format('DD-MM-YYYY HH:mm:ss');
                    return (
                        <ManageUserEdit data={newData} signChanged={signChanged}></ManageUserEdit>
                    );
                }
            }
        },
    ]
}
import React, { useCallback, useState } from 'react';
import ManageUserInput from './Control/ManageUserInput';
import ManageUserSelect from './Control/ManageUserSelect';
import { Row, Col, Form, Button, Modal } from 'antd';
import { UserAttribute, UserPlaceholder, UserInitData, UserCommon } from './Model/ManageUserModel';
import { ManageUserService } from './Service/ManageUserService';
import { store } from 'react-notifications-component';
import { DELETE_RETURN_CODE_DESCRIPTION, RESULT_CODES, UPDATE_RETURN_CODE_DESCRIPTION } from '../../../common/Constant.Common';
import ManageNewsDeleteConfirm from '../ManageNews/Control/ManageNewsDeleteConfirm';

export default function ManageUserEdit(props) {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onUpdate = useCallback(async () => {
    const values = await form.validateFields();
    values._id = props.data._id;
    const data = await ManageUserService.update(values);
    props.signChanged();
    handleClose();
    store.addNotification({
      title: data.returnCode !== RESULT_CODES.SUCCESS ? 'Error!' : 'Success',
      message: `${UPDATE_RETURN_CODE_DESCRIPTION[parseInt(data.returnCode, 10)]}`,
      type: data.returnCode !== RESULT_CODES.SUCCESS ? 'danger' : 'success',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  }, [props, form]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const onDelete = useCallback(async () => {
    const data = await ManageUserService.deleted(props.data._id);
    props.signChanged();
    store.addNotification({
      title: data.returnCode !== RESULT_CODES.SUCCESS ? 'Error!' : 'Success',
      message: `${DELETE_RETURN_CODE_DESCRIPTION[parseInt(data.returnCode, 10)]}`,
      type: data.returnCode !== RESULT_CODES.SUCCESS ? 'danger' : 'success',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  }, [props]);

  return (
    <>
      <p
        className='admin-button-detail'
        onClick={() => showModal()}
      >
        Xem chi tiết
      </p>
      <Form
        form={form}
        initialValues={props.data}
      >
        <Modal
          title={UserCommon.TitleMainModel}
          visible={isModalVisible}
          onOk={form.submit}
          onCancel={handleClose}
          width='80%'
          className='admin-modal'
          footer={[
            <ManageNewsDeleteConfirm
              key='delete'
              title='Bạn có chắc chắn muốn xóa dữ liệu này ?'
              deleteLabel='Xóa'
              agreeLabel='Có'
              disagreeLabel='Không'
              onDelete={onDelete}
            />,
            <Button
              key='submit'
              type='primary'
              // form='form-modal-view'
              onClick={onUpdate}
              htmlType='submit'
              className='admin-button__main'>
              Lưu
            </Button>,
          ]}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} key='main-form-input-admin'>
            <Col lg={12} md={12} sm={24} xs={24}>
              <ManageUserInput
                name='User_Username'
                title={UserAttribute['User_Username'][0]}
                placeholder={UserPlaceholder['User_Username']}
                typeInput={UserAttribute['User_Username'][1]}
              // disabled={true}
              ></ManageUserInput>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <ManageUserInput
                disabled={true}
                name={'User_Password'}
                title={UserAttribute['User_Password'][0]}
                placeholder={UserPlaceholder['User_Password']}
                typeInput={UserAttribute['User_Password'][1]}
              ></ManageUserInput>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <ManageUserInput
                name={'User_Email'}
                title={UserAttribute['User_Email'][0]}
                placeholder={UserPlaceholder['User_Email']}
                typeInput={UserAttribute['User_Email'][1]}
              ></ManageUserInput>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <ManageUserSelect
                name={'Status'}
                title={UserAttribute['Status'][0]}
                placeholder={UserPlaceholder['Status']}
                typeInput={UserAttribute['Status'][1]}
                select={UserInitData.Select['Status']}
              ></ManageUserSelect>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <ManageUserInput
                name={'User_Phone'}
                title={UserAttribute['User_Phone'][0]}
                placeholder={UserPlaceholder['User_Phone']}
                typeInput={UserAttribute['User_Phone'][1]}
              ></ManageUserInput>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <ManageUserInput
                name={'CreatedAt'}
                title={UserAttribute['CreatedAt'][0]}
                placeholder={UserPlaceholder['CreatedAt']}
                typeInput={UserAttribute['CreatedAt'][1]}
                disabled={true}
              ></ManageUserInput>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <ManageUserSelect
                name={'User_Gender'}
                title={UserAttribute['User_Gender'][0]}
                placeholder={UserPlaceholder['User_Gender']}
                typeInput={UserAttribute['User_Gender'][1]}
                select={UserInitData.Select['User_Gender']}
              ></ManageUserSelect>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>

            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <ManageUserSelect
                name={'User_Call'}
                title={UserAttribute['User_Call'][0]}
                placeholder={UserPlaceholder['User_Call']}
                typeInput={UserAttribute['User_Call'][1]}
                select={UserInitData.Select['User_Call']}
              ></ManageUserSelect>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>

            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <ManageUserInput
                name={'User_Address'}
                title={UserAttribute['User_Address'][0]}
                placeholder={UserPlaceholder['User_Address']}
                typeInput={UserAttribute['User_Address'][1]}
              ></ManageUserInput>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>

            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <ManageUserInput
                name={'User_City'}
                title={UserAttribute['User_City'][0]}
                placeholder={UserPlaceholder['User_City']}
                typeInput={UserAttribute['User_City'][1]}
              ></ManageUserInput>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>

            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <ManageUserInput
                name={'User_District'}
                title={UserAttribute['User_District'][0]}
                placeholder={UserPlaceholder['User_District']}
                typeInput={UserAttribute['User_District'][1]}
              ></ManageUserInput>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>

            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <ManageUserInput
                name={'User_Wards'}
                title={UserAttribute['User_Wards'][0]}
                placeholder={UserPlaceholder['User_Wards']}
                typeInput={UserAttribute['User_Wards'][1]}
              ></ManageUserInput>
            </Col>
          </Row>
        </Modal>
      </Form>
    </>
  );
}

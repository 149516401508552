import React, { useState, useCallback, useEffect } from 'react';
import { ManagePostIntroContext, ManagePostIntroLanguageContext } from './ManageIntroPostPage';
import { Button, Modal, Form, Tabs, Input, Row, Col } from 'antd';
import { postIntroInitData, postIntroFormInitialField } from './Model/ManageIntroPost.Model';
import MpsRichTextEditor from '../../MpsCotrols/MpsRichTextEditor/MpsRichTextEditor';
import { ManagePostIntroService } from './Service/ManagePostIntro.Service';
import { openErrorNotification, openSuccessNotification } from '../Common/MethodCommon';
import { RESULT_CODES } from '../../../common/Constant.Common';
import ManagePostIntroDeleteConfirm from './Control/ManagePostIntroDeleteConfirm';
import MpsDatePicker from '../../MpsCotrols/MpsDatePicker/MpsDatePicker';
import ManagePostTypeSelect from '../ManageIntroTypePost/Control/ManagePostTypeSelect';
import ManagePostIntroStatusSelect from './Control/ManagePostIntroStatusSelect';
import MpsUploadImageIntroPost from '../../MpsCotrols/MpsUploadImage/MpsUploadImageIntroPost';
import MpsLanguageSelect from '../../MpsCotrols/MpsSelect/MpsLanguageSelect';
import Metadata from '../../Metatada/Metadata';

const { TabPane } = Tabs;

function ManageIntroPostEdit(props) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const [productOriginalImagesPc, setProductOriginalImagesPc] = useState([]);
  const [removedImagesPc, setRemovedImagesPc] = useState([]);
  const [addedImagesPc, setAddedImagesPc] = useState([]);

  const [productOriginalImagesTablet, setProductOriginalImagesTablet] = useState([]);
  const [removedImagesTablet, setRemovedImagesTablet] = useState([]);
  const [addedImagesTablet, setAddedImagesTablet] = useState([]);

  const [productOriginalImagesMobile, setProductOriginalImagesMobile] = useState([]);
  const [removedImagesMobile, setRemovedImagesMobile] = useState([]);
  const [addedImagesMobile, setAddedImagesMobile] = useState([]);
  const [metadata, setMetadata] = useState([]);

  const [postIntroData, setPostIntroData] = useState({ ...postIntroInitData });

  const language = React.useContext(ManagePostIntroLanguageContext);
  const handleSearch = React.useContext(ManagePostIntroContext);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };
  
  const handleChangeMetadata = (values) => {
    setMetadata(values);
  };


  const handleDelete = () => {
    const model = { _id: props._id };
    ManagePostIntroService.deleteModel(model).then(result => {
      if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);
      // Xóa hình ảnh
      const arrImageDelete = [
        ...postIntroData.Post_Intro_Image_Pc,
        ...postIntroData.Post_Intro_Image_Mobile,
        ...postIntroData.Post_Intro_Image_Tablet
      ]
      return ManagePostIntroService.deleteImages({ Post_Intro_Images: arrImageDelete });
    }).then(result => {
      if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);

      openSuccessNotification({ title: 'Thông báo', message: 'Xóa dữ liệu thành công' });
      hideModal();
      handleSearch();
    }).catch((error) => {
      if (error !== RESULT_CODES.SUCCESS)
        return openErrorNotification({ title: 'Thông báo', message: 'Xóa dữ liệu thất bại' });
    });
  };

  const handleFininsh = () => {
    form.validateFields().then(values => {

      const formData = new FormData();
      //append data image for Pc
      postIntroData.Post_Intro_Image_Pc.forEach(image => {
        formData.append('Post_Intro_Image_Pc', JSON.stringify(image));
      });
      addedImagesPc.forEach(image => {
        formData.append('Post_Intro_New_Images_Pc', image.originFileObj);
      });
      removedImagesPc.forEach(image => {
        formData.append('Post_Intro_Removed_Images_Pc', image.name);
      });
      //append data image for tablet
      postIntroData.Post_Intro_Image_Tablet.forEach(image => {
        formData.append('Post_Intro_Image_Tablet', JSON.stringify(image));
      });
      addedImagesTablet.forEach(image => {
        formData.append('Post_Intro_New_Images_Tablet', image.originFileObj);
      });
      removedImagesTablet.forEach(image => {
        formData.append('Post_Intro_Removed_Images_Tablet', image.name);
      });
      //aappend data image for mobile
      postIntroData.Post_Intro_Image_Mobile.forEach(image => {
        formData.append('Post_Intro_Image_Mobile', JSON.stringify(image));
      });
      addedImagesMobile.forEach(image => {
        formData.append('Post_Intro_New_Images_Mobile', image.originFileObj);
      });
      removedImagesMobile.forEach(image => {
        formData.append('Post_Intro_Removed_Images_Mobile', image.name);
      });
      // Append values to form data
      const formObjectValues = Object.entries(values);
      formObjectValues.forEach(value => {
        formData.append(value[0], value[1]);
      });
      formData.append('_id', postIntroData._id);
      formData.append('Post_Intro_AdditionalInfo', postIntroData.Post_Intro_AdditionalInfo);
      formData.append('Post_Intro_AdditionalInfo_Eng', postIntroData.Post_Intro_AdditionalInfo_Eng);
      formData.append('Post_Intro_Content', postIntroData.Post_Intro_Content);
      formData.append('Post_Intro_Content_Eng', postIntroData.Post_Intro_Content_Eng);
      const filterMetadata = metadata.filter(
        (tag) => tag.Meta_Key && tag.Meta_Content
      );
      formData.append("Metadata", JSON.stringify(filterMetadata));
      // console.log("check formdata", formData)
      return ManagePostIntroService.update(formData);

    }).then(result => {
      if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);
      setAddedImagesPc([]);
      setAddedImagesMobile([]);
      setAddedImagesTablet([])
      handleSearch();
      hideModal();
      // Xóa hình ảnh
      const arrImageDelete = [...removedImagesPc, ...removedImagesMobile, ...removedImagesTablet]
      return ManagePostIntroService.deleteImages({ Post_Intro_Images: arrImageDelete });
    }).then(result => {
      //console.log("check result", result)
      if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);

      setRemovedImagesPc([]);
      setRemovedImagesMobile([]);
      setRemovedImagesTablet([]);
      handleSearch();
      openSuccessNotification({ title: 'Thông báo', message: 'Cập nhật thành công' });
      hideModal();

    }).catch(error => {
      if (error !== RESULT_CODES.SUCCESS)
        return openErrorNotification({ title: 'Thông báo', message: 'Cập nhật thất bại' });
    });
  }

  const handleAdditionalInfoChange = useCallback((text) => {
    setPostIntroData(prevState => ({ ...prevState, Post_Intro_AdditionalInfo: text }));
  }, []);

  const handleAdditionalInfoEngChange = useCallback((text) => {
    setPostIntroData(prevState => ({ ...prevState, Post_Intro_AdditionalInfo_Eng: text }));
  }, []);
  const handleMainContentChange = useCallback((text) => {
    setPostIntroData(prevState => ({ ...prevState, Post_Intro_Content: text }));
  }, [])

  const handleMainContentEngChange = useCallback((text) => {
    setPostIntroData(prevState => ({ ...prevState, Post_Intro_Content_Eng: text }));
  }, [])

  //Onchange Image Pc change
  const handleImagesPcChange = useCallback((images) => {
    setPostIntroData(prevState => ({ ...prevState, Post_Intro_Image_Pc: images }));
  }, []);

  const handleRemoveImagePc = useCallback((image) => {
    const index = productOriginalImagesPc.findIndex(item => item.name === image.name);

    if (index === -1) return;

    setRemovedImagesPc(prevState => ([...prevState, image]));
  }, [productOriginalImagesPc]);

  const handleAddImagePc = useCallback((image) => {
    setAddedImagesPc([...image.fileList]);
  }, []);
  //Onchange Image tablet
  const handleRemoveImageTablet = useCallback((image) => {
    const index = productOriginalImagesTablet.findIndex(item => item.name === image.name);

    if (index === -1) return;

    setRemovedImagesTablet(prevState => ([...prevState, image]));
  }, [productOriginalImagesTablet]);

  const handleAddImageTablet = useCallback((image) => {
    setAddedImagesTablet([...image.fileList]);
  }, []);

  const handleImageTabletChange = useCallback((images) => {
    setPostIntroData(prevState => ({ ...prevState, Post_Intro_Image_Tablet: images }));
  }, []);
  //Onchange Image Mobile
  const handleRemoveImageMobile = useCallback((image) => {
    const index = productOriginalImagesMobile.findIndex(item => item.name === image.name);

    if (index === -1) return;

    setRemovedImagesMobile(prevState => ([...prevState, image]));
  }, [productOriginalImagesMobile]);

  const handleAddImageMobile = useCallback((image) => {
    setAddedImagesMobile([...image.fileList]);
  }, []);

  const handleImagesMobileChange = useCallback((images) => {
    setPostIntroData(prevState => ({ ...prevState, Post_Intro_Image_Mobile: images }));
  }, []);

  useEffect(() => {
    if (!visible) return;
    ManagePostIntroService.getById(props._id).then(result => {
      setMetadata(result?.data[0]?.Metadata || []);
      setPostIntroData(result.data[0] || { ...postIntroInitData });
      setProductOriginalImagesPc(result.data[0].Post_Intro_Image_Pc || []);
      setProductOriginalImagesTablet(result.data[0].Post_Intro_Image_Tablet || []);
      setProductOriginalImagesMobile(result.data[0].Post_Intro_Image_Mobile || []);
      form.resetFields();
    });
  }, [props._id, visible, form]);
  return (
    <>
      <p className="admin-button-detail" onClick={showModal}>Xem chi tiết</p>

      <Modal
        title={language.MANAGE_NEWS_VIEW_TITLE}
        visible={visible}
        width='80%'
        className='manage-news-modal admin-modal'
        onCancel={hideModal}
        footer={
          [
            <ManagePostIntroDeleteConfirm key='cancel'
              title={language.MANAGE_NEWS_CONFIRM_DELETE}
              deleteLabel={language.MANAGE_NEWS_DELETE}
              agreeLabel={language.MANAGE_NEWS_AGREE}
              disagreeLabel={language.MANAGE_NEWS_DISAGREE}
              onDelete={handleDelete}
            />,
            <Button
              key='submit'
              type='primary'
              onClick={handleFininsh}
              className='admin-button__main admin-button__save'
            >
              {language.MANAGE_NEWS_SAVE}
            </Button>
          ]
        }
      >
        <Tabs className='manage-news-tabs' defaultActiveKey='generalInfo'>
          <TabPane tab='Thông tin chung' key='generalInfo'>
            <Form form={form} requiredMark={false} id='manage-post-add-new-form'
              name='manage-news-form'
              className='manage-news-form'
              labelCol={{ lg: { span: 3 }, md: { span: 24 } }}
              wrapperCol={{ lg: { span: 8 }, md: { span: 24 } }}
            >
              <Form.Item
                colon={false}
                label={language.MANAGE_POST_INTRO_TITLE}
                labelAlign='left'
                name={postIntroFormInitialField.Post_Intro_Title.name}
                rules={[{ required: postIntroFormInitialField.Post_Intro_Title.required || false, message: '' }]}
                initialValue={postIntroData.Post_Intro_Title}
              >
                <Input.TextArea placeholder={language.MANAGE_POST_INTRO_TITLE_PLACEHOLDER} maxLength={200} />
              </Form.Item>
              {/* <Form.Item
                colon={false}
                label={language.MANAGE_POST_INTRO_TITLE_ENG}
                labelAlign='left'
                name={postIntroFormInitialField.Post_Intro_Title_Eng.name}
                rules={[{ required: postIntroFormInitialField.Post_Intro_Title_Eng.required || false, message: '' }]}
                initialValue={postIntroData.Post_Intro_Title_Eng}
              >
                <Input.TextArea placeholder={language.MANAGE_POST_INTRO_TITLE_ENG_PLACEHOLDER} maxLength={200} />
              </Form.Item> */}
              <ManagePostTypeSelect status={false}
                name={postIntroFormInitialField.Post_Intro_Type.name}
                label={language.MANAGE_POST_INTRO_TYPE}
                placeholder={language.MANAGE_POST_INTRO_TYPE_PLACEHOLDER}
                required={postIntroFormInitialField.Post_Intro_Type.required}
                initialValue={postIntroData.Post_Intro_Type}
                form={form} />
              <MpsLanguageSelect
                name={postIntroFormInitialField.Language.name}
                label={language.MANAGE_POST_LANGUAGE}
                hasInitialValue={true}
                initialValue={postIntroData.Language}
                form={form}
                required={postIntroFormInitialField.Language.required}
              />
              <ManagePostIntroStatusSelect
                name={postIntroFormInitialField.Status.name}
                placeholder={language.MANAGE_NEWS_GROUP_PLACEHOLDER}
                label={language.MANAGE_NEWS_STATUS}
                hasInitialValue={true}
                required={postIntroFormInitialField.Status.required}
                initialValue={postIntroData.Status}
                form={form}
              />
              <MpsDatePicker
                label={language.MANAGE_NEWS_CREATED_DATE}
                name={postIntroFormInitialField.CreatedDate.name}
                value={postIntroData.CreatedDate}
                required={postIntroFormInitialField.CreatedDate.required}
              // disabled={true}
              />
            </Form>
          </TabPane>
          <TabPane tab={language.MANAGE_NEWS_IMAGE} key='imageInfo'>
            <div style={{ display: 'flex', marginTop: '50px', textAlign: 'center' }}>
              <MpsUploadImageIntroPost
                isConvertUrl
                onRemove={handleRemoveImagePc}
                onAddImage={handleAddImagePc}
                onChange={handleImagesPcChange}
                images={postIntroData.Post_Intro_Image_Pc}
                max={1} for='PC' />
              <MpsUploadImageIntroPost
                isConvertUrl
                onRemove={handleRemoveImageTablet}
                onAddImage={handleAddImageTablet}
                onChange={handleImageTabletChange}
                images={postIntroData.Post_Intro_Image_Tablet}
                max={1} for='Tablet' />
              <MpsUploadImageIntroPost
                isConvertUrl
                onRemove={handleRemoveImageMobile}
                onAddImage={handleAddImageMobile}
                onChange={handleImagesMobileChange}
                images={postIntroData.Post_Intro_Image_Mobile}
                max={1} for='Mobile' />
            </div>
          </TabPane>
          <TabPane tab={language.MANAGE_POST_INTRO_CONTENT_TITLE} key='maincontent_edit'>
            <Row>
              <Col span={24}>
                <Form.Item  >{language.MANAGE_POST_INTRO_CONTENT}</Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <MpsRichTextEditor onChange={handleMainContentChange} data={postIntroData.Post_Intro_Content} />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
              <Col span={24}>
                <Form.Item  >{language.MANAGE_POST_INTRO_CONTENT_ENG}</Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item  >
                  <MpsRichTextEditor onChange={handleMainContentEngChange} data={postIntroData.Post_Intro_Content_Eng} />
                </Form.Item>
              </Col>
            </Row> */}
          </TabPane>
          <TabPane tab={language.MANAGE_NEWS_ADDITIONAL_INFO} key='additionalInfo_edit'>
            <MpsRichTextEditor onChange={handleAdditionalInfoChange} data={postIntroData.Post_Intro_AdditionalInfo} />
          </TabPane>
          {/* <TabPane tab={language.MANAGE_NEWS_ADDITIONAL_INFO_ENG} key='additionalInfo_eng_edit'>
            <MpsRichTextEditor onChange={handleAdditionalInfoEngChange} data={postIntroData.Post_Intro_AdditionalInfo_Eng} />
          </TabPane> */}
          <TabPane tab="Metadata" key="metadata">
            <Metadata metadata={metadata} onChange={handleChangeMetadata} />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
}

export default React.memo(ManageIntroPostEdit);

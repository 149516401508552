import React, { useState } from 'react'
import { Layout, Menu } from 'antd';
import logo from '../../../asset/img/Logo.png'
import { Link } from 'react-router-dom';
const { Sider } = Layout;
const { SubMenu } = Menu

export default function AdminSider(props) {
    let params = window.location.pathname.replace('/admin/', '');
    const [collapsed, setCollapsed] = useState(false);
    return (
        <Sider
            breakpoint="xxl"
            width={280}
            className="admin-sider"
            // collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}
        >
            <div className="admin-sider__logo">
                <img src={logo} alt={logo}></img>
                <p>
                    T.L.C QUẢN TRỊ
                </p>
            </div>
            <Menu theme="dark" mode="inline" selectedKeys={[params]} className="admin-sider__menu">
                <SubMenu key='sub1' title={<span><span style={{ fontSize: '14px' }}>Hệ thống</span></span>} icon={<i className="fas fa-user"></i>}>
                    <Menu.Item key="manageuser">
                        <Link to="/admin/manageuser">
                            Người dùng
                        </Link>
                    </Menu.Item>
                </SubMenu>

                <SubMenu key='sub2' title={<span><span style={{ fontSize: '14px' }}>Quản lý sản phẩm</span></span>} icon={<i className="fas fa-dolly"></i>}>
                    <Menu.Item key="manage-product">
                        <Link to="/admin/manage-product">
                            Sản phẩm
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="manage-specification">
                        <Link to="/admin/manage-specification">
                            Quy cách sản phẩm
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="manage-product-type">
                        <Link to="/admin/manage-product-type">
                            Chủng loại sản phẩm
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="manage-unit">
                        <Link to="/admin/manage-unit">
                            Đơn vị tính
                        </Link>
                    </Menu.Item>
                </SubMenu>

                <SubMenu key='sub3' title={<span><span style={{ fontSize: '14px' }}>Quản lý tin tức</span></span>} icon={<i className="far fa-newspaper"></i>}>
                    <Menu.Item key="manage-banner">
                        <Link to="/admin/manage-banner">
                            Banner
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="manage-introductory-type-post">
                        <Link to="/admin/manage-introductory-type-post">
                            Loại bài giới thiệu
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="manage-introductory-post">
                        <Link to="/admin/manage-introductory-post">
                            Bài giới thiệu
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="managenews">
                        <Link to="/admin/managenews">
                            Tin tức
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="manage-recruitment">
                        <Link to="/admin/manage-recruitment">
                            Tuyển dụng
                        </Link>
                    </Menu.Item>
                </SubMenu>

                <SubMenu key='sub4' title={<span><span style={{ fontSize: '14px' }}>Quản lý tài liệu</span></span>} icon={<i className="fas fa-file-alt"></i>}>
                    <Menu.Item key="managerelation">
                        <Link to="/admin/managerelation">
                            Quan hệ cổ đông
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="manage-documents">
                        <Link to="/admin/manage-documents">
                            Tài liệu
                        </Link>
                    </Menu.Item>
                </SubMenu>
            </Menu>
        </Sider>
    )
}

import React, { Fragment, useEffect, useState } from 'react';
import { SwapRightOutlined } from '@ant-design/icons';
import { Link, useRouteMatch } from 'react-router-dom';
import backgroundTitle from '../../asset/img/img-8.jpg';
import Helmet from '../Layout/Helmet';
import Breadcrum from '../Layout/Breadcrum';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import background from '../../asset/img/img-9.jpg';
import NewsList from './Control/NewsList';
import { ManageNewsService } from '../Admin/ManageNews/Service/ManageNews.Service';
import { ManageNewsCategoryService } from '../Admin/ManageNews/Service/ManageNewsCategory.Service';
import { ManageNewsGroupService } from '../Admin/ManageNews/Service/ManageNewsGroup.Service';
import { NEWS_LANGUAGE } from './Language/Language';
import { getCategoryNameByLanguage, getGroupNameByLanguage } from './Helper/Helper';
import MpsRangePicker from '../MpsCotrols/MpsRangePicker/MpsRangePicker';
import { SearchOutlined } from '@ant-design/icons';
import { RelationService } from '../Relation/Service/RelationService';
import { Skeleton } from 'antd';

const searchOption = {
  page: 1,
  limit: 23,
  getImages: true,
  sort: ['CreatedAt'],
  sortAscending: false,
};

export default function NewsPage({ language }) {
  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [news, setNews] = useState([]);
  const [flagLoading, setFlagLoading] = useState(false);
  const [dataSearch, setDataSearch] = useState('');
  const [dataSearchShow, setDataSearchShow] = useState(null);
  const [dataSort, setDataSort] = useState([]);
  const { path } = useRouteMatch();

  const handleValueSort = (value) => {
    setDataSort(value);
  };
  const handleSearchChange = (e) => {
    // console.log(e.target.value)
    setDataSearch(e.target.value);
  };
  const handleSearchShow = (e) => {
    setDataSearchShow(e);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setFlagLoading(true);
    Promise.all([
      ManageNewsCategoryService.search({}, searchOption),
      ManageNewsGroupService.search({}, searchOption),
      ManageNewsService.search(
        {
          CreatedDate: dataSort,
          News_Title: dataSearchShow,
          Status: true,
          Language: language,
        },
        searchOption
      ),
    ])
      .then((result) => {
        const [categoryData, groupData, newsData] = result;
        setCategories([...categoryData.result.docs]);
        console.log(groupData.result.docs);
        setGroups([...groupData.result.docs]);
        setNews([...newsData.result.docs]);
        setFlagLoading(false);
      })
      .catch(() => {
        // TO DO
        setFlagLoading(false);
      });
  }, [dataSort, dataSearchShow, language]);
  return (
    <Helmet title={NEWS_LANGUAGE[language]['title']}>
      <Breadcrum data={[NEWS_LANGUAGE[language]['title']]}></Breadcrum>
      <TitleBreadcrum title={NEWS_LANGUAGE[language]['title']} background={backgroundTitle}></TitleBreadcrum>
      <div className='news-generation' style={{ backgroundImage: `url(${background})`, minHeight: '400px' }}>
        <div className='news-generation__overlay'></div>
        <div className='container'>
          <div className='news-generation__heading1'>
            <label>{NEWS_LANGUAGE[language].documentRangeTime}</label>
          </div>
          <div className='news-generation__heading'>
            <div className='generation-rangedate__heading__item'>
              <MpsRangePicker language={language} onChange={(e) => handleValueSort(e)} className='generation-rangedate__heading__item__input'></MpsRangePicker>
            </div>
            <div className='news-generation-search__heading__item'>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSearchShow(dataSearch);
                }}
              >
                <input value={dataSearch} onChange={(e) => handleSearchChange(e)} type='text' placeholder={NEWS_LANGUAGE[language].search} name='search' />
                <button onClick={() => handleSearchShow(dataSearch)}>
                  <SearchOutlined />
                </button>
              </form>
            </div>
          </div>

          <div className='news'>
            {flagLoading && <Skeleton active />}
            {categories.length > 0 &&
              news.length > 0 &&
              groups.length > 0 &&
              groups.map((item1, index1) => {
                const firstGroupNews = news.filter((n) => n.News_Group === item1.News_Group_Code);
                return (
                  <div className='news__main' key={`${index1}${item1.News_Group_Code}${item1.News_Category_Code}`}>
                    <div className='news__main__title-container'>
                      <div className='news__main__title'>
                        <div className='news__main__title__circle'></div>
                        <h3>{getGroupNameByLanguage(item1, language)}</h3>
                      </div>
                      <Link to={`${path}/${item1.News_Group_Slug}`} className='news__main__detail'>
                        <p>{NEWS_LANGUAGE[language]['viewAll']}</p>
                        <div className='news__main__detail__icon'>
                          <SwapRightOutlined />
                        </div>
                        <div className='news__main__detail__line'></div>
                      </Link>
                    </div>
                    <div className='news__main__item'>
                      <div className='news__main__item__detail'>
                        <NewsList language={language} data={firstGroupNews}></NewsList>
                      </div>
                      <div className='news__main__item__background'></div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Helmet>
  );
}

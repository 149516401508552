import { formatDateTime } from '../../Common/FormatCommon';
import ManageUnitEdit from '../ManageUnitEdit';

export const statusModel = [
  { name: 'Sử dụng', value: true },
  { name: 'Ngưng sử dụng', value: false },
];
export const initialFormField = {
  Unit_Code: {
    Name: 'Unit_Code',
    Required: true
  },
  Unit_Name: {
    Name: 'Unit_Name',
    Required: true
  },
  Unit_Name_Eng: {
    Name: 'Unit_Name_Eng',
    Required: true
  },
  CreatedDate: {
    Name: 'CreatedDate',
    Required: true,
  },
  CreatedPerson: {
    Name: 'CreatedPerson',
    Required: false,
  },
  UpdatedDate: {
    Name: 'UpdatedDate',
    Required: false,
  },
  UpdatedPerson: {
    Name: 'UpdatedPerson',
    Required: false,
  },
  Status: {
    Name: 'Status',
    Required: true
  },
};


export const unitInitData = {
  _id: null,
  Unit_Code: null,
  Unit_Name: null,
  Unit_Name_Eng: null,
  Status: null,
  CreatedDate: null,
  CreatedPerson:null,
  UpdatedDate: null,
  UpdatedPerson:null
};

export const columns = (language) => {
  return [
    {
      width: 300,
      title: language.MANAGE_UNIT_CODE,
      dataIndex: 'Unit_Code',
      key: 'Unit_Code'
    },
    {
      width: 450,
      title: language.MANAGE_UNIT_NAME_ENG,
      dataIndex: 'Unit_Name_Eng',
      key: 'Unit_Name_Eng'
    },
    {
      width: 450,
      title: language.MANAGE_UNIT_NAME_VIE,
      dataIndex: 'Unit_Name',
      key: 'Unit_Name'
    },
    {
      width: 300,
      title: language.MANAGE_UNIT_CREATED_DATE,
      dataIndex: 'CreatedDate',
      key: 'CreatedDate',
      render: (value) => {
        return formatDateTime(value);
      }
    },
    {
      width: 300,
      title: language.MANAGE_UNIT_CREATED_PERSON,
      dataIndex: 'CreatedPerson',
      key: 'CreatedPerson',
    },
    {
      width: 300,
      title: language.MANAGE_UNIT_UPDATED_DATE,
      dataIndex: 'UpdatedDate',
      key: 'UpdatedDate',
      render: (value) => {
        return value?formatDateTime(value):'';
      }
    },
    {
      width: 300,
      title: language.MANAGE_UNIT_UPDATED_PERSON,
      dataIndex: 'UpdatedPerson',
      key: 'UpdatedPerson',
    },
    {
      title: language.MANAGE_UNIT_STATUS,
      dataIndex: 'Status',
      key: 'Status',
      render: (value) => {
        return value === false ? 'Ngưng sử dụng' : 'Sử dụng';
      }
    },
    {
      title: '',
      dataIndex: '_id',
      render: (value) => {
        return <ManageUnitEdit _id={value} />;
      }
    }
  ];
};



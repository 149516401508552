import React, { useEffect } from "react";
import "./Css/Home.css";
import HomeSlider from "./Control/HomeSlider";
import { HomeCommon } from "./Model/HomeModel";
import HomeContent from "./HomeContent";
import RenderMeta from "../Metatada/RenderMeta";

function HomePage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const metadata = [
    {
      key: 0,
      Meta_Key: "title",
      Meta_Content: HomeCommon[props.language].title,
    },
  ];
  return (
    <>
      <RenderMeta metadata={metadata} />
      <HomeSlider
        language={props.language}
        slider={HomeCommon[props.language].slider}
      ></HomeSlider>
      <HomeContent language={props.language}></HomeContent>
    </>
  );
}

export default HomePage;

import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { RelationCommon } from '../Model/RelationModel';

export default function RelationItem(props) {
    const { data, language } = props;
    console.log({data});
    return (
        <div className='relation-item'>
            <div className='relation-item__time'>
                {
                    moment(data.CreatedAt).format('DD/MM/YYYY - HH:mm:ss')
                }
            </div>
            <div className='relation-item__main'>
                <a href={`${process.env.REACT_APP_API_URL + `/relation/${data.Relation_File}`}`}
                    target='_blank' rel='noopener noreferrer'
                    className='relation-item__main__title'>
                    {data.Relation_Title}
                </a>
                <a href={`${process.env.REACT_APP_API_URL + `/relation/${data.Relation_File}`}`}
                    download
                    target='_blank' rel='noopener noreferrer'
                    className='relation-item__main__button'
                >
                    {RelationCommon[language].download}
                    <div className='relation-item__main__button__icon'>
                        <DownloadOutlined />
                    </div>
                </a>
            </div>
        </div>
    )
}

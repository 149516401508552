import { authHeader } from '../../../../helper/authHeader';

export const ManageDocumentService = {
    create,
    search,
    update,
    deleted,
    deleteFile,
    getRelation_Category,
    createImagePdf
};

const content = 'Content-Type';
const Type = 'application/json';

function create(values) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: values
    };
    return fetch(process.env.REACT_APP_API_URL + `/Document/create`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
}
function createImagePdf(value) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(value)
    };
    return fetch(process.env.REACT_APP_API_URL + `/Document/Create_Image_Pdf`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
}
function search(searchModel, searchOptions) {
    const value = {
        searchModel,
        searchOptions
    };
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(value)
    };
    return fetch(process.env.REACT_APP_API_URL + `/Document/search`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
}

function update(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: model
    };
    return fetch(process.env.REACT_APP_API_URL + `/Document/update`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}

function deleted(_id) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify({
            _id
        })
    };
    return fetch(process.env.REACT_APP_API_URL + `/Document/delete`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}

function getRelation_Category() {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'GET',
    };
    return fetch(process.env.REACT_APP_API_URL + `/Relation_Category/getData`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}

function deleteFile(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth);

    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(model),
    };
    return fetch(`${process.env.REACT_APP_API_URL}/Document/deleteFile`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
import React, { useCallback, useEffect, useState } from 'react';
import Helmet from '../../Layout/Helmet';
import AdminBreadcrum from '../Layout/AdminBreadcrumb';
import { Form, Button } from 'antd';
import AdminTable from '../Layout/AdminTable';
import { DocumentDisplayTable, DocumentAttribute, DocumentInitData, DocumentPlaceholder, DocumentCommon } from './Model/ManageDocumentModel';
import { ManageDocumentService } from './Service/ManageDocumentService';
import ManageDocumentSearch from './ManageDocumentSearch';
import ManageDocumentAdd from './ManageDocumentAdd';
import { LayoutAdminCommon } from '../Layout/Model/LayoutAdminModel';

export default function ManageDocumentPage() {
  const [dataDocument, setDataDocument] = useState(null);
  const [searchOption, setSearchOption] = useState({ page: 1, limit: 10 });
  const [signChanged, setSignChanged] = useState(false);
  const [visibleModalCreate, setVisibleModalCreate] = useState(false);
  const [initModelDocument, setInitModelDocument] = useState(null);
  const [form] = Form.useForm();

  const handleSearch = useCallback(async () => {
    const searchModel = form.getFieldsValue();
    const sortAscending = form.getFieldValue('Document_Sort');
    const data = await ManageDocumentService.search(searchModel, { ...searchOption, sortAscending: sortAscending || false });
    const { pages } = data.result;
    if (pages >= searchOption.page) {
      setDataDocument(data.result);
    } else {
      setSearchOption(prevState => ({ ...prevState, page: 1 }));
    }
  }, [form, searchOption]);

  const handlePagination = (currentPage, pageSizes) => {
    setSearchOption(prevState => ({ ...prevState, page: currentPage, limit: pageSizes }));
  };

  const handleSignChanged = () => {
    setSignChanged(prevSign => !prevSign);
  };

  const onHandleClearFormSearch = () => {
    form.resetFields();
  };

  const handleCloseModalCreate = () => {
    setVisibleModalCreate(false);
  };

  useEffect(() => {
    const initData = {
      ...DocumentInitData,
      Select: { ...DocumentInitData.Select }
    };
    setInitModelDocument(initData);
  }, []);

  useEffect(() => {
    handleSearch();
  }, [handleSearch, signChanged]);

  return dataDocument && initModelDocument && (
    <Helmet title={DocumentCommon.Title}>
      <AdminBreadcrum title={DocumentCommon.Title} subKey='sub4'></AdminBreadcrum>
      <div className='admin-content'>
        <Form
          form={form}
          onFinish={handleSearch}
        >
          <ManageDocumentSearch
            initModelDocument={initModelDocument}
            DocumentAttribute={DocumentAttribute}
            DocumentPlaceholder={DocumentPlaceholder}
          >
          </ManageDocumentSearch>
          <div className='admin-button admin-button-search'>
            <Button type='secondary' className='admin-button__main admin-button__delete' onClick={onHandleClearFormSearch}>
              {LayoutAdminCommon.buttonDeleteSearch}
            </Button>
            <Button type='primary' htmlType='submit' className='admin-button__main admin-button__save'>
              {LayoutAdminCommon.buttonSaveSearch}
            </Button>
          </div>
        </Form>
        <div className='admin-button'>
          <Button htmlType='submit' className='admin-button__main admin-button__download-pdf' onClick={() => setVisibleModalCreate(true)}>
            <i className='fas fa-file-upload'></i>
            {DocumentCommon.TitleCreateModal}
          </Button>
        </div>
        <AdminTable
          data={dataDocument.docs}
          columnsData={() => DocumentDisplayTable(handleSignChanged, initModelDocument)}
          changePage={handlePagination}
          select={initModelDocument.Select}
          total={dataDocument.total || 0}
          current={dataDocument.page}
        >
        </AdminTable>
        <ManageDocumentAdd
          initModelDocument={initModelDocument}
          data={DocumentInitData}
          visible={visibleModalCreate}
          onClose={handleCloseModalCreate}
          signChanged={handleSignChanged}
        // handleSearch={handleSearch}
        />
      </div>
    </Helmet>
  );
}

export const LANGUAGE_STATE = {
  'vi': {
    MANAGE_NEWS: 'Tin tức',
    MANAGE_NEWS_SEARCH_INFO: 'Thông tin tìm kiếm',
    MANAGE_NEWS_TITLE: 'Tiêu đề ',
    MANAGE_NEWS_TITLE_PLACEHOLDER: 'Tiêu đề  (tối đa 200 ký tự)',
    MANAGE_NEWS_TITLE_ENG: 'Tiêu đề Tiếng Anh',
    MANAGE_NEWS_LANGUAGE: 'Ngôn ngữ',
    MANAGE_NEWS_TITLE_ENG_PLACEHOLDER: 'Tiêu đề Tiếng Anh (tối đa 200 ký tự)',
    MANAGE_NEWS_CATEGORY: 'Thể loại',
    MANAGE_NEWS_CATEGORY_PLACEHOLDER: 'Chọn thể loại',
    MANAGE_NEWS_GROUP: 'Nhóm',
    MANAGE_NEWS_GROUP_PLACEHOLDER: 'Chọn nhóm',
    MANAGE_NEWS_STATUS: 'Trạng thái',
    MANAGE_NEWS_STATUS_PLACEHOLDER: 'Chọn trạng thái',
    MANAGE_NEWS_CREATED_DATE: 'Ngày tạo',
    MANAGE_NEWS_CREATE_NEW_POST: 'Đăng tin tức mới',
    MANAGE_NEWS_VIEW_TITLE: 'Xem nội dung tin tức',
    MANAGE_NEWS_GENERAL_INFO: 'Thông tin chung',
    MANAGE_NEWS_ADDITIONAL_INFO: 'Thông tin thêm',
    MANAGE_NEWS_ADDITIONAL_INFO_ENG: 'Thông tin thêm tiếng Anh',
    MANAGE_NEWS_EXCERPT: 'Trích dẫn',
    MANAGE_NEWS_EXCERPT_ENG: 'Trích dẫn tiếng Anh',
    MANAGE_NEWS_IMAGE: 'Hình ảnh',

    // ACTION
    MANAGE_NEWS_CANCEL: 'Huỷ',
    MANAGE_NEWS_CANCEL_FLIPPAGE: 'Thoát',
    MANAGE_NEWS_DELETE: 'Xóa',
    MANAGE_NEWS_SAVE: 'Lưu',
    MANAGE_NEWS_AGREE: 'Có',
    MANAGE_NEWS_DISAGREE: 'Không',
    MANAGE_NEWS_UPLOAD_IMAGE: 'Đăng ảnh',
    MANAGE_NEWS_CLEAR_SELECT: 'Xóa lựa chọn',
    MANAGE_NEWS_SEARCH: 'Tìm kiếm',

    // MESSAGE
    MANAGE_NEWS_CONFIRM_DELETE: 'Bạn có chắc chắn muốn xóa dữ liệu này ?',
  },
  'en': {
    MANAGE_NEWS: 'Manage news',
    MANAGE_NEWS_SEARCH_INFO: 'Search information',
    MANAGE_NEWS_TITLE: 'Tiêu đề Tiếng Việt',
    MANAGE_NEWS_LANGUAGE: 'Language',
    MANAGE_NEWS_TITLE_PLACEHOLDER: 'Tiêu đề Tiếng Việt (tối đa 200 ký tự)',
    MANAGE_NEWS_TITLE_ENG: 'Tiêu đề Tiếng Anh',
    MANAGE_NEWS_TITLE_ENG_PLACEHOLDER: 'Tiêu đề Tiếng Anh (tối đa 200 ký tự)',
    MANAGE_NEWS_CATEGORY: 'Category',
    MANAGE_NEWS_CATEGORY_PLACEHOLDER: 'Select category',
    MANAGE_NEWS_GROUP: 'Group',
    MANAGE_NEWS_GROUP_PLACEHOLDER: 'Select group',
    MANAGE_NEWS_STATUS: 'Status',
    MANAGE_NEWS_STATUS_PLACEHOLDER: 'Select status',
    MANAGE_NEWS_CREATED_DATE: 'Created date',
    MANAGE_NEWS_CREATE_NEW_POST: 'Create new post',
    MANAGE_NEWS_VIEW_TITLE: 'Xem nội dung tin tức',
    MANAGE_NEWS_GENERAL_INFO: 'General information',
    MANAGE_NEWS_ADDITIONAL_INFO: 'Thông tin thêm Tiếng Việt',
    MANAGE_NEWS_ADDITIONAL_INFO_ENG: 'Thông tin thêm Tiếng Anh',
    MANAGE_NEWS_IMAGE: 'Image',

    // ACTION
    MANAGE_NEWS_CANCEL: 'Cancel',
    MANAGE_NEWS_CANCEL_FLIPPAGE: 'Cancel',
    MANAGE_NEWS_DELETE: 'Delete',
    MANAGE_NEWS_SAVE: 'Save',
    MANAGE_NEWS_AGREE: 'Yes',
    MANAGE_NEWS_DISAGREE: 'No',
    MANAGE_NEWS_UPLOAD_IMAGE: 'Upload image',
    MANAGE_NEWS_CLEAR_SELECT: 'Clear',
    MANAGE_NEWS_SEARCH: 'Search',

    // MESSAGE
    MANAGE_NEWS_CONFIRM_DELETE: 'Are you sure you want to delete this data ?',
  }
};

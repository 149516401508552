import "antd/dist/antd.min.css";
// import "./App.css";
import "./components/Layout/Css/layout.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Index from "./route/Index";
import Admin from './route/Admin';
import LanguageContextProvider from "./components/ContextProvider/LanguageContextProvider";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/admin" component={Admin}></Route>
        <LanguageContextProvider>
          <Route path="/" component={Index} />
        </LanguageContextProvider>
      </Switch>
    </BrowserRouter>
  );
}

export default App;

import React from 'react';
import { Row, Col, Form, Input } from 'antd';
import { pdfjs, Document, Page } from 'react-pdf';
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
pdfjs.GlobalWorkerOptions.workerSrc =
  "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.10.377/build/pdf.worker.min.js";

export default function ManageRelationFileView(props) {

  const handleChangeFileUpload = (e) => {
    if (e.target.files.length !== 0) {
      props.handleFileUpload(e.target.files[0]);
    }
  };

  return (
    <Row className='admin-filed__list' gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
      <Col className='admin-filed__list__item' lg={6} md={24} sm={24} xs={24}>
        <label>{props.title} <span className={`admin-filed__list__item__required ${props.require ? 'active' : ''}`}>*</span></label>
      </Col>
      <Col className='admin-filed__list__item' lg={18} md={24} sm={24} xs={24}>
        <Form.Item
          name={props.name}
          rules={[{ required: props.require || false, message: `${props.warning}` }]}
          className={`admin-filed ${props.className || ''}`}
        >
          <div className='admin-input-upload-file admin-input-upload-view'>
            <div className='admin-input-upload-file-view'>
              <div>
                <Document
                  file={props.value}
                >
                  <Page width={200} pageNumber={1} />
                </Document>
              </div>
            </div>
            <div className='admin-input-upload-file-button'>
              <a href={props.value}
                target='_blank'
                rel='noreferrer noopener'
                className='admin-input-upload-file__browser admin-input-upload-file-view__button'
              >
                Xem
              </a>
              <a href={props.value}
                target='_blank'
                rel='noreferrer noopener'
                className='admin-input-upload-file__browser admin-input-upload-file-view__button'
              >
                Tải về
              </a>
              <label htmlFor='upload-file' className='admin-input-upload-file__browser'>
                Browse
                <Input type='file' id='upload-file' name='myFile' accept='.pdf' onChange={handleChangeFileUpload} />
              </label>
            </div>
          </div>
        </Form.Item>
      </Col>
    </Row>
  );
}

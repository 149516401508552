import React, { useEffect, useRef, useState } from 'react';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight, FaSearchMinus, FaSearchPlus, FaCompressArrowsAlt, FaUndoAlt, FaVolumeUp, FaVolumeMute } from 'react-icons/fa';
import HTMLFlipBook from 'react-pageflip';
import { Document, Page, pdfjs } from 'react-pdf';
import Draggable from 'react-draggable';
import useWindowWide from './usewidth';
import InputNumberPage from './InputNumberPage';
// import audio from '../../../asset/audio/Am-thanh-chuyen-trang.mp3';
import '../Css/styles_flipbook.css';


const zoomStep = 0.3;
const maxScale = 1.6;
const minScale = 1;
const defaultScale = minScale;
const defaultRotate = 0;

const FlipPages = (props) => {
  const { url_1, isOpenModal } = props;
  const book = useRef(null);
  // const audioBook = useRef(null);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [scale, setScale] = useState(() => {
    return defaultScale;
  });
  const [rotate, setRotate] = useState(() => {
    return defaultRotate;
  });
  const [onAudio, setOnAudio] = useState(true);
  const [positionItem, setPositionItem] = useState(null);
  const [array, setArray] = useState([]);
  const [width, setWidth] = useState(800);
  const [height, setHeight] = useState(500);
  const [urlImage, setUrlImage] = useState('');
  const [url,setUrl] = useState(()=>{
    return props.url_1
  })
  const { widthScreen, heightScreen } = useWindowWide();

  const handleUrl = () => {
    const arrUrl = url_1.split('/');
    const nameFile = arrUrl[arrUrl.length - 1].replace('.pdf', '').replace(' ', '');
    const url = `${process.env.REACT_APP_API_URL}/document/images/${nameFile}`;

    setUrlImage(url);
  };
  function onDocumentLoadSuccess({ numPages }) {
    setTotal(numPages);
  }
  const renderArr = () => {
    const arr = new Array(total);
    for (let index = 0; index < total; index++) {
      arr[index] = index + 1;
    }
    setArray(arr);
  };

  ////hàm thay nhập số trang cần tới

  const onChangePage = async (number) => {
    if (number >= total) {
      await book.current.pageFlip().flip(+total - 1);
    }
    if (number > 1 && number < total) {
      await book.current.pageFlip().flip(+number);
    }
    if (number === 1) {
      await book.current.pageFlip().flip(0);
    }
  };

  ////hàm tới trang kế tiếp
  const nextButtonClick = async () => {
    await book.current.pageFlip().flipNext();
  };
  ////hàm trở về trang trước đó
  const prevButtonClick = async () => {
    await book.current.pageFlip().flipPrev();
  };
  ////hàm trở tới trang cuối cùng
  const endButtonClick = async () => {
    await book.current.pageFlip().flip(total - 1);
  };
  ////hàm trở tới trang đầu tiên
  const startButtonClick = () => {
    book.current.pageFlip().flip(0);
  };

  const handleZoomIn = () => {
    const newScale = scale + zoomStep;
    setScale(newScale <= maxScale ? newScale : maxScale);
    setPositionItem(null);
  };
  const handleZoomOut = () => {
    if (scale === 1.2) {
      reset();
    }
    const newScale = scale - zoomStep;
    setScale(newScale >= minScale ? newScale : minScale);
    setPositionItem({ x: 0, y: 0 });
  };

  const reset = () => {
    setScale(defaultScale);
    setRotate(0);
    setPositionItem({ x: 0, y: 0 });
    // setVersion(version + 1)
  };

  const handleRotate = () => {
    const rotatex = rotate === 270 ? 0 : rotate + 90;
    setRotate(rotatex);
  };
  const handlePressKey = async (e) => {
    const keycurrent = e.key;
    if (keycurrent === 'ArrowRight') {
      nextButtonClick();
    }
    if (keycurrent === 'ArrowLeft') {
      prevButtonClick();
    }
  };

  const resetFlip = () => {
    
    const test = <HTMLFlipBook
    onFlip={(e) => {
      setPage(e.data);
    }}
    width={width}
    height={height}
    ref={book}
    showCover={true}
  >
    {array.map((item, index) => {
      return (
        <div key={index} className='page'>
          <img src={`${urlImage}/${index + 1}.png`} alt='person' />
        </div>
      );
    })}
  </HTMLFlipBook>
    return test;
  };

  useEffect(() => {
    window.addEventListener('keydown', handlePressKey);
    return () => window.removeEventListener('keydown', handlePressKey);
  }, []);
  // useEffect(() => {
  //   if (onAudio) {
  //     audioBook.current.play();
  //   }

  // }, [page]);
  console.log(isOpenModal);
  useEffect(() => {
  
    handleUrl();
  }, [url_1,isOpenModal]);
  useEffect(() => {
    renderArr();
  }, [total]);
  useEffect(() => {
    if (widthScreen < 600) {
      // setWidth(350);
      // setHeight(495);
      setWidth(Math.ceil((0.8 * widthScreen) ));
      setHeight(Math.ceil((1 * widthScreen) ));
    } 
     
    if (600 < widthScreen && widthScreen < 700) {
      setWidth(Math.ceil((0.7 * widthScreen) ));
      setHeight(Math.ceil((0.9 * widthScreen) ));
    } 
    if (700 < widthScreen && widthScreen < 800) {
      setWidth(Math.ceil((0.7 * widthScreen) ));
      setHeight(Math.ceil((0.9 * widthScreen) ));
    } 
    if (800 < widthScreen && widthScreen < 900) {
      setWidth(Math.ceil((0.7 * widthScreen) ));
      setHeight(Math.ceil((0.85 * widthScreen) ));
    } 
    if (900 < widthScreen && widthScreen < 1025) {
      // setWidth(Math.ceil(( widthScreen) / 1.8));
      // setHeight(Math.ceil(( widthScreen) / 1.3));
      setWidth(Math.ceil((0.7 * widthScreen) ));
      setHeight(Math.ceil((0.8 * widthScreen) ));
    } 
    if (1025 < widthScreen && widthScreen < 1100) {
      // setWidth(Math.ceil(( widthScreen) / 1.8));
      // setHeight(Math.ceil(( widthScreen) / 1.3));
      setWidth(Math.ceil((0.64 * widthScreen) ));
      setHeight(Math.ceil((0.7 * widthScreen) ));
    } 

    if (widthScreen > 1100 && widthScreen < 1200) {
      setWidth(Math.ceil((0.70 * widthScreen) / 2));
      setHeight(Math.ceil((0.90 * widthScreen) / 2));
    }
    if (widthScreen > 1200) {
      setWidth(Math.ceil((0.75 * widthScreen) / 2));
      setHeight(Math.ceil((0.95 * widthScreen) / 2));
    } 
    setUrl('')
  }, [widthScreen,isOpenModal]);
  useEffect(()=>{
    setUrl(props.url_1)
  },[width,height])
  const isDraggable = scale > 1;

  return (
    <>
 
       <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={
          <div
            style={{
              width: '100%',
              minHeight: 'calc(100vh - 140px)',
            }}
          >
            <div id='loading-wrapper'>
              <div id='loading-text'>LOADING</div>
              <div id='loading-content'></div>
            </div>
          </div>
        }
      >
        <Draggable disabled={!isDraggable} position={positionItem} bounds={{ top: -500, left: -500, right: 500, bottom: 500 }}>
          <div style={{ cursor: isDraggable ? 'move' : null }}>
            {isDraggable ? <div className='flip__book--overlay' style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}></div> : ''}
            <div
              className={page === 0 ? 'flip__book__flex flip__book__flex--17start' : page + 1 === total ? 'flip__book__flex flip__book__flex--17end' : 'flip__book__flex'}
              // className={'flip__book__flex'}
              style={{ padding: '50px 0', transform: `scale(${scale}) rotate(${rotate}deg)`, transition: '0.5s', height: '100vh' }}
              draggable='false'
            >
              { resetFlip()}

            </div>
          </div>
        </Draggable>
      </Document>
      <span onClick={() => book.current.pageFlip().flipNext()} className={'next button'}>
        <i className='fas fa-chevron-right'></i>
      </span>
      <span onClick={() => book.current.pageFlip().flipPrev()} className={'previous button'}>
        <i className='fas fa-chevron-left'></i>
      </span>
      <div className='main__page--mode'>
        <div className='flex main__page--mode-control'>
          <div
            className='main__page--mode-item'
            onClick={() => {
              startButtonClick();
            }}
          >
            <FaAngleDoubleLeft />
          </div>
          <div
            className='main__page--mode-item'
            onClick={() => {
              prevButtonClick();
            }}
          >
            <FaAngleLeft />
          </div>
          <div className='main__page--mode-item'>
            <div className='main__page--number-page'>{`${page + 1}/${total}`}</div>
          </div>
          <div
            className='main__page--mode-item'
            onKeyPress={() => nextButtonClick()}
            onClick={() => {
              nextButtonClick();
            }}
          >
            <FaAngleRight />
          </div>
          <div className='main__page--mode-item' onClick={() => endButtonClick()}>
            <FaAngleDoubleRight />
          </div>
          <div className='main__page--input'>
            <InputNumberPage loadNextImage={props.loadNextImage} number={page} total={total} onChangePage={onChangePage} setPageFlipage={setPage} currentLoad={props.currentLoad} />
          </div>
          <div
            className='main__page--mode-item'
            onClick={() => {
              handleZoomOut();
            }}
          >
            <FaSearchMinus />
          </div>
          <div
            className='main__page--mode-item'
            onClick={() => {
              handleZoomIn();
            }}
          >
            <FaSearchPlus />
          </div>
          <div
            className='main__page--mode-item'
            onClick={() => {
              reset();
            }}
          >
            <FaCompressArrowsAlt />
          </div>
          <div
            className='main__page--mode-item'
            onClick={() => {
              handleRotate();
            }}
          >
            <FaUndoAlt />
          </div>
        </div>
      </div>
    </>
  );
};

export default FlipPages;

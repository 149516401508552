export const LANGUAGE_STATE = {
  'vi': {
    MANAGE_TYPE_POST_INFOR: 'Loại bài giới thiệu',
    MANAGE_TYPE_POST_SEARCH_INFO: 'Thông tin tìm kiếm',
    MANAGE_TYPE_POST_TITLE: 'Tiêu đề Tiếng Việt',
    MANAGE_TYPE_POST_SLUG: 'Đường dẫn',
    MANAGE_TYPE_POST_TITLE_PLACEHOLDER: 'Tiêu đề Tiếng Việt (tối đa 200 ký tự)',
    MANAGE_TYPE_POST_TITLE_ENG: 'Tiêu đề Tiếng Anh',
    MANAGE_TYPE_POST_TITLE_ENG_PLACEHOLDER: 'Tiêu đề Tiếng Anh (tối đa 200 ký tự)',
    MANAGE_TYPE_POST_CATEGORY: 'Loại bài giới thiệu',
    MANAGE_TYPE_POST_CATEGORY_PLACEHOLDER: 'Chọn loại bài giới thiệu',
    MANAGE_TYPE_POST_STATUS: 'Trạng thái',
    MANAGE_TYPE_POST_STATUS_PLACEHOLDER: 'Chọn trạng thái',
    MANAGE_TYPE_POST_CREATED_DATE: 'Ngày tạo',
    MANAGE_TYPE_POST_CREATE_NEW_POST: 'Thêm loại bài giới thiệu',
    MANAGE_TYPE_POST_VIEW_TITLE: 'Xem nội dung loại bài giới thiệu',
    MANAGE_TYPE_POST_GENERAL_INFO: 'Thông tin chung',
    MANAGE_TYPE_POST_ADDITIONAL_INFO: 'Thông tin thêm Tiếng Việt',
    MANAGE_TYPE_POST_ADDITIONAL_INFO_ENG: 'Thông tin thêm Tiếng Anh',
    MANAGE_TYPE_POST_IMAGE: 'Hình ảnh',
    MANAGE_TYPE_POST_INTRO_STYLE_SHOW: "Kiểu hiển thị",
    MANAGE_TYPE_POST_INTRO_STYLE_SHOW_PLACEHOLDER: "Chọn kiểu hiển thị",

    // ACTION
    MANAGE_CANCEL: 'Huỷ',
    MANAGE_DELETE: 'Xóa',
    MANAGE_SAVE: 'Lưu',
    MANAGE_AGREE: 'Có',
    MANAGE_DISAGREE: 'Không',
    MANAGE_UPLOAD_IMAGE: 'Đăng ảnh',
    MANAGE_CLEAR_SELECT: 'Xóa lựa chọn',
    MANAGE_SEARCH: 'Tìm kiếm',

    // MESSAGE
    MANAGE_CONFIRM_DELETE: 'Bạn có chắc chắn muốn xóa dữ liệu này ?',
  },
  'en': {
    MANAGE_NEWS: 'Manage news',
    MANAGE_NEWS_SEARCH_INFO: 'Search information',
    MANAGE_NEWS_TITLE: 'Tiêu đề Tiếng Việt',
    MANAGE_NEWS_TITLE_PLACEHOLDER: 'Tiêu đề Tiếng Việt (tối đa 200 ký tự)',
    MANAGE_NEWS_TITLE_ENG: 'Tiêu đề Tiếng Anh',
    MANAGE_NEWS_TITLE_ENG_PLACEHOLDER: 'Tiêu đề Tiếng Anh (tối đa 200 ký tự)',
    MANAGE_NEWS_CATEGORY: 'Category',
    MANAGE_NEWS_CATEGORY_PLACEHOLDER: 'Select category',
    MANAGE_NEWS_GROUP: 'Group',
    MANAGE_NEWS_GROUP_PLACEHOLDER: 'Select group',
    MANAGE_NEWS_STATUS: 'Status',
    MANAGE_NEWS_STATUS_PLACEHOLDER: 'Select status',
    MANAGE_NEWS_CREATED_DATE: 'Created date',
    MANAGE_NEWS_CREATE_NEW_POST: 'Create new post',
    MANAGE_NEWS_VIEW_TITLE: 'Xem nội dung tin tức',
    MANAGE_NEWS_GENERAL_INFO: 'General information',
    MANAGE_NEWS_ADDITIONAL_INFO: 'Thông tin thêm Tiếng Việt',
    MANAGE_NEWS_ADDITIONAL_INFO_ENG: 'Thông tin thêm Tiếng Anh',
    MANAGE_NEWS_IMAGE: 'Image',

    // ACTION
    MANAGE_NEWS_CANCEL: 'Cancel',
    MANAGE_NEWS_DELETE: 'Delete',
    MANAGE_NEWS_SAVE: 'Save',
    MANAGE_NEWS_AGREE: 'Yes',
    MANAGE_NEWS_DISAGREE: 'No',
    MANAGE_NEWS_UPLOAD_IMAGE: 'Upload image',
    MANAGE_NEWS_CLEAR_SELECT: 'Clear',
    MANAGE_NEWS_SEARCH: 'Search',

    // MESSAGE
    MANAGE_NEWS_CONFIRM_DELETE: 'Are you sure you want to delete this data ?',
  }
};

import React from 'react'
import { Layout } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { LoginService } from '../Login/Services/LoginService';

const { Header } = Layout;
export default function AdminHeader() {
    const history = useHistory();
    const handleLogout = () => {
        LoginService.logout();
        history.push('/admin/login')
    }
    return (
        <Header className="admin-header">
            <div className="admin-header__account">
                <div className="admin-header__account__user">
                    <i className="fas fa-user-cog"></i>
                    <p>
                        Admin
                    </p>
                    <i className="fas fa-caret-down"></i>
                    <div className="admin-header__account__user__dropdown">
                        <Link to="/admin/changepassword" className="admin-header__account__user__dropdown__item">
                            Thay đổi mật khẩu
                        </Link>
                        <div className="admin-header__account__user__dropdown__item" onClick={handleLogout}>
                            Đăng xuất
                        </div>
                    </div>
                </div>
            </div>
        </Header>
    )
}

export const LANGUAGE_STATE = {
  'vi': {
    MANAGE_RECRUITMENT: 'Tuyển dụng',
    MANAGE_RECRUITMENT_SEARCH_INFO: 'Thông tin tìm kiếm',
    MANAGE_RECRUITMENT_SEARCH_TITLE: 'Chức danh',
    MANAGE_RECRUITMENT_SEARCH_TITLE_PLACEHOLDER: 'Chức danh',
    MANAGE_RECRUITMENT_SEARCH_TYPE: 'Phân loại',
    MANAGE_RECRUITMENT_SEARCH_TYPE_PLACEHOLDER: 'Phân loại',
    MANAGE_RECRUITMENT_CREATED_DATE: 'Ngày tạo',
    MANAGE_RECRUITMENT_SEARCH_FROM_DATE: 'Từ ngày',
    MANAGE_RECRUITMENT_SEARCH_TO_DATE: 'Đến ngày',

    MANAGE_RECRUITMENT_TITLE: 'Chức danh',
    MANAGE_RECRUITMENT_TITLE_PLACEHOLDER: 'Chức danh (tối đa 200 ký tự)',
    MANAGE_RECRUITMENT_TITLE_ENG: 'Chức danh tiếng Anh',
    MANAGE_RECRUITMENT_TITLE_ENG_PLACEHOLDER: 'Chức danh tiếng Anh (tối đa 200 ký tự)',
    MANAGE_RECRUITMENT_TYPE: 'Phân loại',
    MANAGE_RECRUITMENT_LANGUAGE: 'Ngôn ngữ',
    MANAGE_RECRUITMENT_ADDRESS: 'Địa chỉ',
    MANAGE_RECRUITMENT_QUANTITY: 'Số lượng tuyển',
    MANAGE_RECRUITMENT_TYPE_PLACEHOLDER: 'Chọn phân loại',
    MANAGE_RECRUITMENT_EXPIRED_DATE: 'Ngày hết hạn',
    MANAGE_RECRUITMENT_STATUS: 'Trạng thái',
    MANAGE_RECRUITMENT_STATUS_PLACEHOLDER: 'Chọn trạng thái',
    MANAGE_RECRUITMENT_DETAIL_INFO: 'Thông tin tuyển dụng chi tiết',
    MANAGE_RECRUITMENT_DETAIL_INFO_ENG: 'Thông tin tuyển dụng chi tiết tiếng Anh',

    MANAGE_RECRUITMENT_CREATE_NEW: 'Đăng tuyển dụng mới',
    MANAGE_RECRUITMENT_EDIT: 'Xem thông tin tuyển dụng',
    MANAGE_RECRUITMENT_GENERAL_INFO: 'Thông tin chung',
    MANAGE_RECRUITMENT_SELECT_DATE: 'Chọn ngày',
    MANAGE_RECRUITMENT_VIEW_DETAIL: 'Xem chi tiết',

    // ACTION
    MANAGE_RECRUITMENT_CANCEL: 'Huỷ',
    MANAGE_RECRUITMENT_DELETE: 'Xóa',
    MANAGE_RECRUITMENT_SAVE: 'Lưu',
    MANAGE_RECRUITMENT_AGREE: 'Có',
    MANAGE_RECRUITMENT_DISAGREE: 'Không',
    MANAGE_RECRUITMENT_CLEAR_SELECT: 'Xóa lựa chọn',
    MANAGE_RECRUITMENT_SEARCH: 'Tìm kiếm',

    // MESSAGE
    MANAGE_RECRUITMENT_CONFIRM_DELETE: 'Bạn có chắc chắn muốn xóa dữ liệu này ?',
  },
  'en': {

  }
};

export const NewsCommon = {
    title: 'Tin tức',
    path: '/news',
    sort: 'Sắp xếp theo'
};

export const NewsSort =
    [
        {
            value: 'newest',
            content: 'Tin mới nhất'
        },
        {
            value: 'oldest',
            content: 'Tin cũ nhất'
        },
        {
            value: 'popular',
            content: 'Tin phổ biến'
        },
    ];

// News Category
export const NewsCategoryData = [
    {
        id: 1,
        title: 'Thị trường sắt thép'
    },
    {
        id: 2,
        title: 'Thông tin công ty'
    }
];

export const NewsGroupData = [
    {
        id: 1,
        category: 2,
        title: 'Thông cáo báo chí'
    },
    {
        id: 2,
        category: 2,
        title: 'Tin tức hoạt động'
    },
    {
        id: 3,
        category: 2,
        title: 'Tin cổ phiếu'
    },
    {
        id: 4,
        category: 1,
        title: 'Thị trường sắt thép'
    }
];

export const searchModel = {
    News_Title: null,
    News_Category: null,
    Status: null,
    CreatedDate: null,
};




import React, { useState, useCallback } from 'react';
import { Button, Modal, Form, Tabs, Input, Col, Row } from 'antd';
import { postIntroInitData, postIntroFormInitialField } from './Model/ManageIntroPost.Model';
import ManagePostIntroStatusSelect from './Control/ManagePostIntroStatusSelect';
import MpsRichTextEditor from '../../MpsCotrols/MpsRichTextEditor/MpsRichTextEditor';
import { ManagePostIntroService } from './Service/ManagePostIntro.Service';
import { RESULT_CODES } from '../../../common/Constant.Common';
import { openSuccessNotification, openErrorNotification } from '../Common/MethodCommon';
import { ManagePostIntroContext, ManagePostIntroLanguageContext } from './ManageIntroPostPage';
import { momentDateTime } from '../Common/FormatCommon';
import MpsDatePicker from '../../MpsCotrols/MpsDatePicker/MpsDatePicker';
import ManagePostTypeSelect from '../ManageIntroTypePost/Control/ManagePostTypeSelect';
import MpsUploadImageIntroPost from '../../MpsCotrols/MpsUploadImage/MpsUploadImageIntroPost';
import MpsLanguageSelect from '../../MpsCotrols/MpsSelect/MpsLanguageSelect';
import Metadata from '../../Metatada/Metadata';

const { TabPane } = Tabs;

function ManageIntroPostAdd() {
  const language = React.useContext(ManagePostIntroLanguageContext);
  const handleSearch = React.useContext(ManagePostIntroContext);

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [postIntroData, setPostIntroData] = useState({ ...postIntroInitData });
  const [activeTabKey, setActiveTabKey] = useState('generalInfo');
  const [metadata, setMetadata] = useState([]);

  const showModal = () => {
    form.setFieldsValue({ CreatedDate: momentDateTime(Date.now()) });
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };
  
  const handleChangeMetadata = (values) => {
    setMetadata(values);
  };

  const handleFininsh = () => {
    form.validateFields().then(values => {

      const formData = new FormData();

      // Append images to form data
      postIntroData.Post_Intro_Image_Pc.forEach(image => {
        formData.append('Post_Intro_Image_Pc', image.originFileObj);
      });
      postIntroData.Post_Intro_Image_Mobile.forEach(image => {
        formData.append('Post_Intro_Image_Mobile', image.originFileObj);
      });
      postIntroData.Post_Intro_Image_Tablet.forEach(image => {
        formData.append('Post_Intro_Image_Tablet', image.originFileObj);
      });

      // Append values to form data
      const formObjectValues = Object.entries(values);
      formObjectValues.forEach(value => {
        formData.append(value[0], value[1]);
      });
      formData.append('Post_Intro_AdditionalInfo', postIntroData.Post_Intro_AdditionalInfo);
      formData.append('Post_Intro_AdditionalInfo_Eng', postIntroData.Post_Intro_AdditionalInfo_Eng);
      formData.append('Post_Intro_Content', postIntroData.Post_Intro_Content);
      formData.append('Post_Intro_Content_Eng', postIntroData.Post_Intro_Content_Eng);
      // console.log("check formdata", formData)
      const filterMetadata = metadata.filter(
        (tag) => tag.Meta_Key && tag.Meta_Content
      );
      formData.append("Metadata", JSON.stringify(filterMetadata));
      return ManagePostIntroService.create(formData);
    })
      .then(result => {
        if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);

        handleSearch();
        resetField();
        hideModal();
        setActiveTabKey('generalInfo');
        openSuccessNotification({ title: 'Thông báo', message: 'Thêm mới thành công' });
      })
      .catch((error) => {
        if (error !== RESULT_CODES.SUCCESS)
          return openErrorNotification({ title: 'Thông báo', message: 'Thêm mới thất bại' });
      });
  };

  const handleImagesPcChange = useCallback((images) => {
    setPostIntroData(prevState => ({ ...prevState, Post_Intro_Image_Pc: images }));
  }, []);

  const handleImagesTabletChange = useCallback((images) => {
    setPostIntroData(prevState => ({ ...prevState, Post_Intro_Image_Tablet: images }));
  }, []);

  const handleImagesMobileChange = useCallback((images) => {
    setPostIntroData(prevState => ({ ...prevState, Post_Intro_Image_Mobile: images }));
  }, []);

  const handleAdditionalInfoChange = useCallback((text) => {
    setPostIntroData(prevState => ({ ...prevState, Post_Intro_AdditionalInfo: text }));
  }, []);

  const handleAdditionalInfoEngChange = useCallback((text) => {
    setPostIntroData(prevState => ({ ...prevState, Post_Intro_AdditionalInfo_Eng: text }));
  }, []);

  const resetField = () => {
    form.resetFields();
    setPostIntroData({ ...postIntroInitData });
  };
  const handleMainContentChange = useCallback((text) => {
    setPostIntroData(prevState => ({ ...prevState, Post_Intro_Content: text }));
  }, [])

  const handleMainContentEngChange = useCallback((text) => {
    setPostIntroData(prevState => ({ ...prevState, Post_Intro_Content_Eng: text }));
  }, [])
  return (
    <>
      <Button onClick={showModal} className='admin-button__main admin-button__download-pdf'>
        <i className='fas fa-plus'></i>
        {language.MANAGE_NEWS_CREATE_NEW_POST}
      </Button>
      <Modal
        title={language.MANAGE_NEWS_CREATE_NEW_POST}
        visible={visible}
        width='80%'
        className='manage-news-modal admin-modal'
        onCancel={hideModal}
        footer={
          [
            <Button
              key='submit'
              type='secondary'
              onClick={hideModal}
              className='admin-button__main admin-button__delete'
            >
              {language.MANAGE_NEWS_CANCEL}
            </Button>,
            <Button
              onClick={handleFininsh}
              key='cancel'
              type='primary'
              className='admin-button__main admin-button__save'
            >
              {language.MANAGE_NEWS_SAVE}
            </Button>
          ]
        }
      >
        <Tabs className='manage-news-tabs' activeKey={activeTabKey} onChange={handleTabChange}>
          <TabPane tab={language.MANAGE_NEWS_GENERAL_INFO} key='generalInfo'>
            <Form form={form} requiredMark={false} id='manage-post-add-new-form'
              name='manage-news-form'
              className='manage-news-form'
              labelCol={{ lg: { span: 3 }, md: { span: 24 } }}
              wrapperCol={{ lg: { span: 8 }, md: { span: 24 } }}
            >
              <Form.Item
                colon={false}
                label={language.MANAGE_POST_INTRO_TITLE}
                labelAlign='left'
                name={postIntroFormInitialField.Post_Intro_Title.name}
                rules={[{ required: postIntroFormInitialField.Post_Intro_Title.required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_POST_INTRO_TITLE_PLACEHOLDER} maxLength={200} />
              </Form.Item>
              {/* <Form.Item
                colon={false}
                label={language.MANAGE_POST_INTRO_TITLE_ENG}
                labelAlign='left'
                name={postIntroFormInitialField.Post_Intro_Title_Eng.name}
                rules={[{ required: postIntroFormInitialField.Post_Intro_Title_Eng.required || false, message: '' }]}
              >
                <Input.TextArea placeholder={language.MANAGE_POST_INTRO_TITLE_ENG_PLACEHOLDER} maxLength={200} />
              </Form.Item> */}
              <ManagePostTypeSelect status={false}
                name={postIntroFormInitialField.Post_Intro_Type.name}
                label={language.MANAGE_POST_INTRO_TYPE}
                placeholder={language.MANAGE_POST_INTRO_TYPE_PLACEHOLDER}
                required={postIntroFormInitialField.Post_Intro_Type.required}
                form={form} />
              <MpsLanguageSelect
                name={postIntroFormInitialField.Language.name}
                label={language.MANAGE_POST_LANGUAGE}
                required={postIntroFormInitialField.Language.required}
                hasInitialValue={true}
                form={form}
              />
              <ManagePostIntroStatusSelect
                name={postIntroFormInitialField.Status.name}
                placeholder={language.MANAGE_NEWS_GROUP_PLACEHOLDER}
                label={language.MANAGE_NEWS_STATUS}
                hasInitialValue={true}
                required={postIntroFormInitialField.Status.required}
                form={form}
              />
              <MpsDatePicker
                label={language.MANAGE_NEWS_CREATED_DATE}
                name={postIntroFormInitialField.CreatedDate.name}
                value={Date.now()}
                required={postIntroFormInitialField.CreatedDate.required}
                disabled={true}
              />
            </Form>
          </TabPane>
          <TabPane tab={language.MANAGE_NEWS_IMAGE} key='imageInfo'>
            <div style={{ display: 'flex', marginTop: '50px', textAlign: 'center' }}>
              <MpsUploadImageIntroPost onChange={handleImagesPcChange} images={postIntroData.Post_Intro_Image_Pc} max={1} for='PC' />
              <MpsUploadImageIntroPost onChange={handleImagesTabletChange} images={postIntroData.Post_Intro_Image_Tablet} max={1} for='Tablet' />
              <MpsUploadImageIntroPost onChange={handleImagesMobileChange} images={postIntroData.Post_Intro_Image_Mobile} max={1} for='Mobile' />
            </div>
          </TabPane>
          <TabPane tab={language.MANAGE_POST_INTRO_CONTENT_TITLE} key='maincontent_add'>
            <Row>
              <Col span={24}>
                <Form.Item  >{language.MANAGE_POST_INTRO_CONTENT}</Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <MpsRichTextEditor onChange={handleMainContentChange} data={postIntroData.Post_Intro_Content} />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
              <Col span={24}>
                <Form.Item  >{language.MANAGE_POST_INTRO_CONTENT_ENG}</Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item  >
                  <MpsRichTextEditor onChange={handleMainContentEngChange} data={postIntroData.Post_Intro_Content_Eng} />
                </Form.Item>
              </Col>
            </Row> */}
          </TabPane>
          <TabPane tab={language.MANAGE_NEWS_ADDITIONAL_INFO} key='additionalInfo_add'>
            <MpsRichTextEditor onChange={handleAdditionalInfoChange} data={postIntroData.Post_Intro_AdditionalInfo} />
          </TabPane>

          {/* <TabPane tab={language.MANAGE_NEWS_ADDITIONAL_INFO_ENG} key='additionalInfo_eng_add'>
            <MpsRichTextEditor onChange={handleAdditionalInfoEngChange} data={postIntroData.Post_Intro_AdditionalInfo_Eng} />
          </TabPane> */}
          <TabPane tab="Metadata" key="metadata">
            <Metadata metadata={metadata} onChange={handleChangeMetadata} />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
}

export default React.memo(ManageIntroPostAdd);

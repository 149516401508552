import React, { useEffect, useMemo } from 'react';
import Breadcrum from '../Layout/Breadcrum';
import Helmet from '../Layout/Helmet';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import background from '../../asset/img/img-9.jpg';
import IntroButton from './Control/IntroButton';
import { Link } from 'react-router-dom';
import { IntroAboutCommon, IntroCommon } from './Model/IntroModel';

export default function IntroAboutPage(props) {
  const { language, dataSource, typePostData } = props
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const processDataSource = useMemo(() => {
    if (dataSource.length === 0) {
      return {
        title: "",
        additional: "",
        content: ""
      }
    }
    return {
      title: dataSource[0]?.Post_Intro_Title,
      additional: dataSource[0]?.Post_Intro_AdditionalInfo,
      content: dataSource[0]?.Post_Intro_Content,
    }
  }, [language, dataSource, typePostData])
  const processTypePostData = useMemo(() => {

  })
  return (
    <Helmet title={language === "vi" ? typePostData[0]?.Type_Post_Title : typePostData[0]?.Type_Post_Title_Eng}>
      <Breadcrum data={[
        {
          title: IntroCommon[props.language].title,
          path: `/${props.language}${IntroCommon[props.language].path}`
        },
        language === "vi" ? typePostData[0]?.Type_Post_Title : typePostData[0]?.Type_Post_Title_Eng]}
      ></Breadcrum>
      <TitleBreadcrum title={language === "vi" ? typePostData[0]?.Type_Post_Title : typePostData[0]?.Type_Post_Title_Eng}></TitleBreadcrum>
      <div className="intro-generation" style={{ backgroundImage: `url(${background})` }}>
        <div className="intro-generation__overlay"></div>
        <div className="container">
          <div className="intro-about__heading">
            <div className="intro-about__heading__item">
              <h2 className="intro-about__heading__title">
                {processDataSource.title}
              </h2>
              <div className='intro-about__heading__main' dangerouslySetInnerHTML={{
                __html: processDataSource.additional
              }} >
              </div>
            </div>
            <div className="intro-about__heading__item intro-about__heading-fake">
            </div>
          </div>
          <div className="intro-about__content">
            <div className="intro-about__content__fake-bg-1"></div>
            <div className="intro-about__content__fake-bg-2"></div>
            <div className="intro-about__content__img" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL + "/" + dataSource[0]?.Post_Intro_Image_Pc[0]?.url})` }}>
            </div>
            <p dangerouslySetInnerHTML={{
              __html: processDataSource.content
            }} >
            </p>
          </div>
          <div className="intro__main__button-container">
            <Link to={`/${props.language}/intro`}>
              <IntroButton title={IntroCommon[props.language].backIntro} color="white"></IntroButton>
            </Link>
          </div>
        </div>
      </div>
    </Helmet>
  );
}
